import FileSaver from 'file-saver';
import blob from 'blob';

function handleErrors(response) {
    if (!response.ok) {
        throw Error(response.statusText);
    }
    return response;
}

export const fetchPortfolioPerformance = (id, token) => {
  return dispatch => {
  dispatch({ type: 'FETCH_PORTFOLIO_PERFORMANCE_REQUEST' });
    const headers = {"Content-Type": "application/json", "Authorization": "JWT " + token};
    return fetch(process.env.REACT_APP_HTTP_PROTOCOL + "://" + process.env.REACT_APP_API_IP + "/api/results/performanceportfolio/" + id + "/", {headers, })
      .then(handleErrors)
      .then(res => res.json())
      .then(portfolioperformance => {
        return dispatch({
          type: 'FETCH_PORTFOLIO_PERFORMANCE_SUCCESS',
          portfolioperformance
        })
      })
      .catch(error => {
        return dispatch({ type: 'FETCH_PORTFOLIO_PERFORMANCE_FAILURE', error_type: error, error: true })
      })
  }
}

export const createXLSXPortfolioPerformance = (text, token) => {
  return dispatch => {
    const headers = {"Content-Type": "application/json", "Authorization": "JWT " + token};
    let body = JSON.stringify(text);
    return fetch(process.env.REACT_APP_HTTP_PROTOCOL + "://" + process.env.REACT_APP_API_IP + "/api/results/performanceportfolio/", {headers, method: "POST", body})
      .then(res => res.blob())
      .then(portfolioperformance => {
        FileSaver.saveAs(portfolioperformance, 'nameFile.xlsx');
    })
  }
}

// Import React & Redux components
import React from 'react';
import { connect } from 'react-redux';
import {
  Card,
  Form,
  Col,
  Row,
  Button,
  Nav,
} from 'react-bootstrap';
import { Redirect } from 'react-router-dom';
import ReactTable from 'react-table';

import DatePicker from 'react-datepicker';

// Import layout and helper functions/templates
import Header from './Header';
import Notification from './Notification';
import {
  createLoadingSelector,
  createErrorMessageSelector,
} from './Helpers';

// Import data sources
import {
  scenarios,
  inputs,
} from '../actions';

const styledButton = (props) => (
  <button type="button" {...props} className="btn btn-outline-primary-page-selection">
    {props.children}
  </button>
);

class CreateScenario extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // Dates also initialised in FormSimulationSetup, together with other parameters
      assessmentDate: new Date(),
      horizonDate: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
      fundingRollOverDate: new Date(),
      newOriginations: false,
      targetPortfolio: false,
      selectedScenario: undefined,
      pageView: 'select',
      renderHelp: false,
      helpText: undefined,
    };
    this.saveSettings = this.saveSettings.bind(this);
    this.changePageViewToSelect = this.changePageViewToSelect.bind(this);
    this.changePageViewToCreate = this.changePageViewToCreate.bind(this);
    this.onChangeAssessmentDate = this.onChangeAssessmentDate.bind(this);
    this.onChangeFundingRollOverDate = this.onChangeFundingRollOverDate.bind(this);
    this.onChangeHorizonDate = this.onChangeHorizonDate.bind(this);
    this.onTickNewOriginations = this.onTickNewOriginations.bind(this);
    this.onTickTargetPortfolio = this.onTickTargetPortfolio.bind(this);
    this.renderHelp = this.renderHelp.bind(this);
  }

  // Can probably do without onChangeStartDate and onChangeHorizonDate. Leaving them for now
  onChangeAssessmentDate = (assessmentDate) => {
    this.setState({ assessmentDate });
  }

  onChangeHorizonDate = (horizonDate) => {
    this.setState({ horizonDate });
  }

  onChangeFundingRollOverDate = (fundingRollOverDate) => {
    this.setState({ fundingRollOverDate });
  }

  renderHelp = (content) => {
    if (this.state.renderHelp) {
      this.setState({ renderHelp: false, helpText: undefined });
    } else {
      this.setState({ renderHelp: true, helpText: content });
    }
  }

  onTickNewOriginations = () => {
    if (this.state.newOriginations === true) {
      this.setState({ newOriginations: false, targetPortfolio: false });
    } else {
      this.setState({ newOriginations: true, targetPortfolio: false });
    }
  }

  onTickTargetPortfolio = () => {
    if (this.state.targetPortfolio === true) {
      this.setState({ targetPortfolio: false, newOriginations: false });
    } else {
      this.setState({ targetPortfolio: true, newOriginations: false });
    }
  }

  saveSettings = (values) => {
    values.preventDefault();
    const form = values.currentTarget;
    if (form.checkValidity() === false) {
      values.stopPropagation();
    }
    const values2 = {};
    // Fix this state mutation!
    const { assessmentDate, horizonDate, fundingRollOverDate } = this.state;
    assessmentDate.setUTCHours(0);
    assessmentDate.setUTCMinutes(0);
    assessmentDate.setUTCSeconds(0);
    horizonDate.setUTCHours(0);
    horizonDate.setUTCMinutes(0);
    horizonDate.setUTCSeconds(0);
    fundingRollOverDate.setUTCHours(0);
    fundingRollOverDate.setUTCMinutes(0);
    fundingRollOverDate.setUTCSeconds(0);

    if (parseInt(this.refs.numberOfTrials.value, 10) > 1000000) {
      this.renderHelp({
        header: 'Trial version limitation',
        body: 'As you are using a trial license, some of the features are limited. Your trial count has been adjusted to 1,000,000.',
      });
    }
    values2.scenarioName = this.refs.scenarioName.value;
    values2.timeStep = this.refs.timestep.value;
    values2.numberOfTrials = Math.min(1000000, parseInt(this.refs.numberOfTrials.value, 10));
    values2.numberOfInterestRateTrials = Math.min(
      10000,
      parseInt(this.refs.numberOfInterestRateTrials.value, 10),
    );
    values2.assessmentDate = assessmentDate.toISOString();
    values2.assesmentDate = values2.assessmentDate;
    values2.horizonDate = horizonDate.toISOString();
    values2.fundingRollOverDate = fundingRollOverDate.toISOString();
    if (this.state.newOriginations) {
      values2.newOriginFlag = 1;
      values2.newOriginPerc = parseFloat(this.refs.newOriginPerc.value) / 100;
      values2.newOriginEndDate = this.refs.newOriginEndDate.valueAsDate.toISOString();
    } else {
      values2.newOriginFlag = 0;
    }
    if (this.state.targetPortfolio) {
      values2.targetPortfolioFlag = 1;
      if (this.refs.targetPortfolioDate.valueAsDate !== null
        && this.refs.targetPortfolioAssets.value !== null
        && this.refs.targetPortfolioMargin.value !== null
      ) {
        values2.targetPortfolioDate = this.refs.targetPortfolioDate.valueAsDate.toISOString();
        values2.targetPortfolioAssets = parseFloat(this.refs.targetPortfolioAssets.value) / 100;
        values2.targetPortfolioMargin = parseFloat(this.refs.targetPortfolioMargin.value) / 100;
      } else {
        values2.targetPortfolioFlag = 0;
      }
    } else {
      values2.targetPortfolioFlag = 0;
    }
    values2.curveSetID = parseInt(this.refs.discountCurve.value, 10);
    values2.transition_matrix = parseInt(this.refs.transitionMatrix.value, 10);
    this.props.addScenario(values2, sessionStorage.getItem('jwtToken'));
  }

  deleteScenario = (id) => {
    this.props.deleteScenario(id, sessionStorage.getItem('jwtToken'));
  }

  componentDidMount() {
    this.props.fetchScenarios(sessionStorage.getItem('jwtToken'));
    this.props.fetchTransitionMatrices(sessionStorage.getItem('jwtToken'));
    this.props.fetchRiskFreeCurves(sessionStorage.getItem('jwtToken'));
  }

  toggleItem = (scenarioID) => {
    this.setState({
      selectedScenario: parseInt(scenarioID, 10),
    });
  }

  trashcanColumn = {
    accessor: 'delete_scenario',
    Cell: ({ original }) => (
      <i
        className="fa fa-trash"
        style={{ display: 'inline-block', width: '100%', textAlign: 'center' }}
        onClick={() => this.deleteScenario(original.scenarioID)}
      >
      </i>
    ),
    header: 'Delete Scenario',
    sortable: false,
    width: 45,
  };

  changePageViewToSelect = () => {
    this.setState({
      pageView: 'select',
    });
  }

  changePageViewToCreate = () => {
    this.setState({
      pageView: 'create',
    });
  }

  render() {
    const curveSets = [];
    /* if (this.props.isError !== null) {
      return (
        <div>
          <Header title={"Scenario Creation"} />
          <Notification heading={'Settings'} body={'Error Loading Data'}/>
        </div>
      )
    } */

    const showNewOriginationOptions = this.state.newOriginations === true;
    const showTargetPortfolio = this.state.targetPortfolio === true;
    const showScenarioCreationForm = this.props.transitionMatrix.isLoading !== true
      && this.props.riskFreeCurves.isLoading !== true
      && this.props.transitionMatrix.data !== undefined
      && this.props.riskFreeCurves.data !== undefined;
    const renderExistingScenarios = this.state.pageView === 'select';
    const renderCreateScenario = this.state.pageView === 'create';

    if (this.props.scenarios.isLoading === true
      || this.props.transitionMatrix.isLoading === true
      || this.props.riskFreeCurves.isLoading === true
    ) {
      return (
        <div>
          <Header title={'Step 2: Select Scenario'} />
          <Notification heading={'Select Scenario'} body={'Loading Data'}/>
        </div>
      );
    }

    if (this.props.scenarios.error === true) {
      if (this.props.scenarios.error_text === 'Unauthorized') {
        return <Redirect to={'/login_screen'} />;
      }
    }

    if (this.props.riskFreeCurves !== undefined
      && this.props.riskFreeCurves.data !== undefined
      && this.props.riskFreeCurves.data.length > 0) {
      let curveSetNames = [];
      this.props.riskFreeCurves.data.forEach((elem) => {
        curveSetNames.push(elem.curveSetName);
      });
      curveSetNames = [...new Set(curveSetNames)];
      curveSetNames.forEach((elem) => {
        const id = this.props.riskFreeCurves.data.filter((curve) => (
          curve.curveSetName === elem
        ))[0].curveSetID;
        curveSets.push({ name: elem, id });
      });
    }

    const scenarioCreationForm = () => (
      <div>
        <Form onSubmit={this.saveSettings}>
          <Form.Group as={Row} controlId="scenarioCreation.scenarioName">
            <Form.Label column sm={3}>Scenario Name</Form.Label>
            <Col sm={9}>
              <Form.Control required type="text" ref="scenarioName" />
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="scenarioCreation.assessmentDate">
            <Form.Label column sm={3}>Assessment Date</Form.Label>
            <Col sm={9}>
              <DatePicker
                todayButton={'Today'}
                dateFormat='yyyy-MM-dd'
                selected={this.state.assessmentDate}
                onChange={this.onChangeAssessmentDate}
                className={'datepicker-right-align'}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId='scenarioCreation.horizonDate'>
            <Form.Label column sm={3}>Horizon Date</Form.Label>
            <Col sm={9}>
              <DatePicker
                todayButton={'Today'}
                dateFormat='yyyy-MM-dd'
                selected={this.state.horizonDate}
                onChange={this.onChangeHorizonDate}
                className={'datepicker-right-align'}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="scenarioCreation.timeStep">
            <Form.Label column sm={3}>Timestep</Form.Label>
            <Col sm={9}>
              <Form.Control as="select" ref='timestep'>
                <option value="year">1 year</option>
                <option value="month">1 month</option>
                {// <option value="week">1 week</option>
                }
                {// <option value="day">1 day</option>
                }
              </Form.Control>
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="scenarioCreation.numberOfTrials">
            <Form.Label column sm={3}>Number of Trials</Form.Label>
            <Col sm={9}>
              <Form.Control required type="number" ref="numberOfTrials" defaultValue="1000000" />
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="scenarioCreation.numberOfInterestRateTrials">
            <Form.Label column sm={3}>Number of Interest Rate Trials</Form.Label>
            <Col sm={9}>
              <Form.Control required type="number" ref="numberOfInterestRateTrials" defaultValue="1000" />
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="scenarioCreation.fundingRollOverDate">
            <Form.Label column sm={3}>Funding Roll-over Date</Form.Label>
            <Col sm={9}>
              <DatePicker
                todayButton={'Today'}
                dateFormat='yyyy-MM-dd'
                selected={this.state.fundingRollOverDate}
                onChange={this.onChangeFundingRollOverDate}
                className={'datepicker-right-align'}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="scenarioCreation.transitionMatrix">
            <Form.Label column sm={3}>Transition Matrix</Form.Label>
            <Col sm={9}>
              <Form.Control as="select" ref='transitionMatrix'>
                {this.props.transitionMatrix.data.map((elem) => (
                  <option
                    key={elem.transition_matrix_id}
                    value={elem.transition_matrix_id}>
                    {elem.name}
                  </option>
                ))}
              </Form.Control>
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="scenarioCreation.discountCurve">
            <Form.Label column sm={3}>Discount Curve</Form.Label>
            <Col sm={9}>
              <Form.Control as="select" ref='discountCurve'>
                { curveSets.map((elem) => (
                  <option key={elem.id} value={elem.id}>{elem.name}</option>
                ))}
              </Form.Control>
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="scenarioCreation.targetPortfolio">
            <Form.Label column sm={3}>Originate to Target Portfolio</Form.Label>
            <Col sm={9}>
              <Form.Check
                type="checkbox"
                ref="targetPortfolio"
                value={this.state.targetPortfolio}
                onChange={this.onTickTargetPortfolio}
              />
            </Col>
          </Form.Group>
          <Button variant="outline-primary" fill="false" type="submit" disabled={false}>
            Save
          </Button>
        </Form>
      </div>
    );

    return (
      <div>
        { this.state.renderHelp === true && (
          <div className="overlay-help" onClick={() => this.renderHelp(undefined)}>
            <div className="overlay-help-card">
              <Card className="mt-4">
                <Card.Header><div className="fa-pull-left">{this.state.helpText.header}</div>
                <i className="fa fa-window-close fa-pull-right" onClick={() => this.renderHelp(undefined)}></i></Card.Header>
                <Card.Body>{this.state.helpText.body}</Card.Body>
              </Card>
            </div>
          </div>
        )}
        <Header
          title={'Step 2: Select Scenario'}
          subtitle={
            { startText: 'Specify your assumptions and settings for the simulation you are going to run. Either select an existing scenario or create a new scenario.' }}
        />

        <Nav variant="pills" defaultActiveKey="simple" fill="true" className="mt-4">
          <Nav.Item style={{ marginRight: '1.5em' }}>
            <Nav.Link className="main-selector" eventKey="simple" onClick={this.changePageViewToSelect}>Select Existing Scenario</Nav.Link>
          </Nav.Item>
          <Nav.Item style={{ marginLeft: '1.5em' }}>
            <Nav.Link className="main-selector" eventKey="detailed" onClick={this.changePageViewToCreate}>Create New Scenario</Nav.Link>
          </Nav.Item>
        </Nav>

        { (showScenarioCreationForm && renderCreateScenario) && (
          <Card className="mt-4">
            <Card.Header>Settings and Assumptions</Card.Header>
            <Card.Body>
              {scenarioCreationForm()}
            </Card.Body>
          </Card>
        )}

        { showNewOriginationOptions && (
          <div>
          <Card className="mt-4">
            <Card.Header>New Origination Characteristics</Card.Header>
            <Card.Body>
              <Form>
                <Form.Group controlId="newOrigination.Settings">
                  <Form.Control type="date" ref="newOriginEndDate"/>
                  <Form.Control type="text" ref="newOriginPerc" placeholder="New origination percentage (in 5.01 of 5.01%)"/>
                </Form.Group>
              </Form>
            </Card.Body>
          </Card>
          </div>
        )}

        { showTargetPortfolio && (
          <div>
          <Card className="mt-4">
            <Card.Header>Target Portfolio Characteristics</Card.Header>
            <Card.Body>
              <Form>
                <Form.Group controlId="targetPortfolio.Settings">
                  <Form.Control required type="date" ref="targetPortfolioDate"/>
                  <Form.Control required type="text" ref="targetPortfolioAssets" placeholder="Target portfolio size as percentage of current portfolio size (104 for 104%)"/>
                  <Form.Control required type="text" ref="targetPortfolioMargin" placeholder="Net Interest Margin at target date (in 2.01 of 2.01%)"/>
                </Form.Group>
              </Form>
            </Card.Body>
          </Card>
          </div>
        )}

        { renderExistingScenarios && (
          <Card className="mt-4">
            <Card.Header>Existing Scenarios</Card.Header>
            <Card.Body>
              <ReactTable
                data={this.props.scenarios.data}
                sorted={[{
                  id: 'scenarioID',
                  desc: true,
                }]}
                ref={(r) => {
                  this.scenarioTable = r;
                }}
                columns={[
                  {
                    accessor: 'radio',
                    Cell: ({ original }) => (
                      <div style={{ textAlign: 'center' }}>
                        {// <Form.Check
                          // type="radio"
                          // key={original.scenarioID}
                          // value={original.scenarioID}
                          // onChange={() => this.toggleItem(original.scenarioID)}
                          // checked={this.state.selectedScenario === original.scenarioID} />
                        }
                        <Form.Check type="radio" key={original.scenarioID} value={original.scenarioID} onChange={() => this.props.updateFunction(original.scenarioID)} checked={this.props.selectedScenario === original.scenarioID} />
                      </div>
                    ),
                    sortable: false,
                    width: 45,
                  },
                  {
                    accessor: 'scenarioName',
                    Header: 'Scenario Name',
                  },
                  {
                    accessor: 'assesmentDate',
                    Header: 'Assesment Date',
                    Cell: (row) => (
                      <div style={{ textAlign: 'right' }}>{row.value === null ? 'N/A' : new Date(row.value).toLocaleString('en-GB', { year: 'numeric', month: '2-digit', day: '2-digit' })}</div>
                    ),
                  },
                  {
                    accessor: 'horizonDate',
                    Header: 'Horizon Date',
                    Cell: (row) => (
                      <div style={{ textAlign: 'right' }}>{row.value === null ? 'N/A' : new Date(row.value).toLocaleString('en-GB', { year: 'numeric', month: '2-digit', day: '2-digit' })}</div>
                    ),
                  },
                  {
                    accessor: 'timeStep',
                    Header: 'Time Step',
                    Cell: (row) => (
                      <div style={{ textAlign: 'right' }}>{row.value}</div>
                    ),
                  },
                  {
                    accessor: 'numberOfTrials',
                    Header: 'Number of Trials',
                    Cell: (row) => (
                      <div style={{ textAlign: 'right' }}>{row.value}</div>
                    ),
                  },
                  {
                    accessor: 'numberOfInterestRateTrials',
                    Header: 'Number of Rate Trials',
                    Cell: (row) => (
                      <div style={{ textAlign: 'right' }}>{row.value}</div>
                    ),
                  },
                  {
                    accessor: 'targetPortfolioMargin',
                    Header: 'TP Margin',
                    Cell: (row) => (
                      <div style={{ textAlign: 'right' }}>{row.value === null ? 'N/A' : `${(row.value * 100).toFixed(2)}%`}</div>
                    ),
                  },
                  {
                    accessor: 'targetPortfolioAssets',
                    Header: 'TP Growth',
                    Cell: (row) => (
                      <div style={{ textAlign: 'right' }}>{row.value === null ? 'N/A' : `${(row.value * 100).toFixed(1)}%`}</div>
                    ),
                  },
                  {
                    accessor: 'targetPortfolioDate',
                    Header: 'TP Date',
                    Cell: (row) => (
                      <div style={{ textAlign: 'right' }}>{row.value === null ? 'N/A' : new Date(row.value).toLocaleString('en-GB', { year: 'numeric', month: '2-digit', day: '2-digit' })}</div>
                    ),
                  },
                  this.trashcanColumn,
                ]}
                defaultPageSize={10}
                keyField={'id'}
                className="-striped -highlight"
                PreviousComponent={styledButton}
                NextComponent={styledButton}
              />
            </Card.Body>
          </Card>
        )}
      </div>
    );
  }
}

const loadingSelector = createLoadingSelector(['FETCH_SCENARIOS', 'FETCH_TRANSITION_MATRICES']);
const errorSelector = createErrorMessageSelector(['FETCH_SCENARIOS', 'FETCH_TRANSITION_MATRICES']);

const mapStateToProps = (state) => (
  {
    scenarios: state.scenarios,
    transitionMatrix: state.transitionMatrix,
    riskFreeCurves: state.riskFreeCurves,
    isFetching: loadingSelector(state),
    isError: errorSelector(state),
    loading: state.loading,
    error: state.error,
  }
);

const mapDispatchToProps = (dispatch) => (
  {
    fetchTransitionMatrices: (token) => {
      dispatch(inputs.fetchTransitionMatrices(token));
    },
    fetchRiskFreeCurves: (token) => {
      dispatch(inputs.fetchRiskFreeCurves(token));
    },
    fetchScenarios: (token) => {
      dispatch(scenarios.fetchScenarios(token));
    },
    addScenario: (text, token) => {
      dispatch(scenarios.addScenario(text, token));
    },
    deleteScenario: (id, token) => {
      dispatch(scenarios.deleteScenario(id, token));
    },
  }
);

export default connect(mapStateToProps, mapDispatchToProps)(CreateScenario);

const initialState = {data: {}, isLoading: true};

export function counterparties(state=initialState, action) {
  switch(action.type) {
    case 'FETCH_COUNTERPARTIES_SUCCESS':
      return {data: action.counterparties, isLoading: false};

    case 'FETCH_COUNTERPARTIES_FAILURE':
      return {data: action.counterparties, error: true};

    default:
      return state;
  }
}

export function counterpartydata(state=initialState, action) {
  switch(action.type) {
    case 'FETCH_COUNTERPARTY_DATA_SUCCESS':
      return {data: action.counterpartydata, isLoading: false};

    case 'FETCH_COUNTERPARTY_DATA_FAILURE':
      return {data: action.counterpartydata, isLoading: false, error: true};
    default:

      return state;
  }
}


export function portfoliooverview(state=initialState, action) {
  switch(action.type) {
    case 'FETCH_COUNTERPARTY_PORTFOLIO_OVERVIEW_SUCCESS':
      return {data: action.portfoliodata, isLoading: false};

    case 'FETCH_COUNTERPARTY_PORTFOLIO_OVERVIEW_FAILURE':
      return {data: action.portfoliodata, isLoading: false, error: true};
    default:

      return state;
  }
}

export function industrydata(state=initialState, action) {
  switch(action.type) {
    case 'FETCH_COUNTERPARTY_INDUSTRY_DATA_SUCCESS':
      return {data: action.industrydata, isLoading: false};

    case 'FETCH_COUNTERPARTY_INDUSTRY_DATA_FAILURE':
      return {data: action.industrydata, isLoading: false, error: true};
    default:

      return state;
  }
}

export function sectordata(state=initialState, action) {
  switch(action.type) {
    case 'FETCH_COUNTERPARTY_SECTOR_DATA_SUCCESS':
      return {data: action.sectordata, isLoading: false};

    case 'FETCH_COUNTERPARTY_SECTOR_DATA_FAILURE':
      return {data: action.sectordata, isLoading: false, error: true};
    default:

      return state;
  }
}

export function industryexposures(state=initialState, action) {
  switch(action.type) {
    case 'FETCH_INDUSTRY_EXPOSURES_SUCCESS':
      return {data: action.industryexposures, isLoading: false};

    case 'FETCH_INDUSTRY_EXPOSURES_FAILURE':
      return {data: state.data, isLoading: false, error: true};
    default:

      return state;
  }
}

export function countryexposures(state=initialState, action) {
  switch(action.type) {
    case 'FETCH_COUNTRY_EXPOSURES_SUCCESS':
      return {data: action.countryexposures, isLoading: false};

    case 'FETCH_COUNTRY_EXPOSURES_FAILURE':
      return {data: state.data, isLoading: false, error: true};
    default:

      return state;
  }
}

export function industrybenchmarkdata(state=initialState, action) {
  switch(action.type) {
    case 'FETCH_INDUSTRY_BENCHMARK_DATA_SUCCESS':
      return {data: action.benchmark_data, isLoading: false};

    case 'FETCH_INDUSTRY_BENCHMARK_DATA_FAILURE':
      return {data: action.benchmark_data, isLoading: false, error: true};
    default:

      return state;
  }
}

export function reports(state=initialState, action) {
  switch(action.type) {
    case 'FETCH_REPORTS_SUCCESS':
      return {data: action.reports, isLoading: false};

    case 'FETCH_REPORTS_FAILURE':
      return {data: action.reports, isLoading: false, error: true};

    case 'CREATE_REPORTS_SUCCESS':
      let new_elem = {
        createdOn: new Date(),
        html_url: 'https://s3.eu-west-1.amazonaws.com/generatedreportsdemo/generated_reports/' + action.report.filename + '.html',
        id: undefined,
        pdf_url: 'test',
        name: action.report.filename,
      }
      return {...state, data: [...state.data, new_elem], lastAdded: true};

    default:
      return state;
  }
}

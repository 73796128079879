const initialState = {data: {}, isLoading: true};

export function selections(state=initialState, action) {

  switch(action.type) {

    case 'ADD_SELECTION_SUCCESS':
      return {data: [...state.data, action.selection], lastAdded: true};

    case 'ADD_SELECTION_FAILURE':
      return {data: action.selection, error: true, error_text: action.error_type.message, lastAdded: false};

    case 'DELETE_SELECTION_SUCCESS':
      let new_state = [...state.data].filter(elem => {return elem.selectionID !== action.id})
      return {data: new_state, deleted: true};

    case 'DELETE_SELECTION_FAILURE':
      return {data: action.selection, deleted: false};

    case 'FETCH_SELECTIONS_SUCCESS':
      return {data: action.selections, isLoading: false};

    case 'FETCH_SELECTIONS_FAILURE':
      return {data: [], error: true, error_type: action.error_type, isLoading: false};

    default:
      return state;
  }
}

export function portfolio_counterparties(state=initialState, action) {

  switch(action.type) {
    case 'FETCH_PORTFOLIO_COUNTERPARTIES_SUCCESS':
      return {data: action.counterparties, isLoading: false};

    case 'FETCH_PORTFOLIO_COUNTERPARTIES_FAILURE':
      return {data: action.counterparties, isLoading: false};
    default:
      return state;
  }
}

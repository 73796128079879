// Import React & Redux components
import React from 'react';
import { Card } from 'react-bootstrap'

class Notification extends React.Component {

  constructor(props) {
    super(props);
  }

  render(){
    return(
      <Card>
        <Card.Header>{this.props.heading}</Card.Header>
        <Card.Body>
          {this.props.body}
        </Card.Body>
      </Card>
    )
  }
}

export default (Notification)

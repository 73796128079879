// Import React & Redux components
import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { Button, Input } from 'react-bootstrap';

// Import layout and helper functions/templates
import { populateOptions } from './Helpers'

class FormSelectOneDropDown extends React.Component {

  render() {

    return (
      <div>
        <form className="form-inline" onSubmit={this.props.handleSubmit}>
          <div className="form-group">
            <label style={{marginRight: '10px'}}>{this.props.itemLabel}</label>
            <Field name="selectedItemIndex" className="form-control" component="select" style={{width: '200px'}}>
              <option value="">{this.props.currentlySelectedItemName}</option>
              {populateOptions(this.props.itemName)}
            </Field>
          </div>
        </form>
      </div>
    )
  }
};

export default reduxForm({
  form: 'selectOneDropDown',
})(FormSelectOneDropDown);

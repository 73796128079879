function handleErrors(response) {
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return response;
}

export const addScenario = (text, token) => (
  (dispatch) => {
    dispatch({ type: 'ADD_SCENARIO_REQUEST' });
    const headers = { 'Content-Type': 'application/json', Authorization: `JWT ${token}` };
    const body = JSON.stringify(text);
    return fetch(`${process.env.REACT_APP_HTTP_PROTOCOL}://${process.env.REACT_APP_API_IP}/api/simulation/scenarios/`, { headers, method: 'POST', body })
      .then(handleErrors)
      .then((res) => res.json())
      .then((scenario) => (
        dispatch({ type: 'ADD_SCENARIO_SUCCESS', scenario })
      ))
      .catch((error) => (
        dispatch({ type: 'ADD_SCENARIO_FAILURE', error_text: error, error: true })
      ));
  }
);

export const deleteScenario = (id, token) => (
  (dispatch) => {
    const headers = { 'Content-Type': 'application/json', Authorization: `JWT ${token}` };
    const body = JSON.stringify(id);
    return fetch(`${process.env.REACT_APP_HTTP_PROTOCOL}://${process.env.REACT_APP_API_IP}/api/simulation/scenarios/${id}/`, { headers, method: 'DELETE', body })
      .then(handleErrors)
      .then((res) => res.json())
      .then((scenario) => (
        dispatch({ type: 'DELETE_SCENARIO_SUCCESS', id })
      ))
      .catch((error) => (
        dispatch({ type: 'DELETE_SCENARIO_FAILURE', error_text: error, error: true })
      ));
  }
);

export const fetchScenario = (scenarioID, token) => (
  (dispatch) => {
    dispatch({ type: 'FETCH_SCENARIO_REQUEST' });
    const headers = { 'Content-Type': 'application/json', Authorization: `JWT ${token}` };
    return fetch(`${process.env.REACT_APP_HTTP_PROTOCOL}://${process.env.REACT_APP_API_IP}/api/simulation/scenarios/${scenarioID}/`, { headers })
      .then(handleErrors)
      .then((res) => res.json())
      .then((scenario) => (
        dispatch({ type: 'FETCH_SCENARIO_SUCCESS', scenario })
      ))
      .catch((error) => (
        dispatch({ type: 'FETCH_SCENARIO_FAILURE', error_type: error, error: true })
      ));
  }
);

export const fetchScenarios = (token) => (
  (dispatch) => {
    dispatch({ type: 'FETCH_SCENARIOS_REQUEST' });
    const headers = { 'Content-Type': 'application/json', Authorization: `JWT ${token}` };
    return fetch(`${process.env.REACT_APP_HTTP_PROTOCOL}://${process.env.REACT_APP_API_IP}/api/simulation/scenarios/`, { headers })
      .then(handleErrors)
      .then((res) => res.json())
      .then((scenarios) => (
        dispatch({ type: 'FETCH_SCENARIOS_SUCCESS', scenarios })
      ))
      .catch((error) => (
        dispatch({ type: 'FETCH_SCENARIOS_FAILURE', error_type: error, error: true })
      ));
  }
);

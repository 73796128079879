// There are three possible states for our login process and we need actions for each of them

const requestLogin = (creds) => {
  return dispatch => {
    return dispatch({
      type: 'LOGIN_REQUEST',
      isFetching: true,
      isAuthenticated: false,
      creds
    })
  }
}

export const getUser = (jwt_token) => {
  let config = {
    method: 'POST',
    headers: { 'Content-Type':'application/json' },
    body: '{"token": "' + jwt_token + '"}'
  }
  return dispatch => {
    return fetch(process.env.REACT_APP_HTTP_PROTOCOL + "://" + process.env.REACT_APP_API_IP + '/api-token-verify/', config)
      .then(response => response.json()
        .then(user => ({ user, response }))
      ).then(({ user, response }) =>  {
        if (!response.ok) {
          // If there was a problem, we want to
          // dispatch the error condition
          dispatch(loginError(user.message))
          return Promise.reject(user)
        } else {
          // Dispatch the success action
          dispatch(receiveLogin(user))
        }
      }).catch(err => console.log("Error: ", err))
  }
}

export const receiveLogin = (user) => {
  return dispatch => {
    return dispatch({
      type: 'LOGIN_SUCCESS',
      isFetching: false,
      isAuthenticated: true,
      token: user.token
    })
  }
}

const loginError = (message) => {
  return {
    type: 'LOGIN_FAILURE',
    isFetching: false,
    isAuthenticated: false,
    message
  }
}

// Calls the API to get a token and dispatches actions along the way
export const loginUser = (creds) => {

  let config = {
    method: 'POST',
    headers: { 'Content-Type':'application/x-www-form-urlencoded' },
    body: `username=${creds.username}&password=${creds.password}`
  }

  return dispatch => {
    // We dispatch requestLogin to kickoff the call to the API
    dispatch(requestLogin(creds))

    return fetch(process.env.REACT_APP_HTTP_PROTOCOL + "://" + process.env.REACT_APP_API_IP + '/api-token-auth/', config)
      .then(response =>
        response.json().then(user => ({ user, response }))
            ).then(({ user, response }) =>  {
        if (!response.ok) {
          // If there was a problem, we want to
          // dispatch the error condition
          dispatch(loginError(user.message))
          return Promise.reject(user)
        } else {
          // If login was successful, set the token in local storage. This will
          // allow the token to persist accross windows and tabs, as opposed
          // to sessionStorage which limits it to one tab.
          sessionStorage.setItem('jwtToken', user.token)
          // Dispatch the success action
          dispatch(receiveLogin(user))
        }
      }).catch(err => console.log("Error: ", err))
  }
}

// Three possible states for our logout process as well.
// Since we are using JWTs, we just need to remove the token
// from sessionStorage. These actions are more useful if we
// were calling the API to log the user out

const requestLogout = () => {
  return {
    type: 'LOGOUT_REQUEST',
    isFetching: true,
    isAuthenticated: true
  }
}

const receiveLogout = () => {
  return {
    type: 'LOGOUT_SUCCESS',
    isFetching: false,
    isAuthenticated: false
  }
}

// Logs the user out
export const logoutUser = () => {
  return dispatch => {
    dispatch(requestLogout())
    sessionStorage.removeItem('jwtToken')
    dispatch(receiveLogout())
  }
}

import React from 'react';
import { Line, Bar, Scatter } from 'react-chartjs-2';
import Format from '../components/Format'
import 'chartjs-plugin-annotation'

// createDataForChart returns a dataformat suitable for use with react-chartjs-2
// charts. The function takes 3 argument blocks:
// - dataseries: array of arrays containing dataseries.
// - xaxislabels: an array containing the labels for the xaxislabels.
// - dataserieslabels: an array containing the names of the dataseries.
//
// For example, following function call:
// createDataForChart([1,2],[[1,2],[3,6]], ['A','B'])
// returns an output, which when used with react-chartjs-2 to plot a line chart,
// results in two lines being drawn, one labeled A and the other B, with the
// former being drawn through [1,1] and [2,2] and the latter being drawn through
// [1,3] and [2,6].

const createDataForChart = (xaxislabels, dataseries, dataserieslabels) => {
  let datasets =[]
  for(let i = 0; i < dataseries.length; ++i) {
    datasets.push({
      label: dataserieslabels[i],
      backgroundColor: Format.fillcolors[i%Format.fillcolors.length],
      borderColor: Format.bordercolors[i%Format.bordercolors.length],
      borderWidth: 1,
      hoverBackgroundColor: 'rgba(139,139,139,0.4)',
      hoverBorderColor: 'rgba(139,139,139,1)',
      pointRadius: 2,
      data: dataseries[i]
    })
  };
  return {
    labels: xaxislabels,
    datasets: datasets
  }
}

// Similar to createDataForChart, createDataForChartSplitColors returns a
// dataformat suitable for use with react-chartjs-2 charts, only with different
// colour for dataseries with positive and negative values.
const createDataForChartSplitColors = (xaxislabels, positive, positivedataseries, negativedataseries, dataserieslabels) => {
  let datasets =[]
  let colourindexpositive = [0]
  let colourindexnegative = [1]
  let labelpositive = []
  let labelnegative = []

  if (positive === "Positive Impact") {
    colourindexpositive = 0
    colourindexnegative = 1
    labelpositive = "Positive Impact"
    labelnegative = "Negative Impact"
  } else {
    colourindexpositive = 1
    colourindexnegative = 0
    labelpositive = "Negative Impact"
    labelnegative = "Positive Impact"
  }
  for(var i = 0; i < positivedataseries.length + negativedataseries.length; ++i) {
    if (i < positivedataseries.length) {
      datasets.push({
        label: labelpositive,
        backgroundColor: Format.fillcolors[colourindexpositive],
        borderColor: Format.bordercolors[colourindexpositive],
        borderWidth: 1,
        hoverBackgroundColor: 'rgba(139,139,139,0.4)',
        hoverBorderColor: 'rgba(139,139,139,1)',
        pointRadius: 2,
        data: positivedataseries[i],
        type: 'bar'
      })
    } else {
      datasets.push({
        label: labelnegative,
        backgroundColor: Format.fillcolors[colourindexnegative],
        borderColor: Format.bordercolors[colourindexnegative],
        borderWidth: 1,
        hoverBackgroundColor: 'rgba(139,139,139,0.4)',
        hoverBorderColor: 'rgba(139,139,139,1)',
        pointRadius: 2,
        data: negativedataseries[i-(positivedataseries.length)],
        type: 'bar'
      })
    }

  };
  return {
    labels: xaxislabels,
    datasets: datasets
  }
}

// Similar to createDataForChart, createDataForChartScatter returns a
// dataformat suitable for use with react-chartjs-2 charts, with datasets fed
// in multiples of 2 (i.e. combinations of x values and y values).
const createDataForChartScatter = ( dataseries, dataserieslabels) => {
  let datasets =[]
  for(var i = 0; i < dataseries.length/2; ++i) {
    for(var j = 0; j < dataseries[0].length; ++j) {
      datasets.push({
        backgroundColor: Format.fillcolors[i%Format.fillcolors.length],
        borderColor: Format.bordercolors[i%Format.bordercolors.length],
        borderWidth: 1,
        hoverBackgroundColor: 'rgba(139,139,139,0.4)',
        hoverBorderColor: 'rgba(139,139,139,1)',
        pointRadius: 2,
        data: [{
          x: dataseries[2*i][j],
          y: dataseries[2*i+1][j]
        }]
      })
    }
  };
  return {
    datasets: datasets
  }
}

// TO DO: CLEAN UP setOptions. CURRENT SETUP IS NOT VERY CLEAN.

// Format the charts based on chart type and other parameters
// Options for unit:
//   - ISO 4217 currency type, e.g. EUR, USD
//   - decimal
//   - percent
// Default currency is EUR here, default style is decimal.
const setOptions = (chart_type, unit, vlines, vlinestitles, hlines, hlinestitles, xaxistooltips) => {
  let style = unit
  let currency = "EUR"
  let minimumFractionDigits = 0
  let maximumFractionDigits = 2
  if (unit === undefined) {
    style = "decimal"
  } else
  if (unit !== "decimal" && unit !== "percent") {
    style = "currency"
    currency = unit
  }
  if (unit === "percent") {
    minimumFractionDigits = 1
  }

  let options = {
    annotation: {
      annotations: [{}]
    },
    legend:{
      display: [{}]
    },
    scales: {
      xAxes: [{
        ticks: {
          min: null,
          callback: function(value, index, values) {
            // This stuff is just for date conversion, delete for others
            if(typeof(value) === 'object'){
              return value.getFullYear()
            } else {
              return value.toLocaleString("en-GB",
                { style: "decimal",
                  currency: currency,
                  minimumFractionDigits: minimumFractionDigits,
                  maximumFractionDigits: maximumFractionDigits
                });
            }
          }
        }

      }],
      yAxes: [{
        ticks: {
          //min: 0,
          callback: function(value, index, values) {
            return value.toLocaleString("en-GB",
              { style: style,
                currency: currency,
                minimumFractionDigits: minimumFractionDigits,
                maximumFractionDigits: maximumFractionDigits
              });
          }
        }
      }]
    }
  }
  if (unit === undefined) {
    options.tooltips = {
      enabled: true,
      mode: 'single',
      callbacks: {
        label: function(tooltipItems, data) {
          return tooltipItems.yLabel.toLocaleString("en-GB", {"minimumFractionDigits": 2, "maximumFractionDigits": 2});
        }
      }
    }
  }
  if (unit === "EUR") {
    options.tooltips = {
      enabled: true,
      mode: 'single',
      callbacks: {
        label: function(tooltipItems, data) {
          return tooltipItems.yLabel.toLocaleString("en-GB", {"minimumFractionDigits": 2, "maximumFractionDigits": 2});
        }
      }
    }
  }
  if (unit === "decimal") {
    options.tooltips = {
      enabled: true,
      mode: 'single',
      callbacks: {
        label: function(tooltipItems, data) {
          return tooltipItems.yLabel.toLocaleString("en-GB", {"minimumFractionDigits": 2, "maximumFractionDigits": 2});
        }
      }
    }
  }
  if (unit === 'percent') {
    options.tooltips = {
      enabled: true,
      mode: 'single',
      callbacks: {
        label: function(tooltipItems, data) {
          return tooltipItems.yLabel.toLocaleString("en-GB", {"style": "percent", "minimumFractionDigits": 2, "maximumFractionDigits": 2});
        }
      }
    }
    if(xaxistooltips !== undefined){
      options.tooltips = {
        enabled: true,
        mode: 'single',
        callbacks: {
          title: function(tooltipItems, data) {
            return xaxistooltips[tooltipItems[0].index]
          },
          label: function(tooltipItems, data) {
            return tooltipItems.yLabel.toLocaleString("en-GB", {"style": "percent", "minimumFractionDigits": 2, "maximumFractionDigits": 2});
          }
        }
      }
    }
  }
  // If a vertical line is to be added, this is done here.
  if (vlines !== undefined) {
    options.annotation.content = "Test"
    for(let i = 0; i < vlines.length; ++i) {
      options.annotation.annotations[i] = {
        drawTime : 'afterDatasetsDraw',
        borderColor : 'black',
        borderWidth : 2,
        mode : 'vertical',
        borderDash : [4, 4],
        type : 'line',
        value : vlines[i],
        scaleID : 'x-axis-0',
        label : {
          backgroundColor: 'white',
          fontFamily: 'sans-serif',
          fontSize: 12,
          fontStyle: 'bold',
          fontColor: 'black',
          textAlign: 'center',
          xPadding: 4,
          yPadding: 4,
          cornerRadius: 4,
          position: 'top',
          enabled: true,
          content: vlinestitles[i]
        }
      }
    }
  }
  // If a horizontal line is to be added, this is done here, taking into account
  // any vertical lines already present through VLineEnd
  var VLineEnd = null
  if (vlines !== undefined) {
    VLineEnd = vlines.length
  } else {
    VLineEnd = 0
  }
  if (hlines !== undefined) {
    for(let j = 0 + VLineEnd; j < hlines.length + VLineEnd; ++j) {
      options.annotation.annotations[j] = {
        drawTime : 'afterDatasetsDraw',
        borderColor : 'black',
        borderWidth : 2,
        mode : 'horizontal',
        borderDash : [4, 4],
        type : 'line',
        value : hlines[j-VLineEnd],
        scaleID : 'y-axis-0',
        label : {
          backgroundColor: 'white',
          fontFamily: 'sans-serif',
          fontSize: 12,
          fontStyle: 'bold',
          fontColor: 'black',
          textAlign: 'center',
          xPadding: 4,
          yPadding: 4,
          cornerRadius: 4,
          position: 'top',
          enabled: true,
          content: hlinestitles[j-VLineEnd]
        }
      }
    }
  }

  // Create options for a stacked bar chart.
  if (chart_type === "Stacked Bar") {
    options.scales.xAxes[0].stacked = true
    options.scales.yAxes[0].stacked = true
  }

  // For scatter charts exclude the legend for each datapoint to avoid a large
  // number of labels
  if (chart_type === "Scatter") {
    options.legend.display = false
  }

  return options
}

// Call react-chartjs-2 functions, including previously defined options.
const renderChart = (chart_type, data, options) => {
  if(chart_type === "Line") {
    return <Line data={data} options={options}/>;
  } else if (chart_type === "Bar") {
    return <Bar data={data} options={options}/>;
  } else if (chart_type === "Stacked Bar") {
    return <Bar data={data} options={options}/>;
  } else if (chart_type === "Scatter") {
    return <Scatter data={data} options={options}/>;
  }
};

class Chart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
    };
  }

  render() {
    // Set options for the chart to be drawn, includes formatting
    let options = setOptions(this.props.type, this.props.unit, this.props.verticallines, this.props.verticallinestitles, this.props.horizontallines, this.props.horizontallinestitles, this.props.xaxistooltips)

    if (this.props.xaxisstart !== undefined) {
      options.scales.yAxes[0].ticks.min = this.props.xaxisstart
    }

    let data =[]
    if (this.props.positive !== undefined) {

      var positive_dataseries = new Array(this.props.dataseries.length).fill(new Array(this.props.dataseries[0].length))
      var negative_dataseries = new Array(this.props.dataseries.length).fill(new Array(this.props.dataseries[0].length))

      for(var i = 0; i < this.props.dataseries.length; ++i){
        for(var j = 0; j < this.props.dataseries[0].length; ++j){
          if(this.props.dataseries[i][j] < 0) {
            negative_dataseries[i][j] = this.props.dataseries[i][j]
            positive_dataseries[i][j] = 0
          } else {
            negative_dataseries[i][j] = 0
            positive_dataseries[i][j] = this.props.dataseries[i][j]
          }
        }
      }
      data = createDataForChartSplitColors(this.props.xaxislabels, this.props.positive, positive_dataseries, negative_dataseries, this.props.dataserieslabels)
    } else if (this.props.type === "Scatter") {
      data = createDataForChartScatter( this.props.dataseries, this.props.dataserieslabels)
    } else {
      data = createDataForChart(this.props.xaxislabels, this.props.dataseries, this.props.dataserieslabels)
    }

    if (this.props.data === []) {
      return (
        <div>
        </div>
      );
    } else {
      return (
        <div>
          {renderChart(this.props.type, data, options)}
        </div>
      )
    }
  }
}

export default Chart;

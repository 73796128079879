const initialState = {data: [], isLoading: true};

export function resultsjob(state = initialState, action) {
  switch(action.type) {
    case 'FETCH_RESULTS_JOB_SUCCESS':
      let data = action.resultsjob
      data = data.map(transaction => {
        transaction['cashFlows'] = JSON.parse(transaction['cashFlows']).data
        transaction['counterparty'] = parseInt(transaction['counterparty'])
        transaction['interestPayments'] = JSON.parse(transaction['interestPayments']).data
        try {
          transaction['fundingPayments'] = JSON.parse(transaction['fundingPayments']).data
        } catch {
          transaction['fundingPayments'] = []
        }
        try {
          transaction['creditRiskB3RWAThroughTime'] = JSON.parse(transaction['creditRiskB3RWAThroughTime']).data
        } catch {
          transaction['creditRiskB3RWAThroughTime'] = []
        }
        try {
          transaction['creditRiskRWAThroughTime'] = JSON.parse(transaction['creditRiskRWAThroughTime']).data
        } catch {
          transaction['creditRiskRWAThroughTime'] = []
        }
        try {
          transaction['economicCapitalThroughTime'] = JSON.parse(transaction['economicCapitalThroughTime']).data
        } catch {
          transaction['economicCapitalThroughTime'] = []
        }
        try {
          transaction['operationalRiskB3RWAThroughTime'] = JSON.parse(transaction['operationalRiskB3RWAThroughTime']).data
        } catch {
          transaction['operationalRiskB3RWAThroughTime'] = []
        }
        try {
          transaction['operationalRiskRWAThroughTime'] = JSON.parse(transaction['operationalRiskRWAThroughTime'].replaceAll('-nan', '0').replaceAll('nan', '0')).data
        } catch {
          transaction['operationalRiskRWAThroughTime'] = []
        }
        try {
          transaction['regulatoryCapitalThroughTime'] = JSON.parse(transaction['regulatoryCapitalThroughTime']).data
        } catch {
          transaction['regulatoryCapitalThroughTime'] = []
        }
        try {
          transaction['periodVector'] = JSON.parse(transaction['periodVector']).data
        } catch {
          transaction['periodVector'] = []
        }
        try {
          transaction['periodsHorizonToMaturity'] = JSON.parse(transaction['periodsHorizonToMaturity']).data
        } catch {
          transaction['periodsHorizonToMaturity'] = []
        }
        try {
          transaction['redemptionProfile'] = JSON.parse(transaction['redemptionProfile']).data
        } catch {
          transaction['redemptionProfile'] = []
        }
        try {
          transaction['netInterestIncome'] = transaction['interestPayments'][0].map((elem, index) => (
            transaction['interestPayments'][0][index] - transaction['interestPayments'][1][index] / 12 - transaction['fundingPayments'][0][index]
          ))
        } catch {
          transaction['netInterestIncome'] = []
        }
        return transaction
      })
      return {data: data, isLoading: false};
    case 'FETCH_RESULTS_JOB_FAILURE':
      return {data: [], isLoading: false};
    default:
      return state;
  }
}

 export function resultsdatasets(state = initialState, action) {
  switch(action.type) {
    case 'FETCH_RESULTS_DATASETS_SUCCESS':
      return {data: action.resultsdatasets, isLoading: false};
    case 'FETCH_RESULTS_DATASETS_FAILURE':
      return {data: action.resultsdatasets, isLoading: false};
    default:
      return state;
  }
}

// Import React & Redux components
import React from "react";
import { connect } from 'react-redux';
import { Button, Card, Form, Row, Col } from 'react-bootstrap';
import { Redirect } from 'react-router-dom';
import ReactTable from 'react-table';

// Import layout and helper functions/templates
import Header from "../components/Header";
import Notification from "../components/Notification"
import { createLoadingSelector, createErrorMessageSelector, createAddMessage } from '../components/Helpers'

// Import data sources
import { inputs, scenarios , selections, jobs } from "../actions";

// Import dumb components
import FormSelectTwoDropDownPlusNameMessage from './FormSelectTwoDropDownPlusNameMessage'
import SimpleTable from './SimpleTable'

class CreateJob extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      selectionID: this.props.selectedSelectionID === undefined ? undefined : this.props.selectedSelectionID,
      scenarioID: this.props.selectedScenarioID === undefined ? undefined : this.props.selectedScenarioID,
      jobVerbosityOptions: ['full_db_submit', 'minimal_storage', 'initial_run', 'follow_up_run'],
      jobVerbosity: 'minimal_storage',
      factorGroupID: undefined,
      renderHelp: false,
    }
    this.saveSelection = this.saveSelection.bind(this);
    this.saveScenario = this.saveScenario.bind(this);
    this.saveFactorGroup = this.saveFactorGroup.bind(this);
    this.saveJobVerbosity = this.saveJobVerbosity.bind(this);
    this.renderHelp = this.renderHelp.bind(this);
  }

  saveSelection = () => {
    this.setState({
      selectionID: parseInt(this.refs.selectionID.value)
    })
  }

  saveScenario = () => {
    this.setState({
      scenarioID: parseInt(this.refs.scenarioID.value)
    })
  }

  saveFactorGroup = () => {
    this.setState({
      factorGroupID: parseInt(this.refs.factorGroupID.value)
    })
  }

  saveJobVerbosity = () => {
    this.setState({
      jobVerbosity: this.refs.jobVerbosity.value
    })
  }

  renderHelp = (content) => {
    this.state.renderHelp ? this.setState({ renderHelp: false, helpText: undefined}) : this.setState({ renderHelp: true, helpText: content})
  }

// Create a new simulation job, converting the form output into a json usable
// by the jobs action. Additionally the flag to indicate whether an attempt to
// create a new simulation job was made is set to true and the error message
// selector is called.
  handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
    }
    const datecheck = new Date()
    let values2 = {}

    values2.jobName = this.refs.jobName.value //+ " " + datecheck.toLocaleDateString('zh-Hans-CN') + " " + datecheck.toLocaleTimeString()
    values2.selectionName = this.props.selections.data[this.refs.selectionID.selectedIndex].selectionName
    values2.selectionID = parseInt(this.refs.selectionID.value)
    values2.scenarioName = this.props.scenarios.data[this.refs.scenarioID.selectedIndex].scenarioName
    values2.scenarioID = parseInt(this.refs.scenarioID.value)
    values2.factorLoadings = parseInt(this.refs.factorGroupID.value)
    values2.verbositySettings = this.state.jobVerbosity
    values2.jobCompletion = 0
    values2.createJob = 1
    values2.runCPSensitivities = 1
    values2.jobStart = 1
    //if(this.refs.jobStart.checked === true){
    //  values2.jobStart = 1
    //} else {
    // values2.jobStart = 0
    //}
    event.preventDefault()
    this.performFactorGroupVsPortfolioSanityCheck(values2.selectionID, values2.factorLoadings)
    this.props.addJob(values2, sessionStorage.getItem('jwtToken'))

    this.renderHelp({
      header: 'Job Created',
      body: 'Your job has started and the results will be ready soon.  Total runtime depends on your choices regarding the portfolio size, the number of Monte Carlo trials, and the distance between the assessment date and the horizon date. Please allow up to 15 minutes for an extensive run, such as a full portfolio with one million trials and a 5-year simulation horizon. Typical one-year simulation runs will be done in less than 10 minutes. In an enterprise environment, dependent on user requirements, this can be reduced to less than 5 minutes. Interested in enterprise deployment? Contact the team at ruud@ubtechnologies.nl or jorn@ubtechnologies.nl'
    })
    //createErrorMessageSelector(['ADD_JOB'])
  }

  performFactorGroupVsPortfolioSanityCheck = (selectionID, factorGroupID) => {
    return true
  }

  deleteJob = (id) => {
    this.props.deleteJob(id, sessionStorage.getItem('jwtToken'))
  }

  componentDidMount() {
    this.props.fetchScenarios(sessionStorage.getItem('jwtToken'))
    this.props.fetchSelections(sessionStorage.getItem('jwtToken'))
    this.props.fetchFactorOverviews(sessionStorage.getItem('jwtToken'))
    this.props.fetchJobs(sessionStorage.getItem('jwtToken'))
  }

  trashcan_column = {
    accessor: "delete_job",
    Cell: ({ original }) => {
      if(original.deleting === true){
        return (
          <i
            className="fa fa-trash spinning"
            style={{ display: "inline-block", width: "100%", textAlign: "center" }}
          >
          </i>
        );
      } else {
        return (
          <i
            className="fa fa-trash"
            style={{ display: "inline-block", width: "100%", textAlign: "center" }}
            onClick={() => {this.deleteJob(original.jobID)}}
          >
          </i>
        );
      }
    },
    header: "Delete Job",
    sortable: false,
    width: 45
  };

  render() {
    if(this.props.scenarios.isLoading === true ||
      this.props.selections.isLoading === true ||
      this.props.jobs.isLoading === true ||
      this.props.factorLoadings.isLoading === true
    ){
      return(
        <div>
          <Header title={"Create Simulation Job"} />
          <Notification heading={'Settings'} body={'Loading Data'}/>
        </div>
      )
    }

    if (this.props.error.length > 0 ||
      this.props.scenarios.data === undefined ||
      this.props.selections.data === undefined ||
      this.props.jobs.data === undefined ||
      this.props.factorLoadings.data === undefined)
      //this.props.factorLoadings.data === undefined) && this.props.error.ADD_JOB === null )
      {
        return (
          <div>
            <Header title={"Create Simulation Job"} />
            <Notification heading={'Error Message'} body={this.props.jobs.error_text}/>
          </div>
        )
    }

    if (this.props.jobs.error === true) {
      if (this.props.jobs.error_text === "Unauthorized") {
        return <Redirect to={'/login_screen'} />;
      }
    }

    return (
      <div>
      { this.state.renderHelp === true && (
        <div className="overlay-help" onClick={() => this.renderHelp(undefined)}>
          <div className="overlay-help-card">
            <Card className="mt-4">
              <Card.Header><div className="fa-pull-left">{this.state.helpText.header}</div>
              <i className="fa fa-window-close fa-pull-right" onClick={() => this.renderHelp(undefined)}></i></Card.Header>
              <Card.Body>{this.state.helpText.body}</Card.Body>
            </Card>
          </div>
        </div>
      )}
        <Header
          title={"Start Simulation Job"}
          subtitle={
            { startText: 'Select and double-check the settings for your simulation. You are now ready to simulate at unprecedented speed and acquire insights to boost and optimise your risk/return decision-making! Once your job is completed the results are available in Projections and Analytics.' }}
        />
        <div>
        <Card className="mt-4">
          <Card.Header>Settings</Card.Header>
          <Card.Body>
          <Form onSubmit={this.handleSubmit}>
            <Form.Group as={Row} controlId="createJob.jobName">
              <Form.Label column sm={4}>Job Name</Form.Label>
              <Col sm={8}>
                <Form.Control type="text" ref="jobName" placeholder="Job Name" required />
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="createJob.selectionID">
              <Form.Label column sm={4}>Portfolio</Form.Label>
                <Col sm={8}>
                  <Form.Control as="select" ref="selectionID" onChange={this.saveSelection} value={this.state.selectionID}>
                  { this.props.selections.data.map((k, index) => {
                    return <option key={k.selectionID} value={k.selectionID}>{k.selectionName}</option>
                    })
                  }
                </Form.Control>
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="createJob.scenarioID">
            <Form.Label column sm={4}>Scenario</Form.Label>
              <Col sm={8}>
                <Form.Control as="select" ref="scenarioID" onChange={this.saveScenario} value={this.state.scenarioID}>
                  { this.props.scenarios.data.map((k, index) => {
                    return <option key={k.scenarioID} value={k.scenarioID}>{k.scenarioName}</option>
                    })
                  }
                </Form.Control>
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="createJob.factorGroupID">
              <Form.Label column sm={4}>Factor Group</Form.Label>
              <Col sm={8}>
                <Form.Control as="select" ref="factorGroupID" onChange={this.saveFactorGroup} value={this.state.factorGroupID}>
                  { this.props.factorLoadings.data.map((k, index) => {
                    return <option key={k.setID} value={k.setID}>{k.setName}</option>
                    })
                  }
                </Form.Control>
              </Col>
            </Form.Group>
            {//<Form.Group controlId="createJob.jobStartButton">
            //  <Form.Check type="checkbox" ref="jobStart" label="Start job" />
            //</Form.Group>
            }
            { ['jornkoch', 'giorgiocarbone'].includes(this.props.authentication.user) && (
              <Form.Group as={Row} controlId="createJob.jobVerbosity">
                <Form.Label column sm={4}>Job Verbosity</Form.Label>
                <Col sm={8}>
                  <Form.Control as="select" ref="jobVerbosity" onChange={this.saveJobVerbosity} value={this.state.jobVerbosity}>
                    { this.state.jobVerbosityOptions.map((k, index) => (
                        <option key={index} value={k}>{k}</option>
                      ))
                    }
                  </Form.Control>
                </Col>
              </Form.Group>
            )}
            <Button variant="outline-primary" fill="true" type="submit">Start Job</Button>
          </Form>
          </Card.Body>
        </Card>

        <Card className="mt-4">
          <Card.Header>Created Jobs</Card.Header>
          <Card.Body>
            <SimpleTable data={this.props.jobs.data} sortColumn='jobID' sortDescendingOrder={true} columns={[
              {accessor: "jobName", Header: "Job Name"},
              {accessor: "selectionName", Header: "Selection"},
              {accessor: "scenarioName", Header: "Scenario"},
              { accessor: "jobCompletion",
                Header: "Job Status",
                Cell: row => (
                  <div><span style={{ textAlign: "left" }}><progress max="2" value={row.value}></progress></span><span style={{ textAlign: "right" }}>{" " + (100 * row.value).toLocaleString("en-GB", {"style": "decimal", "minimumFractionDigits": 1, "maximumFractionDigits": 1})+'%'}</span></div>
                )},
              { accessor: "startedOn",
                Header: "Job started at",
                Cell: row => (
                  <div style={{ textAlign: "right" }}>{row.value ? new Date(row.value).toLocaleDateString('nl-NL', { year: 'numeric', month: '2-digit', day: '2-digit' }) : 'N/A'}</div>
                )},
              this.trashcan_column,
            ]}/>
          </Card.Body>
        </Card>
        </div>
      </div>
    );
  }
}

const loadingSelector = createLoadingSelector(['FETCH_SELECTIONS', 'FETCH_SCENARIOS', 'FETCH_JOBS', 'FETCH_FACTOR_OVERVIEWS']);
//const errorSelector = createErrorMessageSelector(['FETCH_SELECTIONS', 'FETCH_SCENARIOS', 'FETCH_JOBS']);

const mapStateToProps = state => {
  return {
    authentication: state.authentication,
    factorLoadings: state.factorLoadings,
    scenarios: state.scenarios,
    selections: state.selections,
    jobs: state.jobs,
    isFetching: loadingSelector(state),
    //isError: errorSelector(state),
    loading: state.loading,
    error: state.error,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    fetchFactorOverviews: (token) => {
      dispatch(inputs.fetchFactorOverviews(token));
    },
    fetchJobs: (token) => {
        dispatch(jobs.fetchJobs(token));
    },
    fetchScenarios: (token) => {
        dispatch(scenarios.fetchScenarios(token));
    },
    addJob: (text, token) => {
      return dispatch(jobs.addJob(text, token));
    },
    deleteJob: (id, token) => {
      return dispatch(jobs.deleteJob(id, token));
    },
    fetchSelections: (token) => {
      dispatch(selections.fetchSelections(token));
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateJob);

// Import React & Redux components
import React from "react";
import { reduxForm } from 'redux-form';
import { Button, Form } from 'react-bootstrap';

// Import dumb component
import ReactTable from 'react-table';

const styledButton = props => (
  <button type="button" {...props} className="btn btn-outline-primary-page-selection">
    {props.children}
  </button>
)

class FormSelectTable extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      selectionItems: [],
      selectionName: null,
      selectAll: false
    };
  }

  logSelection = () => {
    console.log("selectionItems:", this.state.selectionItems);
  };

  toggleItem = (elem) => {
    let existing = this.state.selectionItems.filter(item => {return item.transactionID === elem.transactionID})
    if (existing.length > 0){
      this.setState({
        selectionItems: this.state.selectionItems.filter(counterparty => {return counterparty.transactionID !== elem.transactionID})
      })
    } else {
      this.setState({
        selectionItems: [...this.state.selectionItems, elem]
      })
    }
  }

  toggleAll = () => {
    let indices_in_focus = this.portfolioTransactionTable.getResolvedState().sortedData;
    let elems_in_focus = indices_in_focus.map(elem => {return this.props.data[elem._index]})
    const selectionItems = [];
    let new_selected_array = [];
    let selectAll = this.state.selectAll ? false : true;
    if(selectAll){
      elems_in_focus.forEach(item => {
        let filter_result = this.state.selectionItems.filter(elem => {return elem.transactionID === item.transactionID})
        if (filter_result.length === 0) {
          selectionItems.push(item);
        }
      });
      new_selected_array = [...this.state.selectionItems,...selectionItems]
    } else {
      this.state.selectionItems.forEach(item => {
        let selected_elem = elems_in_focus.filter(elem => {return elem.transactionID === item.transactionID})
        if(selected_elem.length === 0){
          selectionItems.push(item)
        }
      })
      new_selected_array = [...selectionItems]
    }
    this.setState({
      selectAll: selectAll,
		  selectionItems: new_selected_array
		});
  };

  checkbox_column = {
    accessor: "checkbox",
    Cell: ({ original }) => {
      return (
        <div style={{textAlign: "center"}}>
          {<Form.Check type="checkbox" key={original.transactionID} value={original.transactionID} onChange={() => this.toggleItem(original)} checked={this.state.selectionItems.filter(elem => {return elem.transactionID === original.transactionID}).length > 0}/>}
        </div>
      );
    },
    Header: x => {
      return (
        <div style={{textAlign: "center"}}>
          <input
            type="checkbox"
            className="checkbox"
            checked={this.state.selectAll === true}
            ref = {
              input => {
                if (input) {
                  input.indeterminate = this.state.selectAll === 2;
                }
               }
             }
            onChange = {() => this.toggleAll()}
          />
        </div>
      );
    },
    sortable: false,
    width: 45,
  };

  render() {
    // replace for a bind
    const { toggleSelection } = this;

    let columns_changed = this.props.columns
    if (this.props.columns[0].accessor !== 'checkbox') {
      columns_changed.unshift(this.checkbox_column)
    }
    return (
      <div>
        <form onSubmit={this.props.handleSubmit}>
          <ReactTable
            data={this.props.data}
            filterable
            columns={columns_changed}
            sorted={[{
              id: this.state.sortColumn === undefined ? '' : this.state.sortColumn,
              desc: false
            }]}
            defaultPageSize={50}
            keyField={'id'}
            ref={(r) => {
              this.portfolioTransactionTable = r;
            }}
            className="-striped -highlight"
            NextComponent={styledButton}
            PreviousComponent={styledButton}
          />
          <Button variant="outline-primary" onClick={this.update.bind(this)} fill="true" type="submit" disabled={this.props.submitting} className="mt-3" >{this.props.saveButtonText}</Button>
        </form>
      </div>
    )
  }

  update = () => {
    this.props.saveSelectionItems(this.state.selectionItems)
  }
}

export default reduxForm({
  form: 'selectTable', // a unique identifier for this form
})(FormSelectTable);

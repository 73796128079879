function handleErrors(response) {
    if (!response.ok) {
        throw Error(response.statusText);
    }
    return response;
}

export const fetchUsers = (token) => {
  return dispatch => {
    dispatch({ type: 'FETCH_USERS_REQUEST' });
    const headers = {"Content-Type": "application/json", "Authorization": "JWT " + token};
    return fetch(process.env.REACT_APP_HTTP_PROTOCOL + "://" + process.env.REACT_APP_API_IP + "/api/configuration/user/", {headers, })
      .then(handleErrors)
      .then(res => res.json())
      .then(results_users => {
        return dispatch({
          type: 'FETCH_USERS_SUCCESS',
          results_users
        })
      })
      .catch(error => {
        return dispatch({ type: 'FETCH_USERS_FAILURE', error_type: error, error: true })
      })
  }
}

export const addUser = (text, token) => {
  return dispatch => {
    const headers = {"Content-Type": "application/json", "Authorization": "JWT " + token};
    let body = JSON.stringify(text);
    return fetch(process.env.REACT_APP_HTTP_PROTOCOL + "://" + process.env.REACT_APP_API_IP + "/api/configuration/user/", {headers, method: "POST", body})
      .then(handleErrors)
      .then(res => res.json())
      .then(user => {
        return dispatch({
          type: 'ADD_USER_SUCCESS',
          user
        })
      })
      .catch(error => {
        return dispatch({ type: 'ADD_USER_FAILURE', error_type: error, error: true })
      })
  }
}

export const deleteUser = (id, token) => {
  return dispatch => {
    const headers = {"Content-Type": "application/json", "Authorization": "JWT " + token};
    let body = JSON.stringify(id);
    return fetch(process.env.REACT_APP_HTTP_PROTOCOL + "://" + process.env.REACT_APP_API_IP + "/api/configuration/user/", {headers, method: "DELETE", body})
      .then(handleErrors)
      .then(res => res.json())
      .then(user => {
        return dispatch({
          type: 'DELETE_USER_SUCCESS',
          user
        })
      })
      .catch(error => {
        return dispatch({ type: 'DELETE_USER_FAILURE', error_type: error, error: true })
      })
  }
}

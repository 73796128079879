import React from "react";
import { connect } from 'react-redux'

import DatasetUpload from "../components/DatasetUpload"
import { Card } from 'react-bootstrap'

// Import layout and helper functions/templates
import Header from "../components/Header";

import SimpleTable from '../components/SimpleTable'

import { datasetActions } from "../actions";

class Heatmap extends React.Component{
  constructor() {
    super();
  }

  componentDidMount(){
    this.props.fetchDataSets(sessionStorage.getItem('jwtToken'))
  }

  deleteDataset = (id) => {
    this.props.deleteDataSet(id, sessionStorage.getItem('jwtToken'))
  }

  trashcan_column = {
    accessor: "delete_curveset",
    Cell: ({ original }) => {
      return (
        <i
          className="fa fa-trash"
          style={{ display: "inline-block", width: "100%", textAlign: "center" }}
          onClick={() => {this.deleteDataset(original.dataSetID)}}
        >
        </i>
      );
    },
    header: "Delete Dataset",
    sortable: false,
    width: 45
  };

  render() {
    const renderDatasetOverview = this.props.datasets.isLoading !== true

    const datasetOverview = () => {
      return (
        <SimpleTable
          data={this.props.datasets.data}
          sortColumn='dataSetID'
          sortDescendingOrder={false}
          columns={[
            {accessor: "dataSetID", Header: "Data Set ID",
              Cell: row => (
                <div style={{ textAlign: "right" }}>{row.value}</div>
              )
            },
            {accessor: "dataSetName", Header: "Data Set Name"},
            {accessor: "reportingDate", Header: "Reporting Date",
              Cell: row => (
                <div style={{ textAlign: "right" }}>{new Date(row.value).toLocaleString('nl-NL', {timeZone: 'UTC', year: "numeric", month: "2-digit", day: "2-digit"})}</div>
              )},
            {accessor: "createdOn", Header: "Creation Date",
              Cell: row => (
                <div style={{ textAlign: "right" }}>{new Date(row.value).toLocaleString('nl-NL', {timeZone: 'UTC', year: "numeric", month: "2-digit", day: "2-digit"})}</div>
              )},
            this.trashcan_column,
          ]}
        />
      )

      let datasets = []
      return datasets
    }

    return(
      <div>
        <Header title={"Upload new dataset"} />
        <Card className="mt-4">
          <Card.Header>Upload dataset</Card.Header>
          <Card.Body>
          <DatasetUpload>
            {({getRootProps}) => <div {...getRootProps()} />}
          </DatasetUpload>
          </Card.Body>
        </Card>

        { renderDatasetOverview && (
        <Card className="mt-4">
          <Card.Header>Uploaded Datasets</Card.Header>
          <Card.Body>
            {datasetOverview()}
          </Card.Body>
        </Card>
      )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    authentication: state.authentication,
    loading: state.loading,
    error: state.error,
    datasets: state.datasets,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    deleteDataSet: (id, token) => {
      return dispatch(datasetActions.deleteDataset(id, token));
    },
    fetchDataSets: (token) => {
      return dispatch(datasetActions.fetchDataSets(token));
    },
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(Heatmap);

// Import React & Redux components
import React from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Card,
  Form,
  Row,
  Col,
  Nav,
} from 'react-bootstrap';
import ReactTable from 'react-table';

import { scaleLinear } from 'd3-scale';
import DeckGL, { OrbitView } from 'deck.gl';
import PlotLayer from '../deckgl/plot-layer';

// Import layout and helper functions/templates
import Chart from './Chart';
import Header from './Header';
import ULvsRCChart from './ULvsRCChart';
import Notification from './Notification';
import {
  roundToNearestValue,
  createLoadingSelector,
  createErrorMessageSelector,
  generateCSVFile,
} from './Helpers';

// Import data sources
import {
  assetsperformance,
  portfolioperformance,
  portfoliovalue,
  performancefeatures,
  jobs,
  scenarios,
} from '../actions';

function lookUpInGrid(x, y) {
  // const timepoints = [];
  // const values = [];
  // let timepoint = timepoints.find(elem => elem.timepoint < x);
  // let value = values.find(elem => elem.valueBucket < y);
  // return value_grid[timepoint][value];
  // return value_grid[x][y];
  return x * y;
}

class ResultsSimulation extends React.Component {
  constructor(props) {
    super(props);
    if (this.props.match.params.id === undefined) {
      this.state = {
        aggregatedPortfolioData: {},
        selectedJob: undefined,
        selectedJobID: undefined,
        selectedJobName: undefined,
        selectedScenario: undefined,
        selectedScenarioID: undefined,
        selectedScenarioName: undefined,
        startDateExcessExpectedReturns: {},
        startDateExcessRiskContribution: {},
        startDateExcessTailRiskContribution: {},
        startDateExcessRiskContributionRAROC: {},
        startDateExcessTailRiskContributionRAROC: {},
        productTypeExcessExpectedReturns: {},
        productTypeExcessRiskContribution: {},
        productTypeExcessTailRiskContribution: {},
        productTypeExcessRiskContributionRAROC: {},
        productTypeExcessTailRiskContributionRAROC: {},
        hoverInfo: null,
        evaluationPeriod: 0,
        evaluationRateBucket: 0,
        renderHelp: false,
        helpText: undefined,
        featureIdentifier: 'rating',
        validAggregatedPortfolioValues: false,
      };
    } else {
      this.state = {
        aggregatedPortfolioData: {},
        evaluationPeriod: 0,
        evaluationRateBucket: 0,
        featureIdentifier: 'rating',
        helpText: undefined,
        renderHelp: false,
        selectedJob: undefined,
        selectedJobID: parseInt(this.props.match.params.id, 10),
        selectedJobName: undefined,
        selectedScenario: undefined,
        selectedScenarioID: undefined,
        selectedScenarioName: undefined,
        validAggregatedPortfolioValues: false,
      };
    }
    this.onHover = this.onHover.bind(this);
    this.renderTooltip = this.renderTooltip.bind(this);
    this.saveEvaluationPeriod = this.saveEvaluationPeriod.bind(this);
    this.saveRateBucket = this.saveRateBucket.bind(this);
    this.renderHelp = this.renderHelp.bind(this);
    this.updateFeatureIdentifier = this.updateFeatureIdentifier.bind(this);
    this.updateAggregatedPortfolioValues = this.updateAggregatedPortfolioValues.bind(this);
    this.updateScenarioInState = this.updateScenarioInState.bind(this);
    this.aggregatedPortfolioData = this.aggregatedPortfolioData.bind(this);
  }

  onHover(info) {
    const hoverInfo = info.sample ? info : null;
    if (hoverInfo !== this.state.hoverInfo) {
      this.setState({ hoverInfo });
    }
  }

  renderTooltip() {
    const { hoverInfo } = this.state;
    return (
      hoverInfo && (
        <div className="tooltip" style={{ left: hoverInfo.x, top: hoverInfo.y }}>
          { hoverInfo.sample.map((x) => x.toFixed(3)).join(', ') }
        </div>
      )
    );
  }

  saveEvaluationPeriod = () => {
    this.setState({
      validAggregatedPortfolioValues: false,
      evaluationPeriod: this.refs.evaluationPeriod.value,
    });
  };

  saveRateBucket = () => {
    this.setState({
      validAggregatedPortfolioValues: false,
      evaluationRateBucket: this.refs.rateBucket.value,
    });
  };

  updateFeatureIdentifier = (value) => {
    this.setState({
      featureIdentifier: value,
    });
  };

  // Force a url update to reflected the selected jobID and retrieve the data
  // for that jobID.
  saveJobID = (value) => {
    this.props.history.push(`/performancemeasures/${parseInt(this.refs.jobID.value, 10)}`);
    const selectedJobID = this.refs.jobID.value === undefined
      ? value
      : parseInt(this.refs.jobID.value, 10);
    const jobElem = this.props.jobs.data.filter((elem) => (elem.jobID === selectedJobID))[0];

    this.setState({
      evaluationPeriod: 0,
      selectedJobID,
      selectedJob: jobElem,
      selectedJobName: jobElem.jobName,
      validAggregatedPortfolioValues: false,
    });
    //this.props.fetchAssetsPerformance(selectedJobID, sessionStorage.getItem('jwtToken'));
    this.props.fetchPortfolioPerformance(selectedJobID, sessionStorage.getItem('jwtToken'));
    this.props.fetchPortfolioValues(selectedJobID, sessionStorage.getItem('jwtToken'));
    this.props.fetchPerformanceFeatures(selectedJobID, 'All', sessionStorage.getItem('jwtToken'));
    this.props.fetchScenario(jobElem.scenarioID, sessionStorage.getItem('jwtToken'));
  }

  renderHelp = (content) => {
    if (this.state.renderHelp) {
      this.setState({ renderHelp: false, helpText: undefined });
    } else {
      this.setState({ renderHelp: true, helpText: content });
    }
  }

  saveToExcel = (axislabels, dataserieslabels, dataseries) => {
    generateCSVFile(axislabels, dataserieslabels, dataseries);
    // if(featureset.selectedComponent === undefined) {
    //   this.props.createXLSXPerformanceMeasures(featureset, sessionStorage.getItem('jwtToken'))
    // } else if (featureset.selectedComponent === "Feature") {
    //   this.props.createXLSXPerformanceMeasuresFeatures(featureset,
    //    sessionStorage.getItem('jwtToken'))
    // } else if (featureset.selectedComponent === "StartDate_ExcessExpectedReturns") {
    //   this.props.createXLSXPerformanceFeatures({
    //      jobID: this.state.selectedJobID,
    //      selectedFeature: 'Start_Date',
    //      selectedComponent: 'Excess_Expected_Returns',
    //    }, sessionStorage.getItem('jwtToken'))
    // }
    return false;
  }

  linkULToECOrder = (sortedULContributions, ECContributions) => {
    const sortedArray = sortedULContributions.map((elem) => (
      ECContributions[elem.key]
    ));
    return sortedArray;
  }

  // Load the required data, for performanceMeasures and fetchPerformanceMeasuresFeatures
  // check first if a jobID has been selected as this is required by the API.
  componentDidMount() {
    const { selectedJobID } = this.state;
    this.props.fetchJobs(sessionStorage.getItem('jwtToken'));
    if (this.props.assetsPerformance.isLoading && selectedJobID !== undefined) {
      //this.props.fetchAssetsPerformance(selectedJobID, sessionStorage.getItem('jwtToken'));
    }
    if (this.props.portfolioPerformance.isLoading === true && selectedJobID !== undefined) {
      this.props.fetchPortfolioPerformance(selectedJobID, sessionStorage.getItem('jwtToken'));
    }
    if (this.props.portfolioValues.isLoading === true && selectedJobID !== undefined) {
      this.props.fetchPortfolioValues(selectedJobID, sessionStorage.getItem('jwtToken'));
    }
    if (this.props.performanceFeatures.isLoading === true && selectedJobID !== undefined) {
      this.props.fetchPerformanceFeatures(selectedJobID, 'All', sessionStorage.getItem('jwtToken'));
    }
  }

  componentDidUpdate() {
    if ([this.state.selectedJobID, this.state.selectedJob].includes(undefined)
      && this.props.jobs.isLoading !== true
      && this.props.jobs.data !== undefined
      && this.props.jobs.data.filter((elem) => elem.jobCompletion === 3).length > 0) {
      const eligibleJobs = this.props.jobs.data.filter((elem) => elem.jobCompletion === 3);
      this.saveJobID(eligibleJobs[0].jobID);
    }

    if (this.state.evaluationPeriod === undefined
      && this.props.portfolioPerformance.isLoading !== true
      && this.props.portfolioPerformance.data.length > 0) {
      this.saveEvaluationPeriod(Math.max(this.props.portfolioPerformance.data.length - 2, 0));
    }

    if (!this.props.portfolioValues.isLoading
      && Object.prototype.hasOwnProperty.call(this.props.loading, 'FETCH_PORTFOLIO_VALUE')
      && !this.props.loading.FETCH_PORTFOLIO_VALUE
      && Object.keys(this.props.loading).map((key) => this.props.loading[key]).filter((elem) => elem === true).length === 0
      && !this.props.scenarios.isLoading
      && !this.state.validAggregatedPortfolioValues) {
      this.updateAggregatedPortfolioValues();
    }

    if ((Object.prototype.hasOwnProperty.call(this.props.loading, 'FETCH_SCENARIO'))
      && !this.props.loading.FETCH_SCENARIO
      && this.props.scenarios.data.length > 0
      && this.state.selectedJob !== undefined
      && this.state.selectedScenarioID !== this.props.scenarios.data[0].scenarioID) {
      this.updateScenarioInState();
    }
  }

  updateScenarioInState = () => {
    const targetScenario = this.props.scenarios.data.filter((elem) => (
      elem.scenarioID === this.state.selectedJob.scenarioID
    ));
    if (targetScenario.length > 0) {
      this.setState({
        selectedScenarioName: targetScenario[0].scenarioName,
        selectedScenarioID: targetScenario[0].scenarioID,
        selectedScenario: targetScenario[0],
      });
    } else {
      this.setState({
        selectedScenarioName: undefined,
        selectedScenarioID: undefined,
        selectedScenario: undefined,
        validAggregatedPortfolioValues: false,
      });
    }
  }

  updateAggregatedPortfolioValues = () => {
    const portfolioValuesDict = this.aggregatedPortfolioData();
    if (Object.keys(portfolioValuesDict).length > 0) {
      this.setState({
        aggregatedPortfolioData: portfolioValuesDict,
        validAggregatedPortfolioValues: true,
      });
    }
  }

  aggregatedPortfolioData = () => {
    const portfolioValuesDict = {};
    const { portfolioValues, portfolioPerformance } = this.props;
    const { evaluationPeriod, evaluationRateBucket } = this.state;
    if (portfolioValues.isLoading === true
      || portfolioValues.data.length === 0
      || this.props.scenarios.data.length === 0
    ) {
      return portfolioValuesDict;
    }

    const [scenario] = this.props.scenarios.data;
    const timepoints = [...new Set(portfolioValues.data.map((elem) => elem.date))];
    const interestRateBuckets = Math.min(1000,
      Math.max(20, scenario.numberOfInterestRateTrials / 50));
    const ratingBuckets = Math.min(1000,
      Math.max(20, scenario.numberOfTrials / 50));
    const timestampsInPortValues = [
      ...new Set(portfolioValues.data.map((elem) => elem.date)),
    ].length;
    let portfolioValuesAtTimepoint = [];

    if (scenario.numberOfInterestRateTrials === 0) {
      portfolioValuesAtTimepoint = portfolioValues.data.filter((elem) => (
        elem.date === timepoints[evaluationPeriod]
      ));
    } else {
      portfolioValuesAtTimepoint = this.state.evaluationRateBucket === 0
        ? portfolioValues.data.slice(interestRateBuckets * timestampsInPortValues * 6, -1)
          .filter((elem) => elem.date === timepoints[this.state.evaluationPeriod])
        : portfolioValues.data.slice(portfolioValues.data.length
          - timestampsInPortValues * ratingBuckets)
          .filter((elem) => elem.date === timepoints[evaluationPeriod]);
      const portfolioValuesAtTimepoint5thP = evaluationRateBucket === 0
        ? portfolioValues.data.slice(interestRateBuckets * timestampsInPortValues * 1,
          interestRateBuckets * timestampsInPortValues * 2)
          .filter((elem) => elem.date === timepoints[evaluationPeriod])
        : portfolioValues.data.slice(portfolioValues.data.length
          - timestampsInPortValues * ratingBuckets)
          .filter((elem) => elem.date === timepoints[evaluationPeriod]);
      const portfolioValuesAtTimepoint10thP = evaluationRateBucket === 0
        ? portfolioValues.data.slice(interestRateBuckets * timestampsInPortValues * 2,
          interestRateBuckets * timestampsInPortValues * 3)
          .filter((elem) => elem.date === timepoints[evaluationPeriod])
        : portfolioValues.data.slice(portfolioValues.data.length
          - timestampsInPortValues * ratingBuckets)
          .filter((elem) => elem.date === timepoints[evaluationPeriod]);
      const portfolioValuesAtTimepoint50thP = evaluationRateBucket === 0
        ? portfolioValues.data.slice(interestRateBuckets * timestampsInPortValues * 3,
          interestRateBuckets * timestampsInPortValues * 4)
          .filter((elem) => elem.date === timepoints[evaluationPeriod])
        : portfolioValues.data.slice(portfolioValues.data.length
          - timestampsInPortValues * ratingBuckets)
          .filter((elem) => elem.date === timepoints[evaluationPeriod]);
      const portfolioValuesAtTimepoint90thP = evaluationRateBucket === 0
        ? portfolioValues.data.slice(interestRateBuckets * timestampsInPortValues * 4,
          interestRateBuckets * timestampsInPortValues * 5)
          .filter((elem) => elem.date === timepoints[evaluationPeriod])
        : portfolioValues.data.slice(portfolioValues.data.length
          - timestampsInPortValues * ratingBuckets)
          .filter((elem) => elem.date === timepoints[evaluationPeriod]);
      const portfolioValuesAtTimepoint95thP = evaluationRateBucket === 0
        ? portfolioValues.data.slice(interestRateBuckets * timestampsInPortValues * 5,
          interestRateBuckets * timestampsInPortValues * 6)
          .filter((elem) => elem.date === timepoints[evaluationPeriod])
        : portfolioValues.data.slice(portfolioValues.data.length
          - timestampsInPortValues * ratingBuckets)
          .filter((elem) => elem.date === timepoints[evaluationPeriod]);
      const portfolioValuesAtTimepointExpected = evaluationRateBucket === 0
        ? portfolioValues.data.slice(0, interestRateBuckets * timestampsInPortValues * 1)
          .filter((elem) => elem.date === timepoints[evaluationPeriod])
        : portfolioValues.data.slice(portfolioValues.data.length
          - timestampsInPortValues * ratingBuckets)
          .filter((elem) => elem.date === timepoints[evaluationPeriod]);

      portfolioValuesDict.valueBins_5p = portfolioValuesAtTimepoint5thP
        .map((elem) => elem.valueBin);
      portfolioValuesDict.valueCounts_5p = portfolioValuesAtTimepoint5thP
        .map((elem) => elem.valueCount);
      portfolioValuesDict.valueBins_10p = portfolioValuesAtTimepoint10thP
        .map((elem) => elem.valueBin);
      portfolioValuesDict.valueCounts_10p = portfolioValuesAtTimepoint10thP
        .map((elem) => elem.valueCount);
      portfolioValuesDict.valueBins_50p = portfolioValuesAtTimepoint50thP
        .map((elem) => elem.valueBin);
      portfolioValuesDict.valueCounts_50p = portfolioValuesAtTimepoint50thP
        .map((elem) => elem.valueCount);
      portfolioValuesDict.valueBins_90p = portfolioValuesAtTimepoint90thP
        .map((elem) => elem.valueBin);
      portfolioValuesDict.valueCounts_90p = portfolioValuesAtTimepoint90thP
        .map((elem) => elem.valueCount);
      portfolioValuesDict.valueBins_95p = portfolioValuesAtTimepoint95thP
        .map((elem) => elem.valueBin);
      portfolioValuesDict.valueCounts_95p = portfolioValuesAtTimepoint95thP
        .map((elem) => elem.valueCount);
      portfolioValuesDict.valueBins_Base = portfolioValuesAtTimepointExpected
        .map((elem) => elem.valueBin);
      portfolioValuesDict.valueCounts_Base = portfolioValuesAtTimepointExpected
        .map((elem) => elem.valueCount);
    }

    const valueBinsAtTimepoint = portfolioValuesAtTimepoint.map((elem) => elem.valueBin);
    const valueCountsAtTimepoint = portfolioValuesAtTimepoint.map((elem) => elem.valueCount);
    const lossBinsAtTimepoint = portfolioValuesAtTimepoint.map((elem) => elem.lossBin);
    const lossCountsAtTimepoint = portfolioValuesAtTimepoint.map((elem) => elem.lossCount);

    portfolioValuesDict.valueBins = valueBinsAtTimepoint;
    portfolioValuesDict.valueCounts = valueCountsAtTimepoint;
    portfolioValuesDict.lossBins = lossBinsAtTimepoint;
    portfolioValuesDict.lossCounts = lossCountsAtTimepoint;
    if (portfolioPerformance !== undefined && portfolioPerformance.data.length > 0) {
      portfolioValuesDict.portfolioRiskFreeValue = roundToNearestValue(portfolioPerformance
        .data[evaluationPeriod].portfolioRiskFreeValue, valueBinsAtTimepoint);
      portfolioValuesDict.portfolioExpectedValue = roundToNearestValue(portfolioPerformance
        .data[evaluationPeriod].portfolioExpectedValue, valueBinsAtTimepoint);
      portfolioValuesDict.portfolioEconomicCapital = roundToNearestValue(portfolioPerformance
        .data[evaluationPeriod].portfolioEconomicCapital, lossBinsAtTimepoint);
      portfolioValuesDict.portfolioExpectedShortfall = roundToNearestValue(portfolioPerformance
        .data[evaluationPeriod].portfolioExpectedShortfall
        + portfolioPerformance.data[evaluationPeriod].portfolioEconomicCapital, lossBinsAtTimepoint);
    }
    return portfolioValuesDict;
  };

  render() {
    const showDescriptivesTable = !this.props.portfolioPerformance.isLoading && !(Object.prototype.hasOwnProperty.call(this.props.error, 'FETCH_PORTFOLIO_PERFORMANCE'));
    const keys_to_check = ['FETCH_PORTFOLIO_PERFORMANCE', 'FETCH_PERFORMANCE_MEASURES', 'FETCH_JOBS', 'FETCH_PERFORMANCE_FEATURES'];
    const intersection = keys_to_check.filter(element => Object.keys(this.props.error).includes(element));
    const showError = Object.keys(this.props.error).length > 0 && (Object.prototype.hasOwnProperty.call(this.props.error, 'FETCH_PORTFOLIO_PERFORMANCE') || Object.prototype.hasOwnProperty.call(this.props.error, 'FETCH_PERFORMANCE_MEASURES') || Object.prototype.hasOwnProperty.call(this.props.error, 'FETCH_JOBS') || Object.prototype.hasOwnProperty.call(this.props.error, 'FETCH_PERFORMANCE_FEATURES'));
    const showLoadingScreen = this.props.jobs.isLoading === true;
    const showSelectionMenu = this.props.jobs.isLoading !== true
      && this.props.jobs.data !== undefined;
    const showTimestepMenu = this.props.portfolioPerformance.isLoading !== true
      && this.props.portfolioPerformance.data !== undefined;
    //const renderULvsECChart = this.props.assetsPerformance.isLoading !== true;
    const renderULvsECChart = false;
    const renderPortfolioValues = !this.props.portfolioValues.isLoading
      && !this.props.portfolioPerformance.isLoading
      && this.state.selectedScenario !== undefined
      && this.state.validAggregatedPortfolioValues;
    // const renderRateStressPercentiles = this.props.scenarios.isLoading !== true
    //  && this.state.selectedScenario.numberOfInterestRateTrials > 0
    //  && Object.keys(this.state.aggregatedPortfolioData).length > 0;
    const renderRateStressPercentiles = false;
    const renderRateStressCombinedOverview = this.state.selectedScenario !== undefined
      && this.state.selectedScenario.numberOfInterestRateTrials > 0
      && this.state.validAggregatedPortfolioValues;
    //const render3DPortfolioValues = this.props.portfolioValues.isLoading !== true
    //  && this.state.selectedScenario !== undefined
    //  && this.props.portfolioValues !== undefined
    //  && [...new Set(this.props.portfolioValues.data.map((elem) => elem.date))].length > 1;
    const render3DPortfolioValues = false;
    const renderIdentifierSelector = this.props.performanceFeatures.isLoading !== true;
    // const renderPortfolioLosses = Object.keys(this.state.aggregatedPortfolioData).length !== 0;
    const renderPortfolioLosses = false;
    const renderStartDateExcessExpectedReturns = !this.props.performanceFeatures.isLoading && !showError && (this.state.featureIdentifier === 'startDate' || this.state.featureIdentifier === 'all');
    const renderStartDateExcessRiskContribution = !this.props.performanceFeatures.isLoading && !showError && (this.state.featureIdentifier === 'startDate' || this.state.featureIdentifier === 'all');
    const renderStartDateExcessTailRiskContribution = !this.props.performanceFeatures.isLoading && !showError && (this.state.featureIdentifier === 'startDate' || this.state.featureIdentifier === 'all');
    const renderStartDateExcessRiskContributionRAROC = !this.props.performanceFeatures.isLoading && !showError && (this.state.featureIdentifier === 'startDate' || this.state.featureIdentifier === 'all');
    const renderStartDateExcessTailRiskContributionRAROC = !this.props.performanceFeatures.isLoading && !showError && (this.state.featureIdentifier === 'startDate' || this.state.featureIdentifier === 'all');
    const renderRiskRatingExcessExpectedReturns = !this.props.performanceFeatures.isLoading && !showError && (this.state.featureIdentifier === 'rating' || this.state.featureIdentifier === 'all');
    const renderRiskRatingExcessRiskContribution = !this.props.performanceFeatures.isLoading && !showError && (this.state.featureIdentifier === 'rating' || this.state.featureIdentifier === 'all');
    const renderRiskRatingExcessTailRiskContribution = !this.props.performanceFeatures.isLoading && !showError && (this.state.featureIdentifier === 'rating' || this.state.featureIdentifier === 'all');
    const renderRiskRatingExcessRiskContributionRAROC = !this.props.performanceFeatures.isLoading && !showError && (this.state.featureIdentifier === 'rating' || this.state.featureIdentifier === 'all');
    const renderRiskRatingExcessTailRiskContributionRAROC = !this.props.performanceFeatures.isLoading && !showError && (this.state.featureIdentifier === 'rating' || this.state.featureIdentifier === 'all');
    const renderProductTypeExcessExpectedReturns = !this.props.performanceFeatures.isLoading && !showError && (this.state.featureIdentifier === 'productType' || this.state.featureIdentifier === 'all');
    const renderProductTypeExcessRiskContribution = !this.props.performanceFeatures.isLoading && !showError && (this.state.featureIdentifier === 'productType' || this.state.featureIdentifier === 'all');
    const renderProductTypeExcessTailRiskContribution = !this.props.performanceFeatures.isLoading && !showError && (this.state.featureIdentifier === 'productType' || this.state.featureIdentifier === 'all');
    const renderProductTypeExcessRiskContributionRAROC = !this.props.performanceFeatures.isLoading && !showError && (this.state.featureIdentifier === 'productType' || this.state.featureIdentifier === 'all');
    const renderProductTypeExcessTailRiskContributionRAROC = !this.props.performanceFeatures.isLoading && !showError && (this.state.featureIdentifier === 'productType' || this.state.featureIdentifier === 'all');
    const renderDepartmentExcessExpectedReturns = !this.props.performanceFeatures.isLoading && !showError && (this.state.featureIdentifier === 'department' || this.state.featureIdentifier === 'all');
    const renderDepartmentExcessRiskContribution = !this.props.performanceFeatures.isLoading && !showError && (this.state.featureIdentifier === 'department' || this.state.featureIdentifier === 'all');
    const renderDepartmentExcessTailRiskContribution = !this.props.performanceFeatures.isLoading && !showError && (this.state.featureIdentifier === 'department' || this.state.featureIdentifier === 'all');
    const renderDepartmentExcessRiskContributionRAROC = !this.props.performanceFeatures.isLoading && !showError && (this.state.featureIdentifier === 'department' || this.state.featureIdentifier === 'all');
    const renderDepartmentExcessTailRiskContributionRAROC = !this.props.performanceFeatures.isLoading && !showError && (this.state.featureIdentifier === 'department' || this.state.featureIdentifier === 'all');
    const renderRegionExcessExpectedReturns = !this.props.performanceFeatures.isLoading && !showError && (this.state.featureIdentifier === 'region' || this.state.featureIdentifier === 'all');
    const renderRegionExcessRiskContribution = !this.props.performanceFeatures.isLoading && !showError && (this.state.featureIdentifier === 'region' || this.state.featureIdentifier === 'all');
    const renderRegionExcessTailRiskContribution = !this.props.performanceFeatures.isLoading && !showError && (this.state.featureIdentifier === 'region' || this.state.featureIdentifier === 'all');
    const renderRegionExcessRiskContributionRAROC = !this.props.performanceFeatures.isLoading && !showError && (this.state.featureIdentifier === 'region' || this.state.featureIdentifier === 'all');
    const renderRegionExcessTailRiskContributionRAROC = !this.props.performanceFeatures.isLoading && !showError && (this.state.featureIdentifier === 'region' || this.state.featureIdentifier === 'all');

    const [scenario] = this.props.scenarios.data;
    const portfolioValuesOverTimeChart = () => {
      const interestRateBuckets = Math.min(1000,
        Math.max(20, scenario.numberOfInterestRateTrials / 50));
      const timestampsInPortValues = [
        ...new Set(this.props.portfolioValues.data.map((elem) => elem.date)),
      ].length;
      const timepoints = [
        ...new Set(this.props.portfolioValues.data
          .slice(interestRateBuckets * timestampsInPortValues * 6, -1)
          .map((elem) => elem.date)),
      ];

      const portfolioValues = [
        ...new Set(this.props.portfolioValues.data
          .slice(interestRateBuckets * timestampsInPortValues * 6, -1)
          .map((elem) => elem.valueBin)),
      ];

      const dataGrid = [];
      timepoints.forEach((item) => {
        const elems = this.props.portfolioValues.data
          .slice(interestRateBuckets * timestampsInPortValues * 6, -1)
          .filter((elem) => elem.date === item);
        dataGrid.push(elems.map((elem) => elem.valueCount));
      });

      const resolution = dataGrid.length > 0 ? dataGrid[0].length : false;

      const INITIAL_VIEW_STATE = {
        target: [0.5, 0.5, 0.5],
        orbitAxis: 'Y',
        rotationX: 20,
        rotationOrbit: 150,
        /* global window */
        // fit 3x3x3 box in current viewport
        zoom: Math.log2(window.innerHeight / 4),
      };

      function getScale({ min, max }) {
        return scaleLinear()
          .domain([min, max])
          .range([0, 1]);
      }

      // move to: https://observablehq.com/@pessimistress/deck-gl-pointcloudlayer-example
      const layers = [
        lookUpInGrid
        && resolution
        && new PlotLayer({
          getPosition: (u, v) => {
            const timesteps = timepoints.length - 1;
            const x = u * timesteps;
            const y = v * (resolution - 1);
            const timepointIndex = Math.min(Math.floor(x), timesteps);
            const valueIndex = Math.min(Math.floor(y), resolution - 1);
            return [x, y, dataGrid[timepointIndex][valueIndex]];
          },
          getColor: (x, y, z) => [40, z * 128 + 128, 160],
          getXScale: getScale,
          getYScale: getScale,
          getZScale: getScale,
          uCount: resolution,
          vCount: resolution,
          drawAxes: true,
          axesPadding: 0.25,
          axesColor: [0, 0, 0, 128],
          opacity: 1,
          // Put an array of values!
          xTicks: 6,
          yTicks: 6,
          zTicks: 6,
          xTickFormat: (value) => new Date(timepoints[value]).toLocaleString('en-GB', { year: 'numeric', month: '2-digit', day: '2-digit' }),
          yTickFormat: (value) => (portfolioValues[value] !== undefined
            ? portfolioValues[value].toLocaleString('en-GB', {
              style: 'currency',
              currency: 'EUR',
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            })
            : undefined),
          zTickFormat: (value) => value.toFixed(0),
          xTitle: 'time',
          yTitle: 'value',
          zTitle: 'count',
          pickable: true,
          onHover: this.onHover,
          // Fix this guy
          updateTriggers: {
            // getPosition: this.lookUpInGrid
          },
        }),
      ];

      return (
        <div>
        {this.renderTooltip()}
        <DeckGL
          layers={layers}
          views={new OrbitView()}
          initialViewState={INITIAL_VIEW_STATE}
          controller={true}
        >
        </DeckGL>
        </div>
      );
    };

    /* const capitalOverTimeChart = () => {
      const capital = this.props.portfolioPerformance.data;
      return (
        <Chart type={'Line'}
          unit={'decimal'}
          xaxislabels={capital.map((elem) => elem.timepoint)}
          dataseries={capital.map((elem) => elem.value)}
          dataserieslabels={['Unexpected Loss']}
        />
      );
    }; */

    const startDateExcessExpectedReturnsChart = () => {
      const excessExpectedReturnsStartDate = this.props.performanceFeatures.data.filter((elem) => (
        elem.measureID === 0 && elem.featureName === 'Region_Booking_Entity'
      ));
      const labels = [
        ...new Set(excessExpectedReturnsStartDate.map((elem) => elem.featureLabelName)),
      ];
      const values = excessExpectedReturnsStartDate.map((elem) => elem.measureValue);
      return (
        <React.Fragment>
        <Card.Header className="card-header-with-btn">
          <div className="pull-left">Start Date - Excess Expected Returns</div>
          <div className="fa-pull-right">
            <Button className="fa-icon" variant="secondary" size="sm" onClick={() => this.saveToExcel(labels, ['Excess Expected Returns'], values)}>
              <span className="fa fa-save"></span>
              <span> Export</span>
            </Button>
            <span
              className="ml-3 question-mark"
              onClick={() => this.renderHelp({
                header: 'Start Date - Excess Expected Returns',
                body: 'Expected returns in excess of the full portfolio’s return.',
              })}
            ></span>
          </div>
        </Card.Header>
        <Card.Body>
          <Chart type={'Stacked Bar'}
            unit={'percent'}
            positive={'Positive Impact'}
            xaxislabels={labels}
            dataseries={[values]}
            dataserieslabels={['Excess Expected Returns']}
          />
        </Card.Body>
        </React.Fragment>
      );
    };

    const startDateExcessRiskContributionChart = () => {
      const excessRiskContributionStartDate = this.props.performanceFeatures.data.filter((elem) => (
        elem.measureID === 1 && elem.featureName === 'Region_Booking_Entity'
      ));
      const labels = [
        ...new Set(excessRiskContributionStartDate.map((elem) => elem.featureLabelName)),
      ];
      const values = excessRiskContributionStartDate.map((elem) => elem.measureValue);
      return (
        <React.Fragment>
        <Card.Header className="card-header-with-btn">
          <div className="pull-left">Start Date - Excess Risk Contribution</div>
          <div className="fa-pull-right">
            <Button className="fa-icon" variant="secondary" size="sm" onClick={() => this.saveToExcel(labels, ['Excess Risk Contribution'], values)}>
              <span className="fa fa-save"></span>
              <span> Export</span>
            </Button>
            <span
              className="ml-3 question-mark"
              onClick={() => this.renderHelp({
                header: 'Start Date - Excess Risk Contribution',
                body: 'Excess risk contribution to full portfolio’s economic capital. A negative value indicates above average economic capital requirements for the respective sub-portfolio.',
              })}
            ></span>
          </div>
        </Card.Header>
        <Card.Body>
          <Chart type={'Stacked Bar'}
            unit={'percent'}
            positive={'Positive Impact'}
            xaxislabels={labels}
            dataseries={[values]}
            dataserieslabels={['Excess Risk Contribution']}
          />
        </Card.Body>
        </React.Fragment>
      );
    };

    const startDateExcessTailRiskContributionChart = () => {
      const { performanceFeatures } = this.props;
      const excessTailRiskContributionStartDate = performanceFeatures.data.filter((elem) => (
        elem.measureID === 3 && elem.featureName === 'Region_Booking_Entity'
      ));
      const labels = [
        ...new Set(excessTailRiskContributionStartDate.map((elem) => elem.featureLabelName)),
      ];
      const values = excessTailRiskContributionStartDate.map((elem) => elem.measureValue);
      return (
        <React.Fragment>
        <Card.Header>
          <div className="fa-pull-left">Start Date - Excess Tail Risk Contribution</div>
          <div className="fa-pull-right">
            <Button className="fa-icon" variant="secondary" size="sm" onClick={() => this.saveToExcel(labels, ['Excess Tail Risk Contribution'], values)}>
              <span className="fa fa-save"></span>
              <span> Export</span>
            </Button>
            <span
              className="ml-3 question-mark"
              onClick={() => this.renderHelp({
                header: 'Start Date - Excess Tail Risk Contribution',
                body: 'Excess risk contribution to full portfolio\'s expected shortfall. A negative value indicates higher tail risk contribution or, in other words, higher probability of extreme losses above capital level.',
              })}
            ></span>
          </div>
        </Card.Header>
        <Card.Body>
          <Chart type={'Stacked Bar'}
            unit={'percent'}
            positive={'Positive Impact'}
            xaxislabels={labels}
            dataseries={[values]}
            dataserieslabels={['Excess Tail Risk Contribution']}
          />
        </Card.Body>
        </React.Fragment>
      );
    };

    const startDateExcessRiskContributionRAROCChart = () => {
      const { performanceFeatures } = this.props;
      const excessRiskContributionRAROCStartDate = performanceFeatures.data.filter((elem) => (
        elem.measureID === 2 && elem.featureName === 'Region_Booking_Entity'
      ));
      const labels = [
        ...new Set(excessRiskContributionRAROCStartDate.map((elem) => elem.featureLabelName)),
      ];
      const values = excessRiskContributionRAROCStartDate.map((elem) => elem.measureValue);
      return (
        <React.Fragment>
        <Card.Header>
          <div className="fa-pull-left">Start Date - Excess Risk Contribution RAROC</div>
          <div className="fa-pull-right">
            <Button className="fa-icon" variant="secondary" size="sm" onClick={() => this.saveToExcel(labels, ['Excess Risk Contribution RAROC'], values)}>
              <span className="fa fa-save"></span>
              <span> Export</span>
            </Button>
            <span
              className="ml-3 question-mark"
              onClick={() => this.renderHelp({
                header: 'Start Date - Excess Risk Contribution RAROC',
                body: 'Ratio of expected spread (sum of expected interest income minus funding costs) over the risk contribution, compared to the full portfolio. A positive ratio indicates positive performance impact with respect to the full portfolio, driven by higher expected return, lower risk contribution, or a combination of both.',
              })}
            ></span>
          </div>
        </Card.Header>
        <Card.Body>
          <Chart type={'Stacked Bar'}
            unit={'percent'}
            positive={'Positive Impact'}
            xaxislabels={labels}
            dataseries={[values]}
            dataserieslabels={['Excess Risk Contribution RAROCs']}
          />
        </Card.Body>
        </React.Fragment>
      );
    };

    const startDateExcessTailRiskContributionRAROCChart = () => {
      const { performanceFeatures } = this.props;
      const excessTailRiskContributionRAROCStartDate = performanceFeatures.data.filter((elem) => (
        elem.measureID === 4 && elem.featureName === 'Region_Booking_Entity'
      ));
      const labels = [
        ...new Set(excessTailRiskContributionRAROCStartDate.map((elem) => elem.featureLabelName)),
      ];
      const values = excessTailRiskContributionRAROCStartDate.map((elem) => elem.measureValue);
      return (
        <React.Fragment>
        <Card.Header>
          <div className="fa-pull-left">Start Date - Excess Tail Risk Contribution RAROC</div>
          <div className="fa-pull-right">
            <Button className="fa-icon" variant="secondary" size="sm" onClick={() => this.saveToExcel(labels, ['Excess Tail Risk Contribution RAROC'], values)}>
              <span className="fa fa-save"></span>
              <span> Export</span>
            </Button>
            <span
              className="ml-3 question-mark"
              onClick={() => this.renderHelp({
                header: 'Start Date - Excess Tail Risk Contribution RAROC',
                body: 'Ratio of expected spread (sum of expected interest income minus funding costs) over the tail risk contribution, compared to the full portfolio. A positive ratio indicates positive performance impact with respect to the full portfolio, driven by higher expected return, lower tail risk contribution, or a combination of both.',
              })}
            ></span>
          </div>
        </Card.Header>
        <Card.Body>
          <Chart type={'Stacked Bar'}
            unit={'percent'}
            positive={'Positive Impact'}
            xaxislabels={labels}
            dataseries={[values]}
            dataserieslabels={['Excess Tail Risk Contribution RAROCs']}
          />
        </Card.Body>
        </React.Fragment>
      );
    };

    const riskRatingExcessExpectedReturnsChart = () => {
      const { performanceFeatures } = this.props;
      const excessExpectedReturnsRiskRating = performanceFeatures.data.filter((elem) => (
        elem.measureID === 0 && elem.featureName === 'Rating'
      ));
      const labels = [...new Set(excessExpectedReturnsRiskRating.filter((elem) => (
        Math.abs(elem.measureValue) < 1
      )).map((elem) => elem.featureLabelName))];
      const values = excessExpectedReturnsRiskRating.map((elem) => elem.measureValue);
      return (
        <React.Fragment>
        <Card.Header className="card-header-with-btn">
          <div className="fa-pull-left">Risk Rating - Excess Expected Returns</div>
          <div className="fa-pull-right">
            <Button className="fa-icon" variant="secondary" size="sm" onClick={() => this.saveToExcel(labels, ['Excess Expected Returns'], values)}>
              <span className="fa fa-save"></span>
              <span> Export</span>
            </Button>
            <span
              className="ml-3 question-mark"
              onClick={() => this.renderHelp({
                header: 'Risk Rating - Excess Expected Returns',
                body: 'Expected returns in excess of the full portfolio’s return',
              })}
            ></span>
          </div>
        </Card.Header>
        <Card.Body>
        <Chart type={'Stacked Bar'}
          unit={'percent'}
          positive={'Positive Impact'}
          xaxislabels={labels}
          dataseries={[values]}
          dataserieslabels={['Excess Expected Returns']}
        />
        </Card.Body>
        </React.Fragment>
      );
    };

    const riskRatingExcessRiskContributionChart = () => {
      const { performanceFeatures } = this.props;
      const excessRiskContributionRiskRating = performanceFeatures.data.filter((elem) => (
        elem.measureID === 1 && elem.featureName === 'Rating'
      ));
      const labels = [...new Set(excessRiskContributionRiskRating.filter((elem) => (
        Math.abs(elem.measureValue) < 1
      )).map((elem) => elem.featureLabelName))];
      const values = excessRiskContributionRiskRating.filter((elem) => (
        Math.abs(elem.measureValue) < 1
      )).map((elem) => elem.measureValue);
      return (
        <React.Fragment>
        <Card.Header className="card-header-with-btn">
          <div className="fa-pull-left">Risk Rating - Excess Risk Contribution</div>
          <div className="fa-pull-right">
            <Button className="fa-icon" variant="secondary" size="sm" onClick={() => this.saveToExcel(labels, ['Excess Risk Contribution'], values)}>
              <span className="fa fa-save"></span>
              <span> Export</span>
            </Button>
            <span
              className="ml-3 question-mark"
              onClick={() => this.renderHelp({
                header: 'Risk Rating - Excess Risk Contribution',
                body: 'Excess risk contribution to full portfolio’s economic capital. A negative value indicates above average economic capital requirements for the respective sub-portfolio.',
              })}
            ></span>
          </div>
        </Card.Header>
        <Card.Body>
          <Chart type={'Stacked Bar'}
            unit={'percent'}
            positive={'Positive Impact'}
            xaxislabels={labels}
            dataseries={[values]}
            dataserieslabels={['Excess Risk Contribution']}
          />
        </Card.Body>
        </React.Fragment>
      );
    };

    const riskRatingExcessTailRiskContributionChart = () => {
      const { performanceFeatures } = this.props;
      const excessTailRiskContributionRiskRating = performanceFeatures.data.filter((elem) => (
        elem.measureID === 2 && elem.featureName === 'Rating'
      ));
      const labels = [...new Set(excessTailRiskContributionRiskRating.filter((elem) => (
        Math.abs(elem.measureValue) < 1
      )).map((elem) => elem.featureLabelName))];
      const values = excessTailRiskContributionRiskRating.filter((elem) => (
        Math.abs(elem.measureValue) < 1
      )).map((elem) => elem.measureValue);
      return (
        <React.Fragment>
        <Card.Header>
          <div className="fa-pull-left">Risk Rating - Excess Tail Risk Contribution</div>
          <div className="fa-pull-right">
            <Button className="fa-icon" variant="secondary" size="sm" onClick={() => this.saveToExcel(labels, ['Excess Tail Risk Contribution'], values)}>
              <span className="fa fa-save"></span>
              <span> Export</span>
            </Button>
            <span
              className="ml-3 question-mark"
              onClick={() => this.renderHelp({
                header: 'Risk Rating - Excess Tail Risk Contribution',
                body: 'Excess risk contribution to full portfolio\'s expected shortfall. A negative value indicates higher tail risk contribution or, in other words, higher probability of extreme losses above capital level.',
              })}
            ></span>
          </div>
        </Card.Header>
        <Card.Body>
          <Chart type={'Stacked Bar'}
            unit={'percent'}
            positive={'Positive Impact'}
            xaxislabels={labels}
            dataseries={[values]}
            dataserieslabels={['Excess Tail Risk Contribution']}
          />
        </Card.Body>
        </React.Fragment>
      );
    };

    const riskRatingExcessRiskContributionRAROCChart = () => {
      const { performanceFeatures } = this.props;
      const excessRiskContributionRAROCRiskRating = performanceFeatures.data.filter((elem) => (
        elem.measureID === 3 && elem.featureName === 'Rating'
      ));
      const labels = [
        ...new Set(excessRiskContributionRAROCRiskRating.filter((elem) => (
          Math.abs(elem.measureValue) < 1
        )).map((elem) => elem.featureLabelName)),
      ];
      const values = excessRiskContributionRAROCRiskRating.filter((elem) => (
        Math.abs(elem.measureValue) < 1
      )).map((elem) => elem.measureValue);
      return (
        <React.Fragment>
        <Card.Header>
          <div className="fa-pull-left">Risk Rating - Excess Risk Contribution RAROC</div>
          <div className="fa-pull-right">
            <Button className="fa-icon" variant="secondary" size="sm" onClick={() => this.saveToExcel(labels, ['Excess Risk Contribution RAROC'], values)}>
              <span className="fa fa-save"></span>
              <span> Export</span>
            </Button>
            <span
              className="ml-3 question-mark"
              onClick={() => this.renderHelp({
                header: 'Risk Rating - Excess Risk Contribution RAROC',
                body: 'Ratio of expected spread (sum of expected interest income minus funding costs) over the risk contribution, compared to the full portfolio. A positive ratio indicates positive performance impact with respect to the full portfolio, driven by higher expected return, lower risk contribution, or a combination of both.',
              })}
            ></span>
          </div>
        </Card.Header>
        <Card.Body>
          <Chart type={'Stacked Bar'}
            unit={'percent'}
            positive={'Positive Impact'}
            xaxislabels={labels}
            dataseries={[values]}
            dataserieslabels={['Excess Risk Contribution RAROCs']}
          />
        </Card.Body>
        </React.Fragment>
      );
    };

    const riskRatingExcessTailRiskContributionRAROCChart = () => {
      const { performanceFeatures } = this.props;
      const excessTailRiskContributionRAROCRiskRating = performanceFeatures.data.filter((elem) => (
        elem.measureID === 4 && elem.featureName === 'Rating'
      ));
      const labels = [
        ...new Set(excessTailRiskContributionRAROCRiskRating.filter((elem) => (
          Math.abs(elem.measureValue) < 1
        )).map((elem) => elem.featureLabelName)),
      ];
      const values = excessTailRiskContributionRAROCRiskRating.filter((elem) => (
        Math.abs(elem.measureValue) < 1
      )).map((elem) => elem.measureValue);
      return (
        <React.Fragment>
        <Card.Header>
          <div className="fa-pull-left">Risk Rating - Excess Tail Risk Contribution RAROC</div>
          <div className="fa-pull-right">
            <Button className="fa-icon" variant="secondary" size="sm" onClick={() => this.saveToExcel(labels, ['Excess Tail Risk Contribution RAROC'], values)}>
              <span className="fa fa-save"></span>
              <span> Export</span>
            </Button>
            <span
              className="ml-3 question-mark"
              onClick={() => this.renderHelp({
                header: 'Risk Rating - Excess Tail Risk Contribution RAROC',
                body: 'Ratio of expected spread (sum of expected interest income minus funding costs) over the tail risk contribution, compared to the full portfolio. A positive ratio indicates positive performance impact with respect to the full portfolio, driven by higher expected return, lower tail risk contribution, or a combination of both.',
              })}
            ></span>
          </div>
        </Card.Header>
        <Card.Body>
          <Chart type={'Stacked Bar'}
            unit={'percent'}
            positive={'Positive Impact'}
            xaxislabels={labels}
            dataseries={[values]}
            dataserieslabels={['Excess Tail Risk Contribution RAROCs']}
          />
        </Card.Body>
        </React.Fragment>
      );
    };

    const productTypeExcessExpectedReturnsChart = () => {
      const { performanceFeatures } = this.props;
      const excessExpectedReturnsProductType = performanceFeatures.data.filter((elem) => (
        elem.measureID === 0 && elem.featureName === 'Redemption_Type'
      ));
      const labels = [
        ...new Set(excessExpectedReturnsProductType.map((elem) => elem.featureLabelName)),
      ];
      const values = excessExpectedReturnsProductType.map((elem) => elem.measureValue);

      const labelToTagMapping = [
        {label: 0, description: "Interest Only"},
        {label: 1, description: "Linear"},
        {label: 2, description: "Annuity"},
      ]

      const mappedLabels = labels.map((label) => (
        labelToTagMapping.filter((tag) => tag.label == label)[0].description
      ))

      return (
        <React.Fragment>
        <Card.Header>
          <div className="fa-pull-left">Product Type - Excess Expected Returns</div>
          <div className="fa-pull-right">
            <Button className="fa-icon" variant="secondary" size="sm" onClick={() => this.saveToExcel(labels, ['Excess Expected Returns'], values)}>
              <span className="fa fa-save"></span>
              <span> Export</span>
            </Button>
            <span
              className="ml-3 question-mark"
              onClick={() => this.renderHelp({
                header: 'Product Type - Excess Expected Returns',
                body: 'Expected returns in excess of the full portfolio’s return',
              })}
            ></span>
          </div>
        </Card.Header>
        <Card.Body>
          <Chart type={'Stacked Bar'}
            unit={'percent'}
            positive={'Positive Impact'}
            xaxislabels={mappedLabels}
            dataseries={[values]}
            dataserieslabels={['Excess Expected Returns']}
          />
        </Card.Body>
        </React.Fragment>
      );
    };

    const productTypeExcessRiskContributionChart = () => {
      const { performanceFeatures } = this.props;
      const excessRiskContributionProductType = performanceFeatures.data.filter((elem) => (
        elem.measureID === 1 && elem.featureName === 'Redemption_Type'
      ));
      const labels = [
        ...new Set(excessRiskContributionProductType.map((elem) => elem.featureLabelName)),
      ];
      const values = excessRiskContributionProductType.filter((elem) => (
        Math.abs(elem.measureValue) < 1
      )).map((elem) => elem.measureValue);

      const labelToTagMapping = [
        {label: 0, description: "Interest Only"},
        {label: 1, description: "Linear"},
        {label: 2, description: "Annuity"},
      ]

      const mappedLabels = labels.map((label) => (
        labelToTagMapping.filter((tag) => tag.label == label)[0].description
      ))

      return (
        <React.Fragment>
        <Card.Header>
          <div className="fa-pull-left">Product Type - Excess Risk Contribution</div>
          <div className="fa-pull-right">
            <Button className="fa-icon" variant="secondary" size="sm" onClick={() => this.saveToExcel(labels, ['Excess Risk Contribution'], values)}>
              <span className="fa fa-save"></span>
              <span> Export</span>
            </Button>
            <span
              className="ml-3 question-mark"
              onClick={() => this.renderHelp({
                header: 'Product Type - Excess Risk Contribution',
                body: 'Excess risk contribution to full portfolio’s economic capital. A negative value indicates above average economic capital requirements for the respective sub-portfolio.',
              })}
            ></span>
          </div>
        </Card.Header>
        <Card.Body>
          <Chart type={'Stacked Bar'}
            unit={'percent'}
            positive={'Positive Impact'}
            xaxislabels={mappedLabels}
            dataseries={[values]}
            dataserieslabels={['Excess Risk Contribution']}
          />
        </Card.Body>
        </React.Fragment>
      );
    };

    const productTypeExcessTailRiskContributionChart = () => {
      const { performanceFeatures } = this.props;
      const excessTailRiskContributionProductType = performanceFeatures.data.filter((elem) => (
        elem.measureID === 2 && elem.featureName === 'Redemption_Type'
      ));
      const labels = [
        ...new Set(excessTailRiskContributionProductType.map((elem) => elem.featureLabelName)),
      ];
      const values = excessTailRiskContributionProductType.map((elem) => elem.measureValue);

      const labelToTagMapping = [
        {label: 0, description: "Interest Only"},
        {label: 1, description: "Linear"},
        {label: 2, description: "Annuity"},
      ]

      const mappedLabels = labels.map((label) => (
        labelToTagMapping.filter((tag) => tag.label == label)[0].description
      ))

      return (
        <React.Fragment>
        <Card.Header>
          <div className="fa-pull-left">Product Type - Excess Tail Risk Contribution</div>
          <div className="fa-pull-right">
            <Button className="fa-icon" variant="secondary" size="sm" onClick={() => this.saveToExcel(labels, ['Excess Tail Risk Contribution'], values)}>
              <span className="fa fa-save"></span>
              <span> Export</span>
            </Button>
            <span
              className="ml-3 question-mark"
              onClick={() => this.renderHelp({
                header: 'Product Type - Excess Risk Contribution',
                body: 'Excess risk contribution to full portfolio\'s expected shortfall. A negative value indicates higher tail risk contribution or, in other words, higher probability of extreme losses above capital level.',
              })}
            ></span>
          </div>
        </Card.Header>
        <Card.Body>
          <Chart type={'Stacked Bar'}
            unit={'percent'}
            positive={'Positive Impact'}
            xaxislabels={mappedLabels}
            dataseries={[values]}
            dataserieslabels={['Excess Tail Risk Contribution']}
          />
        </Card.Body>
        </React.Fragment>
      );
    };

    const productTypeExcessRiskContributionRAROCChart = () => {
      const { performanceFeatures } = this.props;
      const excessRiskContributionRAROCProductType = performanceFeatures.data.filter((elem) => (
        elem.measureID === 3 && elem.featureName === 'Redemption_Type'
      ));
      const labels = [
        ...new Set(excessRiskContributionRAROCProductType.map((elem) => elem.featureLabelName)),
      ];
      const values = excessRiskContributionRAROCProductType.map((elem) => elem.measureValue);

      const labelToTagMapping = [
        {label: 0, description: "Interest Only"},
        {label: 1, description: "Linear"},
        {label: 2, description: "Annuity"},
      ]

      const mappedLabels = labels.map((label) => (
        labelToTagMapping.filter((tag) => tag.label == label)[0].description
      ))

      return (
        <React.Fragment>
        <Card.Header>
          <div className="fa-pull-left">Product Type - Excess Risk Contribution RAROC</div>
          <div className="fa-pull-right">
            <Button className="fa-icon" variant="secondary" size="sm" onClick={() => this.saveToExcel(labels, ['Excess Risk Contribution RAROC'], values)}>
              <span className="fa fa-save"></span>
              <span> Export</span>
            </Button>
            <span
              className="ml-3 question-mark"
              onClick={() => this.renderHelp({
                header: 'Product Type - Excess Risk Contribution RAROC',
                body: 'Ratio of expected spread (sum of expected interest income minus funding costs) over the risk contribution, compared to the full portfolio. A positive ratio indicates positive performance impact with respect to the full portfolio, driven by higher expected return, lower risk contribution, or a combination of both.',
              })}
            ></span>
          </div>
        </Card.Header>
        <Card.Body>
          <Chart type={'Stacked Bar'}
            unit={'percent'}
            positive={'Positive Impact'}
            xaxislabels={mappedLabels}
            dataseries={[values]}
            dataserieslabels={['Excess Risk Contribution RAROCs']}
          />
        </Card.Body>
        </React.Fragment>
      );
    };

    const productTypeExcessTailRiskContributionRAROCChart = () => {
      const { performanceFeatures } = this.props;
      const excessTailRiskContributionRAROCProductType = performanceFeatures.data.filter((elem) => (
        elem.measureID === 4 && elem.featureName === 'Product_Type'
      ));
      const labels = [
        ...new Set(excessTailRiskContributionRAROCProductType.map((elem) => elem.featureLabelName)),
      ];
      const values = excessTailRiskContributionRAROCProductType.map((elem) => elem.measureValue);

      const labelToTagMapping = [
        {label: 0, description: "Interest Only"},
        {label: 1, description: "Linear"},
        {label: 2, description: "Annuity"},
      ]

      const mappedLabels = labels.map((label) => (
        labelToTagMapping.filter((tag) => tag.label == label)[0].description
      ))

      return (
        <React.Fragment>
        <Card.Header>
          <div className="fa-pull-left">Product Type - Excess Tail Risk Contribution RAROC</div>
          <div className="fa-pull-right">
            <Button className="fa-icon" variant="secondary" size="sm" onClick={() => this.saveToExcel(labels, ['Excess Tail Risk Contribution RAROC'], values)}>
              <span className="fa fa-save"></span>
              <span> Export</span>
            </Button>
            <span
              className="ml-3 question-mark"
              onClick={() => this.renderHelp({
                header: 'Product Type - Excess Tail Risk Contribution RAROC',
                body: 'Ratio of expected spread (sum of expected interest income minus funding costs) over the tail risk contribution, compared to the full portfolio. A positive ratio indicates positive performance impact with respect to the full portfolio, driven by higher expected return, lower tail risk contribution, or a combination of both.',
              })}
            ></span>
          </div>
        </Card.Header>
        <Card.Body>
          <Chart type={'Stacked Bar'}
            unit={'percent'}
            positive={'Positive Impact'}
            xaxislabels={mappedLabels}
            dataseries={[values]}
            dataserieslabels={['Excess Tail Risk Contribution RAROCs']}
          />
        </Card.Body>
        </React.Fragment>
      );
    };

    const departmentExcessExpectedReturnsChart = () => {
      const { performanceFeatures } = this.props;
      const excessExpectedReturnsDepartment = performanceFeatures.data.filter((elem) => (
        elem.measureID === 0 && elem.featureName === 'Deparment'
      ));
      const labels = [
        ...new Set(excessExpectedReturnsDepartment.map((elem) => elem.featureLabelName)),
      ];
      const values = excessExpectedReturnsDepartment.map((elem) => elem.measureValue);

      const labelToTagMapping = [
        {label: 1, description: "10 Year Fixed"},
        {label: 2, description: "20 Year Fixed"},
      ]

      const mappedLabels = labels.map((label) => (
        labelToTagMapping.filter((tag) => tag.label == label)[0].description
      ))

      return (
        <React.Fragment>
        <Card.Header>
          <div className="fa-pull-left">Department - Excess Expected Returns</div>
          <div className="fa-pull-right">
            <Button className="fa-icon" variant="secondary" size="sm" onClick={() => this.saveToExcel(labels, ['Excess Expected Returns'], values)}>
              <span className="fa fa-save"></span>
              <span> Export</span>
            </Button>
            <span
              className="ml-3 question-mark"
              onClick={() => this.renderHelp({
                header: 'Department - Excess Expected Returns',
                body: 'Expected returns in excess of the full portfolio’s return',
              })}
            ></span>
          </div>
        </Card.Header>
        <Card.Body>
          <Chart type={'Stacked Bar'}
            unit={'percent'}
            positive={'Positive Impact'}
            xaxislabels={mappedLabels}
            dataseries={[values]}
            dataserieslabels={['Excess Expected Returns']}
          />
        </Card.Body>
        </React.Fragment>
      );
    };

    const departmentExcessRiskContributionChart = () => {
      const { performanceFeatures } = this.props;
      const excessRiskContributionDepartment = performanceFeatures.data.filter((elem) => (
        elem.measureID === 1 && elem.featureName === 'Deparment'
      ));
      const labels = [
        ...new Set(excessRiskContributionDepartment.map((elem) => elem.featureLabelName)),
      ];
      const values = excessRiskContributionDepartment.filter((elem) => (
        Math.abs(elem.measureValue) < 1
      )).map((elem) => elem.measureValue);

      const labelToTagMapping = [
        {label: 1, description: "10 Year Fixed"},
        {label: 2, description: "20 Year Fixed"},
      ]

      const mappedLabels = labels.map((label) => (
        labelToTagMapping.filter((tag) => tag.label == label)[0].description
      ))

      return (
        <React.Fragment>
        <Card.Header>
          <div className="fa-pull-left">Department - Excess Risk Contribution</div>
          <div className="fa-pull-right">
            <Button className="fa-icon" variant="secondary" size="sm" onClick={() => this.saveToExcel(labels, ['Excess Risk Contribution'], values)}>
              <span className="fa fa-save"></span>
              <span> Export</span>
            </Button>
            <span
              className="ml-3 question-mark"
              onClick={() => this.renderHelp({
                header: 'Department - Excess Risk Contribution',
                body: 'Excess risk contribution to full portfolio’s economic capital. A negative value indicates above average economic capital requirements for the respective sub-portfolio.',
              })}
            ></span>
          </div>
        </Card.Header>
        <Card.Body>
          <Chart type={'Stacked Bar'}
            unit={'percent'}
            positive={'Positive Impact'}
            xaxislabels={mappedLabels}
            dataseries={[values]}
            dataserieslabels={['Excess Risk Contribution']}
          />
        </Card.Body>
        </React.Fragment>
      );
    };

    const departmentExcessTailRiskContributionChart = () => {
      const { performanceFeatures } = this.props;
      const excessTailRiskContributionDepartment = performanceFeatures.data.filter((elem) => (
        elem.measureID === 2 && elem.featureName === 'Deparment'
      ));
      const labels = [
        ...new Set(excessTailRiskContributionDepartment.map((elem) => elem.featureLabelName)),
      ];
      const values = excessTailRiskContributionDepartment.map((elem) => elem.measureValue);

      const labelToTagMapping = [
        {label: 1, description: "10 Year Fixed"},
        {label: 2, description: "20 Year Fixed"},
      ]

      const mappedLabels = labels.map((label) => (
        labelToTagMapping.filter((tag) => tag.label == label)[0].description
      ))

      return (
        <React.Fragment>
        <Card.Header>
          <div className="fa-pull-left">Department - Excess Tail Risk Contribution</div>
          <div className="fa-pull-right">
            <Button className="fa-icon" variant="secondary" size="sm" onClick={() => this.saveToExcel(labels, ['Excess Tail Risk Contribution'], values)}>
              <span className="fa fa-save"></span>
              <span> Export</span>
            </Button>
            <span
              className="ml-3 question-mark"
              onClick={() => this.renderHelp({
                header: 'Department - Excess Risk Contribution',
                body: 'Excess risk contribution to full portfolio\'s expected shortfall. A negative value indicates higher tail risk contribution or, in other words, higher probability of extreme losses above capital level.',
              })}
            ></span>
          </div>
        </Card.Header>
        <Card.Body>
          <Chart type={'Stacked Bar'}
            unit={'percent'}
            positive={'Positive Impact'}
            xaxislabels={mappedLabels}
            dataseries={[values]}
            dataserieslabels={['Excess Tail Risk Contribution']}
          />
        </Card.Body>
        </React.Fragment>
      );
    };

    const departmentExcessRiskContributionRAROCChart = () => {
      const { performanceFeatures } = this.props;
      const excessRiskContributionRAROCDepartment = performanceFeatures.data.filter((elem) => (
        elem.measureID === 3 && elem.featureName === 'Deparment'
      ));
      const labels = [
        ...new Set(excessRiskContributionRAROCDepartment.map((elem) => elem.featureLabelName)),
      ];
      const values = excessRiskContributionRAROCDepartment.map((elem) => elem.measureValue);

      const labelToTagMapping = [
        {label: 1, description: "10 Year Fixed"},
        {label: 2, description: "20 Year Fixed"},
      ]

      const mappedLabels = labels.map((label) => (
        labelToTagMapping.filter((tag) => tag.label == label)[0].description
      ))

      return (
        <React.Fragment>
        <Card.Header>
          <div className="fa-pull-left">Department - Excess Risk Contribution RAROC</div>
          <div className="fa-pull-right">
            <Button className="fa-icon" variant="secondary" size="sm" onClick={() => this.saveToExcel(labels, ['Excess Risk Contribution RAROC'], values)}>
              <span className="fa fa-save"></span>
              <span> Export</span>
            </Button>
            <span
              className="ml-3 question-mark"
              onClick={() => this.renderHelp({
                header: 'Department - Excess Risk Contribution RAROC',
                body: 'Ratio of expected spread (sum of expected interest income minus funding costs) over the risk contribution, compared to the full portfolio. A positive ratio indicates positive performance impact with respect to the full portfolio, driven by higher expected return, lower risk contribution, or a combination of both.',
              })}
            ></span>
          </div>
        </Card.Header>
        <Card.Body>
          <Chart type={'Stacked Bar'}
            unit={'percent'}
            positive={'Positive Impact'}
            xaxislabels={mappedLabels}
            dataseries={[values]}
            dataserieslabels={['Excess Risk Contribution RAROCs']}
          />
        </Card.Body>
        </React.Fragment>
      );
    };

    const departmentExcessTailRiskContributionRAROCChart = () => {
      const { performanceFeatures } = this.props;
      const excessTailRiskContributionRAROCDepartment = performanceFeatures.data.filter((elem) => (
        elem.measureID === 4 && elem.featureName === 'Deparment'
      ));
      const labels = [
        ...new Set(excessTailRiskContributionRAROCDepartment.map((elem) => elem.featureLabelName)),
      ];
      const values = excessTailRiskContributionRAROCDepartment.map((elem) => elem.measureValue);

      const labelToTagMapping = [
        {label: 1, description: "10 Year Fixed"},
        {label: 2, description: "20 Year Fixed"},
      ]

      const mappedLabels = labels.map((label) => (
        labelToTagMapping.filter((tag) => tag.label == label)[0].description
      ))

      return (
        <React.Fragment>
        <Card.Header>
          <div className="fa-pull-left">Department - Excess Tail Risk Contribution RAROC</div>
          <div className="fa-pull-right">
            <Button className="fa-icon" variant="secondary" size="sm" onClick={() => this.saveToExcel(labels, ['Excess Tail Risk Contribution RAROC'], values)}>
              <span className="fa fa-save"></span>
              <span> Export</span>
            </Button>
            <span
              className="ml-3 question-mark"
              onClick={() => this.renderHelp({
                header: 'Department - Excess Tail Risk Contribution RAROC',
                body: 'Ratio of expected spread (sum of expected interest income minus funding costs) over the tail risk contribution, compared to the full portfolio. A positive ratio indicates positive performance impact with respect to the full portfolio, driven by higher expected return, lower tail risk contribution, or a combination of both.',
              })}
            ></span>
          </div>
        </Card.Header>
        <Card.Body>
          <Chart type={'Stacked Bar'}
            unit={'percent'}
            positive={'Positive Impact'}
            xaxislabels={mappedLabels}
            dataseries={[values]}
            dataserieslabels={['Excess Tail Risk Contribution RAROCs']}
          />
        </Card.Body>
        </React.Fragment>
      );
    };

    const regionExcessExpectedReturnsChart = () => {
      const excessExpectedReturnsRegion = this.props.performanceFeatures.data.filter((elem) => (
        elem.measureID === 0 && elem.featureName === 'Region_Booking_Entity'
      ));
      const labels = [
        ...new Set(excessExpectedReturnsRegion.map((elem) => elem.featureLabelName)),
      ];
      const values = excessExpectedReturnsRegion.map((elem) => elem.measureValue);
      return (
        <React.Fragment>
        <Card.Header className="card-header-with-btn">
          <div className="pull-left">Region - Excess Expected Returns</div>
          <div className="fa-pull-right">
            <Button className="fa-icon" variant="secondary" size="sm" onClick={() => this.saveToExcel(labels, ['Excess Expected Returns'], values)}>
              <span className="fa fa-save"></span>
              <span> Export</span>
            </Button>
            <span
              className="ml-3 question-mark"
              onClick={() => this.renderHelp({
                header: 'Region - Excess Expected Returns',
                body: 'Expected returns in excess of the full portfolio’s return.',
              })}
            ></span>
          </div>
        </Card.Header>
        <Card.Body>
          <Chart type={'Stacked Bar'}
            unit={'percent'}
            positive={'Positive Impact'}
            xaxislabels={labels}
            dataseries={[values]}
            dataserieslabels={['Excess Expected Returns']}
          />
        </Card.Body>
        </React.Fragment>
      );
    };

    const regionExcessRiskContributionChart = () => {
      const excessRiskContributionRegion = this.props.performanceFeatures.data.filter((elem) => (
        elem.measureID === 1 && elem.featureName === 'Region_Booking_Entity'
      ));
      const labels = [
        ...new Set(excessRiskContributionRegion.map((elem) => elem.featureLabelName)),
      ];
      const values = excessRiskContributionRegion.map((elem) => elem.measureValue);
      return (
        <React.Fragment>
        <Card.Header className="card-header-with-btn">
          <div className="pull-left">Region - Excess Risk Contribution</div>
          <div className="fa-pull-right">
            <Button className="fa-icon" variant="secondary" size="sm" onClick={() => this.saveToExcel(labels, ['Excess Risk Contribution'], values)}>
              <span className="fa fa-save"></span>
              <span> Export</span>
            </Button>
            <span
              className="ml-3 question-mark"
              onClick={() => this.renderHelp({
                header: 'Region - Excess Risk Contribution',
                body: 'Excess risk contribution to full portfolio’s economic capital. A negative value indicates above average economic capital requirements for the respective sub-portfolio.',
              })}
            ></span>
          </div>
        </Card.Header>
        <Card.Body>
          <Chart type={'Stacked Bar'}
            unit={'percent'}
            positive={'Positive Impact'}
            xaxislabels={labels}
            dataseries={[values]}
            dataserieslabels={['Excess Risk Contribution']}
          />
        </Card.Body>
        </React.Fragment>
      );
    };

    const regionExcessTailRiskContributionChart = () => {
      const { performanceFeatures } = this.props;
      const excessTailRiskContributionRegion = performanceFeatures.data.filter((elem) => (
        elem.measureID === 3 && elem.featureName === 'Region_Booking_Entity'
      ));
      const labels = [
        ...new Set(excessTailRiskContributionRegion.map((elem) => elem.featureLabelName)),
      ];
      const values = excessTailRiskContributionRegion.map((elem) => elem.measureValue);
      return (
        <React.Fragment>
        <Card.Header>
          <div className="fa-pull-left">Region - Excess Tail Risk Contribution</div>
          <div className="fa-pull-right">
            <Button className="fa-icon" variant="secondary" size="sm" onClick={() => this.saveToExcel(labels, ['Excess Tail Risk Contribution'], values)}>
              <span className="fa fa-save"></span>
              <span> Export</span>
            </Button>
            <span
              className="ml-3 question-mark"
              onClick={() => this.renderHelp({
                header: 'Region - Excess Tail Risk Contribution',
                body: 'Excess risk contribution to full portfolio\'s expected shortfall. A negative value indicates higher tail risk contribution or, in other words, higher probability of extreme losses above capital level.',
              })}
            ></span>
          </div>
        </Card.Header>
        <Card.Body>
          <Chart type={'Stacked Bar'}
            unit={'percent'}
            positive={'Positive Impact'}
            xaxislabels={labels}
            dataseries={[values]}
            dataserieslabels={['Excess Tail Risk Contribution']}
          />
        </Card.Body>
        </React.Fragment>
      );
    };

    const regionExcessRiskContributionRAROCChart = () => {
      const { performanceFeatures } = this.props;
      const excessRiskContributionRAROCRegion = performanceFeatures.data.filter((elem) => (
        elem.measureID === 2 && elem.featureName === 'Region_Booking_Entity'
      ));
      const labels = [
        ...new Set(excessRiskContributionRAROCRegion.map((elem) => elem.featureLabelName)),
      ];
      const values = excessRiskContributionRAROCRegion.map((elem) => elem.measureValue);
      return (
        <React.Fragment>
        <Card.Header>
          <div className="fa-pull-left">Region - Excess Risk Contribution RAROC</div>
          <div className="fa-pull-right">
            <Button className="fa-icon" variant="secondary" size="sm" onClick={() => this.saveToExcel(labels, ['Excess Risk Contribution RAROC'], values)}>
              <span className="fa fa-save"></span>
              <span> Export</span>
            </Button>
            <span
              className="ml-3 question-mark"
              onClick={() => this.renderHelp({
                header: 'Region - Excess Risk Contribution RAROC',
                body: 'Ratio of expected spread (sum of expected interest income minus funding costs) over the risk contribution, compared to the full portfolio. A positive ratio indicates positive performance impact with respect to the full portfolio, driven by higher expected return, lower risk contribution, or a combination of both.',
              })}
            ></span>
          </div>
        </Card.Header>
        <Card.Body>
          <Chart type={'Stacked Bar'}
            unit={'percent'}
            positive={'Positive Impact'}
            xaxislabels={labels}
            dataseries={[values]}
            dataserieslabels={['Excess Risk Contribution RAROCs']}
          />
        </Card.Body>
        </React.Fragment>
      );
    };

    const regionExcessTailRiskContributionRAROCChart = () => {
      const { performanceFeatures } = this.props;
      const excessTailRiskContributionRAROCRegion = performanceFeatures.data.filter((elem) => (
        elem.measureID === 4 && elem.featureName === 'Region_Booking_Entity'
      ));
      const labels = [
        ...new Set(excessTailRiskContributionRAROCRegion.map((elem) => elem.featureLabelName)),
      ];
      const values = excessTailRiskContributionRAROCRegion.map((elem) => elem.measureValue);
      return (
        <React.Fragment>
        <Card.Header>
          <div className="fa-pull-left">Region - Excess Tail Risk Contribution RAROC</div>
          <div className="fa-pull-right">
            <Button className="fa-icon" variant="secondary" size="sm" onClick={() => this.saveToExcel(labels, ['Excess Tail Risk Contribution RAROC'], values)}>
              <span className="fa fa-save"></span>
              <span> Export</span>
            </Button>
            <span
              className="ml-3 question-mark"
              onClick={() => this.renderHelp({
                header: 'Region - Excess Tail Risk Contribution RAROC',
                body: 'Ratio of expected spread (sum of expected interest income minus funding costs) over the tail risk contribution, compared to the full portfolio. A positive ratio indicates positive performance impact with respect to the full portfolio, driven by higher expected return, lower tail risk contribution, or a combination of both.',
              })}
            ></span>
          </div>
        </Card.Header>
        <Card.Body>
          <Chart type={'Stacked Bar'}
            unit={'percent'}
            positive={'Positive Impact'}
            xaxislabels={labels}
            dataseries={[values]}
            dataserieslabels={['Excess Tail Risk Contribution RAROCs']}
          />
        </Card.Body>
        </React.Fragment>
      );
    };

    const dataSummary = () => {
      const portfolioTimepoint = this.props.portfolioPerformance.data[this.state.evaluationPeriod];
      return {
        dataSummaryColumns: [
          {
            Header: 'Metric',
            accessor: 'Metric',
          },
          {
            Header: 'Value',
            accessor: 'Value',
            Cell: (row) => (
              <div style={{ textAlign: 'right' }}>{ row.value }</div>
            ),
          },
        ],
        dataSummary: [
          {
            Metric: 'Outstanding',
            Value: portfolioTimepoint.outstanding === null ? 'N/A' : portfolioTimepoint.outstanding.toLocaleString('en-GB', {
              style: 'currency',
              currency: 'EUR',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }),
          },
          {
            Metric: 'Expected Portfolio Value',
            Value: portfolioTimepoint.portfolioExpectedValue === null ? 'N/A' : portfolioTimepoint.portfolioExpectedValue.toLocaleString('en-GB', {
              style: 'currency',
              currency: 'EUR',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }),
          },
          {
            Metric: 'Expected Portfolio Value without Defaults',
            Value: portfolioTimepoint.portfolioExpectedValueNoDefaults === null ? 'N/A' : portfolioTimepoint.portfolioExpectedValueNoDefaults.toLocaleString('en-GB', {
              style: 'currency',
              currency: 'EUR',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }),
          },
          {
            Metric: 'Economic Capital',
            Value: portfolioTimepoint.portfolioEconomicCapital === null ? 'N/A' : portfolioTimepoint.portfolioEconomicCapital.toLocaleString('en-GB', {
              style: 'currency',
              currency: 'EUR',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }),
          },
          {
            Metric: 'Capital Percentage',
            Value: (portfolioTimepoint.portfolioEconomicCapital / portfolioTimepoint.portfolioRiskFreeValue).toLocaleString('en-GB', {
              style: 'percent',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }),
          },
          {
            Metric: 'Expected Shortfall',
            Value: portfolioTimepoint.portfolioExpectedShortfall.toLocaleString('en-GB', {
              style: 'currency',
              currency: 'EUR',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }),
          },
          {
            Metric: 'Expected Loss',
            Value: portfolioTimepoint.portfolioExpectedLoss === null ? 'N/A' : portfolioTimepoint.portfolioExpectedLoss.toLocaleString('en-GB', {
              style: 'percent',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }),
          },
          {
            Metric: 'Expected Spread',
            Value: portfolioTimepoint.portfolioExpectedSpread === null ? 'N/A' : portfolioTimepoint.portfolioExpectedSpread.toLocaleString('en-GB', {
              style: 'percent',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }),
          },
          {
            Metric: 'RAROC',
            Value: portfolioTimepoint.portfolioRAROC === null ? 'N/A' : portfolioTimepoint.portfolioRAROC.toLocaleString('en-GB', {
              style: 'percent',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }),
          },
          {
            Metric: 'Risk Contribution',
            Value: portfolioTimepoint.portfolioRiskContribution === null ? 'N/A' : portfolioTimepoint.portfolioRiskContribution.toLocaleString('en-GB', {
              style: 'percent',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }),
          },
          {
            Metric: 'Risk Free Value',
            Value: portfolioTimepoint.portfolioRiskFreeValue === null ? 'N/A' : portfolioTimepoint.portfolioRiskFreeValue.toLocaleString('en-GB', {
              style: 'currency',
              currency: 'EUR',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }),
          },
          {
            Metric: 'Sharpe Ratio',
            Value: portfolioTimepoint.portfolioSharpeRatio === null ? 'N/A' : portfolioTimepoint.portfolioSharpeRatio.toLocaleString('en-GB', {
              style: 'decimal',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }),
          },
          {
            Metric: 'Vasicek Score',
            Value: portfolioTimepoint.portfolioVasicekScore === null ? 'N/A' : portfolioTimepoint.portfolioVasicekScore.toLocaleString('en-GB', {
              style: 'decimal',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }),
          },
        ],
      };
    };

    const portfolioData = this.state.aggregatedPortfolioData;
    let combinedAxes;
    if (renderRateStressCombinedOverview) {
      combinedAxes = [
        ...new Set([
          ...portfolioData.valueBins_5p,
          ...portfolioData.valueBins_10p,
          ...portfolioData.valueBins_50p,
          ...portfolioData.valueBins_90p,
          ...portfolioData.valueBins_95p,
        ]),
      ];
      combinedAxes.sort();
      combinedAxes = portfolioData.valueBins_5p;
      // let index_array = portfolio_data.valueBins_Base.map((elem) => combinedAxes.indexOf(elem))
      // portfolio_data.valueCounts_Base = Array.apply(null, Array()).map(function () {})
      // portfolio_data.valueCounts_5p =
      // portfolio_data.valueCounts_10p =
      // portfolio_data.valueCounts_50p =
      // portfolio_data.valueCounts_90p =
      // portfolio_data.valueCounts_95p =
    }


    return (
      <div>
        { this.state.renderHelp === true && (
          <div className="overlay-help" onClick={() => this.renderHelp(undefined)}>
            <div className="overlay-help-card">
              <Card className="mt-4">
                <Card.Header><div className="fa-pull-left">{this.state.helpText.header}</div>
                <i className="fa fa-window-close fa-pull-right" onClick={() => this.renderHelp(undefined)}></i></Card.Header>
                <Card.Body>{this.state.helpText.body}</Card.Body>
              </Card>
            </div>
          </div>
        )}
        <Header
          title={'Analytics'}
          subtitle={
            {
              startText: 'This section provides simulated risk/return metrics and output visualisations at portfolio level.',
              list: undefined,
              closingText: undefined,
            }
          }/>

        { showLoadingScreen && (
          <div>
            <Notification heading={'Select a Simulation'} body={'Loading Simulations'}/>
          </div>
        )}

        { showError && (
          <div>
            {Object.keys(this.props.error).map((elem) => (
              <Card className="mt-4">
                <Card.Header>Error</Card.Header>
                <Card.Body>{elem}: {this.props.error[elem].message}</Card.Body>
              </Card>
            ))}
          </div>
        )}

        { showSelectionMenu && (
          <div>
            <Card className="mt-4">
              <Card.Header>
                <div className="fa-pull-left">Select a Job</div>
                <div>
                  <span
                    className="question-mark fa-pull-right"
                    onClick={() => this.renderHelp({
                      header: 'Job Selection',
                      body: 'Select the job for which you want to view the results.',
                    })
                  }></span>
                </div>
              </Card.Header>
              <Card.Body>
                <Form.Group as={Row} controlId="createJob.jobID">
                <Form.Label column sm={3}>Job Name</Form.Label>
                  <Col sm={9}>
                    <Form.Control as="select" ref="jobID" onChange={this.saveJobID} value={this.state.selectedJobID} defaultValue="">
                      { this.props.jobs.data.filter((elem) => (
                        elem.jobCompletion === 3)).map((k, index) => (
                          <option key={index} value={k.jobID}>{k.jobName}</option>
                      ))}
                    </Form.Control>
                  </Col>
                </Form.Group>
              </Card.Body>
            </Card>
          </div>
        )}

        { false && (
          <div>
            <Card className="mt-4">
              <Card.Header>
                <div className="fa-pull-left">Select rate stresses</div>
                <div>
                  <span
                    className="question-mark fa-pull-right"
                    onClick={() => this.renderHelp({
                      header: 'Timepoint Selection',
                      body: 'View your key portfolio characteristics, portfolio value distribution and portfolio loss distribution at the monthly intervals between assessment and horizon date. Please note that this does not impact the unexpected loss versus risk contribution plot and the feature measures, which are all presented at the horizon date.',
                    })}
                  ></span>
                </div>
              </Card.Header>
              <Card.Body>
                <Form noValidate>
                <Form.Group as={Row} controlId="resultsSimulation.selectEvaluationPeriod">
                <Form.Label column sm={3}>Rate stress</Form.Label>
                  <Col sm={9}>
                  <Form.Control
                    as="select"
                    ref="rateBucket"
                    onChange={this.saveRateBucket} value={this.state.evaluationRateBucket}>
                      { [0, 1, 2, 3, 4, 5].map((k, index) => (
                        <option key={index} value={index}>{index}</option>
                      ))}
                  </Form.Control>
                  </Col>
                </Form.Group>
                </Form>
              </Card.Body>
            </Card>
          </div>
        )}

        { showDescriptivesTable && (
          <div>
            <Card className="mt-4">
              <Card.Header>
                <div className="fa-pull-left">Portfolio Characteristics</div>
                <div><span className="question-mark fa-pull-right" onClick={() => this.renderHelp({ header: 'Portfolio Characteristics', body: 'Overview of key risk/return metrics' })}></span></div>
              </Card.Header>
              <Card.Body>
                <ReactTable
                  data={dataSummary().dataSummary}
                  columns={dataSummary().dataSummaryColumns}
                  defaultPageSize={13}
                  keyField={'id'}
                  className="-striped -highlight"
                  showPagination={false}
                />
              </Card.Body>
            </Card>
          </div>
        )}

        { showTimestepMenu && (
          <div>
            <Card className="mt-4">
              <Card.Header>
                <div className="fa-pull-left">Select a timepoint</div>
                <div><span className="question-mark fa-pull-right" onClick={() => this.renderHelp({ header: 'Timepoint Selection', body: 'View your key portfolio characteristics, portfolio value distribution and portfolio loss distribution at the monthly intervals between assessment and horizon date. Please note that this does not impact the unexpected loss versus risk contribution plot and the feature measures, which are all presented at the horizon date.' })}></span></div>
              </Card.Header>
              <Card.Body>
                <Form noValidate>
                <Form.Group as={Row} controlId="resultsSimulation.selectEvaluationPeriod">
                <Form.Label column sm={3}>Timepoint</Form.Label>
                  <Col sm={9}>
                  <Form.Control as="select" ref="evaluationPeriod" onChange={this.saveEvaluationPeriod} value={this.state.evaluationPeriod}>
                    { this.props.portfolioPerformance.data.map((k, index) => (
                      <option key={index} value={index}>{index}</option>
                    ))}
                  </Form.Control>
                  </Col>
                </Form.Group>
                </Form>
              </Card.Body>
            </Card>
          </div>
        )}

        { renderPortfolioValues && (
          <Card className="mt-4">
            <Card.Header className="card-header-with-btn">
              <div className="fa-pull-left">Portfolio Values</div>
              <div className="fa-pull-right">
                <Button className="fa-icon" variant="secondary" size="sm" onClick={() => this.saveToExcel(this.state.aggregatedPortfolioData.valueBins, ['Portfolio Values'], this.state.aggregatedPortfolioData.valueCounts)}>
                  <span className="fa fa-save"></span>
                  <span> Export</span>
                </Button>
                <span
                  className="ml-3 question-mark"
                  onClick={() => this.renderHelp({
                    header: 'Portfolio Values',
                    body: 'Distribution of portfolio values at the selected time step. The Y-axis indicates the number of simulation trials in which the portfolio value ended up in the vaue bucket on the x-axis. It could be seen as a probability to arrive there under the model of use. Clustering over time can occur because of multiple reasons, one of them being loans being fully repaid.',
                  })}
                ></span>
              </div>
            </Card.Header>
            <Card.Body>
              <Chart type={'Line'}
                verticallines={[
                  this.state.aggregatedPortfolioData.portfolioRiskFreeValue,
                  this.state.aggregatedPortfolioData.portfolioExpectedValue,
                ]}
                verticallinestitles={['Risk-Free Value', 'Expected Value']}
                unit={'decimal'}
                xaxislabels={this.state.aggregatedPortfolioData.valueBins}
                dataseries={[this.state.aggregatedPortfolioData.valueCounts]}
                dataserieslabels={['Portfolio Values Count']}/>
              </Card.Body>
          </Card>
        )}

        { renderRateStressCombinedOverview && (
          <Card className="mt-4">
            <Card.Header className="card-header-with-btn">
              <div className="fa-pull-left">Spread Stressed Portfolio Values</div>
              <div className="fa-pull-right">
                <Button className="fa-icon" variant="secondary" size="sm" onClick={() => this.saveToExcel(this.state.aggregatedPortfolioData.valueBins, ['Portfolio Values'], this.state.aggregatedPortfolioData.valueCounts)}>
                  <span className="fa fa-save"></span>
                  <span> Export</span>
                </Button>
                <span
                  className="ml-3 question-mark"
                  onClick={() => this.renderHelp({
                    header: 'Portfolio Values',
                    body: 'Distribution of credit spread stressed portfolio values at the selected time step. In this view, you are looking at how a percentile point on the credit distribution (95th percentile or expected value) is impacted by simulated spread movements at the refinancing moment. The Y-axis indicates the number of simulation trials in which the portfolio value ended up in the vaue bucket on the x-axis. It could be seen as a probability to arrive there under the model of use. Clustering over time can occur because of multiple reasons, one of them being loans being fully repaid.',
                  })}
                ></span>
              </div>
            </Card.Header>
            <Card.Body>
              <Chart type={'Line'}
                verticallines={[
                  portfolioData.portfolioRiskFreeValue,
                  portfolioData.portfolioExpectedValue,
                ]}
                verticallinestitles={['Risk-Free Value', 'Expected Value']}
                unit={'decimal'}
                xaxislabels={combinedAxes}
                dataseries={[
                  portfolioData.valueCounts_Base,
                  portfolioData.valueCounts_5p,
                  portfolioData.valueCounts_10p,
                  portfolioData.valueCounts_50p,
                  portfolioData.valueCounts_90p,
                  portfolioData.valueCounts_95p,
                ]}
                dataserieslabels={[
                  'Expected Value',
                  '5th percentile',
                  '10th percentile',
                  '50th percentile',
                  '90th percentile',
                  '95th percentile',
                ]}/>
              </Card.Body>
          </Card>
        )}

        { renderRateStressPercentiles && (
          <Card className="mt-4">
            <Card.Header className="card-header-with-btn">
              <div className="fa-pull-left">Rate Stressed Portfolio Values</div>
              <div className="fa-pull-right">
                <Button className="fa-icon" variant="secondary" size="sm" onClick={() => this.saveToExcel(this.state.aggregatedPortfolioData.valueBins, ['Portfolio Values'], this.state.aggregatedPortfolioData.valueCounts)}>
                  <span className="fa fa-save"></span>
                  <span> Export</span>
                </Button>
                <span
                  className="ml-3 question-mark"
                  onClick={() => this.renderHelp({
                    header: 'Portfolio Values',
                    body: 'Distribution of portfolio values at the selected time step. The Y-axis indicates the number of simulation trials in which the portfolio value ended up in the vaue bucket on the x-axis. It could be seen as a probability to arrive there under the model of use. Clustering over time can occur because of multiple reasons, one of them being loans being fully repaid.',
                  })}
                ></span>
              </div>
            </Card.Header>
            <Card.Body>
              <Chart type={'Line'}
                verticallines={[
                  this.state.aggregatedPortfolioData.portfolioRiskFreeValue,
                  this.state.aggregatedPortfolioData.portfolioExpectedValue,
                ]}
                verticallinestitles={['Risk-Free Value', 'Expected Value']}
                unit={'decimal'}
                xaxislabels={this.state.aggregatedPortfolioData.valueBins_Base}
                dataseries={[this.state.aggregatedPortfolioData.valueCounts_Base]}
                dataserieslabels={['Expected Value']}/>
              </Card.Body>
          </Card>
        )}

        { renderRateStressPercentiles && (
          <Card className="mt-4">
            <Card.Header className="card-header-with-btn">
              <div className="fa-pull-left">Rate Stressed Portfolio Values</div>
              <div className="fa-pull-right">
                <Button className="fa-icon" variant="secondary" size="sm" onClick={() => this.saveToExcel(this.state.aggregatedPortfolioData.valueBins, ['Portfolio Values'], this.state.aggregatedPortfolioData.valueCounts)}>
                  <span className="fa fa-save"></span>
                  <span> Export</span>
                </Button>
                <span
                  className="ml-3 question-mark"
                  onClick={() => this.renderHelp({
                    header: 'Portfolio Values',
                    body: 'Distribution of portfolio values at the selected time step. The Y-axis indicates the number of simulation trials in which the portfolio value ended up in the vaue bucket on the x-axis. It could be seen as a probability to arrive there under the model of use. Clustering over time can occur because of multiple reasons, one of them being loans being fully repaid.',
                  })}
                ></span>
              </div>
            </Card.Header>
            <Card.Body>
              <Chart type={'Line'}
                verticallines={[
                  this.state.aggregatedPortfolioData.portfolioRiskFreeValue,
                  this.state.aggregatedPortfolioData.portfolioExpectedValue,
                ]}
                verticallinestitles={['Risk-Free Value', 'Expected Value']}
                unit={'decimal'}
                xaxislabels={this.state.aggregatedPortfolioData.valueBins_5p}
                dataseries={[this.state.aggregatedPortfolioData.valueCounts_5p]}
                dataserieslabels={['5th Percentile']}/>
              </Card.Body>
          </Card>
        )}

        { renderRateStressPercentiles && (
          <Card className="mt-4">
            <Card.Header className="card-header-with-btn">
              <div className="fa-pull-left">Rate Stressed Portfolio Values</div>
              <div className="fa-pull-right">
                <Button className="fa-icon" variant="secondary" size="sm" onClick={() => this.saveToExcel(this.state.aggregatedPortfolioData.valueBins, ['Portfolio Values'], this.state.aggregatedPortfolioData.valueCounts)}>
                  <span className="fa fa-save"></span>
                  <span> Export</span>
                </Button>
                <span
                  className="ml-3 question-mark"
                  onClick={() => this.renderHelp({
                    header: 'Portfolio Values',
                    body: 'Distribution of portfolio values at the selected time step. The Y-axis indicates the number of simulation trials in which the portfolio value ended up in the vaue bucket on the x-axis. It could be seen as a probability to arrive there under the model of use. Clustering over time can occur because of multiple reasons, one of them being loans being fully repaid.',
                  })}
                ></span>
              </div>
            </Card.Header>
            <Card.Body>
              <Chart type={'Line'}
                verticallines={[
                  this.state.aggregatedPortfolioData.portfolioRiskFreeValue,
                  this.state.aggregatedPortfolioData.portfolioExpectedValue,
                ]}
                verticallinestitles={['Risk-Free Value', 'Expected Value']}
                unit={'decimal'}
                xaxislabels={this.state.aggregatedPortfolioData.valueBins_10p}
                dataseries={[this.state.aggregatedPortfolioData.valueCounts_10p]}
                dataserieslabels={['10th Percentile']}/>
              </Card.Body>
          </Card>
        )}

        { renderRateStressPercentiles && (
          <Card className="mt-4">
            <Card.Header className="card-header-with-btn">
              <div className="fa-pull-left">Rate Stressed Portfolio Values</div>
              <div className="fa-pull-right">
                <Button className="fa-icon" variant="secondary" size="sm" onClick={() => this.saveToExcel(this.state.aggregatedPortfolioData.valueBins, ['Portfolio Values'], this.state.aggregatedPortfolioData.valueCounts)}>
                  <span className="fa fa-save"></span>
                  <span> Export</span>
                </Button>
                <span
                  className="ml-3 question-mark"
                  onClick={() => this.renderHelp({
                    header: 'Portfolio Values',
                    body: 'Distribution of portfolio values at the selected time step. The Y-axis indicates the number of simulation trials in which the portfolio value ended up in the vaue bucket on the x-axis. It could be seen as a probability to arrive there under the model of use. Clustering over time can occur because of multiple reasons, one of them being loans being fully repaid.',
                  })}
                ></span>
              </div>
            </Card.Header>
            <Card.Body>
              <Chart type={'Line'}
                verticallines={[
                  this.state.aggregatedPortfolioData.portfolioRiskFreeValue,
                  this.state.aggregatedPortfolioData.portfolioExpectedValue,
                ]}
                verticallinestitles={['Risk-Free Value', 'Expected Value']}
                unit={'decimal'}
                xaxislabels={this.state.aggregatedPortfolioData.valueBins_50p}
                dataseries={[this.state.aggregatedPortfolioData.valueCounts_50p]}
                dataserieslabels={['50th Percentile']}/>
            </Card.Body>
          </Card>
        )}

        { renderRateStressPercentiles && (
          <Card className="mt-4">
            <Card.Header className="card-header-with-btn">
              <div className="fa-pull-left">Rate Stressed Portfolio Values</div>
              <div className="fa-pull-right">
                <Button className="fa-icon" variant="secondary" size="sm" onClick={() => this.saveToExcel(this.state.aggregatedPortfolioData.valueBins, ['Portfolio Values'], this.state.aggregatedPortfolioData.valueCounts)}>
                  <span className="fa fa-save"></span>
                  <span> Export</span>
                </Button>
                <span
                  className="ml-3 question-mark"
                  onClick={() => this.renderHelp({
                    header: 'Portfolio Values',
                    body: 'Distribution of portfolio values at the selected time step. The Y-axis indicates the number of simulation trials in which the portfolio value ended up in the vaue bucket on the x-axis. It could be seen as a probability to arrive there under the model of use. Clustering over time can occur because of multiple reasons, one of them being loans being fully repaid.',
                  })}
                ></span>
              </div>
            </Card.Header>
            <Card.Body>
              <Chart type={'Line'}
                verticallines={[
                  this.state.aggregatedPortfolioData.portfolioRiskFreeValue,
                  this.state.aggregatedPortfolioData.portfolioExpectedValue,
                ]}
                verticallinestitles={['Risk-Free Value', 'Expected Value']}
                unit={'decimal'}
                xaxislabels={this.state.aggregatedPortfolioData.valueBins_90p}
                dataseries={[this.state.aggregatedPortfolioData.valueCounts_90p]}
                dataserieslabels={['90th Percentile']}/>
            </Card.Body>
          </Card>
        )}

        { renderRateStressPercentiles && (
          <Card className="mt-4">
            <Card.Header className="card-header-with-btn">
              <div className="fa-pull-left">Rate Stressed Portfolio Values</div>
              <div className="fa-pull-right">
                <Button className="fa-icon" variant="secondary" size="sm" onClick={() => this.saveToExcel(this.state.aggregatedPortfolioData.valueBins, ['Portfolio Values'], this.state.aggregatedPortfolioData.valueCounts)}>
                  <span className="fa fa-save"></span>
                  <span> Export</span>
                </Button>
                <span
                  className="ml-3 question-mark"
                  onClick={() => this.renderHelp({
                    header: 'Portfolio Values',
                    body: 'Distribution of portfolio values at the selected time step. The Y-axis indicates the number of simulation trials in which the portfolio value ended up in the vaue bucket on the x-axis. It could be seen as a probability to arrive there under the model of use. Clustering over time can occur because of multiple reasons, one of them being loans being fully repaid.',
                  })}
                ></span>
              </div>
            </Card.Header>
            <Card.Body>
              <Chart type={'Line'}
                verticallines={[
                  this.state.aggregatedPortfolioData.portfolioRiskFreeValue,
                  this.state.aggregatedPortfolioData.portfolioExpectedValue,
                ]}
                verticallinestitles={['Risk-Free Value', 'Expected Value']}
                unit={'decimal'}
                xaxislabels={this.state.aggregatedPortfolioData.valueBins_95p}
                dataseries={[this.state.aggregatedPortfolioData.valueCounts_95p]}
                dataserieslabels={['95th Percentile']}/>
            </Card.Body>
          </Card>
        )}

        { renderPortfolioLosses && (
          <Card className="mt-4">
          <Card.Header className="card-header-with-btn">
            <div className="fa-pull-left">Portfolio Losses</div>
            <div className="fa-pull-right">
              <Button className="fa-icon" variant="secondary" size="sm" onClick={() => this.saveToExcel(this.state.aggregatedPortfolioData.lossBins, ['Portfolio Losses'], this.state.aggregatedPortfolioData.lossCounts)}>
                <span className="fa fa-save"></span>
                <span> Export</span>
              </Button>
              <span
                className="ml-3 question-mark"
                onClick={() => this.renderHelp({
                  header: 'Portfolio Losses',
                  body: 'Distribution of portfolio losses at the selected time step. The losses are calculated as the difference between the Expected Portfolio Value and the Portfolio Value for each simulation. A negative loss indicates an increase in the portfolio value compared to the Expected Portfolio Value.',
                })}
              ></span>
            </div>
          </Card.Header>
            <Card.Body>
              <Chart type={'Line'}
                verticallines={[
                  this.state.aggregatedPortfolioData.portfolioEconomicCapital,
                  this.state.aggregatedPortfolioData.portfolioExpectedShortfall,
                ]}
                verticallinestitles={['Economic Capital', 'Expected Shortfall']}
                unit={'decimal'}
                xaxislabels={this.state.aggregatedPortfolioData.lossBins}
                dataseries={[this.state.aggregatedPortfolioData.lossCounts]}
                dataserieslabels={['Portfolio Losses Count']}
              />
            </Card.Body>
          </Card>
        )}

        { render3DPortfolioValues && (
          <Card className="mt-4">
            <Card.Header className="card-header-with-btn">
              <div className="fa-pull-left">Portfolio Values over Time</div>
              <div className="fa-pull-right">
                <span className="ml-3 question-mark" onClick={() => this.renderHelp({ header: 'Portfolio Values over Time', body: 'The plot shows the distribution of the portfolio values through time. In general, one would expect a lower peak in the distribution over time as the range of outcomes increases due to increased uncertainty. The values plotted in this chart can be retrieved from the database.' })}></span>
              </div>
            </Card.Header>
            <Card.Body style={{ height: '40em' }}>
              {portfolioValuesOverTimeChart()}
            </Card.Body>
          </Card>
        )}

        { renderULvsECChart && (
          <ULvsRCChart
            assetsPerformance={this.props.assetsPerformance}
            renderHelp={this.renderHelp}
          />
        )}

        {/* <Nav.Item className="mx-2">
          <Nav.Link className="main-selector" eventKey="startDate" onClick={() => this.updateFeatureIdentifier('startDate')}>Start Date</Nav.Link>
        </Nav.Item> */}
        { renderIdentifierSelector && (
          <Card className="mt-4">
            <Card.Header>Portfolio Measures breakdown view</Card.Header>
            <Card.Body>
              <Nav variant="pills" defaultActiveKey="rating" fill="true">
                <Nav.Item className="mx-2">
                  <Nav.Link className="main-selector" eventKey="rating" onClick={() => this.updateFeatureIdentifier('rating')}>Risk Rating</Nav.Link>
                </Nav.Item>
                <Nav.Item className="mx-2">
                  <Nav.Link className="main-selector" eventKey="productType" onClick={() => this.updateFeatureIdentifier('productType')}>Product Type</Nav.Link>
                </Nav.Item>
                <Nav.Item className="mx-2">
                  <Nav.Link className="main-selector" eventKey="department" onClick={() => this.updateFeatureIdentifier('department')}>Fixed Rate Period</Nav.Link>
                </Nav.Item>
                <Nav.Item className="mx-2">
                  <Nav.Link className="main-selector" eventKey="region" onClick={() => this.updateFeatureIdentifier('region')}>Region</Nav.Link>
                </Nav.Item>
                <Nav.Item className="mx-2">
                  <Nav.Link className="main-selector" eventKey="all" onClick={() => this.updateFeatureIdentifier('all')}>All</Nav.Link>
                </Nav.Item>
              </Nav>
            </Card.Body>
          </Card>
        )}

        {/*{ renderStartDateExcessExpectedReturns && (
          <Card className="mt-4">
            {startDateExcessExpectedReturnsChart()}
          </Card>
        )}

        { renderStartDateExcessRiskContribution && (
          <Card className="mt-4">
            {startDateExcessRiskContributionChart()}
          </Card>
        )}

        { renderStartDateExcessTailRiskContribution && (
          <Card className="mt-4">
            {startDateExcessTailRiskContributionChart()}
          </Card>
        )}

        { renderStartDateExcessRiskContributionRAROC && (
          <Card className="mt-4">
            {startDateExcessRiskContributionRAROCChart()}
          </Card>
        )}

        { renderStartDateExcessTailRiskContributionRAROC && (
          <Card className="mt-4">
            {startDateExcessTailRiskContributionRAROCChart()}
          </Card>
        )}*/}

        { renderRiskRatingExcessExpectedReturns && (
          <Card className="mt-4">
            {riskRatingExcessExpectedReturnsChart()}
          </Card>
        )}

        { renderRiskRatingExcessRiskContribution && (
          <Card className="mt-4">
            {riskRatingExcessRiskContributionChart()}
          </Card>
        )}

        { renderRiskRatingExcessTailRiskContribution && (
          <Card className="mt-4">
            {riskRatingExcessTailRiskContributionChart()}
          </Card>
        )}

        { renderRiskRatingExcessRiskContributionRAROC && (
          <Card className="mt-4">
            {riskRatingExcessRiskContributionRAROCChart()}
          </Card>
        )}

        { renderRiskRatingExcessTailRiskContributionRAROC && (
          <Card className="mt-4">
            {riskRatingExcessTailRiskContributionRAROCChart()}
          </Card>
        )}

        { renderProductTypeExcessExpectedReturns && (
          <Card className="mt-4">
            {productTypeExcessExpectedReturnsChart()}
          </Card>
        )}

        { renderProductTypeExcessRiskContribution && (
          <Card className="mt-4">
            {productTypeExcessRiskContributionChart()}
          </Card>
        )}

        { renderProductTypeExcessTailRiskContribution && (
          <Card className="mt-4">
            {productTypeExcessTailRiskContributionChart()}
          </Card>
        )}

        { renderProductTypeExcessRiskContributionRAROC && (
          <Card className="mt-4">
            {productTypeExcessRiskContributionRAROCChart()}
          </Card>
        )}

        { renderProductTypeExcessTailRiskContributionRAROC && (
          <Card className="mt-4">
            {productTypeExcessTailRiskContributionRAROCChart()}
          </Card>
        )}

        { renderDepartmentExcessExpectedReturns && (
          <Card className="mt-4">
            {departmentExcessExpectedReturnsChart()}
          </Card>
        )}

        { renderDepartmentExcessRiskContribution && (
          <Card className="mt-4">
            {departmentExcessRiskContributionChart()}
          </Card>
        )}

        { renderDepartmentExcessTailRiskContribution && (
          <Card className="mt-4">
            {departmentExcessTailRiskContributionChart()}
          </Card>
        )}

        { renderDepartmentExcessRiskContributionRAROC && (
          <Card className="mt-4">
            {departmentExcessRiskContributionRAROCChart()}
          </Card>
        )}

        { renderDepartmentExcessTailRiskContributionRAROC && (
          <Card className="mt-4">
            {departmentExcessTailRiskContributionRAROCChart()}
          </Card>
        )}

        { renderRegionExcessExpectedReturns && (
          <Card className="mt-4">
            {regionExcessExpectedReturnsChart()}
          </Card>
        )}

        { renderRegionExcessRiskContribution && (
          <Card className="mt-4">
            {regionExcessRiskContributionChart()}
          </Card>
        )}

        { renderRegionExcessTailRiskContribution && (
          <Card className="mt-4">
            {regionExcessTailRiskContributionChart()}
          </Card>
        )}

        { renderRegionExcessRiskContributionRAROC && (
          <Card className="mt-4">
            {regionExcessRiskContributionRAROCChart()}
          </Card>
        )}

        { renderRegionExcessTailRiskContributionRAROC && (
          <Card className="mt-4">
            {regionExcessTailRiskContributionRAROCChart()}
          </Card>
        )}
      </div>
    );
  }
}

const loadingSelector = createLoadingSelector([
  'FETCH_PERFORMANCE_MEASURES',
  'FETCH_JOBS',
  'FETCH_SCENARIOS',
  'FETCH_PERFORMANCE_FEATURES',
  'FETCH_PORTFOLIO_VALUES',
]);
const errorSelector = createErrorMessageSelector(['FETCH_PERFORMANCE_MEASURES', 'FETCH_JOBS', 'FETCH_PERFORMANCE_FEATURES']);

const mapStateToProps = (state) => (
  {
    authentication: state.authentication,
    jobs: state.jobs,
    scenarios: state.scenarios,
    performanceMeasures: state.performanceMeasures,
    performanceFeatures: state.performancefeatures,
    assetsPerformance: state.assetsPerformance,
    portfolioPerformance: state.portfolioPerformance,
    portfolioValues: state.portfoliovalue,
    isFetching: loadingSelector(state),
    isError: errorSelector(state),
    loading: state.loading,
    error: state.error,
  }
);

const mapDispatchToProps = (dispatch) => (
  {
    fetchJobs: (token) => {
      dispatch(jobs.fetchJobs(token));
    },
    fetchScenario: (scenarioID, token) => {
      dispatch(scenarios.fetchScenario(scenarioID, token));
    },
    fetchAssetsPerformance: (id, token) => {
      dispatch(assetsperformance.fetchAssetsPerformance(id, token));
    },
    fetchPortfolioPerformance: (id, token) => {
      dispatch(portfolioperformance.fetchPortfolioPerformance(id, token));
    },
    fetchPortfolioValues: (id, token) => {
      dispatch(portfoliovalue.fetchPortfolioValue(id, token));
    },
    fetchPerformanceFeatures: (id, feature, token) => {
      dispatch(performancefeatures.fetchPerformanceFeatures(id, feature, token));
    },
    createXLSXPerformanceMeasures: (text, token) => {
      dispatch(portfolioperformance.createXLSXPortfolioPerformance(text, token));
    },
    createXLSXPerformanceFeatures: (text, token) => {
      dispatch(performancefeatures.createXLSXPerformanceFeatures(text, token));
    },
  }
);

export default connect(mapStateToProps, mapDispatchToProps)(ResultsSimulation);

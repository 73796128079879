const initialState = {data: [], isLoading: true};

export default function scenarios(state=initialState, action) {
  switch(action.type) {

    case 'ADD_SCENARIO_SUCCESS':
      return {...state, data: [...state.data, action.scenario], lastAdded: true};

    case 'ADD_SCENARIO_FAILURE':
      return {data: state.data, error: true, error_text: action.error_text.message, lastAdded: false};

    case 'DELETE_SCENARIO_SUCCESS':
      let new_state = [...state.data].filter((elem) => elem.scenarioID !== action.id)
      return {data: new_state, deleted: true};

    case 'DELETE_SCENARIO_FAILURE':
      return {data: state.data, error: true, error_type: action.error_text.message, deleted: false};

    case 'FETCH_SCENARIO_SUCCESS':
      return {data: [action.scenario], isLoading: false};

    case 'FETCH_SCENARIO_FAILURE':
      return {data: state.data, error: true, error_type: action.error_type, lastAdded: false};

    case 'FETCH_SCENARIOS_SUCCESS':
      return {data: action.scenarios, isLoading: false};

    case 'FETCH_SCENARIOS_FAILURE':
      return {data: state.data, error: true, error_type: action.error_type, lastAdded: false};

    default:
      return state;
  }
}

function handleErrors(response) {
    if (!response.ok) {
        throw Error(response.statusText);
    }
    return response;
}

export const fetchAssetCharacteristics = (jobid, id, token) => {
  return dispatch => {
    const headers = {"Content-Type": "application/json", "Authorization": "JWT " + token};
    dispatch({ type: 'FETCH_ASSET_CHARACTERISTICS_REQUEST' });
    return fetch(process.env.REACT_APP_HTTP_PROTOCOL + "://" + process.env.REACT_APP_API_IP + "/api/results/transactions/" + jobid + "/" + id + "/", {headers, })
      .then(handleErrors)
      .then(res => res.json())
      .then(asset_characteristics => {
        return dispatch({
          type: 'FETCH_ASSET_CHARACTERISTICS_SUCCESS',
          asset_characteristics
        })
      })
      .catch(error => {
        return dispatch({ type: 'FETCH_ASSET_CHARACTERISTICS_FAILURE', error_type: error, error: true })
      })
  }
}

export const fetchAssets = (id, token) => {
  return dispatch => {
    const headers = {"Content-Type": "application/json", "Authorization": "JWT " + token};
    dispatch({ type: 'FETCH_ASSETS_REQUEST' });
    return fetch(process.env.REACT_APP_HTTP_PROTOCOL + "://" + process.env.REACT_APP_API_IP + "/api/results/datasettransactions/" + id + "/", {headers, })
      .then(handleErrors)
      .then(res => res.json())
      .then(assets => {
        return dispatch({
          type: 'FETCH_ASSETS_SUCCESS',
          assets
        })
      })
      .catch(error => {
        return dispatch({ type: 'FETCH_ASSETS_FAILURE', error_type: error, error: true })
      })
  }
}

export const fetchAllAssetMTMValues = (jobID, token) => {
  return dispatch => {
    const headers = {"Content-Type": "application/json", "Authorization": "JWT " + token};
    dispatch({ type: 'FETCH_ASSETS_MTM_VALUES_REQUEST' });
    return fetch(process.env.REACT_APP_HTTP_PROTOCOL + "://" + process.env.REACT_APP_API_IP + "/api/results/transactionmtmvalues/" + jobID + "/", {headers, })
      .then(handleErrors)
      .then(res => res.json())
      .then(assets => {
        return dispatch({
          type: 'FETCH_ASSETS_MTM_VALUES_SUCCESS',
          assets
        })
      })
      .catch(error => {
        return dispatch ({ type: 'FETCH_ASSETS_MTM_VALUES_FAILURE', error_type: error, error: true})
      })
  }
}

export const fetchAlternativeAllAssetMTMValues = (jobID, token) => {
  return dispatch => {
    const headers = {"Content-Type": "application/json", "Authorization": "JWT " + token};
    dispatch({ type: 'FETCH_ALTERNATIVE_ASSETS_MTM_VALUES_REQUEST' });
    return fetch(process.env.REACT_APP_HTTP_PROTOCOL + "://" + process.env.REACT_APP_API_IP + "/api/results/transactionmtmvalues/" + jobID + "/", {headers, })
      .then(handleErrors)
      .then(res => res.json())
      .then(assets => {
        return dispatch({
          type: 'FETCH_ALTERNATIVE_ASSETS_MTM_VALUES_SUCCESS',
          assets
        })
      })
      .catch(error => {
        return dispatch ({ type: 'FETCH_ALTERNATIVE_ASSETS_MTM_VALUES_FAILURE', error_type: error, error: true})
      })
  }
}
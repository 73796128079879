// Import React & Redux components
import React from "react";
import { Field, reduxForm } from 'redux-form';
import { Button } from 'react-bootstrap';

// Import layout and helper functions/templates
import { populateOptions } from './Helpers'

class FormSelectOneDropDownPlusNameMessage extends React.Component {

  render() {

    return (
      <div>
        <form className="form-inline" onSubmit={this.props.handleSubmit}>
            <div className="form-group">
              <label style={{marginRight: '10px'}}>{this.props.itemLabel}</label>
              <Field name="selectedItemIndex" className="form-control" component="select" style={{width: '100px'}}>
                <option value="">{this.props.currentlySelectedItemName}</option>
                {populateOptions(this.props.itemName)}
              </Field>
            </div>
            <div className="form-group">
              <label htmlFor="text" style={{marginRight: '10px' }}>{this.props.inputName}</label>
              <Field name="inputName" type="text" className="form-control" component="input" style={{width: '200px'}}/>
            </div>
            <div className="form-group">
              <label htmlFor="text" style={{marginRight: '10px' }}>{this.props.message}</label>
            </div>
        </form>
      </div>
    )
  }
}

export default reduxForm({
  form: 'selectOneDropDownPlusNameMessage', // a unique identifier for this form
})(FormSelectOneDropDownPlusNameMessage);

const initialState = {data: {}, isLoading: true};

export default function scraper(state=initialState, action) {
  switch(action.type) {
    case 'FETCH_SCRAPER_JOBS_SUCCESS':
      return {data: action.scraper, isLoading: false};

    case 'FETCH_SCRAPER_JOBS_FAILURE':
      return {data: action.scraper, error: true};

    case 'ADD_SCRAPER_JOB_SUCCESS':
      return {data: action.scraper, lastAdded: true};

    case 'ADD_SCRAPER_JOB_FAILURE':
      return {data: action.scraper, error: true};

    default:
      return state;
  }
}

export function tickerlists(state=initialState, action) {
  switch(action.type) {
    case 'FETCH_TICKERLISTS_SUCCESS':
      return {data: action.tickerlists, isLoading: false};

    case 'FETCH_TICKERLISTS_FAILURE':
      return {data: action.tickerlists, error: true};

    default:
      return state;
  }
}

import React from "react";
import { connect } from 'react-redux'
import Dropzone from 'react-dropzone'
import Papa from 'papaparse'
import { Card, Button, Form } from 'react-bootstrap';

// Import layout and helper functions/templates
import Chart from "../components/Chart";
import FormSelectName from "../components/FormSelectName";
import Header from "../components/Header";

import SimpleTable from '../components/SimpleTable'

import { inputs, selections } from "../actions";

const baseStyle = {
  width: 200,
  height: 200,
  borderWidth: 2,
  borderColor: '#666',
  borderStyle: 'dashed',
  borderRadius: 5,
  paddingTop: 10,
  paddingLeft: 10
};

const activeStyle = {
  borderStyle: 'solid',
  borderColor: '#6c6',
  backgroundColor: '#eee'
};

const rejectStyle = {
  borderStyle: 'solid',
  borderColor: '#c66',
  backgroundColor: '#eee'
};

function transposeJSON(data) {
  let transposed_JSON = []
  let timestamps = []
  Object.entries(data[0]).forEach(
    ([key, value]) => (
      transposed_JSON.push({
        "header": key,
        "data": []
      })
    )
  )

  data.map((elem, index) => {
    Object.keys(elem).forEach((key) => {
      if(key === "Timepoint"){
        timestamps.push(elem[key])
      }
      let target_elem = transposed_JSON.filter(elem => {if(elem.header === key) return elem})[0]
      target_elem.data.push({
        "timestamp": null,
        "curve": key,
        "value": elem[key]
      })
    });
  })

  transposed_JSON.map((elem, index) => {
    elem.data.map((elem, index) => {
      elem.timestamp = timestamps[index]
    })
  })
  return transposed_JSON
}

class RiskFreeCurveUpload extends React.Component{
  constructor() {
    super();
    this.state = {
      riskFreeCurve: null,
      curveSetName: null,
      riskFreeCurveSubmit: null,
      curveKeys: null,
    }
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  deleteRiskFreeCurveGroup = (id) => {
    this.props.deleteRiskFreeCurveGroup(id, sessionStorage.getItem('jwtToken'))
  }

  componentDidMount(){
    this.props.fetchRiskFreeCurves(sessionStorage.getItem('jwtToken'))
  }

  trashcan_column = {
    accessor: "delete_curveset",
    Cell: ({ original }) => {
      return (
        <i
          className="fa fa-trash"
          style={{ display: "inline-block", width: "100%", textAlign: "center" }}
          onClick={() => {this.deleteRiskFreeCurveGroup(original.curveSetID)}}
        >
        </i>
      );
    },
    header: "Delete Curve Set",
    sortable: false,
    width: 45
  };

  onDrop = (files) => {
    const reader = new FileReader()
    let react_var = this;
    reader.onload = function () {
      Papa.parse(reader.result, {
        complete: function(results) {
          let curves_array = results.meta.fields
          // Shift the first column containing timestamps
          curves_array.shift()
          let rate_curves = results.data.map(x => {
            var new_dict = {}
            Object.entries(x).forEach(
              ([key, value]) => (
                (key === 'Timepoint') ? (new_dict[key] = value) : (new_dict[key] = parseFloat(value))
              )
            )
            return new_dict
          });

          if(Object.keys(rate_curves[rate_curves.length - 1]).length < 2){
            rate_curves.pop()
          }
          let submit_structure = transposeJSON(rate_curves)
          let curve_counter = 1
          submit_structure.forEach((elem, index) => {
            if (elem.header !== 'Timepoint'){
              elem.curveID = curve_counter
              curve_counter = curve_counter + 1
            }
          })
          react_var.setState((state, props) => ({
            riskFreeCurve: rate_curves,
            riskFreeCurveSubmit: submit_structure,
            curveKeys: Object.keys(rate_curves[0])
          }));
  	    },
        header: true,
      });
    }
    reader.readAsText(files[0])
  }

  handleSubmit(event) {
    let value = {}
    value.curveSetName = this.refs.curvename.value.trim()
    value.curveSetDate = this.refs.curvedate.value.trim()
    value.riskFreeCurve = this.state.riskFreeCurveSubmit
    event.preventDefault();
    this.props.addRiskFreeCurve(value, sessionStorage.getItem('jwtToken'))
  }

  render() {
    const renderRiskFreeCurvesOverview = this.props.riskFreeCurves.isLoading !== true
    const renderRiskFreeCurvePreview = this.state.riskFreeCurve !== null

    const riskFreeCurvesOverview = () => {
      let risk_free_curve_group_ids = [...new Set(this.props.riskFreeCurves.data.map(elem => {return elem.curveSetID}))]
      let risk_free_curve_groups = []
      risk_free_curve_group_ids.forEach(id => {
        let entries = this.props.riskFreeCurves.data.filter(entry => id === entry.curveSetID)
        risk_free_curve_groups.push(entries[0])
      })
      return (
        <SimpleTable
          data={risk_free_curve_groups}
          sortColumn='curveSetID'
          sortDescendingOrder={false}
          columns={[
            {accessor: "curveSetID", Header: "Curve Set ID"},
            {accessor: "curveSetName", Header: "Curve Set Name"},
            {accessor: "curveTimepoint", Header: "Curve Timepoint"},
            this.trashcan_column,
          ]}
        />
      )
    }

    const riskFreeCurvePreview = () => {
      let dataseries = []
      this.state.curveKeys.forEach(elem => {
        let serie = this.state.riskFreeCurve.map(timepoint => {return timepoint[elem]})
        dataseries.push(serie)
      })
      return dataseries
    }

    return(
    <div>
      <Header title={"Risk Free Curve Upload"} />
      <Card className="mt-4">
        <Card.Header>Risk Free Curve Upload</Card.Header>
        <Card.Body>
          <form onSubmit={this.handleSubmit}>
            <input type='text' ref='curvename' className="form-control" placeholder='Curve name'/>
            <input type='text' ref='curvedate' className="form-control" placeholder='Curve observation date (YYYY-MM-DD)'/>
            <input type="submit" className="btn btn-primary" value="Save"/>
          </form>
          <Dropzone accept="text/csv, application/vnd.ms-excel" onDrop={this.onDrop}>
            {({ getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject, acceptedFiles, rejectedFiles }) => {
              let styles = {...baseStyle}
              styles = isDragActive ? {...styles, ...activeStyle} : styles
              styles = isDragReject ? {...styles, ...rejectStyle} : styles

              return (
                <div {...getRootProps()} style={styles} >
                  <input {...getInputProps()} />
                  <div>
                    {isDragAccept ? 'Drop' : 'Drag'} files here...
                  </div>
                  {isDragReject && <div>Please provide CSV files</div>}
                </div>
              )
            }}
          </Dropzone>
        </Card.Body>
      </Card>

      { this.state.riskFreeCurve !== null ? (
        <Card className="mt-4">
          <Card.Header>Interest Rate Curve preview</Card.Header>
          <Card.Body>
            <Chart type={"Line"}
              unit={"decimal"}
              xaxislabels={riskFreeCurvePreview()[0]}
              dataseries={riskFreeCurvePreview().slice(1)}
              dataserieslabels={this.state.curveKeys.slice(1)}
            />
          </Card.Body>
        </Card>
      ) : ( <br /> ) }

      { renderRiskFreeCurvesOverview && (
        <Card className="mt-4">
          <Card.Header>Uploaded Risk Free Curves</Card.Header>
          <Card.Body>
            {riskFreeCurvesOverview()}
          </Card.Body>
        </Card>
      )}
    </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    authentication: state.authentication,
    loading: state.loading,
    error: state.error,
    riskFreeCurves: state.riskFreeCurves,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    addRiskFreeCurve: (values, token) => {
      return dispatch(inputs.addRiskFreeCurve(values, token));
    },
    deleteRiskFreeCurveGroup: (id, token) => {
      return dispatch(inputs.deleteRiskFreeCurveGroup(id, token));
    },
    fetchRiskFreeCurves: (token) => {
      return dispatch(inputs.fetchRiskFreeCurves(token));
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RiskFreeCurveUpload);

// Import React & Redux components

import React from 'react';

import { Route, Switch, BrowserRouter as Router, Redirect, withRouter } from 'react-router-dom';
import { Provider, connect } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import logger from "redux-logger";
import SideNav, { Toggle, Nav, NavItem, NavIcon, NavText } from '@trendmicro/react-sidenav';
import thunk from "redux-thunk";
import pm_app from "./reducers";
import { Button, Row, Card, Form, Col } from 'react-bootstrap'
import jwt_decode from 'jwt-decode';
//import LogRocket from 'logrocket';

// Import layout and helper functions/templates
import Header from './components/Header'

// Import components
import NotFound from "./components/NotFound";
import CreatePortfolioSelection from "./components/CreatePortfolioSelection";
import ResultsSimulation from "./components/ResultsSimulation"
import CreateScenario from "./components/CreateScenario";
import ResultsAsset from "./components/ResultsAsset";
import ResultsPortfolio from "./components/ResultsPortfolio"
import CreateJob from './components/CreateJob'
import BasePortfolio from './components/BasePortfolio'
import CounterpartyDetails from './components/CounterpartyDetails'
import PortfolioDetails from './components/PortfolioDetails'
import BenchmarkComposition from './components/BenchmarkComposition'

// New stuff
import Heatmap from "./components/Heatmap"
import FactorLoadingsUpload from "./pages/FactorLoadingsUpload"
import FactorGroupUpload from "./components/FactorGroupUpload"
import DatasetUpload from "./pages/DatasetUpload"
import LinkPortfolio from './pages/LinkPortfolio'
import RiskFreeCurveUpload from './pages/RiskFreeCurveUpload'
import CounterpartyLoadingsUpload from './components/CounterpartyLoadingsUpload'
import ViewPortfolioSelection from './components/ViewPortfolioSelection'
import FirmFundamentalUploads from './components/FirmFundamentalUploads'
import RedemptionProfileUpload from './components/RedemptionProfileUpload'
import UserManagement from './components/UserManagement'
import SimulationWizard from './pages/SimulationWizard'
import PortfolioSelection from './pages/PortfolioSelection'
import ScraperInitializeForm from './components/ScraperInitializeForm'
import PortfolioConstruction from './components/PortfolioConstruction'
import ErrorBoundary from './components/ErrorBoundary'

// Import sources
import { authentication } from "./actions"

//let store = createStore(pm_app, applyMiddleware(thunk, logger));
let store = createStore(pm_app, applyMiddleware(thunk));

// LogRocket.init('ghsb8x/ub_frontend');
// LogRocket.identify(sessionStorage.getItem('jwtToken') !== null ? jwt_decode(sessionStorage.getItem('jwtToken')).user_id : '0', {
  // name: sessionStorage.getItem('jwtToken') !== null ? jwt_decode(sessionStorage.getItem('jwtToken')).username : 'Anonymous',
  // email: sessionStorage.getItem('jwtToken') !== null ? jwt_decode(sessionStorage.getItem('jwtToken')).email : 'Anonymous'
// });

// Constant to keep track of authentication status, which can be called to
// check this status for routes behind password protection.
const checkAuthentication = {
  isAuthenticated: false,
  authenticate() {
    this.isAuthenticated = true;
  },
  signout() {
    sessionStorage.removeItem('jwtToken')
    this.isAuthenticated = false;
  },
  checkAuthentication() {
    const jwt_token = sessionStorage.getItem('jwtToken');
    if (jwt_token === undefined || jwt_token === null) {
      return false
    } else {
      const decoded_jwt = jwt_decode(jwt_token);
      if (decoded_jwt.exp < (Math.round(Date.now() / 1000))) {
        this.isAuthenticated = false;
        sessionStorage.removeItem('jwtToken')
        return false
      } else {
        this.isAuthenticated = true;
        this.username = decoded_jwt.username;
        return true
      }
    }
  }
};

// Function to connect components to the store in addition to other elements.
function connectWithStore(store, WrappedComponent, ...args) {
  let ConnectedWrappedComponent = connect(...args)(WrappedComponent)
  return function (props) {
    return <ConnectedWrappedComponent {...props} store={store} />
  }
}

// Function to check authentication status for routes which are behind the
// password protection.
function PrivateRoute({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={props =>
        checkAuthentication.checkAuthentication() ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/login_screen",
              state: { from: props.location }
            }}
          />
        )
      }
    />
  );
}

function App() {
  return (
    <React.StrictMode>
    <Provider store={store}>
    <Router>
    <Switch>
    <Route render={({ location, history }) => (
      <React.Fragment>
        <SideNav
          onSelect={(selected) => {
            const to = '/' + selected;
            if (location.pathname !== to) {
              history.push(to);
            }
          }}
          id="sidenav"
          disabled={true}>
          <SideNav.Toggle/>
          <SideNav.Nav defaultSelected={location.pathname.substring(1)}>
            <NavItem eventKey="login_screen">
              <NavIcon><svg className="menu-icon" viewBox="0 0 52 59">
                <path d="M26.23,30.11A14.66,14.66,0,0,0,40.41,15.05a14.2,14.2,0,0,0-28.35-1.71,16.26,16.26,0,0,0,0,1.71A14.66,14.66,0,0,0,26.23,30.11Z"/>
                <path d="M26.23,33.19C11.77,33.19,0,43.2,0,55.5v3.35H52.46V55.5C52.46,43.2,40.7,33.19,26.23,33.19Z" /></svg></NavIcon>
              <NavText>Login</NavText>
            </NavItem>
            {/*<NavItem eventKey="portfolioconstruction">
              <NavIcon><i className="fa fa-fw fa-compass" style={{ fontSize: '1.75em' }} /></NavIcon>
              <NavText>Portfolio Construction</NavText>
            </NavItem>*/}
            <NavItem eventKey="create_job">
              <NavIcon><svg className="menu-icon" viewBox="0 0 59 59"><path d="M29.41,0A29.41,29.41,0,1,0,58.82,29.41h0A29.45,29.45,0,0,0,29.41,0ZM43.55,31.67H31.67V44.12a2.26,2.26,0,0,1-4.52,0h0V31.67H15.27a2.26,2.26,0,0,1,0-4.52H27.15V15.84a2.26,2.26,0,0,1,4.52,0V27.15H43.55a2.26,2.26,0,1,1,0,4.52Z" /></svg></NavIcon>
              <NavText>New Job</NavText>
            </NavItem>
            {/*<NavItem eventKey="data_management">
              <NavIcon><svg className="menu-icon" viewBox="0 0 47 47">
                <path d="M43.17,0h-39A4.18,4.18,0,0,0,0,4.16v39a4.18,4.18,0,0,0,4.16,4.16h39a4.17,4.17,0,0,0,4.16-4.16v-39A4.18,4.18,0,0,0,43.17,0ZM26.9,7.77a1.38,1.38,0,0,1,1.38-1.39h0a1.38,1.38,0,0,1,1.38,1.38h0v6.84A1.37,1.37,0,0,1,28.29,16h0a1.37,1.37,0,0,1-1.39-1.38h0Zm0,12.48a1.38,1.38,0,0,1,1.38-1.39h0a1.38,1.38,0,0,1,1.38,1.38h0v6.84a1.38,1.38,0,0,1-1.38,1.39h0a1.39,1.39,0,0,1-1.39-1.39h0ZM8.41,7.77A1.38,1.38,0,0,1,9.79,6.38h0a1.4,1.4,0,0,1,1.39,1.38h0v6.84A1.38,1.38,0,0,1,9.8,16h0a1.37,1.37,0,0,1-1.39-1.38h0Zm2.78,31.81A1.4,1.4,0,0,1,9.8,41h0a1.39,1.39,0,0,1-1.39-1.39h0V32.74a1.38,1.38,0,0,1,1.38-1.39h0a1.4,1.4,0,0,1,1.39,1.38h0ZM13,25.25a3.24,3.24,0,0,1-6.47,0V22.11a3.24,3.24,0,1,1,6.47,0Zm9.25,12.48a3.24,3.24,0,1,1-6.47,0V34.59a3.24,3.24,0,1,1,6.47,0ZM17.66,27.1V20.26A1.38,1.38,0,0,1,19,18.87h0a1.38,1.38,0,0,1,1.39,1.38h0V27.1a1.38,1.38,0,0,1-1.38,1.38h0a1.37,1.37,0,0,1-1.38-1.38h0Zm4.62-14.33a3.24,3.24,0,0,1-6.47,0V9.62a3.24,3.24,0,0,1,6.47,0Zm9.25,25a3.24,3.24,0,1,1-6.47,0V34.59a3.24,3.24,0,1,1,6.47,0Zm7.39,1.85A1.4,1.4,0,0,1,37.54,41h0a1.38,1.38,0,0,1-1.38-1.39h0V32.74a1.38,1.38,0,0,1,1.38-1.39h0a1.38,1.38,0,0,1,1.39,1.38h0Zm1.85-14.33a3.24,3.24,0,0,1-6.47,0V22.11a3.24,3.24,0,1,1,6.47,0Zm0-12.48a3.24,3.24,0,0,1-6.47,0V9.62a3.24,3.24,0,0,1,6.47,0Z" />
                </svg></NavIcon>
              <NavText>Data Management</NavText>
            </NavItem>*/}
            <NavItem eventKey="base_portfolio">
              <NavIcon><i className="fa fa-fw fa-pie-chart" style={{ fontSize: '1.75em' }} /></NavIcon>
              <NavText>Descriptives</NavText>
            </NavItem>
            <NavItem eventKey="projections">
              <NavIcon><svg className="menu-icon" viewBox="0 0 57 57">
                <path d="M8.28,36.33a1.66,1.66,0,0,0,.31,1.92l.4.41c1.18-1.39,2.55-2.9,4.21-4.56.11-.12.24-.18.35-.29L10.94,31.2C9.42,34,8.53,35.83,8.28,36.33Z"/>
                <path d="M22.59,43.5c-1.66,1.66-3.16,3-4.55,4.21l.55.55a1.67,1.67,0,0,0,1.94.3c.86-.44,2.21-1.08,5.08-2.68l-2.73-2.74c-.11.12-.17.25-.29.36Z"/>
                <path d="M21.71,35c-1.2-1.2-4.58-.12-6.16,1.46A44.34,44.34,0,0,0,6.77,47.73,1.66,1.66,0,0,0,9,50a44.31,44.31,0,0,0,11.25-8.81c1.75-1.74,2.61-5,1.48-6.16Z"/>
                <path d="M5.18,22.11.49,26.8a1.66,1.66,0,0,0,1.6,2.79A13.53,13.53,0,0,1,8.37,29a91,91,0,0,1,6.88-10.49c-1.41-.9-6.06-.4-10.06,3.6Z"/>
                <path d="M28.11,56.52A1.66,1.66,0,0,0,30,56.21l4.7-4.7c3.63-3.63,4.27-8.33,3.44-9.83a96.2,96.2,0,0,1-10.5,6.82,20.38,20.38,0,0,1-.47,6.28,1.65,1.65,0,0,0,.89,1.72Z"/>
                <path d="M33,16.65a5,5,0,1,0,7,0A5,5,0,0,0,33,16.65Z"/>
                <path d="M56.61,1.61A1.65,1.65,0,0,0,55.08.08,32.77,32.77,0,0,0,43,1.5a14,14,0,0,0,4.13,8.11,13.9,13.9,0,0,0,8.28,4.12A34.45,34.45,0,0,0,56.61,1.61Z"/>
                <path d="M44.73,12a17.45,17.45,0,0,1-5-9.3c-10,4.09-19.53,13-27.08,25.57L16.41,32a7,7,0,0,1,7.65.65,7,7,0,0,1,.65,7.65l3.88,3.87c9.65-5.82,20.89-15,25.68-27.19A17.44,17.44,0,0,1,44.73,12Zm-2.35,14.1a8.3,8.3,0,1,1,0-11.75A8.32,8.32,0,0,1,42.38,26.06Z"/>
                </svg></NavIcon>
              <NavText>Projections</NavText>
            </NavItem>
            <NavItem eventKey="performancemeasures">
              <NavIcon><svg className="menu-icon" viewBox="0 0 59 59"><path d="M52.85,6a20.63,20.63,0,0,0-30.34,27.9L18.33,38.1a5.69,5.69,0,0,0-6.62,1L1.58,49.26a5.68,5.68,0,0,0,8,8L19.74,47.17a5.69,5.69,0,0,0,1-6.64L25,36.35A20.63,20.63,0,0,0,52.85,6ZM31.24,28a1.73,1.73,0,1,1-3.45,0V22.45a1.73,1.73,0,1,1,3.45,0ZM37,28a1.73,1.73,0,1,1-3.45,0V16.7a1.73,1.73,0,1,1,3.45,0Zm5.76,0a1.73,1.73,0,1,1-3.46,0V19a1.73,1.73,0,1,1,3.46,0h0Zm5.75,0a1.73,1.73,0,1,1-3.45,0V13.23a1.73,1.73,0,1,1,3.45,0Z" /></svg></NavIcon>
              <NavText>Analytics</NavText>
            </NavItem>
            {/*<NavItem eventKey="data_upload">
              <NavIcon><svg className="menu-icon" viewBox="0 0 67 49">
                <path d="M26.108,0L15.585,10.524c-1.048,1.024-0.815,1.746,0.649,1.746h5.873l-0.001,12.788c0,2.208,1.79,4,4,4l0,0c2.209,0,4-1.791,4-4l0.001-12.789h5.876c1.462,0.001,1.693-0.723,0.646-1.747L26.108,0z"/>
                <path d="M49.027,25.77h-6.049c-0.554,0-1,0.447-1,1v17.939H10.78V26.77c0-0.553-0.447-1-1-1H3.731c-0.553,0-1,0.447-1,1v20.464c0,3.045,2.479,5.522,5.524,5.522h36.248c3.046,0,5.523-2.479,5.523-5.522V26.77C50.027,26.217,49.581,25.77,49.027,25.77z"/>
              </svg></NavIcon>
              <NavText>Data Upload</NavText>
            </NavItem>
            <NavItem eventKey="my_old_jobs">
              <NavIcon>
                <svg className="menu-icon" viewBox="0 0 49 49">
                  <path d="M24.27,0A24.28,24.28,0,1,0,48.55,24.27,24.28,24.28,0,0,0,24.27,0ZM36.79,16.85,25.35,28.22h0a1.56,1.56,0,0,1-2.21,0l-3.76-3.78,0,0a1.55,1.55,0,0,1,0-2.2,1.58,1.58,0,0,1,2.23,0l2.65,2.66,4.61-4.59a6.73,6.73,0,1,0,1.59,6.57,1.58,1.58,0,0,1,3,.91,9.86,9.86,0,1,1-2.37-9.72l3.46-3.44.06-.06a1.57,1.57,0,0,1,2.16,2.29Z"/>
                </svg>
              </NavIcon>
              <NavText>My Old Jobs</NavText>
            </NavItem>*/}
            { ['jornkoch', 'giorgiocarbone', 'samirzamarial'].includes(store.getState().authentication.user) && (
              <NavItem eventKey="user_management">
                <NavIcon><i className="fa fa-fw fa-user" style={{ fontSize: '1.75em' }} /></NavIcon>
                <NavText>User Management</NavText>
              </NavItem>
            )}
            { ['jornkoch', 'giorgiocarbone', 'samirzamarial'].includes(store.getState().authentication.user) && (
              <NavItem eventKey="start_scrape_run">
                <NavIcon><i className="fa fa-fw fa-grav" style={{ fontSize: '1.75em' }} /></NavIcon>
                <NavText>Scrape Setup</NavText>
              </NavItem>
            )}
            {/*<NavItem eventKey="portfolio_selection">
              <NavIcon><i className="fa fa-fw fa-list" style={{ fontSize: '1.75em' }} /></NavIcon>
              <NavText>Portfolio Selection</NavText>
            </NavItem>
            <NavItem eventKey="simulation_setup">
              <NavIcon><i className="fa fa-fw fa-tasks" style={{ fontSize: '1.75em' }} /></NavIcon>
              <NavText>Create Scenario</NavText>
            </NavItem>
            <NavItem eventKey="start_job">
              <NavIcon><i className="fa fa-fw fa-rocket" style={{ fontSize: '1.75em' }} /></NavIcon>
              <NavText>Start Job</NavText>
            </NavItem>
            <NavItem eventKey="base_portfolio">
              <NavIcon><i className="fa fa-fw fa-pie-chart" style={{ fontSize: '1.75em' }} /></NavIcon>
              <NavText>Base Portfolio</NavText>
            </NavItem>
            <NavItem eventKey="performancemeasures">
              <NavIcon><i className="fa fa-fw fa-line-chart" style={{ fontSize: '1.75em' }} /></NavIcon>
              <NavText>Simulation Output</NavText>
            </NavItem>
            <NavItem eventKey="portfolio">
              <NavIcon><i className="fa fa-fw fa-bar-chart" style={{ fontSize: '1.75em' }} /></NavIcon>
              <NavText>Asset Portfolio</NavText>
            </NavItem>
            <NavItem eventKey="asset">
              <NavIcon><i className="fa fa-fw fa-search" style={{ fontSize: '1.75em' }} /></NavIcon>
              <NavText>Individual Asset</NavText>
            </NavItem>
            <NavItem eventKey="link_portfolio">
              <NavIcon><i className="fa fa-fw fa-link" style={{ fontSize: '1.75em' }} /></NavIcon>
              <NavText>Link Portfolio</NavText>
            </NavItem>
            <NavItem eventKey="heatmap">
              <NavIcon><i className="fa fa-fw fa-arrow-up" style={{ fontSize: '1.75em' }} /></NavIcon>
              <NavText>Heatmap</NavText>
            </NavItem>
            <NavItem eventKey="factor_loadings_upload">
              <NavIcon><i className="fa fa-fw fa-arrow-up" style={{ fontSize: '1.75em' }} /></NavIcon>
              <NavText>FactorLoadings Upload</NavText>
            </NavItem>*/}
            {/*}<NavItem eventKey="dataset_upload">
              <NavIcon><i className="fa fa-fw fa-arrow-up" style={{ fontSize: '1.75em' }} /></NavIcon>
              <NavText>Dataset Upload</NavText>
            </NavItem>*/}
            {/*<NavItem eventKey="factorgroupupload">
              <NavIcon><i className="fa fa-fw fa-arrow-up" style={{ fontSize: '1.75em' }} /></NavIcon>
              <NavText>FactorGroup Upload</NavText>
            </NavItem>
            <NavItem eventKey="riskfreecurveupload">
              <NavIcon><i className="fa fa-fw fa-arrow-up" style={{ fontSize: '1.75em' }} /></NavIcon>
              <NavText>Risk Free Curve Upload</NavText>
            </NavItem>
            <NavItem eventKey="counterpartyloadingsupload">
              <NavIcon><i className="fa fa-fw fa-arrow-up" style={{ fontSize: '1.75em' }} /></NavIcon>
              <NavText>Counterparty Loadings Upload</NavText>
            </NavItem>
            <NavItem eventKey="firmfundamentaluploads">
              <NavIcon><i className="fa fa-fw fa-arrow-up" style={{ fontSize: '1.75em' }} /></NavIcon>
              <NavText>Firm Fundamental Uploads</NavText>
            </NavItem>
            <NavItem eventKey="redemptionprofileupload">
              <NavIcon><i className="fa fa-fw fa-arrow-up" style={{ fontSize: '1.75em' }} /></NavIcon>
              <NavText>Redemption Profile Upload</NavText>
            </NavItem>
            <NavItem eventKey="counterparty_insights">
              <NavIcon><i className="fa fa-fw fa-building" style={{ fontSize: '1.75em' }} /></NavIcon>
              <NavText>Counterparty Insights</NavText>
            </NavItem>
            <NavItem eventKey="portfolio_insights">
              <NavIcon><i className="fa fa-fw fa-cubes" style={{ fontSize: '1.75em' }} /></NavIcon>
              <NavText>Portfolio Insights</NavText>
            </NavItem>
            <NavItem eventKey="benchmark_composition">
              <NavIcon><i className="fa fa-fw fa-balance-scale" style={{ fontSize: '1.75em' }} /></NavIcon>
              <NavText>Benchmark Composition</NavText>
            </NavItem>*/}
          </SideNav.Nav>
        </SideNav>
        <main className="react-content">
          <div id="content">
            <ErrorBoundary>
              <Route exact path="/" component={LoginScreen} />
              <Route exact path="/login_screen" component={LoginScreen} />
              <PrivateRoute exact path="/user_management" component={UserManagement} />
              <PrivateRoute exact path="/portfolio_selection" component={CreatePortfolioSelection} />
              <PrivateRoute exact path="/portfolio_selection/:id" component={CreatePortfolioSelection} />
              <PrivateRoute exact path="/simulation_setup" component={CreateScenario} />
              <PrivateRoute exact path="/start_job" component={CreateJob} />
              <PrivateRoute exact path="/performancemeasures" component={ResultsSimulation} />
              <PrivateRoute exact path="/performancemeasures/:id" component={ResultsSimulation} />
              <PrivateRoute exact path="/asset" component={ResultsAsset} />
              <PrivateRoute exact path="/asset/:id" component={ResultsAsset} />
              <PrivateRoute exact path="/projections" component={ResultsPortfolio} />
              <PrivateRoute exact path="/projections/:id" component={ResultsPortfolio} />
              <PrivateRoute exact path="/base_portfolio" component={BasePortfolio} />
              <PrivateRoute exact path="/base_portfolio/:id" component={BasePortfolio} />
              <PrivateRoute exact path="/link_portfolio" component={LinkPortfolio} />
              <PrivateRoute exact path="/link_portfolio/:id" component={LinkPortfolio} />
              <PrivateRoute exact path="/heatmap" component={Heatmap} />
              <PrivateRoute exact path="/factor_loadings_upload" component={FactorLoadingsUpload} />
              <PrivateRoute exact path="/dataset_upload" component={DatasetUpload} />
              <PrivateRoute exact path="/factorgroupupload" component={FactorGroupUpload} />
              <PrivateRoute exact path="/riskfreecurveupload" component={RiskFreeCurveUpload} />
              <PrivateRoute exact path="/counterpartyloadingsupload" component={CounterpartyLoadingsUpload} />
              <PrivateRoute exact path="/firmfundamentaluploads" component={FirmFundamentalUploads} />
              <PrivateRoute exact path="/redemptionprofileupload" component={RedemptionProfileUpload} />
              <PrivateRoute exact path="/portfolioselections" component={ViewPortfolioSelection} />
              <PrivateRoute exact path="/portfolioselections/:id" component={ViewPortfolioSelection} />
              <PrivateRoute exact path="/counterparty_insights" component={CounterpartyDetails} />
              <PrivateRoute exact path="/counterparty_insights/:ticker" component={CounterpartyDetails} />
              <PrivateRoute exact path="/portfolio_insights" component={PortfolioDetails} />
              <PrivateRoute exact path="/benchmark_composition" component={BenchmarkComposition} />
              <PrivateRoute exact path="/create_job" component={SimulationWizard} />
              <PrivateRoute exact path="/portfolioselection" component={PortfolioSelection} />
              <PrivateRoute exact path="/start_scrape_run" component={ScraperInitializeForm} />
              <PrivateRoute exact path="/portfolioconstruction" component={PortfolioConstruction} />
            </ErrorBoundary>
          </div>
        </main>
      </React.Fragment>
    )}
    />
    </Switch>
    </Router>
    </Provider>
    </React.StrictMode>
  );
}

class Login extends React.Component {
  constructor() {
    super();
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
  }

  // Pass input credentials to login action.
  handleSubmit(event) {
    const username = this.refs.username
    const password = this.refs.password
    const creds = { username: username.value.trim(), password: password.value.trim() }
    this.props.loginUser(creds)
    event.preventDefault();
  }

  // Call logout action
  handleLogout(event) {
    this.props.logoutUser()
    event.preventDefault();
  }

  render() {

    let { from } = this.props.location.state || { from: { pathname: "/" } };
    // Adjust authentication status available to PrivateRoute function

    if (this.props.authentication.isAuthenticated === true && (from.pathname === '/login_screen' || from.pathname === '/')) {
      checkAuthentication.authenticate()
    } else if ((!checkAuthentication.checkAuthentication() || !this.props.authentication.isAuthenticated) && sessionStorage.getItem('jwtToken')) {
      this.props.getUser(sessionStorage.getItem('jwtToken'))
    } else if (checkAuthentication.checkAuthentication() === false && this.props.authentication.isAuthenticated === true ) {
      this.props.logoutUser()
    }

    // Redirect to selected menu item/chosen url based on authentication status.
    // Avoid infinite loop when selecting / or /login_screen.
    if (this.props.authentication.isAuthenticated && checkAuthentication.checkAuthentication() && from.pathname !== "/login_screen" && from.pathname !== "/") {
      return <Redirect to={from.pathname} />;
    }

    const { errorMessage } = this.props

    const showError = this.props.authentication.errorMessage !== undefined
    const showLogIn = this.props.authentication.isAuthenticated === undefined || this.props.authentication.isAuthenticated !== true
    const showLogOut = !showLogIn

    return (
      <div>
        <Header title={"Ub Technologies"} />

        { showError && (
          <Card className="mb-4">
            <Card.Body>
              {this.props.authentication.errorMessage}
            </Card.Body>
          </Card>
        )}

        { showLogIn && (
          <Card>
            <Card.Header>Login details</Card.Header>
            <Card.Body>
              <Form noValidate onSubmit={this.handleSubmit}>
                <Form.Group as={Row} controlId="username">
                  <Form.Label column sm="3">
                    Username
                  </Form.Label>
                  <Col sm="9">
                    <Form.Control type="text" ref="username" placeholder="Username" id="authentication.username" />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="password">
                  <Form.Label column sm="3">
                    Password
                  </Form.Label>
                  <Col sm="9">
                    <Form.Control type="password" ref="password" placeholder="Password" id="authentication.password" />
                  </Col>
                </Form.Group>
                <Button variant="outline-primary" fill="false" type="submit">Log In</Button>
              </Form>
            </Card.Body>
          </Card>
        )}

        { showLogOut && (
          <div>
          <Card>
            <Card.Header>Demo introduction</Card.Header>
            <Card.Body>

            </Card.Body>
          </Card>
          <Card className="mt-4">
            <Card.Header>Login</Card.Header>
            <Card.Body>
              <form onSubmit={this.handleLogout}>
              <input type="submit" variant="outline-primary" fill="false" value="Logout" />
              </form>
            </Card.Body>
          </Card>
          </div>
        )}
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    authentication: state.authentication,
    redirectToReferrer: state.redirectToReferrer
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getUser: (jwt_token) => {
      dispatch(authentication.getUser(jwt_token))
    },
    loginUser: (creds) => {
      dispatch(authentication.loginUser(creds))
    },
    logoutUser: () => {
      dispatch(authentication.logoutUser())
    }
  }
}

const LoginScreen = connectWithStore(store, Login, mapStateToProps, mapDispatchToProps)
const ConnectedApp = connectWithStore(store, App, mapStateToProps, mapDispatchToProps)

export default ConnectedApp;

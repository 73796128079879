// Import React & Redux components
import React from "react";
import { Field, reduxForm } from 'redux-form';
import { Button } from 'react-bootstrap';

class FormSelectName extends React.Component {

  render() {

    return (
      <div>
        <form className="form-inline" onSubmit={this.props.handleSubmit}>
            <div className="form-group">
              <label htmlFor="text" style={{marginRight: '10px' }}>{this.props.inputName}</label>
              <Field name="inputName" type="text" className="form-control" component="input" style={{width: '400px'}}/>
            </div>
        </form>
      </div>
    )
  }
}

export default reduxForm({
  form: 'selectName', // a unique identifier for this form
})(FormSelectName);

const initialState = {data: {}, isLoading: true};

export default function performanceMeasures(state=initialState, action) {
  switch(action.type) {

    case 'CREATE_XLSX_PERFORMANCE_MEASURES':
      return {data: action.performancemeasures, isLoading: false};

    case 'FETCH_PERFORMANCE_MEASURES_SUCCESS':
      return {data: action.performancemeasures, isLoading: false};

    case 'FETCH_PERFORMANCE_MEASURES_FAILURE':
      return {data: [], error: true, isLoading: false};

    case 'FETCH_PERFORMANCE_MEASURES_ALL_SUCCESS':
      return {data: action.performancemeasures, isLoading: false};

    case 'FETCH_PERFORMANCE_MEASURES_ALL_FAILURE':
      return {data: action.performancemeasures, isLoading: false};

    default:
      return state;
  }
}

const initialState = {data: [], isLoading: true};

 export default function transactions(state=initialState, action) {
  //let transactionList = state.slice();
  switch(action.type) {

    case 'ADD_TRANSACTIONS_SUCCESS':
      return [...state, action.transactions];

    case 'ADD_TRANSACTIONS_FAILURE':
      return {data: action.error_type, isLoading: false};
/*
    case 'UPDATE_TRANSACTION':
      let transactionToUpdate = transactionList[action.index]
      transactionToUpdate.text = action.transaction.text;
      transactionList.splice(action.index, 1, transactionToUpdate);
      return transactionList;

    case 'DELETE_TRANSACTION':
      transactionList.splice(action.index, 1);
      return transactionList;
*/
    case 'FETCH_TRANSACTIONS_SUCCESS':
      return {data: action.transactions, isLoading: false};

    case 'FETCH_TRANSACTIONS_FAILURE':
      return {data: action.transactions, isLoading: false};

    default:
      return state;
  }
}

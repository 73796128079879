// Import React & Redux components
import Format from '../components/Format'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import DatePicker from 'react-datepicker'
import classnames from 'classnames'
import moment from 'moment'
import 'react-datepicker/dist/react-datepicker.css'

// DatePickerInput creates a component that shows a drop down calendar which
// allows for a data to be selected.

export class DatePickerInput extends Component {

  constructor(props) {
    super(props);
    this.onFieldChange = this.onFieldChange.bind(this);
  }

  onFieldChange = (date) => {
    this.props.onChangeDate(date)
  }

  render () {
    const { input, className, meta: { error, touched }, required, ...rest } = this.props
    return (
      <div className={classnames('form-group', 'calendar', { 'has-danger': error })}>
        <DatePicker
          autoOk
          todayButton={"Today"}
          className={classnames(className, { 'form-control-danger': error })}
          dateFormat='yyyy-MM-dd'
          onChange={this.onFieldChange}
          selected={this.props.selectedDate ? this.props.selectedDate : null} />
        {touched && error && <span className='error-block'>{error}</span>}
      </div>
    )
  }
}

DatePickerInput.propTypes = {
  className: PropTypes.string.isRequired,
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  required: PropTypes.bool
}

DatePickerInput.defaultProps = {
  className: 'form-control',
  required: false
}

export const createLoadingSelector = (actions) => (state) => {
  // returns true only when all actions is not loading
  return actions.some(action => state.loading[action]);
};

export const createErrorMessageSelector = (actions) => (state) => {
  // returns the first error messages for actions
  // We assume when any request fails on a page that
  // requires multiple API calls, we shows the first error
  const errors = actions.map(action => state.error[action]);
  if (errors && errors[0]) {
    return errors[0];
  }
};

// createAddMessage returns a success/error notification on submission of data
// to the database.
export const createAddMessage = (error, added, type) => {
  let addSelectionMessage
  if (error !== undefined && error !== '' ) {
    addSelectionMessage = '' + type + ' not created.'
  } else if ( added === true ) {
    addSelectionMessage = '' + type + ' created.'
  } else {
    addSelectionMessage = ''
  }
  return addSelectionMessage
}

// in a sorted array find the nearest value to an input value. If exactly the
// mean of the two values, the lower of the two is chosen.
export const roundToNearestValue = (inputvalue, valuesarray) => {
  let NearestValue = null
  for (let i=0;i<valuesarray.length-1; ++i){
    if((inputvalue <= valuesarray[i] && inputvalue >= valuesarray[i+1]) || (inputvalue >= valuesarray[i] && inputvalue <= valuesarray[i+1])){
      if(Math.abs(valuesarray[i]-inputvalue) < Math.abs(valuesarray[i+1]-inputvalue)) {
        NearestValue = valuesarray[i]
      } else if (Math.abs(valuesarray[i]-inputvalue) > Math.abs(valuesarray[i+1]-inputvalue)) {
        NearestValue = valuesarray[i+1]
      } else {
        if (Math.abs(valuesarray[i]) < Math.abs(valuesarray[i+1])) {
          NearestValue = valuesarray[i]
        } else {
          NearestValue = valuesarray[i+1]
        }
      }
    }
  }
  return NearestValue
}

// findAssociatedValue takes two arrays, one with integer IDs, arrayids, and finds an input
// ID, inputid in that array. It then returns a value at the same index from a
// second array, arrayvalues.
export const findAssociatedValue = (inputid, arrayids, arrayvalues) => {
  let ReturnAssociatedValue = null
  for (let i=0;i<arrayids.length; ++i){
    if (arrayids[i] === parseInt(inputid)) {
      ReturnAssociatedValue = arrayvalues[i]
    }
  }
  return ReturnAssociatedValue
}
// populateOptions takes an array of options and returns dropdown list. Once
// a value is selected it returns the INDEX of the selected value in the array.
// This allows for easier linking to other values associated with that
// selection, for example an ID in a database table.
export const populateOptions = (options) => {
  return options.map((option, index) => (
    <option key={index} value={index}>{option}</option>
  ));
}

export const generateCSVFile = (axislabels, dataserieslabels, dataseries) => {
  console.log(axislabels)
  console.log(dataserieslabels)
  console.log(dataseries)
  let csvContent = "data:text/csv;charset=utf-8," + 'Year' + "," + dataserieslabels.join(",") + "\n"
  axislabels.forEach((label, index) => {
    csvContent = csvContent + label + "," + dataseries[index] + "\n"
  })
  var encodedUri = encodeURI(csvContent);
  window.open(encodedUri);
  return false;
}

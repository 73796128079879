import React from 'react'
import { connect } from 'react-redux';
import classNames from 'classnames'
import { Form, Button} from 'react-bootstrap';
import Dropzone from 'react-dropzone'
import FormSelectName from "./FormSelectName";
import { datasetActions } from "../actions";
import Header from "../components/Header";
import ErrorBoundary from "../components/ErrorBoundary";
import Notification from "../components/Notification"
import { createLoadingSelector, createErrorMessageSelector, createAddMessage } from '../components/Helpers'
import FormSelectOneDropDown from './FormSelectOneDropDown'
import SimpleTable from './SimpleTable'
import Papa from 'papaparse'

const baseStyle = {
  width: 200,
  height: 200,
  borderWidth: 2,
  borderColor: '#666',
  borderStyle: 'dashed',
  borderRadius: 5,
  paddingTop: 10,
  paddingLeft: 10
};

const activeStyle = {
  borderStyle: 'solid',
  borderColor: '#6c6',
  backgroundColor: '#eee'
};

const rejectStyle = {
  borderStyle: 'solid',
  borderColor: '#c66',
  backgroundColor: '#eee'
};

class DatasetUpload extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      files: [],
      table_data: [],
      datasetName: undefined,
      reportingDate: new Date(),
      columns: [
        {accessor: "Reporting_Date", Header: "Reporting_Date"},
        {accessor: "UCI_Legal_Name", Header: "UCI_Legal_Name"},
        {accessor: "Global_Facility_ID", Header: "Global_Facility_ID"},
        {accessor: "Department", Header: "Department"},
        {accessor: "Branch", Header: "Branch"},
        {accessor: "NAICS", Header: "NAICS"},
        {accessor: "Rating", Header: "Rating"}],
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  createData = (data, headers) => {
    let structured_data = []
    data.pop()
    data.forEach((value, index) => {
      structured_data.push(value)
    })
    return structured_data
  }

  saveSettings = (value) => {
    if(value.selectedItemIndex !== undefined){
      this.setState({
        table_data: [],
      })
    }
  }

  handleSubmit = (event) => {
    event.preventDefault();

    let data = {}
    data.datasetName = this.refs.datasetName.value
    data.reportingDate = this.state.reportingDate

    let transaction_data = {}
    transaction_data.transactions = this.state.table_data
    data['transactions'] = transaction_data.transactions
    this.props.addDataset(data, sessionStorage.getItem('jwtToken'))
  }

  onDrop = (files) => {
    let react_var = this;
    this.setState({
      files: files.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      }))
    });
    var results = Papa.parse(files[0], {
      header: true,
	    dynamicTyping: true,
      complete: function(results) {
          let structuredData = react_var.createData(results.data, results.meta.fields)
          react_var.setState({
            table_data: structuredData,
            //columns: results.meta.fields
          })
	    }
    });
  }

  componentWillUnmount() {
    // Make sure to revoke the data uris to avoid memory leaks
    this.state.files.forEach(file => URL.revokeObjectURL(file.preview))

  }

  render() {
    const showError = (Object.entries(this.props.error).length === 0 && this.props.error.constructor === Object) ? false : true
    const showDropzone = this.state.files.length === 0
    const showDatasetNameForm = this.state.datasetName !== undefined
    const showTable = this.state.table_data.length !== 0
    const {files} = this.state;

    let yLabels = new Array(2).fill(0).map((_, i) => `${i + 1}`);
    let xLabels = new Array(2).fill(0).map((_, i) => `${i + 1}`);
    // Data is presented in a columnar fashion! no [row][column] but [column][row]
    let data = new Array(yLabels.length)
      .fill(0)
      .map(() => new Array(xLabels.length).fill(0));

    if (this.state.table_data.length > 0){
      data = this.state.table_data;
    };

    return (
      <div>
      { showError &&
        (<div>{`Error: ${this.props.error.ADD_DATASETS}`}</div>)
      }
      { showDropzone &&
        (<Dropzone accept="text/csv, application/vnd.ms-excel" onDrop={this.onDrop.bind(this)}>
          {({ getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject, acceptedFiles, rejectedFiles }) => {
            let styles = {...baseStyle}
            styles = isDragActive ? {...styles, ...activeStyle} : styles
            styles = isDragReject ? {...styles, ...rejectStyle} : styles

            return (
              <div {...getRootProps()} style={styles} >
                <input {...getInputProps()} />
                <div>
                  {isDragAccept ? 'Drop' : 'Drag'} files here...
                </div>
                {isDragReject && <div>Please provide CSV files</div>}
              </div>
            )
          }}
        </Dropzone>)
      }

      <Form noValidate onSubmit={this.handleSubmit}>
        <Form.Group controlId="datasetUpload.Upload">
          <Form.Control type="text" ref="datasetName" placeholder="Dataset Name" />
          <Button variant="outline-primary" type="submit">Upload Dataset</Button>
        </Form.Group>
      </Form>
      <div>
        <ErrorBoundary>
          { showTable && (
              <SimpleTable
                data={this.state.table_data}
                columns={this.state.columns}
              />
            )
          }
        </ErrorBoundary>
      </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    authentication: state.authentication,
    table_data: state.table_data,
    loading: state.loading,
    error: state.error
  }
}

const mapDispatchToProps = dispatch => {
  return {
    addDataset: (data, token) => {
      dispatch(datasetActions.addDataset(data, token));
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DatasetUpload);

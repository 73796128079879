function handleErrors(response) {
    if (!response.ok) {
        throw Error(response.statusText);
    }
    return response;
}

export const addConfiguration = (text, token) => {
  return dispatch => {
    const headers = {"Content-Type": "application/json", "Authorization": "JWT " + token};
    let body = JSON.stringify(text);
    return fetch(process.env.REACT_APP_HTTP_PROTOCOL + "://" + process.env.REACT_APP_API_IP + "/api/configuration/configurations/", {headers, method: "POST", body})
      .then(handleErrors)
      .then(res => res.json())
      .then(configuration => {
        return dispatch({
          type: 'ADD_CONFIGURATION',
          configuration
        })
      })
  }
}

export const updateConfiguration = (index, text, token) => {
  return (dispatch, getState) => {

    const headers = {"Content-Type": "application/json", "Authorization": "JWT " + token};
    let body = JSON.stringify(text);
    let configurationId = getState().Configurations[index].id;

    return fetch(process.env.REACT_APP_HTTP_PROTOCOL + '://' + process.env.REACT_APP_API_IP + '/api/configuration/configurations/${configurationId}/', {headers, method: "PUT", body})
      .then(handleErrors)
      .then(res => res.json())
      .then(configuration => {
        return dispatch({
          type: 'UPDATE_CONFIGURATION',
          configuration,
          index
        })
      })
  }
}

export const fetchConfigurations = (token) => {
  return dispatch => {
    dispatch({ type: 'FETCH_CONFIGURATIONS_REQUEST' });
    const headers = {"Content-Type": "application/json", "Authorization": "JWT " + token};
    return fetch(process.env.REACT_APP_HTTP_PROTOCOL + "://" + process.env.REACT_APP_API_IP + "/api/configuration/configurations/", {headers, })
      .then(handleErrors)
      .then(res => res.json())
      .then(configurations => {
        return dispatch({
          type: 'FETCH_CONFIGURATIONS_SUCCESS',
          configurations
        })
      })
      .catch(error => {
        return dispatch({ type: 'FETCH_CONFIGURATIONS_FAILURE', error_type: error, error: true })
      })
  }
}

import React from 'react'
import { connect } from 'react-redux';
import classNames from 'classnames'
import Dropzone from 'react-dropzone'
import Papa from 'papaparse'
import HeatMap from "react-heatmap-grid"
import Header from "../components/Header";
import Notification from "../components/Notification"
import FormSelectName from "./FormSelectName";
import FormSelectOneDropDown from './FormSelectOneDropDown'
import { Button, Card, Form } from 'react-bootstrap'
import { inputs, jobs } from "../actions";
import { createLoadingSelector, createErrorMessageSelector, createAddMessage } from '../components/Helpers'

const baseStyle = {
  width: 200,
  height: 200,
  borderWidth: 2,
  borderColor: '#666',
  borderStyle: 'dashed',
  borderRadius: 5,
  paddingTop: 10,
  paddingLeft: 10
};

const activeStyle = {
  borderStyle: 'solid',
  borderColor: '#6c6',
  backgroundColor: '#eee'
};

const rejectStyle = {
  borderStyle: 'solid',
  borderColor: '#c66',
  backgroundColor: '#eee'
};

class RedemptionProfileUpload extends React.Component {
  constructor() {
    super()
    this.state = {
      files: [],
      redemption_profiles: [],
      configurationName: null,
      selectedJobID: undefined,
      selectedJobName: undefined,
    };
    this.saveConfiguration = this.saveConfiguration.bind(this);
    this.constructDataset = this.constructDataset.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit = (event) => {
    event.preventDefault();
    let data = {}
    data.jobID = this.state.selectedJobID
    data.redemptionProfilesName = this.refs.redemptionProfilesName.value
    data.redemptionProfilesValues = this.state.redemption_profiles
    this.props.addRedemptionProfile(data, sessionStorage.getItem('jwtToken'))
  }

  saveConfiguration = (values) => {
    values.configurationName = this.state.configurationName
  }

  constructDataset = (redemption_profiles) => {
    let previous_transaction = undefined;
    let constructed_dataset = [];
    redemption_profiles.forEach((elem, index) => {
      if(elem.contract_code === previous_transaction) {
        constructed_dataset[constructed_dataset.length - 1].push(elem.Value)
      } else {
        constructed_dataset.push([elem.Value])
      }
      previous_transaction = elem.contract_code;
    });
    return constructed_dataset
  }

  onDrop = (files) => {
    const reader = new FileReader()
    let react_var = this;
    reader.onload = function () {
      Papa.parse(reader.result, {
        complete: function(results) {
          let counterparty_identifiers = results.meta.fields
          let redemption_profiles = results.data.map(x => {
            var new_dict = {}
            Object.entries(x).forEach(
              ([key, value]) => (
                (key !== 'Value') ? (new_dict[key] = value) : (new_dict[key] = parseFloat(value))
              )
            )
            return new_dict
          });
          if (Object.keys(redemption_profiles[redemption_profiles.length - 1]).length == 1){
            redemption_profiles.pop()
          }
          redemption_profiles.map((elem, index) => {
            let date_array = elem.Date.split("-")
            let dateObj = new Date(date_array[2], date_array[1] - 1, date_array[0])
            return elem.Date = dateObj.toISOString()
          })

          if(Object.keys(redemption_profiles[redemption_profiles.length - 1]).length < 2){
            redemption_profiles.pop()
          }

          react_var.setState((state, props) => ({
            redemption_profiles: redemption_profiles,
          }));
  	    },
        header: true,
      });
    }
    reader.readAsText(files[0])
  }

  saveSettings = (value) => {
    if(value.selectedItemIndex !== undefined){
      this.setState({
        selectedJobID : this.props.jobs.data[value.selectedItemIndex].jobID,
        selectedJobName : this.props.jobs.data[value.selectedItemIndex].jobName
      })
    }
  }

  componentDidMount() {
    this.props.fetchJobs(sessionStorage.getItem('jwtToken'))
  }

  componentWillUnmount() {
    // Make sure to revoke the data uris to avoid memory leaks
    this.state.files.forEach(file => URL.revokeObjectURL(file.preview))
  }

  render() {
    const showLoadingScreen = this.props.jobs.isLoading === true
    const showSelectionMenu = this.props.jobs.isLoading !== true
    const showDropzone = this.state.selectedJobID !== undefined

    const {files} = this.state;

    let xLabels = [];
    let yLabels = [];
    let data = []

    if (this.state.redemption_profiles.length > 0){
      let constructed_dataset = this.constructDataset(this.state.redemption_profiles)
      let transposted_redemption_profiles = constructed_dataset[0].map((col, i) => constructed_dataset.map(row => row[i]));
      data = transposted_redemption_profiles;
      xLabels = new Array(data[0].length).fill(0).map((_, i) => `${i + 1}`);
      yLabels = new Array(data.length).fill(0).map((_, i) => `${i + 1}`);
    };

    return (
      <div>
        <Header title={"Portfolio Analysis"} />
        { showLoadingScreen && (
            <div>
              <Notification heading={'Select a Job'} body={'Loading Jobs'}/>
            </div>
          )
        }

        { showSelectionMenu && (
            <div>
              <Card className="mt-4">
                <Card.Header>Upload Redemption Profiles</Card.Header>
                <Card.Body>
                  <FormSelectOneDropDown
                    onChange={this.saveSettings}
                    itemLabel={'Job Name'}
                    itemName={this.props.jobs.data.map(k => k.jobName)}
                    currentlySelectedItemName={this.state.selectedJobName}
                  />
                </Card.Body>
              </Card>
            </div>
          )
        }

        { showDropzone && (
            <div>
              <Card className="mt-4">
                <Card.Header>Upload Redemption Profiles</Card.Header>
                <Card.Body>
                  <Dropzone accept="text/csv, application/vnd.ms-excel" onDrop={this.onDrop.bind(this)}>
                    {({ getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject, acceptedFiles, rejectedFiles }) => {
                      let styles = {...baseStyle}
                      styles = isDragActive ? {...styles, ...activeStyle} : styles
                      styles = isDragReject ? {...styles, ...rejectStyle} : styles
                      return (
                        <div {...getRootProps()} style={styles} >
                          <input {...getInputProps()} />
                          <div>
                            {isDragAccept ? 'Drop' : 'Drag'} files here...
                          </div>
                          {isDragReject && <div>Please provide CSV files</div>}
                        </div>
                      )
                    }}
                  </Dropzone>

                  <Form noValidate onSubmit={this.handleSubmit}>
                    <Form.Group controlId="redemptionProfiles">
                      <Form.Control type="text" ref="redemptionProfilesName" placeholder="Redemption Profiles Group Name" />
                      <Button fill="true" type="submit">Upload Redemption Profiles</Button>
                    </Form.Group>
                  </Form>
                </Card.Body>
              </Card>
            </div>
          )
        }

        { this.state.redemption_profiles.length > 0 ? (
            <div>
            <Card className="mt-4">
              <Card.Header>Redemption Profiles Preview</Card.Header>
              <Card.Body>
                <HeatMap
                  xLabels={xLabels}
                  yLabels={yLabels}
                  data={data}
                  squares
                  onClick={(x, y) => alert(`Clicked ${data[y][x]*100}%`)}
                  cellStyle={(background, value, min, max, data, x, y) => ({
                    background: `rgb(66, 86, 244, ${1 - (max - value) / (max - min)})`,
                    fontSize: "11px",
                  })}
                  cellRender={value => value && `${Math.round(value,2)}`}
                />
              </Card.Body>
            </Card>
            </div>
          ) : ( <br /> )
        }
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    authentication: state.authentication,
    jobs: state.jobs,
    loading: state.loading,
    error: state.error,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    addRedemptionProfile: (data, token) => {
      return dispatch(inputs.addRedemptionProfile(data, token));
    },
    fetchJobs: (token) => {
      dispatch(jobs.fetchJobs(token));
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RedemptionProfileUpload);

// Import React & Redux components
import React from 'react';
import { connect } from 'react-redux';
import { Card, Form } from 'react-bootstrap';

// Import dumb components
import ReactTable from 'react-table';

// Import layout and helper functions/templates
import Header from './Header';
import Notification from './Notification';
import Chart from './Chart2';
import { createLoadingSelector, createErrorMessageSelector } from './Helpers';

// Import data sources
import { counterparties } from '../actions';

class CounterpartyDetails extends React.Component {
  constructor(props) {
    super(props);
    const ticker = this.props.match.params.ticker === undefined
      ? undefined
      : this.props.match.params.ticker;
    let targetYear = new Date();
    targetYear.setFullYear(targetYear.getFullYear() - 1);
    targetYear = targetYear.getFullYear();
    this.state = {
      counterparties: [],
      counterpartyTicker: ticker,
      counterpartyName: null,
      counterpartyIndustry: null,
      counterpartySector: null,
      fundamentalMetrics: null,
      comparisonMetric: 'profitMargin',
      comparisonMetrics: ['Leverage ratio', 'Solvency ratio'],
      evaluationYear: targetYear,
      sharepriceConstituents: ['counterparty', 'benchmark'],
      isFetchingIndustry: false,
      isFetchingSector: false,
    };
    this.evaluationYear = React.createRef();
    this.saveEvaluationYear = this.saveEvaluationYear.bind(this);
    this.saveComparisonMetric = this.saveComparisonMetric.bind(this);
    this.setSharepriceConstituents = this.setSharepriceConstituents.bind(this);
  }

  componentDidMount() {
    this.props.fetchCounterparties(sessionStorage.getItem('jwtToken'));
    if (this.state.counterpartyTicker !== undefined) {
      this.props.fetchCounterpartyData(this.state.counterpartyTicker, sessionStorage.getItem('jwtToken'));
    }
  }

  componentDidUpdate() {
    if (this.state.counterpartyTicker === undefined
      && this.props.counterparties.isLoading !== true
      && this.props.counterparties.data !== undefined
      && this.props.counterparties.data.length > 0) {
      this.props.fetchCounterpartyData(this.props.counterparties.data[0].ticker, sessionStorage.getItem('jwtToken'));
      this.setState({
        counterpartyTicker: this.props.counterparties.data[0].ticker,
        counterpartyName: this.props.counterparties.data[0].name,
      });
    }

    if (this.state.counterpartyTicker !== undefined
      && this.props.counterparties.isLoading !== true
      && this.props.counterparties.data !== undefined
      && this.props.counterparties.data.length > 0
      && this.state.isFetchingIndustry !== true) {
      const elem = this.props.counterparties.data.filter((counterparty) => (
        counterparty.ticker === this.state.counterpartyTicker
      ))[0];
      if (elem.industry !== null) {
        this.props.fetchIndustryData(elem.industry, sessionStorage.getItem('jwtToken'));
      }
      this.setState({
        isFetchingIndustry: true,
      });
    }

    if (this.state.counterpartyTicker !== undefined
      && this.props.counterparties.isLoading !== true
      && this.props.counterparties.data !== undefined
      && this.props.counterparties.data.length > 0
      && this.state.isFetchingSector !== true) {
      const elem = this.props.counterparties.data.filter((counterparty) => (
        counterparty.ticker === this.state.counterpartyTicker
      ))[0];
      if (elem.sector !== null) {
        this.props.fetchSectorData(elem.sector, sessionStorage.getItem('jwtToken'));
      }
      this.setState({
        isFetchingSector: true,
      });
    }

    if (this.state.evaluationYear !== undefined
      && (this.props.counterpartydata.data !== undefined
        && this.props.counterpartydata.data.ratios !== undefined)) {
      if (this.props.counterpartydata.data.ratios.leverage.map((k) => (
        k.year)).indexOf(this.state.evaluationYear) === -1) {
        const { leverage } = this.props.counterpartydata.data.ratios;
        this.setState({
          evaluationYear: parseInt(leverage[leverage.length - 1].year, 10),
        });
      }
    }
  }

  saveSettings = () => {
    const counterpartyElem = this.props.counterparties.data.filter((elem) => (
      elem.ticker === this.refs.counterparty.value
    ))[0];
    this.setState({
      counterpartyTicker: this.refs.counterparty.value,
      counterpartyName: counterpartyElem.name,
      counterpartyIndustry: counterpartyElem.industry,
      counterpartySector: counterpartyElem.sector,
    });
    this.props.fetchCounterpartyData(this.refs.counterparty.value, sessionStorage.getItem('jwtToken'));
    /* Can these be deleted, they are already in componentDidUpdate? */
    if (counterpartyElem.industry !== null) {
      this.props.fetchIndustryData(counterpartyElem.industry, sessionStorage.getItem('jwtToken'));
    }
    if (counterpartyElem.sector !== null) {
      this.props.fetchSectorData(counterpartyElem.sector, sessionStorage.getItem('jwtToken'));
    }
    /* End Comment */
  }

  saveEvaluationYear = () => {
    this.setState({
      evaluationYear: parseInt(this.refs.evaluationYear.value, 10),
    });
  }

  saveComparisonMetric = () => {
    this.setState({
      comparisonMetric: this.refs.comparisonMetric.value,
    });
  }


  setSharepriceConstituents = () => {
    this.setState({
      sharepriceConstituents: ['counterparty'],
    });
  }

  render() {
    if (this.props.counterparties.isLoading === true) {
      return (
        <div>
          <Header title={'Counterparty Insights'} />
          <Notification heading={'Settings'} body={'Loading Data'}/>
        </div>
      );
    }

    if (this.props.counterparties.error === true) {
      return (
        <div>
          <Header title={'Counterparty Insights'} />
          <Notification heading={'Error occurred'} body={'An unanticipated error occurred while communicating to the data delivery'}/>
        </div>
      );
    }

    if (this.props.industrydata.error === true
      || this.props.sectordata.error === true
      || this.props.counterpartydata.error === true) {
      return (
        <div>
          <Header title={'Counterparty Insights'} />
          <Notification heading={'Error occurred'} body={`An unanticipated error occurred while retrieving statistics for ${this.state.counterpartyName}`}/>
        </div>
      );
    }

    const renderFirmSelection = this.props.counterparties.isLoading !== true
      && this.props.counterparties.data !== undefined;
    const renderDataTable = this.props.counterpartydata.data.counterparty !== undefined;
    const renderComparisonYear = this.props.counterpartydata.isLoading !== true;
    const renderSectorComparison = this.props.sectordata.isLoading !== true
      && this.props.counterpartydata.isLoading !== true;
    const renderIndustryComparison = this.props.industrydata.isLoading !== true
      && this.props.counterpartydata.isLoading !== true;
    const renderComparisonMetric = this.props.counterpartydata.isLoading !== true
      && this.props.industrydata.isLoading !== true
      && this.props.sectordata.isLoading !== true;
    const renderSectorTrends = this.props.sectordata.isLoading !== true
      && this.props.counterpartydata.isLoading !== true;
    const renderIndustryTrends = this.props.industrydata.isLoading !== true
      && this.props.counterpartydata.isLoading !== true;
    const renderISSInfo = this.props.counterpartydata.isLoading !== true;
    const renderVolatilityInfo = this.props.counterpartydata.isLoading !== true;
    const renderDTDInfo = this.props.counterpartydata.isLoading !== true;
    const renderSharepriceChart = this.props.counterpartydata.data.shareprice !== undefined
      && this.props.counterpartydata.data.benchmark !== undefined
      && this.props.counterpartydata.data.index !== undefined;
    const renderOptionInfoChart = this.props.counterpartydata.data.options !== undefined;
    let keyMetricsCounterparty = [];
    let keyMetricsIndustry = [];
    let keyMetricsSector = [];

    if (this.props.counterpartydata.data.ratios !== undefined) {
      const { ratios } = this.props.counterpartydata.data;
      const { evaluationYear } = this.state;

      const ratiosToInclude = [
        { accessor: 'leverage', header: 'Leverage' },
        { accessor: 'debtCoverageRatio', header: 'Debt Coverage' },
        { accessor: 'solvency', header: 'Solvency' },
        { accessor: 'profitMargin', header: 'Profit Margin' },
      ];

      keyMetricsCounterparty = ratiosToInclude.map((ratio) => {
        const counterpartyElement = ratios[ratio.accessor].filter((elem) => (
          elem.year === parseInt(evaluationYear, 10)
        ))[0];
        if (counterpartyElement !== undefined) {
          return { ratio: ratio.header, value: counterpartyElement.value };
        }
        return { ratio: ratio.header, value: 0.0 };
      });
    }

    if (this.props.industrydata.data.ratios !== undefined) {
      const { ratios } = this.props.industrydata.data;
      const { evaluationYear } = this.state;

      const ratiosToInclude = [
        { accessor: 'leverage', header: 'Leverage' },
        { accessor: 'debtCoverageRatio', header: 'Debt Coverage' },
        { accessor: 'solvency', header: 'Solvency' },
        { accessor: 'profitMargin', header: 'Profit Margin' },
      ];

      keyMetricsIndustry = ratiosToInclude.map((ratio) => {
        const industryElement = ratios[ratio.accessor].filter((elem) => (
          elem.year === parseInt(evaluationYear, 10)
        ))[0];
        if (industryElement !== undefined) {
          return { ratio: ratio.header, value: industryElement.value };
        }
        return { ratio: ratio.header, value: 0.0 };
      });
    }

    if (this.props.sectordata.data.ratios !== undefined) {
      const { ratios } = this.props.sectordata.data;
      const { evaluationYear } = this.state;
      const ratiosToInclude = [
        { accessor: 'leverage', header: 'Leverage' },
        { accessor: 'debtCoverageRatio', header: 'Debt Coverage' },
        { accessor: 'solvency', header: 'Solvency' },
        { accessor: 'profitMargin', header: 'Profit Margin' },
      ];

      keyMetricsSector = ratiosToInclude.map((ratio) => {
        const sectorElement = ratios[ratio.accessor].filter((elem) => (
          elem.year === parseInt(evaluationYear, 10)
        ))[0];
        if (sectorElement !== undefined) {
          return { ratio: ratio.header, value: sectorElement.value };
        }
        return { ratio: ratio.header, value: 0.0 };
      });
    }

    const sectorComparisonChart = () => {
      const counterpartyLabel = this.props.counterpartydata.data.counterparty.name;
      const sectorLabel = this.props.counterpartydata.data.counterparty.sector;
      return (
        <Chart type={'Bar'}
          unit={'decimal'}
          xaxislabels={keyMetricsCounterparty.map((elem) => elem.ratio)}
          dataseries={[
            keyMetricsCounterparty.map((elem) => elem.value),
            this.state.counterpartySector !== null
              ? keyMetricsSector.map((elem) => elem.value)
              : undefined,
          ].filter((elem) => elem !== undefined)}
          dataserieslabels={[counterpartyLabel, sectorLabel]}
        />
      );
    };

    const industryComparisonChart = () => {
      const counterpartyLabel = this.props.counterpartydata.data.counterparty.name;
      const industryLabel = this.props.counterpartydata.data.counterparty.industry;
      return (
        <Chart type={'Bar'}
          unit={'decimal'}
          xaxislabels={keyMetricsCounterparty.map((elem) => elem.ratio)}
          dataseries={[
            keyMetricsCounterparty.map((elem) => elem.value),
            this.state.counterpartyIndustry !== null
              ? keyMetricsIndustry.map((elem) => elem.value)
              : undefined,
          ].filter((elem) => elem !== undefined)}
          dataserieslabels={[counterpartyLabel, industryLabel]}
        />
      );
    };

    const sectorTrendChart = () => {
      const { counterpartydata, sectordata } = this.props;
      const { comparisonMetric, counterpartySector } = this.state;
      const sectorTimeStampsRaw = counterpartydata.data.ratios.leverage.map((elem) => (
        new Date(elem.year, 1, 1)
      ));
      const sectorTimeStamps = sectorTimeStampsRaw.slice(Math.max(
        -4,
        -sectorTimeStampsRaw.length,
      ));
      const ratioCounterparty = `${counterpartydata.data.counterparty.name} ${comparisonMetric}`;
      const ratioSector = `${counterpartydata.data.counterparty.sector} ${comparisonMetric}`;
      const counterpartyData = counterpartydata.data.ratios[comparisonMetric].slice(-4)
        .map((elem) => elem.value);
      const sectorData = sectordata.data.ratios !== undefined && counterpartySector !== null
        ? sectordata.data.ratios[comparisonMetric].slice(-4).map((elem) => elem.value)
        : undefined;

      return (
        <Chart type={'Bar'}
          unit={'decimal'}
          xaxislabels={sectorTimeStamps.slice(-4)}
          dataseries={[
            counterpartyData,
            sectorData,
          ].filter((elem) => elem !== undefined)}
          dataserieslabels={[ratioCounterparty, ratioSector]}
        />
      );
    };

    const industryTrendChart = () => {
      const { counterpartydata, industrydata } = this.props;
      const { comparisonMetric } = this.state;
      const industryTimeStampsRaw = counterpartydata.data.ratios.leverage.map((elem) => (
        new Date(elem.year, 1, 1)
      ));
      const industryTimeStamps = industryTimeStampsRaw.slice(Math.max(
        -4,
        -industryTimeStampsRaw.length,
      ));
      const ratioCounterparty = `${counterpartydata.data.counterparty.name} ${comparisonMetric}`;
      const ratioIndustry = `${counterpartydata.data.counterparty.industry} ${comparisonMetric}`;
      const counterpartyData = counterpartydata.data.ratios[comparisonMetric].slice(-4)
        .map((elem) => elem.value);
      const industryData = industrydata.data.ratios !== undefined
        && this.state.counterpartyIndustry !== null
        ? industrydata.data.ratios[comparisonMetric].slice(-4).map((elem) => elem.value)
        : undefined;
      return (
        <Chart type={'Bar'}
          unit={'decimal'}
          xaxislabels={industryTimeStamps.slice(-4)}
          dataseries={[
            counterpartyData,
            industryData,
          ].filter((elem) => elem !== undefined)}
          dataserieslabels={[ratioCounterparty, ratioIndustry]}
        />
      );
    };

    const sharepriceChart = () => {
      const options = { year: 'numeric', month: '2-digit' };
      const timestamps = this.props.counterpartydata.data.shareprice.map((elem) => (
        new Date(elem.date).toLocaleDateString('en-US', options)
      ));
      const dataseries = [];
      const labels = [];

      if (this.state.sharepriceConstituents.includes('counterparty')) {
        const { data } = this.props.counterpartydata;
        // Generate returns from the sharepriceSeries
        const returnSeries = data.shareprice.map((elem, index) => {
          if (index === 0) {
            return { date: elem.date, value: 1 };
          }
          const returnAtTimepoint = data.shareprice[index].adjclose
            / data.shareprice[index - 1].adjclose;
          return { date: elem.date, value: returnAtTimepoint };
        });

        const counterpartySeries = [];
        returnSeries.forEach((timepoint, index) => {
          if (index === 0) {
            counterpartySeries.push(1);
          } else {
            counterpartySeries.push(counterpartySeries[index - 1] * timepoint.value);
          }
        });
        dataseries.push(counterpartySeries);
        labels.push(this.props.counterpartydata.data.counterparty.name);
      }

      if (this.state.sharepriceConstituents.includes('benchmark')) {
        const { data } = this.props.counterpartydata;

        const benchmarkSeries = [];
        data.benchmark.forEach((timepoint, index) => {
          if (index === 0) {
            benchmarkSeries.push(1);
          } else {
            benchmarkSeries.push(benchmarkSeries[index - 1] * (1 + timepoint.value));
          }
        });

        dataseries.push(benchmarkSeries);
        labels.push(data.counterparty.industry);

        const indexSeries = [];
        data.index.forEach((timepoint, index) => {
          if (index === 0) {
            indexSeries.push(1);
          } else {
            indexSeries.push(indexSeries[index - 1] * (1 + timepoint.value));
          }
        });

        dataseries.push(indexSeries);
        labels.push('S&P 500');
      }

      return (
        <Chart type={'Line'}
          unit={'decimal'}
          xaxislabels={timestamps}
          dataseries={dataseries}
          dataserieslabels={labels}
        />
      );
    };

    const ISSInfoChart = () => {
      const { counterparty } = this.props.counterpartydata.data;
      const auditRisk = counterparty.auditRisk !== undefined
        ? counterparty.auditRisk
        : 'N/A';
      const boardRisk = counterparty.boardRisk !== undefined
        ? counterparty.boardRisk
        : 'N/A';
      const compensationRisk = counterparty.compensationRisk !== undefined
        ? counterparty.compensationRisk
        : 'N/A';
      const shareholderRightsRisk = counterparty.shareHolderRightsRisk !== undefined
        ? counterparty.shareHolderRightsRisk
        : 'N/A';
      const overallRisk = counterparty.overallRisk !== undefined
        ? counterparty.overallRisk
        : 'N/A';
      return (
        <div>
          <Chart type={'Radar'}
            unit={'decimal'}
            dataseries={[[
              auditRisk,
              boardRisk,
              compensationRisk,
              shareholderRightsRisk,
              overallRisk,
            ]]}
            dataserieslabels={[
              ['Audit Risk'],
              ['Board Risk'],
              ['Compensation Risk'],
              ['Shareholder Rights Risk'],
              ['Overall Risk'],
            ]}
            xaxislabels={[
              'Audit Risk',
              'Board Risk',
              'Compensation Risk',
              'Shareholder Rights Risk',
              'Overall Risk',
            ]}
          />
        </div>
      );
    };

    const VolatilityChart = () => {
      const options = { year: 'numeric', month: '2-digit' };
      const equityVolatility = this.props.counterpartydata.data.equity_volatility;
      return (
        <div>
          <Chart type={'Line'}
            unit={'decimal'}
            dataseries={[equityVolatility]}
            dataserieslabels={[['Equity Volatility']]}
            xaxislabels={this.props.counterpartydata.data.shareprice.map((elem) => (
              new Date(elem.date).toLocaleDateString('en-US', options)
            ))}
          />
        </div>
      );
    };

    const DTDChart = () => {
      const options = { year: 'numeric', month: '2-digit' };
      const distanceToDefault = this.props.counterpartydata.data.distanceToDefault.map((elem) => (
        elem.value
      ));
      return (
        <div>
          <Chart type={'Line'}
            unit={'decimal'}
            dataseries={[distanceToDefault]}
            dataserieslabels={[['Distance to Default']]}
            xaxislabels={this.props.counterpartydata.data.shareprice.map((elem) => (
              new Date(elem.date).toLocaleDateString('en-US', options)
            ))}
          />
        </div>
      );
    };

    const tableData = [];
    if (this.props.counterpartydata.data.ratios !== undefined) {
      Object.entries(this.props.counterpartydata.data.ratios).forEach(([key, value]) => {
        const entry = {};
        value.forEach((ratio) => {
          if (ratio.value !== null) {
            entry[ratio.year] = ratio.value.toFixed(2);
          } else {
            entry[ratio.year] = ratio.value;
          }
        });
        entry.Metric = key;
        tableData.push(entry);
      });
    }

    // change the value of the options!!
    return (
      <div>
      <Header title={'Counterparty Insights'} />
      { renderFirmSelection && (
        <div>
          <Card className="mt-4">
            <Card.Header>Select a counterparty</Card.Header>
            <Card.Body>
            <Form>
              <Form.Group controlId="counterpartyInsights.counterparty">
                <Form.Label>Counterparty</Form.Label>
                <Form.Control as="select" ref="counterparty"
                  onChange={this.saveSettings}
                  value={this.state.counterpartyTicker}>
                  { this.props.counterparties.data.map((k, index) => (
                    <option key={index} value={k.ticker}>{k.name}</option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Form>
            </Card.Body>
          </Card>
        </div>
      )}

      { renderDataTable && (
        <div>
          <Card className="mt-4">
            <Card.Header>
              Company Characteristics of {this.props.counterpartydata.data.counterparty.name}
            </Card.Header>
            <Card.Body>
            <ReactTable
              data={tableData}
              columns={[
                {
                  accessor: 'Metric',
                  Header: 'Metric',
                },
                {
                  accessor: '2015',
                  Header: '2015',
                  Cell: (row) => (
                    <div style={{ textAlign: 'right' }}>{row.value}</div>
                  ),
                },
                {
                  accessor: '2016',
                  Header: '2016',
                  Cell: (row) => (
                    <div style={{ textAlign: 'right' }}>{row.value}</div>
                  ),
                },
                {
                  accessor: '2017',
                  Header: '2017',
                  Cell: (row) => (
                    <div style={{ textAlign: 'right' }}>{row.value}</div>
                  ),
                },
                {
                  accessor: '2018',
                  Header: '2018',
                  Cell: (row) => (
                    <div style={{ textAlign: 'right' }}>{row.value}</div>
                  ),
                },
              ]}
              defaultPageSize={Object.keys(this.props.counterpartydata.data.ratios).length}
              keyField={'id'}
              className="-striped -highlight"
            />
            </Card.Body>
          </Card>
        </div>
      )}

      { renderComparisonYear && (
        <div>
          <Card className="mt-4">
            <Card.Header>Select a comparison year</Card.Header>
            <Card.Body>
              <Form noValidate>
                <Form.Group controlId="counterpartyInsights.evaluationYear">
                  <Form.Label>Evaluation year</Form.Label>
                  <Form.Control
                    as="select"
                    ref="evaluationYear"
                    // use this.state.evaluationYear here??
                    defaultValue={this.props.counterpartydata.data.ratios.leverage.length > 0
                      ? this.props.counterpartydata.data.ratios.leverage[
                        this.props.counterpartydata.data.ratios.leverage.length - 1].year
                      : undefined}
                    onChange={this.saveEvaluationYear}>
                    { this.props.counterpartydata.data.ratios.leverage.map((k, index) => (
                      <option key={index} value={k.year}>{k.year}</option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Form>
            </Card.Body>
          </Card>
        </div>
      )}

      { renderSectorComparison && (
        <div>
          <Card className="mt-4">
            <Card.Header>
              Sector Comparison ({this.props.counterpartydata.data.counterparty.name} vs
                {` ${this.props.counterpartydata.data.counterparty.sector}`})
            </Card.Header>
            <Card.Body>
              {sectorComparisonChart()}
            </Card.Body>
          </Card>
        </div>
      )}

      { renderIndustryComparison && (
        <div>
          <Card className="mt-4">
            <Card.Header>
              Industry Comparison ({this.props.counterpartydata.data.counterparty.name} vs
                {` ${this.props.counterpartydata.data.counterparty.industry}`})
            </Card.Header>
            <Card.Body>
              {industryComparisonChart()}
            </Card.Body>
          </Card>
        </div>
      )}

      { renderComparisonMetric && (
        <div>
          <Card className="mt-4">
            <Card.Header>Comparison Metric</Card.Header>
            <Card.Body>
              <Form noValidate>
                <Form.Group controlId="counterpartyInsights.evaluationYear">
                  <Form.Label>Comparison metric</Form.Label>
                  <Form.Control as="select" ref="comparisonMetric" defaultValue={this.state.comparisonMetric} onChange={this.saveComparisonMetric}>
                    { Object.keys(this.props.counterpartydata.data.ratios).map((k, index) => (
                      <option key={index} value={k}>{k}</option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Form>
            </Card.Body>
          </Card>
        </div>
      )}

      { renderSectorTrends && (
        <div>
          <Card className="mt-4">
            <Card.Header>Sector Trends ({this.props.counterpartydata.data.counterparty.name} vs
              {` ${this.props.counterpartydata.data.counterparty.sector}`})</Card.Header>
            <Card.Body>
              {sectorTrendChart()}
            </Card.Body>
          </Card>
        </div>
      )}

      { renderIndustryTrends && (
        <div>
          <Card className="mt-4">
            <Card.Header>
              Industry Trends ({this.props.counterpartydata.data.counterparty.name} vs
                {` ${this.props.counterpartydata.data.counterparty.industry}`})
            </Card.Header>
            <Card.Body>
              {industryTrendChart()}
            </Card.Body>
          </Card>
        </div>
      )}

      { renderSharepriceChart && (
        <div>
          <Card className="mt-4">
            <Card.Header>Share performance</Card.Header>
            <Card.Body>
              {sharepriceChart()}
            </Card.Body>
          </Card>
        </div>
      )}

      { renderOptionInfoChart && (
        <div>
          <Card className="mt-4">
            <Card.Header>Option Market Intel</Card.Header>
            <Card.Body>
              {sharepriceChart()}
            </Card.Body>
          </Card>
        </div>
      )}

      { renderISSInfo && (
        <div>
          <Card className="mt-4">
            <Card.Header>
              {`${this.props.counterpartydata.data.counterparty.name}` }
              - Institutional Shareholder Services info
            </Card.Header>
            <Card.Body>
              {ISSInfoChart()}
            </Card.Body>
          </Card>
        </div>
      )}

      { renderVolatilityInfo && (
        <div>
          <Card className="mt-4">
            <Card.Header>
              {this.props.counterpartydata.data.counterparty.name} - Volatility over Time
            </Card.Header>
            <Card.Body>
              {VolatilityChart()}
            </Card.Body>
          </Card>
        </div>
      )}

      { renderDTDInfo && (
        <div>
          <Card className="mt-4">
            <Card.Header>
              {this.props.counterpartydata.data.counterparty.name} - Distance to Default over Time
            </Card.Header>
            <Card.Body>
              {DTDChart()}
            </Card.Body>
          </Card>
        </div>
      )}
      </div>
    );
  }
}

const loadingSelector = createLoadingSelector(['FETCH_COUNTERPARTIES', 'FETCH_COUNTERPARTY_DATA', 'FETCH_COUNTERPARTY_INDUSTRY_DATA', 'FETCH_COUNTERPARTY_SECTOR_DATA']);
const errorSelector = createErrorMessageSelector(['FETCH_COUNTERPARTIES', 'FETCH_COUNTERPARTY_DATA', 'FETCH_COUNTERPARTY_INDUSTRY_DATA', 'FETCH_COUNTERPARTY_SECTOR_DATA']);

const mapStateToProps = (state) => (
  {
    authentication: state.authentication,
    selections: state.selections,
    counterparties: state.counterparties,
    counterpartydata: state.counterpartydata,
    industrydata: state.industrydata,
    sectordata: state.sectordata,
    jobs: state.jobs,
    isFetching: loadingSelector(state),
    isError: errorSelector(state),
    loading: state.loading,
    error: state.error,
  }
);

const mapDispatchToProps = (dispatch) => (
  {
    fetchCounterparties: (token) => {
      dispatch(counterparties.fetchCounterparties(token));
    },
    fetchCounterpartyData: (ticker, token) => {
      dispatch(counterparties.fetchCounterpartyData(ticker, token));
    },
    fetchIndustryData: (industry, token) => {
      dispatch(counterparties.fetchIndustryData(industry, token));
    },
    fetchSectorData: (sector, token) => {
      dispatch(counterparties.fetchSectorData(sector, token));
    },
  }
);

export default connect(mapStateToProps, mapDispatchToProps)(CounterpartyDetails);

// TO DO: STRATIFICATIONS ARE NOT YET ASSOCIATED WITH JOBS!

// Import React & Redux components
import React from "react";
import { connect } from 'react-redux';
import { Card, Form, Row, Col } from 'react-bootstrap'

// Import layout and helper functions/templates
import Chart from "../components/Chart";
import Header from "../components/Header";
import Notification from "../components/Notification"
import { createLoadingSelector, createErrorMessageSelector, createAddMessage, findAssociatedValue } from '../components/Helpers'

// Import data sources
import { datasetActions, transactionActions, stratificationfeatures } from "../actions"

// Import dumb component
import FormSelectOneDropDown from './FormSelectOneDropDown'

class BasePortfolio extends React.Component {

  constructor(props) {

    super(props);
    if (this.props.match.params.id === undefined) {
      this.state = {
        selectedDataSetID: undefined,
      }
    } else {
      this.state = {
        selectedDataSetID : this.props.match.params.id,
      }
    }
  };

  // Force a url update to reflected the selected dataSetID and retrieve the
  // data for that dataSetID.
  saveSettings = (value) => {
    let target_id = value === undefined ? value : parseInt(this.refs.dataSetID.value)
    this.props.history.push('/base_portfolio/' + target_id)
    this.setState({
      selectedDataSetID : target_id,
    })
    this.props.fetchStratificationFeatures(target_id, ['Rating','Start_Date','Product_Type'], sessionStorage.getItem('jwtToken'))
  }

  componentDidMount() {
    this.props.fetchDataSets(sessionStorage.getItem('jwtToken'))
    if(this.props.transactions.isLoading === true && this.props.match.params.id !== null && this.props.match.params.id !== undefined ) {
      this.props.fetchTransactions(this.props.match.params.id, sessionStorage.getItem('jwtToken'))
      this.props.fetchStratificationFeatures(this.props.match.params.id, ['Rating','Start_Date','Product_Type'], sessionStorage.getItem('jwtToken'))
    }
  }

  componentDidUpdate() {
    if(this.state.selectedDataSetID === undefined && this.props.datasets.isLoading !== true && this.props.datasets.data.length > 0) {
      this.saveSettings(this.props.datasets.data[0].dataSetID);
    }
  }

  render() {

    // Show a loading screen if datasets has not finished loading yet.
    if (this.props.datasets.isLoading === true) {
      return (
        <div>
          <Header title={"Portfolio Descriptives"} />
          <Notification heading={'Data Set Selection'} body={'Loading Data'}/>
        </div>
      )
    }

    // If datasets being loaded resulted in an error, show an error screen.
    if (this.props.error.length > 0 || this.props.datasets.data === undefined){
      return (
        <div>
          <Header title={"Portfolio Descriptives"} />
          <Card className="mt-4">
            <Card.Header>Error</Card.Header>
            <Card.Body>
              <Notification heading={'Data Set Selection'} body={'Error Loading Data'}/>
            </Card.Body>
          </Card>
        </div>
      )
    }

    // If no dataset has selected, show only a selection menu.
    if (this.props.match.params.id === null || this.props.match.params.id === undefined || this.props.transactions.isLoading === true){
      return(
        <div>
          <Header title={"Portfolio Descriptives"} />
          <Card className="mt-4">
            <Card.Header>Data Set Selection</Card.Header>
            <Card.Body>
              <Form.Group as={Row} controlId="basePortfolio.dataSetID">
              <Form.Label column sm={3}>Data Set</Form.Label>
                <Col sm={9}>
                  <Form.Control as="select" ref="dataSetID" onChange={this.saveSettings} value={this.state.selectedDataSetID}>
                    { this.props.datasets.data.map((k, index) => {
                      return <option key={k.dataSetID} value={k.dataSetID}>{k.dataSetName}</option>
                      })
                    }
                  </Form.Control>
                </Col>
              </Form.Group>
            </Card.Body>
          </Card>
        </div>
      )
    }

    // Show a loading screen if not all sources have finished loading yet.
    if(this.props.transactions.isLoading === true || this.props.stratificationfeatures.isLoading === true){
      return (
        <div>
          <Header title={"Portfolio Descriptives"} />
          <Card className="mt-4">
            <Card.Header>Data Set Selection</Card.Header>
            <Card.Body>
              <Form.Group as={Row} controlId="basePortfolio.dataSetID">
              <Form.Label column sm={3}>Data Set</Form.Label>
                <Col sm={9}>
                  <Form.Control as="select" ref="dataSetID" onChange={this.saveSettings} value={this.state.selectedDataSetID}>
                    { this.props.datasets.data.map((k, index) => {
                      return <option key={k.dataSetID} value={k.dataSetID}>{k.dataSetName}</option>
                      })
                    }
                  </Form.Control>
                </Col>
              </Form.Group>
            </Card.Body>
          </Card>
          <Notification heading={'Rating Distribution'} body={'Loading Data'}/>
        </div>
      )
    }

    if (this.props.error.length > 0 || this.props.transactions.data === undefined || this.props.stratificationfeatures.data === undefined){
      return (
        <div>
          <Header title={"Portfolio Descriptives"} />
          <Card className="mt-4">
            <Card.Header>Data Set Selection</Card.Header>
            <Card.Body>
              <Form.Group as={Row} controlId="basePortfolio.dataSetID">
              <Form.Label column sm={3}>Data Set</Form.Label>
                <Col sm={9}>
                  <Form.Control as="select" ref="dataSetID" onChange={this.saveSettings} value={this.state.selectedDataSetID}>
                    { this.props.datasets.data.map((k, index) => {
                      return <option key={k.dataSetID} value={k.dataSetID}>{k.dataSetName}</option>
                      })
                    }
                  </Form.Control>
                </Col>
              </Form.Group>
            </Card.Body>
          </Card>
          <Notification heading={'Rating Distribution'} body={'Error Loading Data'}/>
        </div>
      )
    }

    // Temporary sorting of rating data, to be handled in back-end/Django?
    const ratinglabels = ['R1','R2','R3','R4','R5','R6','R7','R8','R9','R10','R11','R12','R13','R14','R15','R16','R17','R18','R19','R20']
    var ratingvalues = []
    const unsortedratinglabels = this.props.stratificationfeatures.data[0].featureLabels
    const unsortedratingvalues = this.props.stratificationfeatures.data[0].featureValues
    for (let i = 0; i < 20; ++i){
      for (let j = 0; j < unsortedratinglabels.length; ++j){
        if (ratinglabels[i] === unsortedratinglabels[j]){
          ratingvalues[i] = unsortedratingvalues[j]
        }
      }
    }

    const yearlabels = this.props.stratificationfeatures.data[1].featureLabels.map(elem => {
      return new Date(elem)
    })
    const yearvalues = this.props.stratificationfeatures.data[1].featureValues

    return (
      <div>
        <Header title={"Portfolio Descriptives"} />
        <Card className="mt-4">
          <Card.Header>Data Set Selection</Card.Header>
          <Card.Body>
            <Form.Group as={Row} controlId="basePortfolio.dataSetID">
            <Form.Label column sm={3}>Data Set</Form.Label>
              <Col sm={9}>
                <Form.Control as="select" ref="dataSetID" onChange={this.saveSettings} value={this.state.selectedDataSetID}>
                  { this.props.datasets.data.map((k, index) => {
                    return <option key={k.dataSetID} value={k.dataSetID}>{k.dataSetName}</option>
                    })
                  }
                </Form.Control>
              </Col>
            </Form.Group>
          </Card.Body>
        </Card>

        <Card className="mt-4">
          <Card.Header>Rating Distribution</Card.Header>
          <Card.Body>
            <Chart
              type={"Bar"}
              unit={"EUR"}
              xaxislabels={ratinglabels}
              dataseries={[ratingvalues]}
              dataserieslabels={["Rating Distribution"]}
            />
          </Card.Body>
        </Card>

        <Card className="mt-4">
          <Card.Header>Product Type Distribution</Card.Header>
          <Card.Body>
            <Chart
              type={"Bar"}
              unit={"EUR"}
              xaxislabels={this.props.stratificationfeatures.data[2].featureLabels}
              dataseries={[this.props.stratificationfeatures.data[2].featureValues]}
              dataserieslabels={["Product Type Distribution"]}
            />
          </Card.Body>
        </Card>

        <Card className="mt-4">
          <Card.Header>Start Date Distribution</Card.Header>
          <Card.Body>
            <Chart
              type={"Bar"}
              unit={"EUR"}
              xaxislabels={yearlabels}
              dataseries={[yearvalues]}
              dataserieslabels={["Start Date Distribution"]}
            />
          </Card.Body>
        </Card>
      </div>
    );
  }
}

const loadingSelector = createLoadingSelector(['FETCH_TRANSACTIONS', 'FETCH_STRATIFICATION_PRODUCT_TYPE', 'FETCH_STRATIFICATION_RATING', 'FETCH_STRATIFICATION_START_DATE','FETCH_STRATIFICATION_FEATURES']);
const errorSelector = createErrorMessageSelector(['FETCH_TRANSACTIONS', 'FETCH_STRATIFICATION_PRODUCT_TYPE', 'FETCH_STRATIFICATION_RATING', 'FETCH_STRATIFICATION_START_DATE','FETCH_STRATIFICATION_FEATURES']);

const mapStateToProps = (state) => {
  return {
    authentication: state.authentication,
    datasets: state.datasets,
    transactions: state.transactions,
    stratificationfeatures: state.stratificationfeatures,
    isFetching: loadingSelector(state),
    isError: errorSelector(state),
    loading: state.loading,
    error: state.error,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchDataSets: (token) => {
      dispatch(datasetActions.fetchDataSets(token));
    },
    fetchTransactions: (id, token) => {
      dispatch(transactionActions.fetchTransactions(id, token));
    },
    fetchStratificationFeatures: (id, features, token) => {
      dispatch(stratificationfeatures.fetchStratificationFeatures(id, features, token));
    }
  }}

export default connect(mapStateToProps, mapDispatchToProps)(BasePortfolio);

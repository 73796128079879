const initialState = {data: [], isLoading: true};

 export default function portfoliovalue(state = initialState, action) {
  switch(action.type) {
    case 'FETCH_PORTFOLIO_VALUE_REQUEST':
      return {data: state.data, isLoading: true};
    case 'FETCH_PORTFOLIO_VALUE_SUCCESS':
      return {data: action.portfoliovalue, isLoading: false};
    case 'FETCH_PORTFOLIO_VALUE_FAILURE':
      return {data: state.data, lastAdded: false};
    default:
      return state;
  }
}

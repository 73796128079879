function handleErrors(response) {
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return response;
}

export const addTransactions = (text, token) => {
  return dispatch => {
    const headers = {"Content-Type": "application/json", "Authorization": "JWT " + token};
    let body = JSON.stringify(text);
    return fetch(process.env.REACT_APP_HTTP_PROTOCOL + "://" + process.env.REACT_APP_API_IP + "/api/portfolio/transactions/", {headers, method: "POST", body})
      .then(handleErrors)
      .then(res => res.json())
      .then(transactions => {
        return dispatch({
          type: 'ADD_TRANSACTIONS_SUCCESS',
          transactions
        })
      })
      .catch(error => {
        return dispatch({
          type: 'ADD_TRANSACTIONS_FAILURE',
          error_type: error,
          error: true
        })
      })
  }
}

export const updateTransaction = (index, text, token) => {
  return (dispatch, getState) => {
    const headers = {"Content-Type": "application/json", "Authorization": "JWT " + token};
    let body = JSON.stringify(text);
    let transactionId = getState().Transactions[index].id;
    return fetch(process.env.REACT_APP_HTTP_PROTOCOL + "://" + process.env.REACT_APP_API_IP + "/api/portfolio/transactions/${transactionId}/", {headers, method: "PUT", body})
      .then(handleErrors)
      .then(res => res.json())
      .then(transaction => {
        return dispatch({
          type: 'UPDATE_TRANSACTION',
          transaction,
          index
        })
      })
  }
}

export const deleteTransaction = (index, token) => {
  return (dispatch, getState) => {
    const headers = {"Content-Type": "application/json", "Authorization": "JWT " + token};
    let transactionId = getState().transactions[index].id;
    return fetch(process.env.REACT_APP_HTTP_PROTOCOL + "://" + process.env.REACT_APP_API_IP + "/api/transactions/${transactionId}/", {headers, method: "DELETE"})
      .then(handleErrors)
      .then(res => {
        if (res.ok) {
          return dispatch({
            type: 'DELETE_TRANSACTION',
            index
          })
        }
      })
  }
}

export const fetchTransactions = (id, token) => {
  return dispatch => {
    dispatch({ type: 'FETCH_TRANSACTIONS_REQUEST' });
    const headers = {"Content-Type": "application/json", "Authorization": "JWT " + token};
    return fetch(process.env.REACT_APP_HTTP_PROTOCOL + "://" + process.env.REACT_APP_API_IP + "/api/portfolio/transactions/" + id + "/", {headers, })
      .then(handleErrors)
      .then(res => res.json())
      .then(transactions => {
        return dispatch({
          type: 'FETCH_TRANSACTIONS_SUCCESS',
          transactions
        })
      })
      .catch(error => {
        return dispatch({ type: 'FETCH_TRANSACTIONS_FAILURE', error_type: error, error: true })
      })
  }
}

export const fetchTransactionsOfSelection = (id, token) => {
  return dispatch => {
    dispatch({ type: 'FETCH_TRANSACTIONS_REQUEST' });
    const headers = {"Content-Type": "application/json", "Authorization": "JWT " + token};
    return fetch(process.env.REACT_APP_HTTP_PROTOCOL + "://" + process.env.REACT_APP_API_IP + "/api/simulation/selectionslist/" + id + "/", {headers, })
      .then(handleErrors)
      .then(res => res.json())
      .then(transactions => {
        return dispatch({
          type: 'FETCH_TRANSACTIONS_SUCCESS',
          transactions
        })
      })
      .catch(error => {
        return dispatch({ type: 'FETCH_TRANSACTIONS_FAILURE', error_type: error, error: true })
      })
  }
}

const initialState = {token: {}, isFetching: false, isAuthenticated: sessionStorage.getItem('token') ? true : false};

// The authentication reducer. The starting state sets authentication
// based on a token being in local storage. In a real app,
// we would also want a util to check if the token is expired.
export default function authentication(state=initialState, action) {
  switch (action.type) {
    case 'LOGIN_REQUEST':
      return Object.assign({}, state, {
        isFetching: true,
        isAuthenticated: false,
        user: action.creds.username
      })
    case 'LOGIN_SUCCESS':
      return Object.assign({}, state, {
        isFetching: false,
        isAuthenticated: true,
        token: action.token,
        errorMessage: undefined
      })
    case 'LOGIN_FAILURE':
      if(action.message === undefined) {
        return Object.assign({}, state, {
          isFetching: false,
          isAuthenticated: false,
          errorMessage: "Incorrect credentials"
        })
      } else {
      return Object.assign({}, state, {
        isFetching: false,
        isAuthenticated: false,
        errorMessage: action.message
      })}
    case 'LOGOUT_SUCCESS':
      return Object.assign({}, state, {
        isFetching: true,
        isAuthenticated: false,
        errorMessage: undefined
      })
    default:
      return state
  }
}

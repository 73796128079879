// Import React & Redux components
import React from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Card,
  Col,
  Form,
  Row,
} from 'react-bootstrap';

import { scraper } from '../actions';

class ScraperInitializeForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      instanceType: 1,
      selectedMode: 0,
      keypair: 0,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.modeUpdate = this.modeUpdate.bind(this);
  }

  componentDidMount() {
    this.props.fetchTickerlists(sessionStorage.getItem('jwtToken'));
  }

  allowedInstanceTypes = [
    { index: 0, name: 't3a.micro' },
    { index: 1, name: 't3a.nano' },
    { index: 2, name: 't3a.small' },
  ];

  allowedKeypairs = [{ index: 0, name: 'Ireland_R.pem' }];

  allowedModes = [
    { index: 0, name: 'pricing_data' },
    { index: 1, name: 'option_data' },
    { index: 3, name: 'fundamental_data' },
    { index: 4, name: 'profile_data' },
    { index: 5, name: 'name_data' },
    { index: 6, name: 'analysis_data' },
  ];

  handleSubmit = (event) => {
    event.preventDefault();
    const dataDict = {
      CRAWL_NAME_DATA: this.refs.name_data.checked,
      CRAWL_OPTION_DATA: this.refs.option_data.checked,
      CRAWL_PROFILE_DATA: this.refs.profile_data.checked,
      CRAWL_PRICING_DATA: this.refs.pricing_data.checked,
      CRAWL_ANALYSIS_DATA: this.refs.analysis_data.checked,
      CRAWL_FUNDAMENTAL_DATA: this.refs.fundamental_data.checked,
      nodeCount: parseInt(this.refs.instancesCount.value, 10),
      instanceType: this.refs.instanceType.value,
      password: this.refs.scraperPassword.value,
      tickerlistID: parseInt(this.refs.tickerlist.value, 10),
    };
    this.props.addScraperJob(dataDict, sessionStorage.getItem('jwtToken'));
  }

  modeUpdate = (index) => {
    this.setState({
      selectedMode: index,
    });
  }

  render() {
    const showJobsInProgress = false;
    const showJobInitializationForm = this.props.tickerlists.isLoading !== true;

    return (
      <div>
        <Card className="mt-4">
          <Card.Header>General remarks to take into account</Card.Header>
          <Card.Body>
            <ul>
              <li>Remark 1</li>
              <li>Remark 2</li>
              <li>Remark 3</li>
              <li>Remark 4</li>
            </ul>
          </Card.Body>
        </Card>

        { showJobInitializationForm && (
          <div>
            <Card className="mt-4">
              <Card.Header>Initialize Scraper Run</Card.Header>
              <Card.Body>
                <Form onSubmit={this.handleSubmit}>
                  <Form.Group as={Row} controlId="scraperInitialize.modeSelection">
                    <Form.Label column sm={4}>Mode Selection</Form.Label>
                    <Col sm={8}>
                      { this.allowedModes.map((k) => (
                          <Form.Check type="radio" label={k.name} key={k.index} id={k.index} ref={k.name} onChange={() => this.modeUpdate(k.index)} checked={this.state.selectedMode === k.index} />
                      ))
                      }
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} controlId="scraperInitialize.AWSSettings">
                    <Form.Label column sm={4}>SSH Keypair</Form.Label>
                    <Col sm={8}>
                      <Form.Control as="select" ref="keypair" defaultValue={this.state.keypair}>
                        { this.allowedKeypairs.map((k) => (
                            <option key={k.index} value={k.index}>{k.name}</option>
                        ))
                        }
                      </Form.Control>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} controlId="scraperInitialize.instanceSelection">
                    <Form.Label column sm={4}>Instance Type and count</Form.Label>
                    <Col sm={4}>
                      <Form.Control as="select" ref="instanceType" defaultValue={this.state.instanceType}>
                        { this.allowedInstanceTypes.map((k) => (
                            <option key={k.index} value={k.name}>{k.name}</option>
                        ))
                        }
                      </Form.Control>
                    </Col>
                    <Col sm={4}>
                      <Form.Control as="input" type="number" ref="instancesCount" />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} controlId="scraperInitialize.tickerlistSelection">
                    <Form.Label column sm={4}>Tickerlist to use in scrape</Form.Label>
                    <Col sm={8}>
                      <Form.Control as="select" ref="tickerlist" defaultValue={this.state.tickerlist}>
                        { this.props.tickerlists.data.map((k) => (
                            <option key={k.id} value={k.id}>{k.name}</option>
                        ))
                        }
                      </Form.Control>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} controlId="scraperInitialize.scraperPassword">
                    <Form.Label column sm={4}>Scraper Password</Form.Label>
                    <Col sm={8}>
                      <Form.Control as="input" ref="scraperPassword" />
                    </Col>
                  </Form.Group>
                  <Button variant="outline-primary" fill="true" type="submit" className="mt-3" >Start Scrape Run</Button>
                </Form>
              </Card.Body>
            </Card>
          </div>
        )}

        { showJobsInProgress && (
          <div>
            <Card className="mt-4">
              <Card.Header>
                <div className="fa-pull-left">Jobs in Progress</div>
                <div>
                  <span
                    className="question-mark fa-pull-right"
                    onClick={() => this.renderHelp({
                      header: 'Job Selection',
                      body: 'Select the job for which you want to view the results.',
                    }) }>
                  </span>
                </div>
              </Card.Header>
              <Card.Body>
                <Form.Group as={Row} controlId="createJob.jobID">
                <Form.Label column sm={3}>Job Name</Form.Label>
                  <Col sm={9}>
                    <Form.Control as="select" ref="jobID" onChange={this.saveJobID} value={this.state.selectedJobID} defaultValue="">
                      { this.props.jobs.data.filter((elem) => (
                        elem.jobCompletion === 3
                      )).map((k, index) => (
                        <option key={index} value={k.jobID}>{k.jobName}</option>
                      ))}
                    </Form.Control>
                  </Col>
                </Form.Group>
              </Card.Body>
            </Card>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => (
  {
    authentication: state.authentication,
    scraper: state.scraper,
    tickerlists: state.tickerlists,
    loading: state.loading,
    error: state.error,
  });

const mapDispatchToProps = (dispatch) => (
  {
    fetchTickerlists: (token) => {
      dispatch(scraper.fetchTickerlists(token));
    },
    fetchScraperJobs: (token) => {
      dispatch(scraper.fetchScraperJobs(token));
    },
    addScraperJob: (info, token) => {
      dispatch(scraper.addScraperJob(info, token));
    },
  });

export default connect(mapStateToProps, mapDispatchToProps)(ScraperInitializeForm);

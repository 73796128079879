function handleErrors(response) {
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return response;
}

const { apiEndpoint } = window['__runtime_configuration'];

export const addSelection = (text, token) => {
  return dispatch => {
    dispatch({ type: 'ADD_SELECTION_REQUEST' });
    const headers = {"Content-Type": "application/json", "Authorization": "JWT " + token};
    let body = JSON.stringify(text);
    return fetch(process.env.REACT_APP_HTTP_PROTOCOL + "://" + process.env.REACT_APP_API_IP + "/api/simulation/selections/", {headers, method: "POST", body})
      .then(handleErrors)
      .then(res => res.json())
      .then(selection => {
        return dispatch({
          type: 'ADD_SELECTION_SUCCESS',
          selection
        })
      })
      .catch(error => {
        return dispatch({ type: 'ADD_SELECTION_FAILURE', error_type: error, error: true })
      })
  }
}

export const updateSelection = (index, text, token) => {
  return (dispatch, getState) => {
    const headers = {"Content-Type": "application/json", "Authorization": "JWT " + token};
    let body = JSON.stringify(text);
    let selectionId = getState().Selections[index].id;
    return fetch(process.env.REACT_APP_HTTP_PROTOCOL + "://" + process.env.REACT_APP_API_IP + "/api/simulation/selections/${selectionId}/", {headers, method: "PUT", body})
      .then(handleErrors)
      .then(res => res.json())
      .then(selection => {
        return dispatch({
          type: 'UPDATE_SELECTION',
          selection,
          index
        })
      })
  }
}

export const deleteSelection = (id, token) => {
  return (dispatch, getState) => {
    const headers = {"Content-Type": "application/json", "Authorization": "JWT " + token};
    let body = JSON.stringify(id);
    return fetch(process.env.REACT_APP_HTTP_PROTOCOL + "://" + process.env.REACT_APP_API_IP + "/api/simulation/selections/" + id + "/", {headers, method: "DELETE", body})
      .then(handleErrors)
      .then(res => {
        if (res.ok) {
          return dispatch({
            type: 'DELETE_SELECTION_SUCCESS',
            id
          })
        }
      })
  }
}

export const fetchSelection = (id, token) => {
  return dispatch => {
    dispatch({ type: 'FETCH_SELECTIONS_REQUEST' });
    let headers = {"Content-Type": "application/json", "Authorization": "JWT " + token};
    return fetch(process.env.REACT_APP_HTTP_PROTOCOL + "://" + process.env.REACT_APP_API_IP + "/api/simulation/selections/" + id + "/", {headers, })
      .then(handleErrors)
      .then(res => res.json())
      .then(selections => {
        return dispatch({
          type: 'FETCH_SELECTIONS_SUCCESS',
          selections
        })
      })
      .catch(error => {
        return dispatch({ type: 'FETCH_SELECTIONS_FAILURE', error_type: error, error: true })
      })
  }
}

export const fetchSelections = (token) => {
  return dispatch => {
    dispatch({ type: 'FETCH_SELECTIONS_REQUEST' });
    let headers = {"Content-Type": "application/json", "Authorization": "JWT " + token};
    return fetch(process.env.REACT_APP_HTTP_PROTOCOL + "://" + apiEndpoint + "/api/simulation/selections/", {headers, })
      .then(handleErrors)
      .then(res => res.json())
      .then(selections => {
        return dispatch({
          type: 'FETCH_SELECTIONS_SUCCESS',
          selections
        })
      })
      .catch(error => {
        return dispatch({ type: 'FETCH_SELECTIONS_FAILURE', error_type: error, error: true })
      })
  }
}

export const fetchPortfolioCounterparties = (index, token) => {
  return dispatch => {
    dispatch({ type: 'FETCH_PORTFOLIO_COUNTERPARTIES_REQUEST' });
    let headers = {"Content-Type": "application/json", "Authorization": "JWT " + token};
    return fetch(process.env.REACT_APP_HTTP_PROTOCOL + "://" + process.env.REACT_APP_API_IP + "/api/simulation/selectiontransactionmapping/" + index + "/", {headers, })
      .then(handleErrors)
      .then(res => res.json())
      .then(counterparties => {
        return dispatch({
          type: 'FETCH_PORTFOLIO_COUNTERPARTIES_SUCCESS',
          counterparties
        })
      })
      .catch(error => {
        return dispatch({ type: 'FETCH_PORTFOLIO_COUNTERPARTIES_FAILURE', error_type: error, error: true })
      })
  }
}

// Import React & Redux components
import React from "react";
import { connect } from 'react-redux';
import { Card, Form, Button, Nav, Row, Col, ButtonToolbar, ButtonGroup } from 'react-bootstrap'

// Import layout and helper functions/templates
import Header from "../components/Header";
import Notification from "../components/Notification"
import Chart from "../components/Chart2";
import { createLoadingSelector } from '../components/Helpers'
import ReactTable from 'react-table';

// Import data sources
import { counterparties } from "../actions";

class PortfolioDetails extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      counterparties: [],
      counterpartyTicker: null,
      counterpartyName: null,
      counterpartyIndustry: null,
      counterpartySector: null,
      fundamentalMetrics: null,
      comparisonMetric: 'leverage',
      comparisonMetrics: ['Leverage ratio', 'Solvency ratio'],
      selectedCounterparties: [],
      selectionMethod: 'simple',
      industrySelection: [true, true, true, true, true, true],
      countrySelection: [true, true, true, true, true, true],
      countryLabels: [],
      industryLabels: [],
      combinationMetric: 'and',
      selectAll: false,
      renderHelp: false,
      helpText: undefined,
    }
    this.populateCountryLabels = this.populateCountryLabels.bind(this)
    this.addCountryToSelection = this.addCountryToSelection.bind(this)
    this.addIndustryToSelection = this.addIndustryToSelection.bind(this)
    this.updateCounterpartySelection = this.updateCounterpartySelection.bind(this)
    this.changeFormViewToSimple = this.changeFormViewToSimple.bind(this)
    this.changeFormViewToDetailed = this.changeFormViewToDetailed.bind(this)
    this.constructCounterpartySelectionFromCategories = this.constructCounterpartySelectionFromCategories.bind(this)
    this.startReportGeneration = this.startReportGeneration.bind(this)
    this.changeCombinationMetric = this.changeCombinationMetric.bind(this)
    this.toggleCounterparty = this.toggleCounterparty.bind(this)
    this.renderHelp = this.renderHelp.bind(this);
  }

  renderHelp = (content) => {
    this.state.renderHelp ? this.setState({ renderHelp: false, helpText: undefined}) : this.setState({ renderHelp: true, helpText: content})
  }

  componentDidMount() {
    this.props.fetchCounterparties(sessionStorage.getItem('jwtToken'));
    this.props.fetchReports(sessionStorage.getItem('jwtToken'));
    //this.props.fetchPortfolioOverview(['BAMNB.AS', 'ABI.BR', 'MT.AS', 'ARCAD.AS', 'AF.PA', 'OCI.AS', 'BARN.SW', 'GLEN.L','MCD','V','CAT','AAPL','MSFT','NVDA','YUM','M','MCO','UA','MAC','L','DAL','KO','CF','ADP','AMGN','MRK','JNJ','DIS','EBAY','CVX','BA','T','GOOG','FB','PYPL','AALB.AS','PHIA.AS','K'],sessionStorage.getItem('jwtToken'));
    this.props.fetchCountryExposures('2018', sessionStorage.getItem('jwtToken'));
    this.props.fetchIndustryExposures('2018', sessionStorage.getItem('jwtToken'));
  }

  componentDidUpdate() {
    if (this.state.countryLabels.length === 0 && this.props.countryexposures.isLoading !== true){
      let country_labels = [...new Set(this.props.countryexposures.data.map(elem => {return elem.country}))]
      this.setState({
        countryLabels: country_labels
      })
    }

    if (this.state.industryLabels.length === 0 && this.props.industryexposures.isLoading !== true){
      let industry_labels = [...new Set(this.props.industryexposures.data.map(elem => {return elem.industry}))]
      this.setState({
        industryLabels: industry_labels
      })
    }
  }

  startReportGeneration = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      event.preventDefault();
      let tickerlist = []
      if(this.state.selectionMethod === 'simple'){
        tickerlist = this.constructCounterpartySelectionFromCategories()
      } else {
        tickerlist = this.state.selectedCounterparties
      }
      if(tickerlist.length > 50){
        tickerlist = tickerlist.slice(0, 51)
      }
      let data = {}
      data.filename = this.refs.reportName.value
      data.tickerlist = tickerlist.map(elem => {return {"ticker": elem.ticker}})
      this.props.createReport(data, sessionStorage.getItem('jwtToken'))
      this.renderHelp({
        header: 'Report creation started',
        body: 'Creation of your report has started and the report will be ready soon. Please refresh the page in +/- 5 minutes to retrieve the report.'
      })
    }
  }

  changeCombinationMetric = () => {
    if (this.state.combinationMetric === 'and') {
      this.setState({
        combinationMetric: 'or'
      })
    } else {
      this.setState({
        combinationMetric: 'and'
      })
    }
  }

  populateCountryLabels = () => {
    let country_labels = [...new Set(this.props.counterparties.data.map(elem => {return elem.country}))]
    this.setState({
      countryLabels: country_labels
    })
  }

  addIndustryToSelection = (category, value) => {
    let new_values = [...this.state.industrySelection]
    new_values[value] = new_values[value] === true ? false : true;
    this.setState({
      industrySelection: new_values,
    })
  }

  addCountryToSelection = (category, value) => {
    let new_values = [...this.state.countrySelection]
    new_values[value] = new_values[value] === true ? false : true;
    this.setState({
      countrySelection: new_values,
    })
  }

  constructCounterpartySelectionFromCategories = () => {
    let selected_countries = []
    this.state.countrySelection.forEach((elem, index) => {
      if(elem === true){
        selected_countries.push(this.state.countryLabels[index])
      }
    })

    let selected_industries = []
    this.state.industrySelection.forEach((elem, index) => {
      if(elem === true){
        selected_industries.push(this.state.industryLabels[index])
      }
    })

    let tickers_in_countries = selected_countries.map(elem => {
      return {country: elem, tickers: this.props.counterparties.data.filter(counterparty => {return elem === counterparty.country})}
    })

    let tickers_in_industries = selected_industries.map(elem => {
      return {industry: elem, tickers: this.props.counterparties.data.filter(counterparty => {return elem === counterparty.industry})}
    })

    let selected_counterparties = []

    if (this.state.combinationMetric === 'and') {
      let tickers_in_subset = []
      selected_industries.forEach(industry => {
        tickers_in_subset.push(tickers_in_countries.map(country => {
          return country.tickers.filter(ticker => {return industry === ticker.industry})
        }))
      })
      tickers_in_subset = tickers_in_subset.flat(2)
      tickers_in_subset.forEach(elem => selected_counterparties.push(elem))
      selected_counterparties = [...new Set(selected_counterparties)]
    } else {
      tickers_in_countries.forEach(elem => selected_counterparties.push(elem.tickers))
      tickers_in_industries.forEach(elem => selected_counterparties.push(elem.tickers))
      selected_counterparties = [...new Set(selected_counterparties.flat())]
    }
    this.setState({
      selectedCounterparties: selected_counterparties
    })
    if(selected_counterparties.length > 0){
      //this.props.fetchPortfolioOverview(selected_counterparties.map(elem => {return elem.ticker}),sessionStorage.getItem('jwtToken'));
    }
    return selected_counterparties
  }

  updateCounterpartySelection = (ticker) => {
    let list_index = this.state.selectedCounterparties.find(elem => {return elem === ticker})
    let updated_selectionlist = []
    if (list_index === undefined) {
      updated_selectionlist=[...this.state.selectedCounterparties, ticker]
    } else {
      updated_selectionlist=this.state.selectedCounterparties.filter(elem => { return elem !== ticker })
    }
    this.setState({
      selectedCounterparties: updated_selectionlist
    })
  }

  changeFormViewToSimple = () => {
    this.setState({
      selectionMethod: "simple"
    })
  }

  changeFormViewToDetailed = () => {
    this.setState({
      selectionMethod: "detailed"
    })
  }

  toggleCounterparty = (elem) => {
    let existing = this.state.selectedCounterparties.filter(counterparty => {return elem.ticker === counterparty.ticker})
    if (existing.length > 0){
      this.setState({
        selectedCounterparties: this.state.selectedCounterparties.filter(counterparty => {return counterparty.ticker !== elem.ticker})
      })
    } else {
      this.setState({
        selectedCounterparties: [...this.state.selectedCounterparties, elem]
      })
    }
  }

  toggleAll = () => {
    let indices_in_focus = this.detailedGenerationTable.getResolvedState().sortedData;
    let counterparty_elems_in_focus = indices_in_focus.map(elem => {return this.props.counterparties.data[elem._index]})
    const selectionItems = [];
    let new_selected_counterparties_array = [];
    let selectAll = this.state.selectAll ? false : true;
    if(selectAll){
      counterparty_elems_in_focus.forEach(item => {
        let filter_result = this.state.selectedCounterparties.filter(counterparty => {return counterparty.ticker === item.ticker})
        if (filter_result.length === 0) {
          selectionItems.push(item);
        }
      });
      new_selected_counterparties_array = [...this.state.selectedCounterparties,...selectionItems]
    } else {
      this.state.selectedCounterparties.forEach(elem => {
        let selected_counterparty = counterparty_elems_in_focus.filter(counterparty => {return counterparty.ticker === elem.ticker})
        if(selected_counterparty.length === 0){
          selectionItems.push(elem)
        }
      })
      new_selected_counterparties_array = [...selectionItems]
    }
    this.setState({
      selectAll: selectAll,
		  selectedCounterparties: new_selected_counterparties_array
		});
  };

  checkbox_column = {
    accessor: "checkbox",
    Cell: ({ original }) => {
      return (
        <div style={{textAlign: "center"}}>
          {<Form.Check type="checkbox" key={original.ticker} value={original.ticker} onChange={() => this.toggleCounterparty(original)} checked={this.state.selectedCounterparties.filter(elem => {return elem.ticker === original.ticker}).length > 0}/>}
        </div>
      );
    },
    Header: x => {
      return (
        <div style={{textAlign: "center"}}>
          <input
            type="checkbox"
            className="checkbox"
            checked={this.state.selectAll === true}
            ref = {
              input => {
                if (input) {
                  input.indeterminate = this.state.selectAll === 2;
                }
               }
             }
            onChange = {() => this.toggleAll()}
          />
        </div>
      );
    },
    sortable: false,
    width: 45,

  };

  render(){
    if(Object.keys(this.props.error).length > 0){
      const errors = Object.keys(this.props.error)

      if(errors.find(error => error === 'FETCH_COUNTERPARTIES')){
        return (
          <div>
            <Header title={"Portfolio Insights"} />
            <Notification heading={'Status'} body={'Error loading counterparties'}/>
          </div>
        )
      }

      if(errors.find(error => error === 'FETCH_COUNTERPARTY_PORTFOLIO_OVERVIEW')){
        return (
          <div>
            <Header title={"Portfolio Insights"} />
            <Notification heading={'Status'} body={'Error loading portfolio overview'}/>
          </div>
        )
      }
    }
    if (this.props.counterparties.isLoading === true) {
      return (
        <div>
          <Header title={"Portfolio Insights"} />
          <Notification heading={'Settings'} body={'Loading Data'}/>
        </div>
      )
    }

    const renderLeverageVSDSCRBubble = this.props.portfoliodata.isLoading !== true && this.props.portfoliodata.data.length > 0
    const renderReportGenerationForm = this.props.industryexposures.isLoading !== true && this.props.industryexposures.data.length > 0 && this.props.countryexposures.isLoading !== true && this.props.countryexposures.data.length > 0 && this.props.counterparties.data.length > 0
    const renderPortfolioOverviewCreation = true
    const renderGeneratedReports = this.props.reports.isLoading !== true && this.props.reports.data !== undefined && this.props.reports.data.length > 0
    let keyMetricsCounterparty = []
    let keyMetricsIndustry = []
    let keyMetricsSector = []
    let keyMetrics = []

    const detailedGenerationForm = () => {
      return (
        <div>
            <ReactTable
              data={this.props.counterparties.data}
              filterable
              columns={[
                this.checkbox_column,
                {accessor: "ticker", Header: "Ticker"},
                {accessor: "name", Header: "Name"},
                {accessor: "sector", Header: "Sector"},
                {accessor: "industry", Header: "Industry"},
                {accessor: "country", Header: "Country"},
              ]}
              sorted={[{
                id: 'name',
                desc: false
              }]}
              defaultPageSize={50}
              keyField={'id'}
              ref={(r) => {
                this.detailedGenerationTable = r;
              }}
              className="-striped -highlight"
            />
        </div>
      )
    }

    const simpleGenerationForm = () => {
      return (<div>
        <Chart
          type="Doughnut"
          unit={"percent"}
          dataseries={[this.props.countryexposures.data.map(elem => {return elem.value})]}
          dataserieslabels={this.props.countryexposures.data.map(elem => {return elem.country})}
          categorySelection={this.state.countrySelection}
          onElementsClick={this.addCountryToSelection}
        />
        <div className="text-center">
          <Button
            variant={this.state.combinationMetric === 'and' ? 'primary' : 'outline-primary'}
            onClick={this.changeCombinationMetric}
            >And</Button>
          <Button
            variant={this.state.combinationMetric === 'or' ? 'primary' : 'outline-primary'}
            onClick={this.changeCombinationMetric}>Or</Button>
        </div>
        <Chart
          type="Doughnut"
          unit={"percent"}
          dataseries={[this.props.industryexposures.data.map(elem => {return elem.value})]}
          dataserieslabels={this.props.industryexposures.data.map(elem => {return elem.industry})}
          categorySelection={this.state.industrySelection}
          onElementsClick={this.addIndustryToSelection}
        />
      </div>)
    }

    {/*<div style={{ textAlign: "center" }}>{<a href={row.value} style={{color: "#212529"}} target="_blank"><i className="fa fa-file-pdf-o" aria-hidden="true"></i></a>}</div>*/}
    const generatedReportsTable = () => {
      return (<div>
        <ReactTable
          data={this.props.reports.data}
          filterable
          columns={[
            {accessor: "createdOn", Header: "Created On",
            Cell: row => (
              <div style={{ textAlign: "right" }}>{new Date(row.value).toLocaleString('en-GB', { year: 'numeric', month: '2-digit', day: '2-digit' })}</div>
            )},
            {accessor: "name", Header: "Name",
            Cell: row => (
              <div style={{ textAlign: "right" }}>{row.value}</div>
            )},
            {accessor: "html_url", Header: "Page",
            Cell: row => (
              <div style={{ textAlign: "center" }}>{<a href={row.value} style={{color: "#212529"}} target="_blank"><i className="fa fa-file-text" aria-hidden="true"></i></a>}</div>
            )},
            {accessor: "pdf_url", Header: "PDF",
            Cell: row => (
              <div style={{ textAlign: "center" }}>N/A</div>
            )},
          ]}
          sorted={[{
            id: 'generatedOn',
            desc: true
          }]}
          defaultPageSize={5}
          keyField={'id'}
          className="-striped -highlight"
        />
      </div>)
    }

    const reportGenerationForm = () => {
      if (this.state.selectionMethod === 'simple') {
        return (
          <div>
            <Form onSubmit={this.startReportGeneration}>
              <Form.Group as={Row} controlId="reportGeneration.name">
                <Form.Label column sm={2}>Report name</Form.Label>
                <Col sm={10}>
                  <Form.Control type="text" ref="reportName" required/>
                </Col>
              </Form.Group>
              {simpleGenerationForm()}
              <Button fill="true" style={{width: '100%'}} type="submit" variant="outline-primary">Start Report Generation</Button>
            </Form>
          </div>
        )
      } else {
        return (
          <div>
            <Form onSubmit={this.startReportGeneration}>
              <Form.Group as={Row} controlId="reportGeneration.name">
                <Form.Label column sm={2}>Report name</Form.Label>
                <Col sm={10}>
                  <Form.Control type="text" ref="reportName" required/>
                </Col>
              </Form.Group>
              {detailedGenerationForm()}
              <Button fill="true" style={{width: '100%'}} type="submit" variant="outline-primary">Start Report Generation</Button>
            </Form>
          </div>
        )
      }
    };

    const LeverageVSDebtServiceChart = () => {
      /* takes [{debt_service, leverage, size}, {debt_service, leverage, size}] as input */
      var ticker_values = this.props.portfoliodata.data.map(elem => {return {x: elem.leverage, y: elem.interestCoverage, r: 15}})
      return (
        <Chart type={"Bubble"}
          unit={"decimal"}
          dataseries={
            ticker_values
          }
          dataserieslabels={this.props.portfoliodata.data.map(elem => {return elem.ticker})}
        />
      )
    }

    return(
      <div>
      { this.state.renderHelp === true && (
        <div className="overlay-help" onClick={() => this.renderHelp(undefined)}>
          <div className="overlay-help-card">
            <Card className="mt-4">
              <Card.Header><div className="fa-pull-left">{this.state.helpText.header}</div>
              <i className="fa fa-window-close fa-pull-right" onClick={() => this.renderHelp(undefined)}></i></Card.Header>
              <Card.Body>{this.state.helpText.body}</Card.Body>
            </Card>
          </div>
        </div>
      )}
      <Header title={"Portfolio Insights"} />

      { renderGeneratedReports && (
        <div>
        <Card className="mt-4">
          <Card.Header>Created portfolio overviews</Card.Header>
          <Card.Body>
            {generatedReportsTable()}
          </Card.Body>
        </Card>
        </div>
      )}

      { renderReportGenerationForm && (
        <div>
        <Card className="mt-4">
          <Card.Header>Portfolio overview creation</Card.Header>
          <Card.Body>
              <Nav variant="pills" defaultActiveKey="simple" fill="true">
                <Nav.Item>
                  <Nav.Link eventKey="simple" onClick={this.changeFormViewToSimple}>Simplistic View</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="detailed" onClick={this.changeFormViewToDetailed}>Detailed View</Nav.Link>
                </Nav.Item>
              </Nav>
              {reportGenerationForm()}
          </Card.Body>
        </Card>
        </div>
      )}

      { renderLeverageVSDSCRBubble && (
        <div>
          <Card className="mt-4">
            <Card.Header>Leverage (x-axis) VS Interest Service Coverage (y-axis)</Card.Header>
            <Card.Body>
              {LeverageVSDebtServiceChart()}
            </Card.Body>
          </Card>
        </div>
      )}
      </div>
    );
  }
}

const loadingSelector = createLoadingSelector(['FETCH_SELECTIONS', 'FETCH_SCENARIOS', 'FETCH_JOBS']);

const mapStateToProps = state => {
  return {
    authentication: state.authentication,
    counterparties: state.counterparties,
    counterpartydata: state.counterpartydata,
    portfoliodata: state.portfoliodata,
    industryexposures: state.industryexposures,
    countryexposures: state.countryexposures,
    reports: state.reports,
    isFetching: loadingSelector(state),
    loading: state.loading,
    error: state.error,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    fetchCounterparties: (token) => {
      dispatch(counterparties.fetchCounterparties(token));
    },
    fetchReports: (token) => {
      dispatch(counterparties.fetchReports(token));
    },
    fetchCounterpartyData: (ticker, token) => {
      dispatch(counterparties.fetchCounterpartyData(ticker, token));
    },
    fetchPortfolioOverview: (portfolio, token) => {
      dispatch(counterparties.fetchPortfolioOverview(portfolio, token));
    },
    fetchIndustryExposures: (year, token) => {
      dispatch(counterparties.fetchIndustryExposures(year, token));
    },
    fetchCountryExposures: (year, token) => {
      dispatch(counterparties.fetchCountryExposures(year, token));
    },
    createReport: (data, token) => {
      dispatch(counterparties.createReport(data, token));
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PortfolioDetails);

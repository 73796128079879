const initialState = {data: {}, isLoading: true};

 export default function stratificationfeatures(state=initialState, action) {

  switch(action.type) {

    case 'FETCH_STRATIFICATION_FEATURES_SUCCESS':
      return {data: action.stratificationfeatures, isLoading: false};

    case 'FETCH_STRATIFICATION_FEATURES_FAILURE':
      return {data: action.stratificationfeatures, isLoading: false};

    default:
      return state;
  }
}

const initialStateAssets = {data: {}, isLoading: true};
const initialStateAssetCharacteristics = {data: {}, isLoading: true};

export function assets(state = initialStateAssets, action) {
  switch(action.type) {
    case 'FETCH_ASSETS_SUCCESS':
      return {data: action.assets, isLoading: false};
    case 'FETCH_ASSETS_FAILURE':
      return {data: action.assets, error: true, error_type: action.error_type, isLoading: false};
    default:
      return state;
  }
}

export function assetCharacteristics(state = initialStateAssetCharacteristics, action) {
  switch(action.type) {
    case 'FETCH_ASSETS_SUCCESS':
      return {data: action.asset_characteristics, isLoading: false};
    case 'FETCH_ASSETS_FAILURE':
      return {data: action.asset_characteristics, error: true, error_type: action.error_type, isLoading: false};
    case 'FETCH_ASSET_CHARACTERISTICS_SUCCESS':
      return {data: action.asset_characteristics, isLoading: false};
    case 'FETCH_ASSET_CHARACTERISTICS_FAILURE':
      return {data: action.asset_characteristics, error: true, error_type: action.error_type, isLoading: false};
    default:
      return state;
  }
}

export function allAssetMTMValues(state = initialStateAssetCharacteristics, action) {
  switch(action.type) {
    case 'FETCH_ASSETS_MTM_VALUES_SUCCESS':
      return {data: action.assets, isLoading: false};
    case 'FETCH_ASSETS_MTM_VALUES_FAILURE':
      return {data: action.assets, error: true, error_type: action.error_type, isLoading: false};
    default:
      return state;
  }
}

export function alternativeAllAssetMTMValues(state = initialStateAssetCharacteristics, action) {
  switch(action.type){
    case 'FETCH_ALTERNATIVE_ASSETS_MTM_VALUES_SUCCESS':
      return {data: action.assets, isLoading: false};
    case 'FETCH_ALTERNATIVE_ASSETS_MTM_VALUES_FAILURE':
      return {data: action.assets, error: true, error_type: action.error_type, isLoading: false};
    default:
      return state;
  }
}

import React from 'react'
import { connect } from 'react-redux';
import Dropzone from 'react-dropzone'
import HeatMap from "react-heatmap-grid";
import FormSelectName from "./FormSelectName";
import { inputs, selections } from "../actions";
import Header from "../components/Header";
import { Card } from 'react-bootstrap'
import Notification from "../components/Notification"
import { createLoadingSelector, createErrorMessageSelector, createAddMessage } from '../components/Helpers'
import FormSelectOneDropDown from './FormSelectOneDropDown'

// data['factorLoadingsValues'] to FactorLoadingsViewSet

const baseStyle = {
  width: 200,
  height: 200,
  borderWidth: 2,
  borderColor: '#666',
  borderStyle: 'dashed',
  borderRadius: 5,
  paddingTop: 10,
  paddingLeft: 10
};

const activeStyle = {
  borderStyle: 'solid',
  borderColor: '#6c6',
  backgroundColor: '#eee'
};

const rejectStyle = {
  borderStyle: 'solid',
  borderColor: '#c66',
  backgroundColor: '#eee'
};

function mapInputsToPortfolioConstituents(inputs, input_counterparty_list, state_port_constituents) {
  var mapped_data = new Array(state_port_constituents.length).fill(new Array(16));
  state_port_constituents.map(function(value, index, arr) {
    mapped_data[index] = inputs[input_counterparty_list.indexOf(value)]
  })
  return mapped_data
}

class FactorLoadingsUpload extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      files: [],
      heatmap_data: [],
      factorLoadingsMatrixName: null,
      dataSetID: null,
      portfolioCounterparties: [],
      selectedPortfolio: null,
      selectedPortfolioName: null,
      input_counterparty_list: null,
      xLabels: [
        "Global Factor 1",
        "Global Factor 2",
        "Europe",
        "Americas",
        "Japan",
        "Asia",
        "Oceania",
        "Interest Sensitivity",
        "Extraction",
        "Consumer Non-Durables",
        "Consumer Durables",
        "Materials Processing",
        "Technology",
        "Medical Technology",
        "Miscellaneous",
        "R2"
      ],
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.saveFactorLoadingsMatrix = this.saveFactorLoadingsMatrix.bind(this);
    this.saveRSQStructure = this.saveRSQStructure.bind(this);
    this.saveFactorLoadingsMatrixName = this.saveFactorLoadingsMatrixName.bind(this);
    this.onDrop = this.onDrop.bind(this);
  }

  saveSettings = (value) => {
    if(value.selectedItemIndex !== undefined){
      const dataSetID = this.props.selections.data[value.selectedItemIndex].dataSetID
      const selectionID = this.props.selections.data[value.selectedItemIndex].selectionID
      const selectionName = this.props.selections.data[value.selectedItemIndex].selectionName
      const portfolioTransactions = this.props.selections.data[value.selectedItemIndex].selectionTransactions
      const portfolioCounterparties = this.props.fetchPortfolioCounterparties(selectionID, sessionStorage.getItem('jwtToken'))
      this.setState({
        dataSetID: dataSetID,
        selectedPortfolio: selectionID,
        selectedPortfolioName: selectionName,
        portfolioTransactions: portfolioTransactions,
        portfolioCounterparties: portfolioCounterparties,
        heatmap_data: [],
      })
    }
  }

  saveFactorLoadingsMatrix = () => {
    let values = this.state.heatmap_data
    var data_structure = []
    let index = 0
    values.forEach((element) => {
      for(var factor = 0; factor < 15; factor++){
        var obj = {
          "counterpartyID": this.props.portfolioCounterparties.data[index],
          "datasetID": this.state.dataSetID,
          "jobID": 1,
          "portfolioID": this.state.selectionPortfolio,
          "counterpartyWWid": this.props.portfolioCounterparties.data[index],
          "factor_number": factor + 1,
          "factor_loading": this.state.heatmap_data[0][factor],
          "factor_name": this.state.xLabels[factor]
        }
        data_structure.push(obj)
      }
      index += 1
    });
    return data_structure
  }

  saveRSQStructure = () => {
    let values = this.state.heatmap_data
    var data_structure = []
    let index = 0
    values.forEach((element) => {
      var obj = {
        "counterpartyID": this.props.portfolioCounterparties.data[index],
        "datasetID": this.state.dataSetID,
        "jobID": 1,
        "portfolioID": this.state.selectedPortfolio,
        "counterpartyWWid": this.props.portfolioCounterparties.data[index],
        "rsq_value": values[index][15]
      }
      index += 1
      data_structure.push(obj)
    });
    return data_structure
  }

  handleSubmit = (event) => {
    event.preventDefault();
    let data = {}
    data.factorLoadingsValues = this.saveFactorLoadingsMatrix()
    data.factorLoadingsMatrixName = this.state.factorLoadingsMatrixName
    let rsq_data = this.saveRSQStructure()
    this.props.addFactorLoadings(data, sessionStorage.getItem('jwtToken'))
    this.props.addRSQs(rsq_data, sessionStorage.getItem('jwtToken'))
  }

  // Switch to papaparse
  onDrop = (files) => {
    this.setState({
      files: files.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      }))
    });
    const reader = new FileReader()
    let react_var = this;
    reader.onload = function () {
      const lines = reader.result.split('\n').map((line) => {
        return line.split(';').map(item => parseFloat(item))
      })
      // Remove top line
      lines.splice(0, 1)
      // new lines[0] includes WWid, 15 factors, r2
      let input_counterparty_list = lines.map(function(value,index) { return value[0]; })
      input_counterparty_list.pop()

      lines.forEach(function(val,index){
        val.shift()
      })
      var mapped_data_return = mapInputsToPortfolioConstituents(lines, input_counterparty_list, react_var.props.portfolioCounterparties.data)
      react_var.setState((state, props) => ({
        heatmap_data: mapped_data_return,
        input_counterparty_list: input_counterparty_list,
      }));
    }
    reader.readAsText(files[0])
  }

  saveFactorLoadingsMatrixName = (value) => {
    this.setState({
      factorLoadingsMatrixName: value.inputName,
    })
  }

  componentDidMount(){
    if(this.props.selections.isLoading === true){
      this.props.fetchSelections(sessionStorage.getItem('jwtToken'))
    }
  }

  componentWillUnmount() {
    // Make sure to revoke the data uris to avoid memory leaks
    this.state.files.forEach(file => URL.revokeObjectURL(file.preview))
  }

  render() {
    if (this.props.selections.isLoading === true) {
      return (
        <div>
          <Header title={"Portfolio Overview"} />
          <Notification heading={'Settings'} body={'Loading Data'}/>
        </div>
      )
    }

    const {files} = this.state;

    let yLabels = new Array(2).fill(0).map((_, i) => `${i + 1}`);
    if (this.props.portfolioCounterparties !== undefined && this.props.portfolioCounterparties.data.length > 0){
      yLabels = this.props.portfolioCounterparties.data;
    }
    // Data is presented in a columnar fashion! no [row][column] but [column][row]
    let data = new Array(yLabels.length)
      .fill(0)
      .map(() => new Array(this.state.xLabels.length).fill(0));

    if (this.state.heatmap_data.length > 0){
      data = this.state.heatmap_data;
    };

    if(this.props.selections.data.length === 0){
      return (<div>No selections</div>);
    }

    return (
      <div>
        <div>
        <Card className="mt-4">
          <Card.Header>Factor Loadings Upload</Card.Header>
          <Card.Body>
            <Dropzone accept="text/csv, application/vnd.ms-excel" onDrop={this.onDrop}>
              {({ getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject, acceptedFiles, rejectedFiles }) => {
                let styles = {...baseStyle}
                styles = isDragActive ? {...styles, ...activeStyle} : styles
                styles = isDragReject ? {...styles, ...rejectStyle} : styles

                return (
                  <div {...getRootProps()} style={styles} >
                    <input {...getInputProps()} />
                    <div>
                      {isDragAccept ? 'Drop' : 'Drag'} files here...
                    </div>
                    {isDragReject && <div>Please provide CSV files</div>}
                  </div>
                )
              }}
            </Dropzone>

            <FormSelectOneDropDown onChange={this.saveSettings} itemLabel={'Portfolio Name'} itemName={this.props.selections.data.map(k => k.selectionName)} currentlySelectedItemName="" />
            <FormSelectName onChange={this.saveFactorLoadingsMatrixName} inputName='FactorLoadingsName'/>
            <form className="form-inline" onSubmit={this.handleSubmit}>
              <div className="form-group">
                <input type="submit"></input>
              </div>
            </form>
          </Card.Body>
        </Card>
        </div>

        { this.state.heatmap_data.length > 0 ? (
        <Card className="mt-4">
          <Card.Header>File Preview</Card.Header>
          <Card.Body>
            <HeatMap
              xLabels={this.state.xLabels}
              yLabels={yLabels}
              xLabelWidth={200}
              data={data}
              squares
              onClick={(x, y) => alert(`Clicked`)}
              cellStyle={(background, value, min, max, data, x, y) => ({
                background: `rgb(66, 86, 244, ${1 - (max - value) / (max - min)})`,
                fontSize: "11px",
              })}
              cellRender={value => `${value}`}
            />
          </Card.Body>
        </Card>
      ) : ( <br /> ) }
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    authentication: state.authentication,
    selectedPortfolio: state.selectedPortfolio,
    selections: state.selections,
    selectedPortfolioName: null,
    portfolioCounterparties: state.portfolioCounterparties,
    FactorLoadingsMatrix: state.factorLoadingsMatrix,
    loading: state.loading,
    error: state.error,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    addFactorLoadings: (data, token) => {
      return dispatch(inputs.addFactorLoadings(data, token));
    },
    addRSQs: (data, token) => {
      return dispatch(inputs.addRSQs(data, token));
    },
    fetchSelections: (id, token) => {
      return dispatch(selections.fetchSelections(id, token));
    },
    fetchPortfolioCounterparties: (id, token) => {
      return dispatch(selections.fetchPortfolioCounterparties(id, token));
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FactorLoadingsUpload);

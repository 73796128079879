function handleErrors(response) {
    if (!response.ok) {
        throw Error(response.statusText);
    }
    return response;
}

export const addTransitionMatrix = (text, token) => {
  return dispatch => {
    dispatch({ type: 'ADD_MATRIX_REQUEST' });
    const headers = {"Content-Type": "application/json", "Authorization": "JWT " + token};
    let body = JSON.stringify(text)
    return fetch(process.env.REACT_APP_HTTP_PROTOCOL + "://" + process.env.REACT_APP_API_IP + "/api/inputs/transitionmatrix/", {headers, method: "POST", body})
      .then(handleErrors)
      .then(res => res.json())
      .then(transition_matrix => {
        return dispatch({
          type: 'ADD_TRANSITION_MATRIX_SUCCESS',
          transition_matrix
        })
      }).catch(err => console.log(err))
  }
}

export const deleteTransitionMatrix = (id, token) => {
  return (dispatch, getState) => {
    const headers = {"Content-Type": "application/json", "Authorization": "JWT " + token};
    let body = JSON.stringify(id);
    return fetch(process.env.REACT_APP_HTTP_PROTOCOL + "://" + process.env.REACT_APP_API_IP + "/api/inputs/transitionmatrix/" + id + "/", {headers, method: "DELETE", body})
      .then(handleErrors)
      .then(res => {
        if (res.ok) {
          return dispatch({
            type: 'DELETE_TRANSITION_MATRIX_SUCCESS',
            id
          })
        }
      })
  }
}

export const addFactorGroup = (text, token) => {
  return dispatch => {
    dispatch({ type: 'ADD_FACTOR_GROUP_REQUEST' });
    const headers = {"Content-Type": "application/json", "Authorization": "JWT " + token};
    let body = JSON.stringify(text)
    return fetch(process.env.REACT_APP_HTTP_PROTOCOL + "://" + process.env.REACT_APP_API_IP + "/api/inputs/factorgroups/", {headers, method: "POST", body})
      .then(handleErrors)
      .then(data => {
        return dispatch({
          type: 'ADD_FACTOR_GROUP_SUCCESS',
          data
        })
      }).catch(err => console.log(err))
  }
}

export const deleteFactorOverview = (id, token) => {
  return (dispatch, getState) => {
    const headers = {"Content-Type": "application/json", "Authorization": "JWT " + token};
    let body = JSON.stringify(id);
    return fetch(process.env.REACT_APP_HTTP_PROTOCOL + "://" + process.env.REACT_APP_API_IP + "/api/inputs/factoroverviews/" + id + "/", {headers, method: "DELETE", body})
      .then(handleErrors)
      .then(res => {
        if (res.ok) {
          return dispatch({
            type: 'DELETE_FACTOR_OVERVIEW_SUCCESS',
            id
          })
        }
      })
  }
}

export const addFirmFundamentals = (data, token) => {
  return dispatch => {
    dispatch({ type: 'ADD_FIRM_FUNDAMENTALS_REQUEST' });
    const headers = {"Content-Type": "application/json", "Authorization": "JWT " + token};
    let body = JSON.stringify(data)
    return fetch(process.env.REACT_APP_HTTP_PROTOCOL + "://" + process.env.REACT_APP_API_IP + "/api/inputs/firmfundamentals/", {headers, method: "POST", body})
      .then(handleErrors)
      .then(data => {
        return dispatch({
          type: 'ADD_FIRM_FUNDAMENTALS_SUCCESS',
          data
        })
      }).catch(err => console.log(err))
  }
}

export const addCounterpartyLoadings = (text, token) => {
  return dispatch => {
    dispatch({ type: 'ADD_COUNTERPARTY_LOADINGS_REQUEST' });
    const headers = {"Content-Type": "application/json", "Authorization": "JWT " + token};
    let body = JSON.stringify(text)
    return fetch(process.env.REACT_APP_HTTP_PROTOCOL + "://" + process.env.REACT_APP_API_IP + "/api/inputs/counterpartyloadings/", {headers, method: "POST", body})
    .then(handleErrors)
    .then(data => {
      return dispatch({
        type: 'ADD_COUNTERPARTY_LOADINGS_SUCCESS',
        data
      })
    }).catch(err => console.log(err))
  }
}

export const addFactorLoadings = (text, token) => {
  return dispatch => {
    dispatch({ type: 'ADD_FACTOR_LOADINGS_REQUEST' });
    const headers = {"Content-Type": "application/json", "Authorization": "JWT " + token};
    let body = JSON.stringify(text)
    return fetch(process.env.REACT_APP_HTTP_PROTOCOL + "://" + process.env.REACT_APP_API_IP + "/api/inputs/factorloadings/", {headers, method: "POST", body})
    .then(handleErrors)
    .then(data => {
      return dispatch({
        type: 'ADD_FACTOR_LOADINGS_SUCCESS',
        data
      })
    }).catch(err => console.log(err))
  }
}

export const fetchTransitionMatrices = (token) => {
  return dispatch => {
    dispatch({ type: 'FETCH_TRANSITION_MATRICES_REQUEST' });
    let headers = {"Content-Type": "application/json", "Authorization": "JWT " + token};
    return fetch(process.env.REACT_APP_HTTP_PROTOCOL + "://" + process.env.REACT_APP_API_IP + "/api/inputs/transitionmatrix/", {headers, })
      .then(handleErrors)
      .then(res => res.json())
      .then(transition_matrices => {
        return dispatch({
          type: 'FETCH_TRANSITION_MATRICES_SUCCESS',
          transition_matrices
        })
      })
      .catch(error => {
        return dispatch({ type: 'FETCH_TRANSITION_MATRICES_FAILURE', error_type: error, error: true })
      })
  }
}

export const fetchFactorLoadings = (id, token) => {
  return dispatch => {
    dispatch({ type: 'FETCH_FACTOR_LOADINGS_REQUEST' });
    const headers = {"Content-Type": "application/json", "Authorization": "JWT " + token};
    return fetch(process.env.REACT_APP_HTTP_PROTOCOL + "://" + process.env.REACT_APP_API_IP + "/api/inputs/factorloadings/" + id + "/", {headers, })
      .then(handleErrors)
      .then(res => res.json())
      .then(factorloadings => {
        return dispatch({
          type: 'FETCH_FACTOR_LOADINGS_SUCCESS',
          factorloadings
        })
      })
      .catch(err => {
        return dispatch({ type: 'FETCH_FACTOR_LOADINGS_FAILURE', error: true })
      })
  }
}

export const fetchFactorOverviews = (token) => {
  return dispatch => {
    dispatch({ type: 'FETCH_FACTOR_OVERVIEWS_REQUEST' });
    const headers = {"Content-Type": "application/json", "Authorization": "JWT " + token};
    return fetch(process.env.REACT_APP_HTTP_PROTOCOL + "://" + process.env.REACT_APP_API_IP + "/api/inputs/factoroverviews/", {headers, })
      .then(handleErrors)
      .then(res => res.json())
      .then(factorOverviews => {
        return dispatch({
          type: 'FETCH_FACTOR_OVERVIEWS_SUCCESS',
          factorOverviews
        })
      })
      .catch(err => {
        return dispatch({ type: 'FETCH_FACTOR_OVERVIEWS_FAILURE', error: true })
      })
  }
}

export const fetchRiskFreeCurves = (token) => {
  return dispatch => {
    dispatch({ type: 'FETCH_CURVESETS_REQUEST' });
    const headers = {"Content-Type": "application/json", "Authorization": "JWT " + token};
    return fetch(process.env.REACT_APP_HTTP_PROTOCOL + "://" + process.env.REACT_APP_API_IP + "/api/inputs/riskfreecurves/", {headers, })
      .then(handleErrors)
      .then(res => res.json())
      .then(riskFreeCurve => {
        return dispatch({
          type: 'FETCH_RISK_FREE_CURVES_SUCCESS',
          riskFreeCurve
        })
      })
      .catch(err => {
        return dispatch({ type: 'FETCH_RISK_FREE_CURVES_FAILURES', error: true })
      })
  }
}

export const deleteRiskFreeCurveGroup = (id, token) => {
  return (dispatch, getState) => {
    const headers = {"Content-Type": "application/json", "Authorization": "JWT " + token};
    let body = JSON.stringify(id);
    return fetch(process.env.REACT_APP_HTTP_PROTOCOL + "://" + process.env.REACT_APP_API_IP + "/api/inputs/riskfreecurves/" + id + "/", {headers, method: "DELETE", body})
      .then(handleErrors)
      .then(res => {
        if (res.ok) {
          return dispatch({
            type: 'DELETE_RISK_FREE_CURVE_GROUP_SUCCESS',
            id
          })
        }
      })
  }
}

export const addRSQs = (text, token) => {
  return dispatch => {
    dispatch({ type: 'ADD_RSQS_REQUEST' });
    const headers = {"Content-Type": "application/json", "Authorization": "JWT " + token};
    let body = JSON.stringify(text)
    return fetch(process.env.REACT_APP_HTTP_PROTOCOL + "://" + process.env.REACT_APP_API_IP + "/api/inputs/rsqs/", {headers, method: "POST", body})
      .then(handleErrors)
      .then(data => {
        return dispatch({
          type: 'ADD_RSQS_SUCCESS',
          data
        })
      })
      .catch(err => {
        return dispatch({ type: 'ADD_RSQ_FAILURE', error: true })
      })
  }
}

export const addRiskFreeCurve = (text, token) => {
  return dispatch => {
    dispatch({ type: 'ADD_RISK_FREE_CURVE_REQUEST' });
    const headers = {"Content-Type": "application/json", "Authorization": "JWT " + token};
    let body = JSON.stringify(text)
    return fetch(process.env.REACT_APP_HTTP_PROTOCOL + "://" + process.env.REACT_APP_API_IP + "/api/inputs/riskfreecurves/", {headers, method: "POST", body})
      .then(handleErrors)
      .then(data => {
        return dispatch({
          type: 'ADD_RISK_FREE_CURVE_SUCCESS',
          text
        })
      }).catch(error => {
          return dispatch({ type: 'ADD_RISK_FREE_CURVE_FAILURE', error_type: error, error: true })
      })
  }
}

export const addRedemptionProfile = (text, token) => {
  return dispatch => {
    dispatch({ type: 'ADD_REDEMPTION_PROFILES_REQUEST' });
    const headers = {"Content-Type": "application/json", "Authorization": "JWT " + token};
    console.log(text)
    let body = JSON.stringify(text)
    return fetch(process.env.REACT_APP_HTTP_PROTOCOL + "://" + process.env.REACT_APP_API_IP + "/api/inputs/redemptionprofiles/", {headers, method: "POST", body})
      .then(handleErrors)
      .then(data => {
        return dispatch({
          type: 'ADD_REDEMPTION_PROFILES_SUCCESS',
          data
        })
      }).catch(error => {
          return dispatch({ type: 'ADD_REDEMPTION_PROFILES_FAILURE', error_type: error, error: true })
      })
  }
}

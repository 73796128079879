// Import React & Redux components
import React from "react";
import { connect } from 'react-redux';
import { Card, Form } from 'react-bootstrap'

// Import layout and helper functions/templates
import Header from "../components/Header";
import Notification from "../components/Notification"
import SimpleTable from './SimpleTable'
import { createLoadingSelector, createErrorMessageSelector } from '../components/Helpers'

// Import data sources
import { counterparties } from "../actions";

class BenchmarkComposition extends React.Component {
  constructor(props){
    super(props)
    let target_year = new Date()
    target_year.setFullYear(target_year.getFullYear() - 1)
    target_year = target_year.getFullYear()
    this.state = {
      counterparties: [],
      counterpartyTicker: null,
      counterpartyName: null,
      counterpartyIndustry: null,
      counterpartySector: null,
      comparisonMetric: 'profitMargin',
      evaluationYear: target_year,
    }
    this.evaluationYear = React.createRef();
    this.saveEvaluationYear = this.saveEvaluationYear.bind(this);
    this.saveComparisonMetric = this.saveComparisonMetric.bind(this);
  }

  componentDidMount() {
    this.props.fetchCounterparties(sessionStorage.getItem('jwtToken'));
  }

  saveSettings = (value) => {
    this.setState({
      counterpartyTicker: this.props.counterparties.data.filter(elem => {return elem.firmName === this.refs.counterparty.value}),
      counterpartyName: this.refs.counterparty.value,
    })
    this.props.fetchCounterpartyData(this.refs.counterparty.value, sessionStorage.getItem('jwtToken'))
    // change ticker back to firmName!
    //let elem = this.props.counterparties.data.filter(elem => {if(elem.firmName === this.refs.counterparty.value) return elem.industry;})[0]
    let elem = this.props.counterparties.data.filter(elem => {return elem.ticker === this.refs.counterparty.value})[0]
    let industry = elem.industry
    this.props.fetchIndustryBenchmarkData(industry, this.state.comparisonMetric, sessionStorage.getItem('jwtToken'))
  }

  saveEvaluationYear = () => {
    this.setState({
      evaluationYear: parseInt(this.evaluationYear.value)
    })
  }

  saveComparisonMetric = () => {
    this.setState({
      comparisonMetric: this.refs.comparisonMetric.value
    })
    this.props.fetchIndustryBenchmarkData(this.props.counterpartydata.data.counterparty.industry, this.refs.comparisonMetric.value, sessionStorage.getItem('jwtToken'))
  }

  render(){
    if (this.props.counterparties.isLoading === true) {
      return (
        <div>
          <Header title={"Benchmark Composition"} />
          <Notification heading={'Settings'} body={'Loading Data'}/>
        </div>
      )
    }

    const renderBenchmarkTable = () => {
      let filtered_data = this.props.industrybenchmarkdata.data.filter(elem => elem.year === this.state.evaluationYear)
      if(filtered_data.length > 0) {
        return (
          <SimpleTable
            data={filtered_data[0].portfolio_weights}
            sortColumn='weighting'
            sortDescendingOrder={true}
            columns={[
              {accessor: "ticker", Header: "Ticker"},
              {accessor: "ticker", Header: "Ticker"},
              {accessor: "weighting", Header: "Weight", Cell: row => (
                <div style={{ textAlign: "right" }}>{Math.round(row.value*1000,2)/10}%</div>
              )}
            ]}
          />
        )
      }
    }

    const renderFirmSelection = this.props.counterparties.isLoading !== true && this.props.counterparties.data !== undefined;
    const renderComparisonYear = this.props.counterpartydata.isLoading !== true;
    const renderComparisonMetric = this.props.counterpartydata.isLoading !== true;
    const renderDataTable = this.props.industrybenchmarkdata.isLoading !== true;

    return(
      <div>
      <Header title={"Benchmark Composition"} />
      { renderFirmSelection && (
        <div>
          <Card className="mt-4">
            <Card.Header>Select a counterparty</Card.Header>
            <Card.Body>
            <Form noValidate onSubmit={this.saveSettings}>
              <Form.Group controlId="counterpartyInsights.counterparty">
                <Form.Label>Counterparty</Form.Label>
                <Form.Control as="select" ref="counterparty" defaultValue={this.state.comparisonMetric} onChange={this.saveSettings}>
                  { this.props.counterparties.data.map((k, index) => {
                      return <option key={index} value={k.ticker}>{k.name}</option>
                    })
                  }
                </Form.Control>
              </Form.Group>
            </Form>
            </Card.Body>
          </Card>
        </div>
      )}
      { renderComparisonYear && (
          <div>
            <Card className="mt-4">
              <Card.Header>Select a comparison year</Card.Header>
              <Card.Body>
                <Form noValidate>
                  <Form.Group controlId="counterpartyInsights.evaluationYear">
                    <Form.Label>Evaluation year</Form.Label>
                    <Form.Control
                      as="select"
                      ref={select => this.evaluationYear = select}
                      defaultValue={this.props.counterpartydata.data.ratios.leverage[this.props.counterpartydata.data.ratios.leverage.length - 1].year}
                      onChange={this.saveEvaluationYear}>
                      { this.props.counterpartydata.data.ratios.leverage.map((k, index) => {
                          return <option key={index} value={k.year}>{k.year}</option>
                        })
                      }
                    </Form.Control>
                  </Form.Group>
                </Form>
              </Card.Body>
            </Card>
          </div>
      )}

      { renderComparisonMetric && (
        <div>
          <Card className="mt-4">
            <Card.Header>Comparison Metric</Card.Header>
            <Card.Body>
              <Form noValidate>
                <Form.Group controlId="counterpartyInsights.evaluationYear">
                  <Form.Label>Comparison metric</Form.Label>
                  <Form.Control as="select" ref="comparisonMetric" defaultValue={this.state.comparisonMetric} onChange={this.saveComparisonMetric}>
                    { Object.keys(this.props.counterpartydata.data.ratios).map((k, index) => {
                        return <option key={index} value={k}>{k}</option>
                      })
                    }
                  </Form.Control>
                </Form.Group>
              </Form>
            </Card.Body>
          </Card>
        </div>
      )}

      { renderDataTable && (
        <div>
          <Card className="mt-4">
            <Card.Header>Comparison Metric</Card.Header>
            <Card.Body>
              {renderBenchmarkTable()}
            </Card.Body>
          </Card>
        </div>
      )}
      </div>
    );
  }
}

const loadingSelector = createLoadingSelector(['FETCH_COUNTERPARTIES', 'FETCH_COUNTERPARTY_DATA', 'FETCH_INDUSTRY_BENCHMARK_DATA']);
const errorSelector = createErrorMessageSelector(['FETCH_COUNTERPARTIES', 'FETCH_COUNTERPARTY_DATA', 'FETCH_INDUSTRY_BENCHMARK_DATA']);

const mapStateToProps = state => {
  return {
    authentication: state.authentication,
    industrybenchmarkdata: state.industrybenchmarkdata,
    counterparties: state.counterparties,
    counterpartydata: state.counterpartydata,
    isFetching: loadingSelector(state),
    isError: errorSelector(state),
    loading: state.loading,
    error: state.error,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    fetchCounterparties: (token) => {
      dispatch(counterparties.fetchCounterparties(token));
    },
    fetchCounterpartyData: (ticker, token) => {
      dispatch(counterparties.fetchCounterpartyData(ticker, token));
    },
    fetchIndustryBenchmarkData: (industry, ratio, token) => {
      dispatch(counterparties.fetchIndustryBenchmarkData(industry, ratio, token));
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BenchmarkComposition);

const initialState = {data: {}, isLoading: true};

 export default function users(state=initialState, action) {
  switch(action.type) {

    case 'ADD_USER_SUCCESS':
      state.data.push(action.user)
      return {data: state.data, last_added: true};

    case 'ADD_USER_FAILURE':
      return {data: action.error_type, last_added: false};

    case 'DELETE_USER_SUCCESS':
      let index = state.data.findIndex(elem => elem.id===action.id)
      let new_state = state.data.splice(index, 1);
      return {data: state.data, last_added: false};

    case 'DELETE_USER_FAILURE':
      return {data: action.error_type, last_added: false};

    case 'FETCH_USERS_SUCCESS':
      return {data: action.results_users, isLoading: false};

    case 'FETCH_USERS_FAILURE':
      return {data: action.transactions, isLoading: false};

    default:
      return state;
  }
}

import React from "react";

import FactorLoadingsUpload from "../components/FactorLoadingsUpload"
import { Card } from 'react-bootstrap';
import SimpleTable from '../components/SimpleTable'

// Import layout and helper functions/templates
import Header from "../components/Header";

class Heatmap extends React.Component{
  constructor() {
    super();
    this.state = {
      riskFreeCurve: null,
      curveSetName: null,
      riskFreeCurveSubmit: null,
      curveKeys: null,
    }
    this.factorGroupsOverview = this.factorGroupsOverview.bind(this);
  }

  factorGroupsOverview = () => {
    let factor_group_ids = [...new Set(this.props.riskFreeCurves.data.map(elem => {return elem.curveSetID}))]
    //risk_free_curve_group_ids.forEach(id => {
    ////  let entries = this.props.riskFreeCurves.data.filter(entry => id === entry.curveSetID)
    //  risk_free_curve_groups.push(entries[0])
    //})
    return (
      <SimpleTable
        data={factor_group_ids}
        sortColumn='curveSetID'
        sortDescendingOrder={false}
        columns={[
          {accessor: "curveSetID", Header: "Curve Set ID"},
          {accessor: "curveSetName", Header: "Curve Set Name"},
          {accessor: "curveTimepoint", Header: "Curve Timepoint"},
          this.trashcan_column,
        ]}
      />
    )
  }

  render() {
    return(
    <div>
      <Header title={"Upload new Factor Loadings Matrix"} />
      <FactorLoadingsUpload>
        {({getRootProps}) => <div {...getRootProps()} />}
      </FactorLoadingsUpload>
    </div>
    );
  }
}
export default Heatmap;

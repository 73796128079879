// Import React & Redux components
import React from 'react';
import { connect } from 'react-redux';
import { Button, Card, Form } from 'react-bootstrap';

// Import layout and helper functions/templates
import Chart from './Chart';
import Header from './Header';
import Notification from './Notification';
import { createLoadingSelector, createErrorMessageSelector, generateCSVFile } from './Helpers';

// Import data sources
import { assetCharacteristics, jobs, selections } from '../actions';

class ResultsAsset extends React.Component {
  constructor(props) {
    super(props);
    if (this.props.match === undefined || this.props.match.params.id === undefined) {
      this.state = {
        selectedTransactionID: null,
        selectedSelectionID: null,
        selectedJobID: null,
        renderHelp: false,
        helpText: undefined,
      };
    } else {
      this.state = {
        selectedTransactionID: this.props.match.params.id,
        renderHelp: false,
        helpText: undefined,
      };
    }
    this.saveJobID = this.saveJobID.bind(this);
    this.saveTransaction = this.saveTransaction.bind(this);
    this.renderHelp = this.renderHelp.bind(this);
  }

  // Force a url update to reflected the selected jobID and retrieve the data
  // for that jobID.
  saveJobID = () => {
    const { selectionID } = this.props.jobs.data.filter((job) => (
      parseInt(this.refs.jobID.value, 10) === job.jobID
    ))[0];
    this.setState({
      selectedTransactionID: null,
      selectedSelectionID: selectionID,
      selectedJobID: this.refs.jobID.value,
    });
    this.props.fetchSelection(selectionID, sessionStorage.getItem('jwtToken'));
    // this.props.fetchTransactions(this.refs.jobID.value, sessionStorage.getItem('jwtToken'))
  }

  saveTransaction = () => {
    this.setState({
      selectedTransactionID: this.refs.transactionID.value,
    });
    this.props.fetchAssetCharacteristics(this.state.selectedJobID, this.refs.transactionID.value, sessionStorage.getItem('jwtToken'));
  }

  renderHelp = (content) => {
    if (this.state.renderHelp) {
      this.setState({ renderHelp: false, helpText: undefined });
    } else {
      this.setState({ renderHelp: true, helpText: content });
    }
  }

  saveToExcel = (axislabels, dataserieslabels, dataseries) => {
    generateCSVFile(axislabels, dataserieslabels, dataseries);
    return false;
  }

  // Load the required data, check first if a jobID has been selected as this is
  // required by the API.
  componentDidMount() {
    this.props.fetchJobs(sessionStorage.getItem('jwtToken'));
  }

  componentDidUpdate() {
    if (this.state.selectedJobID !== this.props.selectedJobID) {
      this.setState({
        selectedTransactionID: null,
        selectedSelectionID: this.props.selectionID,
        selectedJobID: this.props.selectedJobID,
      });
      this.props.fetchSelection(this.props.selectionID, sessionStorage.getItem('jwtToken'));
    } else {
      if (this.state.selectedJobID === null && this.props.jobs.data.length > 0) {
        if (this.props.selectedJobID !== undefined && this.props.selectionID !== undefined) {
          this.setState({
            selectedTransactionID: null,
            selectedSelectionID: this.props.selectionID,
            selectedJobID: this.props.selectedJobID,
          });
          this.props.fetchSelection(this.props.selectionID, sessionStorage.getItem('jwtToken'));
        } else {
          this.setState({
            selectedTransactionID: null,
            selectedSelectionID: this.props.jobs.data[0].selectionID,
            selectedJobID: this.props.jobs.data[0].jobID,
          });
          this.props.fetchSelection(this.props.jobs.data[0].selectionID, sessionStorage.getItem('jwtToken'));
        }
      }

      if (this.props.loading.FETCH_SELECTIONS !== true
        && this.props.selections.data !== undefined
        && this.props.selections.data.length > 0
        && (this.state.selectedTransactionID === undefined
          || this.state.selectedTransactionID === null)
      ) {
        this.setState({
          selectedTransactionID: JSON.parse(this.props.selections.data[0].selectionTransactions)[0],
        });
        this.props.fetchAssetCharacteristics(this.state.selectedJobID, JSON.parse(this.props.selections.data[0].selectionTransactions)[0], sessionStorage.getItem('jwtToken'));
      }
    }
  }

  render() {
    const showLoadingScreen = this.props.jobs.isLoading === true;
    const showPageHeader = this.props.showPageHeader !== false;
    const showJobMenuSelection = this.props.showJobMenuSelection !== false;
    const showError = Object.keys(this.props.error).length > 0;
    const showJobSelection = this.props.jobs.isLoading !== true && this.props.jobs !== undefined;
    const showTransactionSelection = this.state.selectedJobID !== null
      && this.props.selections.isLoading !== true
      && this.props.selections !== undefined
      && this.props.selections.data.filter((elem) => (
        elem.selectionID === this.state.selectedSelectionID
      )).length > 0;
    const showProjectedCashflows = this.state.selectedTransactionID !== null
      && this.props.assetCharacteristics.isLoading !== true
      && this.props.assetCharacteristics.data !== undefined;
    const showProjectedInterestPayments = this.state.selectedTransactionID !== null
      && this.props.assetCharacteristics.isLoading !== true
      && this.props.assetCharacteristics.data !== undefined;
    const showProjectedRedemptionProfile = this.state.selectedTransactionID !== null
      && this.props.assetCharacteristics.isLoading !== true
      && this.props.assetCharacteristics.data !== undefined;

    return (
      <div>
      { showPageHeader && (
        <div><Header title={'Asset Analysis'} /></div>
      )}

      { showLoadingScreen && (
          <div>
            <Notification heading={'Single Asset Analysis'} body={'Loading Jobs'}/>
          </div>
      )}

      { showError && (
          <div>
            <Notification heading={'Single Asset Analysis'} body={'Error Loading Data'}/>
          </div>
      )}

      { this.state.renderHelp && (
        <div className="overlay-help" onClick={() => this.renderHelp(undefined)}>
          <div className="overlay-help-card">
            <Card className="mt-4">
              <Card.Header>
                <div className="fa-pull-left">{this.state.helpText.header}</div>
                <i className="fa fa-window-close fa-pull-right" onClick={() => this.renderHelp(undefined)}></i>
              </Card.Header>
              <Card.Body>{this.state.helpText.body}</Card.Body>
            </Card>
          </div>
        </div>
      )}

      { showJobSelection && (
        <div>
          <Card className="mt-4">
            <Card.Header>{ showJobMenuSelection ? 'Select a Job followed by Transaction' : 'Select a Transaction'}</Card.Header>
            <Card.Body>
              <Form noValidate>
                { showJobMenuSelection && (
                  <Form.Group controlId="resultsAsset.selectCombi">
                  <Form.Control as="select" ref="jobID" onChange={this.saveJobID}>
                    { this.props.jobs.data.map((k, index) => (
                      <option key={index} value={k.jobID}>{k.jobName}</option>
                    ))}
                  </Form.Control>
                  </Form.Group>
                )}
                { showTransactionSelection && (
                  <Form.Group controlId="resultsAsset.selectCombi">
                  <Form.Control as="select" ref="transactionID" onChange={this.saveTransaction} value={this.state.selectedTransactionID}>
                  {
                    JSON.parse(this.props.selections.data
                      .filter((elem) => elem.selectionID === this.state.selectedSelectionID)[0]
                      .selectionTransactions).map((elem) => (
                        <option key={elem} value={elem}>{elem}</option>
                    ))
                  }
                  </Form.Control>
                  </Form.Group>
                )}
              </Form>
            </Card.Body>
          </Card>
        </div>
      )}

      { showProjectedCashflows && (
        <Card className="mt-4">
          <Card.Header className="card-header-with-btn">
            <div className="pull-left">Projected Cashflow Profile</div>
            <div className="fa-pull-right">
              <Button
                className="fa-icon"
                variant="secondary"
                size="sm"
                onClick={() => (
                  this.saveToExcel(JSON.parse(this.props.assetCharacteristics.data.periodVector).data.map((date) => new Date(date * 1000).toLocaleString('nl-NL', {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                    timeZone: 'UTC',
                  })), ['Projected_cashflows'], JSON.parse(this.props.assetCharacteristics.data.cashFlows).data)
                )}>
                  <span className="fa fa-save"></span>
                  <span> Export</span>
              </Button>
              <span
                className="ml-3 question-mark"
                onClick={() => this.renderHelp({
                  header: 'Projected Cashflow Profile',
                  body: 'Cumulative cashflows of the asset, taking into account all cash inflows (net interest payments, repayments), assuming no defaults.',
                })}
              ></span>
            </div>
          </Card.Header>
          <Card.Body>
            <Chart
              type={'Bar'}
              unit={'EUR'}
              xaxislabels={JSON.parse(this.props.assetCharacteristics.data.periodVector).data.map((date) => new Date(date * 1000).toLocaleString('nl-NL', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                timeZone: 'UTC',
              }))}
              dataseries={[JSON.parse(this.props.assetCharacteristics.data.cashFlows).data]}
              dataserieslabels={['Projected Cashflows']}
            />
          </Card.Body>
        </Card>
      )}

      { showProjectedInterestPayments && (
        <Card className="mt-4">
          <Card.Header className="card-header-with-btn">
            <div className="pull-left">Projected Interest Payments</div>
            <div className="fa-pull-right">
              <Button className="fa-icon" variant="secondary" size="sm" onClick={() => (
                this.saveToExcel(JSON.parse(this.props.assetCharacteristics.data.periodVector).data.map((date) => new Date(date * 1000).toLocaleString('nl-NL', {
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                  timeZone: 'UTC',
                })), ['Projected_interest_payments'], JSON.parse(this.props.assetCharacteristics.data.interestPayments).data)
              )}>
                <span className="fa fa-save"></span>
                <span> Export</span>
              </Button>
              <span
                className="ml-3 question-mark"
                onClick={() => this.renderHelp({
                  header: 'Projected Interest Payments',
                  body: 'Net interest income (interest income minus funding costs) for the asset, assuming no defaults.',
                })}
              ></span>
            </div>
          </Card.Header>
          <Card.Body>
            <Chart
              type={'Bar'}
              unit={'EUR'}
              xaxislabels={JSON.parse(this.props.assetCharacteristics.data.periodVector).data.map((date) => new Date(date * 1000).toLocaleString('nl-NL', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                timeZone: 'UTC',
              }))}
              dataseries={[JSON.parse(this.props.assetCharacteristics.data.interestPayments).data]}
              dataserieslabels={['Projected Interest Payments']}
            />
          </Card.Body>
        </Card>
      )}

      { showProjectedRedemptionProfile && (
        <Card className="mt-4">
          <Card.Header className="card-header-with-btn">
            <div className="pull-left">Projected Redemption Profile</div>
            <div className="fa-pull-right">
              <Button
                className="fa-icon"
                variant="secondary"
                size="sm"
                onClick={() => this.saveToExcel(JSON.parse(this.props.assetCharacteristics.data.periodVector).data.map((date) => new Date(date * 1000).toLocaleString('nl-NL', {
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                  timeZone: 'UTC',
                })), ['Projected_interest_payments'], JSON.parse(this.props.assetCharacteristics.data.redemptionProfile).data)
                }>
                <span className="fa fa-save"></span>
                <span> Export</span>
              </Button>
              <span
                className="ml-3 question-mark"
                onClick={() => this.renderHelp({
                  header: 'Projected Redemption Profile',
                  body: 'Total outstanding of the asset, this does not take potential defaults into account.',
                })}
              ></span>
            </div>
          </Card.Header>
          <Card.Body>
            <Chart
              type={'Bar'}
              unit={'EUR'}
              xaxislabels={JSON.parse(this.props.assetCharacteristics.data.periodVector).data.map((date) => new Date(date * 1000).toLocaleString('nl-NL', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                timeZone: 'UTC',
              }))}
              dataseries={[JSON.parse(this.props.assetCharacteristics.data.redemptionProfile).data]}
              dataserieslabels={['Projected Redemption Profile']}
            />
          </Card.Body>
        </Card>
      )}
      </div>
    );
  }
}

const loadingSelector = createLoadingSelector(['FETCH_SELECTIONS', 'FETCH_ASSET_CHARACTERISTICS']);
const errorSelector = createErrorMessageSelector(['FETCH_ASSET_CHARACTERISTICS']);

const mapStateToProps = (state) => (
  {
    authentication: state.authentication,
    assets: state.assets,
    assetCharacteristics: state.assetCharacteristics,
    isFetching: loadingSelector(state),
    isError: errorSelector(state),
    jobs: state.jobs,
    selections: state.selections,
    loading: state.loading,
    error: state.error,
  }
);

const mapDispatchToProps = (dispatch) => (
  {
    fetchJobs: (token) => {
      dispatch(jobs.fetchJobs(token));
    },
    fetchSelection: (id, token) => {
      dispatch(selections.fetchSelection(id, token));
    },
    fetchAssetCharacteristics: (jobid, id, token) => {
      dispatch(assetCharacteristics.fetchAssetCharacteristics(jobid, id, token));
    },
  }
);

export default connect(mapStateToProps, mapDispatchToProps)(ResultsAsset);

const initialState = {data: {}, isLoading: true};

 export default function datasets(state=initialState, action) {
  switch(action.type) {
    case 'ADD_DATASET_SUCCESS':
      return {data: [...state.data, action.dataset], lastAdded: true};

    case 'ADD_DATASETS_FAILURE':
      console.log(action)
      return {data: action.datasets, isLoading: false};

    case 'DELETE_DATASET_SUCCESS':
      let old_state = [...state.data]
	    let new_state = old_state.filter(elem => {return elem.dataSetID !== action.id})
      return {data: new_state, deleted: true};

    case 'DELETE_DATASETS_FAILURE':
      return {data: state.data, deleted: false};

    case 'FETCH_DATASETS_SUCCESS':
      return {data: action.datasets, isLoading: false};

    case 'FETCH_DATASETS_FAILURE':
      return {data: action.datasets, isLoading: false};

    default:
      return state;
  }
}

const initialState = {data: {}, isLoading: true};

export default function assetsPerformance(state=initialState, action) {
  switch(action.type) {

    case 'CREATE_XLSX_ASSETS_PERFORMANCE':
      return {data: action.assetsperformance, isLoading: false};

    case 'FETCH_ASSETS_PERFORMANCE_SUCCESS':
      return {data: action.assetsperformance, isLoading: false};

    case 'FETCH_ASSETS_PERFORMANCE_FAILURE':
      return {data: action.assetsperformance, error:true, isLoading: false};

    default:
      return state;
  }
}

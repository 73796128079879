const initialState = {data: [], isLoading: true};

export function jobs(state=initialState, action) {
  switch(action.type) {

    case 'ADD_JOB_SUCCESS':
      return {data: [...state.data, action.text], lastAdded: true};

    case 'ADD_JOB_FAILURE':
      return {data: action.job, error: action.error, error_text: action.error_message, lastAdded: false};

    case 'DELETE_JOB_SUCCESS':
      let new_state = [...state.data].filter(elem => {return elem.jobID !== action.id})
      return {data: new_state, deleted: true};

    case 'DELETE_JOB_IN_PROGRESS':
      let elem_to_change = [...state.data].filter(elem => {return elem.jobID === action.id})
      let index_elem = [...state.data].findIndex(elem => {return elem.jobID === action.id});
      elem_to_change[0].deleting = true;
      let altered_state = [...state.data]
      altered_state[index_elem] = elem_to_change[0]
      return {data: altered_state};

    case 'DELETE_JOB_FAILURE':
      return {data: action.job, error_type: action.error_type, error_text: action.error_message, error:true, deleted: false};

    case 'FETCH_JOBS_SUCCESS':
      return {data: action.jobs, isLoading: false};

    case 'FETCH_JOBS_FAILURE':
      return {data: [], error: true, isLoading: false};

    default:
      return state;
  }
}

export function comparableJobs(state=initialState, action) {
  switch(action.type) {
    case 'FETCH_COMPARABLE_JOBS_SUCCESS':
      return { data: action.comparable_jobs, isLoading: false };

    default:
      return state
  }
}

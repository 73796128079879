function handleErrors(response) {
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return response;
}

const TOKEN = 'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6InRlc3QiLCJ1c2VyZ3JvdXAiOjEsImlhdCI6MTU4MDcyMTY4NCwiZXhwIjoxNTg4NDk3Njg0LCJpc3MiOiJVYiBUZWNobm9sb2dpZXMiLCJzdWIiOiJjb3VudGVycGFydHlJbnNpZ2h0cyJ9.JTr_Zl1SFdbYGik8KNv6POIYdGzH4-QAO7BVWigfqzEWDEqmU9iXUEYzlQ8Yb-uKvZU5koUHJURE727fg1I_og'

export const fetchCounterparties = (token) => {
  return dispatch => {
    token = TOKEN
    const headers = {"Content-Type": "application/json", "Authorization": "Bearer " + token};
    dispatch({ type: 'FETCH_COUNTERPARTIES_REQUEST' });
    return fetch("https://" + process.env.REACT_APP_CP_INSIGHTS_API_IP + '/counterparties/', {headers, })
      .then(handleErrors)
      .then(res => res.json())
      .then(counterparties => {
        return dispatch({
          type: 'FETCH_COUNTERPARTIES_SUCCESS',
          counterparties
        })
      })
      .catch(error => {
        return dispatch({ type: 'FETCH_COUNTERPARTIES_FAILURE', error_type: error, error: true })
      })
  }
}

export const fetchCounterpartyData = (id, token) => {
  return dispatch => {
    token = TOKEN
    const headers = {"Content-Type": "application/json", "Authorization": "Bearer " + token};
    dispatch({ type: 'FETCH_COUNTERPARTY_DATA_REQUEST' });
    return fetch("https://" + process.env.REACT_APP_CP_INSIGHTS_API_IP + "/counterparties/" + id, {headers, })
    //return fetch("http://" + process.env.REACT_APP_API_IP + "/counterpartyinsightsAPI/clients/" + id, {headers, })
      .then(handleErrors)
      .then(res => res.json())
      .then(counterpartydata => {
        return dispatch({
          type: 'FETCH_COUNTERPARTY_DATA_SUCCESS',
          counterpartydata
        })
      })
      .catch(error => {
        return dispatch({ type: 'FETCH_COUNTERPARTY_DATA_FAILURE', error_type: error, error: true })
      })
  }
}

export const fetchIndustryData = (industry, token) => {
  return dispatch => {
    token = TOKEN
    const headers = {"Content-Type": "application/json", "Authorization": "Bearer " + token};
    dispatch({ type: 'FETCH_COUNTERPARTY_INDUSTRY_DATA_REQUEST' });
    return fetch("https://" + process.env.REACT_APP_CP_INSIGHTS_API_IP + "/counterparties/industryposition/" + industry + "/", {headers, })
    //return fetch("http://" + process.env.REACT_APP_API_IP + "/counterpartyinsightsAPI/clients/industryposition/" + industry + "/", {headers, })
      .then(handleErrors)
      .then(res => res.json())
      .then(industrydata => {
        return dispatch({
          type: 'FETCH_COUNTERPARTY_INDUSTRY_DATA_SUCCESS',
          industrydata
        })
      })
      .catch(error => {
        return dispatch({ type: 'FETCH_COUNTERPARTY_INDUSTRY_DATA_FAILURE', error_type: error, error: true })
      })
  }
}

export const fetchSectorData = (sector, token) => {
  return dispatch => {
    token = TOKEN
    const headers = {"Content-Type": "application/json", "Authorization": "Bearer " + token};
    dispatch({ type: 'FETCH_COUNTERPARTY_SECTOR_DATA_REQUEST' });
    return fetch("https://" + process.env.REACT_APP_CP_INSIGHTS_API_IP + "/counterparties/sectorposition/" + sector + "/", {headers, })
    //return fetch("http://" + process.env.REACT_APP_API_IP + "/counterpartyinsightsAPI/clients/sectorposition/" + sector + "/", {headers, })
      .then(handleErrors)
      .then(res => res.json())
      .then(sectordata => {
        return dispatch({
          type: 'FETCH_COUNTERPARTY_SECTOR_DATA_SUCCESS',
          sectordata
        })
      })
      .catch(error => {
        return dispatch({ type: 'FETCH_COUNTERPARTY_SECTOR_DATA_FAILURE', error_type: error, error: true })
      })
  }
}

export const fetchIndustryExposures = (year, token) => {
  return dispatch => {
    token = TOKEN
    const headers = {"Content-Type": "application/json", "Authorization": "Bearer " + token};
    dispatch({ type: 'FETCH_INDUSTRY_EXPOSURES_REQUEST' });
    return fetch("https://" + process.env.REACT_APP_CP_INSIGHTS_API_IP + "/counterparties/industryexposures/year/" + year + "/", {headers, })
    //return fetch("http://" + process.env.REACT_APP_API_IP + "/counterpartyinsightsAPI/clients/sectorposition/" + sector + "/", {headers, })
      .then(handleErrors)
      .then(res => res.json())
      .then(industryexposures => {
        return dispatch({
          type: 'FETCH_INDUSTRY_EXPOSURES_SUCCESS',
          industryexposures
        })
      })
      .catch(error => {
        return dispatch({ type: 'FETCH_INDUSTRY_EXPOSURES_FAILURE', error_type: error, error: true })
      })
  }
}

export const fetchCountryExposures = (year, token) => {
  return dispatch => {
    token = TOKEN
    const headers = {"Content-Type": "application/json", "Authorization": "Bearer " + token};
    dispatch({ type: 'FETCH_COUNTRY_EXPOSURES_REQUEST' });
    return fetch("https://" + process.env.REACT_APP_CP_INSIGHTS_API_IP + "/counterparties/countryexposures/year/" + year + "/", {headers, })
    //return fetch("http://" + process.env.REACT_APP_API_IP + "/counterpartyinsightsAPI/clients/sectorposition/" + sector + "/", {headers, })
      .then(handleErrors)
      .then(res => res.json())
      .then(countryexposures => {
        return dispatch({
          type: 'FETCH_COUNTRY_EXPOSURES_SUCCESS',
          countryexposures
        })
      })
      .catch(error => {
        return dispatch({ type: 'FETCH_COUNTRY_EXPOSURES_FAILURE', error_type: error, error: true })
      })
  }
}

export const fetchPortfolioOverview = (portfolio, token) => {
  return dispatch => {
    token = TOKEN
    const headers = {"Content-Type": "application/json", "Authorization": "Bearer " + token};
    dispatch({ type: 'FETCH_COUNTERPARTY_PORTFOLIO_OVERVIEW_REQUEST' });
    return fetch("https://insights.ubtechnologies.nl/counterparties/portfoliooverview/" + portfolio + "/", {headers, })
      .then(handleErrors)
      .then(res => res.json())
      .then(portfoliodata => {
        return dispatch({
          type: 'FETCH_COUNTERPARTY_PORTFOLIO_OVERVIEW_SUCCESS',
          portfoliodata
        })
      })
      .catch(error => {
        return dispatch({ type: 'FETCH_COUNTERPARTY_PORTFOLIO_OVERVIEW_FAILURE', error_type: error, error: true })
      })
  }
}

export const fetchIndustryBenchmarkData = (industry, ratio, token) => {
  return dispatch => {
    token = TOKEN
    const headers = {"Content-Type": "application/json", "Authorization": "Bearer " + token};
    dispatch({ type: 'FETCH_INDUSTRY_BENCHMARK_DATA_REQUEST' });
    return fetch("https://" + process.env.REACT_APP_CP_INSIGHTS_API_IP + "/counterparties/industrybenchmarkcomposition/industry/" + industry + "/ratio/" + ratio + "/", {headers, })
      .then(handleErrors)
      .then(res => res.json())
      .then(benchmark_data => {
        return dispatch({
          type: 'FETCH_INDUSTRY_BENCHMARK_DATA_SUCCESS',
          benchmark_data
        })
      })
      .catch(error => {
        return dispatch({ type: 'FETCH_INDUSTRY_BENCHMARK_DATA_FAILURE', error_type: error, error: true })
      })
  }
}

export const fetchReports = (token) => {
  return dispatch => {
    token = TOKEN
    const headers = {"Content-Type": "application/json", "Authorization": "Bearer " + token};
    dispatch({ type: 'FETCH_REPORTS_REQUEST' });
    return fetch("https://" + process.env.REACT_APP_CP_INSIGHTS_API_IP + "/reports/", {headers, })
      .then(handleErrors)
      .then(res => res.json())
      .then(reports => {
        return dispatch({
          type: 'FETCH_REPORTS_SUCCESS',
          reports
        })
      })
      .catch(error => {
        return dispatch({ type: 'FETCH_REPORTS_FAILURE', error_type: error, error: true })
      })
  }
}

export const createReport = (data, token) => {
  return dispatch => {
    //const headers = {"Content-Type": "application/json","Access-Control-Allow-Origin": "localhost:3000","Authorization": "Bearer " + 'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6InRlc3QiLCJ1c2VyZ3JvdXAiOjEsImlhdCI6MTU2NDA2Mjc3NywiZXhwIjoxNTcxODM4Nzc3LCJpc3MiOiJVYiBUZWNobm9sb2dpZXMiLCJzdWIiOiJjb3VudGVycGFydHlJbnNpZ2h0cyJ9.uTvivRU9XQqDDdy-8ZTKOxOIVGq3bYjEjOPPhkUl3IVoulYhiZC3P6oC1H4nVEoE2qDqo8zwZCG7V5zM6Uow1g'};
    token = TOKEN
    const headers = {"Content-Type": "application/json","Access-Control-Allow-Origin": "*","Authorization": "Bearer " + token};
    dispatch({ type: 'CREATE_REPORTS_REQUEST' });
    let body = JSON.stringify(data);
    return fetch("https://" + process.env.REACT_APP_CP_INSIGHTS_API_IP + "/report/generate", {headers, method: "POST", body })
      .then(handleErrors)
      .then(res => res.json())
      .then(report => {
        return dispatch({
          type: 'CREATE_REPORTS_SUCCESS',
          report
        })
      })
      .catch(error => {
        return dispatch({ type: 'CREATE_REPORTS_FAILURE', error_type: error, error: true })
      })
  }
}

// Import React & Redux components
import React from 'react'
import { connect } from 'react-redux'
import { Card } from 'react-bootstrap'

// Import layout and helper functions/templates
import Header from '../components/Header'
import Notification from "../components/Notification"
import { createLoadingSelector, createErrorMessageSelector, createAddMessage, findAssociatedValue } from './Helpers'
import SimpleTable from './SimpleTable'

// Import data sources
import { transactionActions, selections } from '../actions'

// Import dumb component
import FormSelectOneDropDown from './FormSelectOneDropDown'

class ViewPortfolioSelection extends React.Component {
  constructor(props) {
    super(props);
    if (this.props.match.params.id === undefined) {
      this.state = {
        portfolioID: null,
      }
    } else {
      this.state = {
        portfolioID : this.props.match.params.id,
      }
    }
    this.saveSettings = this.saveSettings.bind(this);
  }

  // Force a url update to reflected the selected dataSetID and retrieve the data
  // for that dataSetID.
  saveSettings = (value) => {
    if(value.selectedItemIndex !== undefined){
      this.props.history.push('/portfolioselections/' + this.props.selections.data[value.selectedItemIndex].selectionID)
      this.setState({
        selectionID : this.props.selections.data[value.selectedItemIndex].selectionID,
      })
      this.props.fetchTransactionsOfSelection(this.props.selections.data[value.selectedItemIndex].selectionID, sessionStorage.getItem('jwtToken'))
    }
  }

  saveSelectionName = (value) => {
    this.setState({
      portfolioID: value.inputName,
    })
  }

  componentDidMount() {
    this.props.fetchSelections(sessionStorage.getItem('jwtToken'));
  }

  render() {
    if (this.props.selections.isLoading === true) {
      return (
        <div>
          <Header title={"Portfolio Selection"} />
          <Notification heading={'Settings'} body={'Loading Data'}/>
        </div>
      )
    }

    if (this.props.isError !== null || this.props.selections === undefined || this.props.selections.data === undefined){
      return (
        <div>
          <Header title={"Portfolio Selection"} />
          <Notification heading={'Settings'} body={'Error Loading Data'}/>
        </div>
      )
    }

    if (this.state.transactions === null || this.props.transactions.isLoading === true){
      return(
        <div>
          <Header title={"Portfolio Selection"} />
          <Card className="mt-4">
            <Card.Header>Settings</Card.Header>
            <Card.Body>
              <FormSelectOneDropDown
                onChange={this.saveSettings}
                itemLabel={'Portfolio'}
                itemName={this.props.selections.data.map(k => k.selectionName)}
                currentlySelectedItemName=''
                inputName='Selection Name'
              />
            </Card.Body>
          </Card>
        </div>
      )
    }

    // Show a loading screen if not all sources have finished loading yet.
    if (this.props.selections.isLoading === true || this.props.transactions.isLoading === true) {
      return (
        <div>
          <Header title={"Portfolio Selection"} />
          <Notification heading={'Loading Data'} body={'Loading Data'}/>
        </div>
      )
    }

    // Show an error page when one of the sources did not load.
    if (this.props.isError !== null || this.props.selections.data === undefined || this.props.transactions.data === undefined) {
      return (
        <div>
          <Header title={"Portfolio Selection"} />
          <Notification heading={'Error Loading Data'} body={'Error Loading Data'}/>
        </div>
      )
    }

    return(
      <div>
        <Header title={"Portfolio Selection"} />
        <Card className="mt-4">
          <Card.Header>Settings</Card.Header>
          <Card.Body>
            <FormSelectOneDropDown
              onChange={this.saveSettings}
              itemLabel='Portfolio'
              itemName={this.props.selections.data.map(k => k.selectionName)}
              currentlySelectedItemName={findAssociatedValue(this.props.match.params.id, this.props.selections.data.map(k => k.selectionID), this.props.selections.data.map(k => k.selectionName))}
              inputName='Selection Name'
            />
          </Card.Body>
        </Card>

        <Card className="mt-4">
          <Card.Header>Transaction Selection</Card.Header>
          <Card.Body>
            <SimpleTable
              data={this.props.transactions.data.map(elem => {
                return elem.transactionID
              })}
              columns={[
                {accessor: "Reporting_Date", Header: "Reporting_Date"},
                {accessor: "UCI_Legal_Name", Header: "UCI_Legal_Name"},
                {accessor: "Global_Facility_ID", Header: "Global_Facility_ID"},
                {accessor: "Department", Header: "Department"},
                {accessor: "Branch", Header: "Branch"},
                {accessor: "NAICS", Header: "NAICS"},
                {accessor: "Rating", Header: "Rating"}
              ]}
            />
          </Card.Body>
        </Card>
      </div>
    );
  }
}

const loadingSelector = createLoadingSelector(['FETCH_SELECTIONS']);
const errorSelector = createErrorMessageSelector(['FETCH_SELECTIONS']);

const mapStateToProps = (state) => {
  return {
    selections: state.selections,
    transactions: state.transactions,
    selectionTransactions: state.selectionTransactions,
    selectionName: state.selectionName,
    isFetching: loadingSelector(state),
    isError: errorSelector(state),
    loading: state.loading,
    error: state.error,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchSelections: (token) => {
      dispatch(selections.fetchSelections(token));
    },
    fetchTransactionsOfSelection: (id, token) => {
      dispatch(transactionActions.fetchTransactionsOfSelection(id, token));
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewPortfolioSelection);

import React from "react";

import CreatePortfolioSelection from "../components/CreatePortfolioSelection"
import { Card } from 'react-bootstrap';

// Import layout and helper functions/templates
import Header from "../components/Header";

class PortfolioSelectionPage extends React.Component{
  constructor() {
    super();
    this.state = {
      riskFreeCurve: null,
      curveSetName: null,
      riskFreeCurveSubmit: null,
      curveKeys: null,
    }
    this.factorGroupsOverview = this.factorGroupsOverview.bind(this);
  }

  factorGroupsOverview = () => {
    let factor_group_ids = [...new Set(this.props.riskFreeCurves.data.map(elem => {return elem.curveSetID}))]
    //risk_free_curve_group_ids.forEach(id => {
    ////  let entries = this.props.riskFreeCurves.data.filter(entry => id === entry.curveSetID)
    //  risk_free_curve_groups.push(entries[0])
    //})
    return (
      <br />
    )
  }

  render() {
    return(
      <div>
        <Header title={"Select Portfolio"} subtitle={{ startText: "Here you can select the portfolio you want to analyse by either selecting portfolio that you already created \
in the past as shown in Existing Portfolios table and add transactions to it from Transaction Selection table, \
or do it from scratch selecting all the transactions you are interested in from Transaction Selection table. Don’t forget to name it before saving."}} />
        <CreatePortfolioSelection>
          {({getRootProps}) => <div {...getRootProps()} />}
        </CreatePortfolioSelection>
      </div>
    );
  }
}
export default PortfolioSelectionPage;

function handleErrors(response) {
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return response;
}

export const fetchTickerlists = (token) => {
  return dispatch => {
    const headers = {"Content-Type": "application/json", "Authorization": "Bearer " + token};
    dispatch({ type: 'FETCH_TICKERLISTS_REQUEST' });
    return fetch("https://" + process.env.REACT_APP_SCRAPER_CONTROL_IP + '/tickerlists', {headers, method: "GET"})
      .then(handleErrors)
      .then(res => res.json())
      .then(tickerlists => {
        return dispatch({
          type: 'FETCH_TICKERLISTS_SUCCESS',
          tickerlists
        })
      })
      .catch(error => {
        return dispatch({ type: 'FETCH_TICKERLISTS_FAILURE', error_type: error, error: true })
      })
  }
}

export const fetchScraperJobs = (text, token) => {
  return dispatch => {
    const headers = {"Content-Type": "application/json", "Authorization": "Bearer " + token};
    dispatch({ type: 'ADD_SCRAPER_JOB_REQUEST' });
    let body = JSON.stringify(text);
    console.log(body)
    console.log(text)
    return fetch("https://" + process.env.REACT_APP_SCRAPER_CONTROL_IP + '/', {headers, method: "POST", body})
      .then(handleErrors)
      .then(res => res.json())
      .then(scraper => {
        return dispatch({
          type: 'ADD_SCRAPER_JOB_SUCCESS',
          scraper
        })
      })
      .catch(error => {
        return dispatch({ type: 'ADD_SCRAPER_JOB_FAILURE', error_type: error, error: true })
      })
  }
}

export const addScraperJob = (text, token) => {
  return dispatch => {
    //const headers = {"Content-Type": "application/json", "Authorization": "Bearer " + token};
    const headers = {"Content-Type": "application/json"}
    dispatch({ type: 'ADD_SCRAPER_JOB_REQUEST' });
    let body = JSON.stringify(text);
    console.log(body)
    console.log(text)
    return fetch("https://" + process.env.REACT_APP_SCRAPER_CONTROL_IP + '/createJob', {headers, method: "POST", body})
      .then(handleErrors)
      .then(scraper => {
        return dispatch({
          type: 'ADD_SCRAPER_JOB_SUCCESS',
          scraper
        })
      })
      .catch(error => {
        return dispatch({ type: 'ADD_SCRAPER_JOB_FAILURE', error_type: error, error: true })
      })
  }
}

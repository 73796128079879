// Import React & Redux components
import React from 'react';
import ReactTable from 'react-table';

const styledButton = props => (
  <button type="button" {...props} className="btn btn-outline-primary-page-selection">
    {props.children}
  </button>
)

class SimpleTable extends React.Component {

  render() {
    let sortColumn = ''
    if (this.props.sortColumn !== undefined) {
      sortColumn = this.props.sortColumn
    }

    let descending = false
    if (this.props.sortDescendingOrder === true) {
      descending = true
    }
//              id: this.props.sortColumn,
    return (
      <div>
          <ReactTable
            data={this.props.data}
            filterable
            ref={this.props.ref}
            columns={this.props.columns}
            sorted={[{
              id: sortColumn,
              desc: descending
            }]}
            defaultPageSize={10}
            keyField={'id'}
            className="-striped -highlight"
            PreviousComponent={styledButton}
            NextComponent={styledButton}
          />
      </div>
    )
  }
};

export default SimpleTable;

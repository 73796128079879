import React from "react";
import { connect } from 'react-redux'
import { Card } from 'react-bootstrap'

// Import layout and helper functions/templates
import Header from "../components/Header";
import Notification from "../components/Notification"
import FormSelectOneDropDown from '../components/FormSelectOneDropDown'

import { inputs, selections } from "../actions";

class LinkPortfolio extends React.Component{
  constructor() {
    super();
    this.state = {
      files: [],
      heatmap_data: [],
      dataSetID: null,
      selectedPortfolio: null,
      selectedPortfolioName: null,
    }
    this.savePortfolioDetails = this.savePortfolioDetails.bind(this);
  }

  componentDidMount(){
    if(this.props.selections.isLoading === true){
      this.props.fetchSelections(sessionStorage.getItem('jwtToken'))
    }
  }

  savePortfolioDetails = (value) => {
    console.log(value)
    this.setState({
      dataSetID: value.dataSetID,
      selectedPortfolio: value.selectionID,
      selectedPortfolioName: value.selectionName,
    })
  }

  render() {
    if (this.props.selections.isLoading === true) {
      return (
        <div>
          <Header title={"Loading screen"} />
          <Notification heading={'Settings'} body={'Loading Data'}/>
        </div>
      )
    }
    console.log(this.state)
    return(
    <div>
      <Header title={"Link Transition Matrices"} />
      <Card>
        <Card.Header>Portfolio Factor Loadings</Card.Header>
        <Card.Body>
        <FormSelectOneDropDown
          onChange={this.savePortfolioDetails}
          itemLabel={'Portfolio Name'}
          itemName={this.props.selections.data.map(k => k.selectionName)}
          currentlySelectedItemName=""
        />
        </Card.Body>
      </Card>
      <Card>
        <Card.Header>Portfolio Transition Matrix</Card.Header>
        <Card.Body>

        </Card.Body>
      </Card>
      <Card>
        <Card.Header>Portfolio Factor Loadings</Card.Header>
        <Card.Body>
        </Card.Body>
      </Card>
    </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    authentication: state.authentication,
    selectedPortfolio: state.selectedPortfolio,
    selections: state.selections,
    selectedPortfolio: null,
    selectedPortfolioName: null,
    portfoliocounterparties: state.portfoliocounterparties,
    FactorLoadingsMatrix: state.factorLoadingsMatrix,
    loading: state.loading,
    error: state.error,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    fetchSelections: (id, token) => {
      return dispatch(selections.fetchSelections(id, token));
    },
    fetchPortfolioCounterparties: (id, token) => {
      return dispatch(selections.fetchPortfolioCounterparties(id, token));
    },
    fetchTransitionMatrix: (id, token) => {
      return dispatch(inputs.fetchTransitionMatrix(id, token));
    },
    fetchFactorLoadings: (id, token) => {
      return dispatch(inputs.fetchFactorLoadings(id, token));
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LinkPortfolio);

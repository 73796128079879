const initialState = {data: {}, isLoading: true};

 export default function configurations(state=initialState, action) {

  switch(action.type) {

    case 'ADD_CONFIGURATION':
      return [...state, action.configuration];

/*    case 'UPDATE_CONFIGURATION':
      let configurationToUpdate = configurationList[action.index]
      configurationToUpdate.text = action.configuration.text;
      configurationList.splice(action.index, 1, configurationToUpdate);
      return configurationList;
*/
    case 'FETCH_CONFIGURATIONS_SUCCESS':
    return {data: action.configurations, isLoading: false};

    case 'FETCH_CONFIGURATIONS_FAILURE':
    return {data: action.configurations, isLoading: false};

    default:
      return state;
  }
}

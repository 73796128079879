function handleErrors(response) {
  if (!response.ok) {
    console.log(response)
    console.log('statusText')
    console.log(response.statusText)
    console.log('response.json()');
    return Promise.reject(response);
  }
  return response;
}

export const addDataset = (text, token) => {
  return dispatch => {
    const headers = {"Content-Type": "application/json", "Authorization": "JWT " + token};
    let body = JSON.stringify(text);
    return fetch(process.env.REACT_APP_HTTP_PROTOCOL + "://" + process.env.REACT_APP_API_IP + "/api/portfolio/datasets/", {headers, method: "POST", body})
      .then(handleErrors)
      .then(res => res.json())
      .then(dataset => {
        return dispatch({
          type: 'ADD_DATASET_SUCCESS',
          dataset
        })
      })
      .catch(error => {
        try {
          error.json().then(res => {
            return dispatch({ type: 'ADD_DATASETS_FAILURE', error_type: res, error: true })
          })
        }
        catch (err) {
          return dispatch({ type: 'ADD_DATASETS_FAILURE', error_type: err, error: true })
        }
      })
  }
}

export const deleteDataset = (id, token) => {
  return (dispatch, getState) => {
    console.log(id)
    const headers = {"Content-Type": "application/json", "Authorization": "JWT " + token};
    let body = JSON.stringify(id);
    return fetch(process.env.REACT_APP_HTTP_PROTOCOL + "://" + process.env.REACT_APP_API_IP + "/api/portfolio/datasets/" + id + "/", {headers, method: "DELETE", body})
      .then(handleErrors)
      .then(res => {
        if (res.ok) {
          return dispatch({
            type: 'DELETE_DATASET_SUCCESS',
            id
          })
        } else{
          return dispatch({
            type: 'DELETE_DATASET_FAILURE',
            error_type: null,
            error: true
          })
        }
      })
      .catch(error => {
        return dispatch({ type: 'FETCH_DATASETS_FAILURE', error_type: error, error: true })
      })
  }
}

export const fetchDataSets = (token) => {
  return dispatch => {
    const headers = {"Content-Type": "application/json", "Authorization": "JWT " + token};
    dispatch({ type: 'FETCH_DATASETS_REQUEST' });
    return fetch(process.env.REACT_APP_HTTP_PROTOCOL + "://" + process.env.REACT_APP_API_IP + "/api/portfolio/datasets/", {headers, })
      .then(handleErrors)
      .then(res => res.json())
      .then(datasets => {
        return dispatch({
          type: 'FETCH_DATASETS_SUCCESS',
          datasets
        })
      })
      .catch(error => {
        return dispatch({ type: 'FETCH_DATASETS_FAILURE', error_type: error, error: true })
      })
  }
}

import React from 'react';
import { connect } from 'react-redux';
import Dropzone from 'react-dropzone';
import HeatMap from 'react-heatmap-grid';
import {
  Button,
  Card,
  Form,
} from 'react-bootstrap';
import Papa from 'papaparse';
import { datasetActions, transactionActions, inputs } from '../actions';

const baseStyle = {
  width: 200,
  height: 200,
  borderWidth: 2,
  borderColor: '#666',
  borderStyle: 'dashed',
  borderRadius: 5,
  paddingTop: 10,
  paddingLeft: 10,
};

const activeStyle = {
  borderStyle: 'solid',
  borderColor: '#6c6',
  backgroundColor: '#eee',
};

const rejectStyle = {
  borderStyle: 'solid',
  borderColor: '#c66',
  backgroundColor: '#eee',
};

class FirmFundamentalUploads extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      counterpartiesNotInDataSet: [],
      counterpartiesNotInFile: [],
      dataSetFileJoinValid: false,
      files: [],
      firmfundamentals: [],
      firmfundamentals_submit: undefined,
      dataSetID: undefined,
      counterpartiesInDataSet: [],
      counterpartiesInFile: [],
      xLabels: [],
      yLabels: [],
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.saveDataSetID = this.saveDataSetID.bind(this);
    this.processData = this.processData.bind(this);
    this.qualityCheckInputVSDataSet = this.qualityCheckInputVSDataSet.bind(this);
    this.assessCounterpartiesInDataSet = this.assessCounterpartiesInDataSet.bind(this);
  }

  saveDataSetID = () => {
    this.setState({
      dataSetID: parseInt(this.refs.dataSetID.value, 10),
      dataSetFileJoinValid: false,
    });
    this.props.fetchTransactions(this.refs.dataSetID.value, sessionStorage.getItem('jwtToken'));
  }

  handleSubmit = (event) => {
    event.preventDefault();
    const data = this.state.firmfundamentals_submit.map((firm) => (
      {
        datasetID: this.state.dataSetID,
        avg_asset_growth_rate: firm.Average_Asset_Growth_Rate,
        equity_volatility: firm.Average_Equity_Volatility,
        equity_value: firm.Equity_Market_Value,
        st_debt: firm.Total_ST_Financings,
        lt_debt: firm.Total_LT_Financings,
        counterpartyWWid: firm.WWid,
      }
    ));
    this.props.addFirmFundamentals(data, sessionStorage.getItem('jwtToken'));
  }

  processData = (readResult) => {
    const counterpartyList = [];

    const floatArrayOfDicts = readResult.data.map((x) => {
      const newArray = [];
      Object.entries(x).forEach(
        ([key, value]) => {
          if (key !== 'counterpartyWWid') {
            newArray.push(value);
          } else {
            counterpartyList.push(value);
          }
        },
      );
      return newArray;
    });
    counterpartyList.pop();

    if (Object.keys(floatArrayOfDicts[floatArrayOfDicts.length - 1]).length < 2) {
      floatArrayOfDicts.pop();
    }

    this.setState({
      counterpartiesInFile: counterpartyList,
      dataSetFileJoinValid: false,
      firmfundamentals: floatArrayOfDicts,
      firmfundamentals_submit: readResult.data,
      yLabels: counterpartyList,
    });
  }

  onDrop = (files) => {
    this.setState({
      files: files.map((file) => Object.assign(file, {
        preview: URL.createObjectURL(file),
      })),
    });
    Papa.parse(files[0], {
      header: true,
      dynamicTyping: true,
      complete: this.processData,
    });
  }

  componentDidMount() {
    if (this.props.datasets.isLoading) {
      this.props.fetchDatasets(sessionStorage.getItem('jwtToken'));
    }
  }

  componentDidUpdate(prevProps) {
    if (!this.state.dataSetFileJoinValid
      && this.state.files.length > 0
      && !this.props.transactions.isLoading) {
      this.qualityCheckInputVSDataSet();
    }

    if (prevProps.transactions.isLoading
      && !this.props.transactions.isLoading
      && this.props.transactions.data.length > 0) {
      this.assessCounterpartiesInDataSet();
    }
  }

  componentWillUnmount() {
    // Make sure to revoke the data uris to avoid memory leaks
    this.state.files.forEach((file) => URL.revokeObjectURL(file.preview));
  }

  qualityCheckInputVSDataSet = () => {
    const dataSetFileJoin = this.state.counterpartiesInDataSet.map((dataSetCounterparty, index) => (
      {
        id: index,
        count: this.state.counterpartiesInFile.filter((fileCounterparty) => (
          fileCounterparty === dataSetCounterparty
        )).length,
      }
    ));
    const dataShortage = dataSetFileJoin.filter((elem) => elem.count !== 1);

    const fileDataSetJoin = this.state.counterpartiesInFile.map((fileCounterparty, index) => (
      {
        id: index,
        count: this.state.counterpartiesInDataSet.filter((dataSetCounterparty) => (
          dataSetCounterparty === fileCounterparty
        )).length,
      }
    ));
    const excessData = fileDataSetJoin.filter((elem) => elem.count !== 1);

    this.setState({
      counterpartiesNotInDataSet: excessData,
      counterpartiesNotInFile: dataShortage,
      dataSetFileJoinValid: true,
    });
  }

  assessCounterpartiesInDataSet = () => {
    const counterparties = this.props.transactions.data.map((transaction) => (
      transaction.WWID_Best_PD_Borrower
    ));
    this.setState({
      counterpartiesInDataSet: [...new Set(counterparties)],
    });
  }

  render() {
    const renderDatasetsDropdown = !this.props.datasets.isLoading;

    const xLabels = ['st_debt', 'lt_debt', 'avg_asset_growth_rate', 'equity_volatility', 'equity_value'];
    const yLabels = this.state.counterpartiesInFile.length > 0
      ? this.state.counterpartiesInFile
      : [];

    return (
      <div>
        <Card className="mt-4">
          <Card.Header>Upload Firm Fundamentals</Card.Header>
          <Card.Body>
            { renderDatasetsDropdown && (
              <Form noValidate onSubmit={this.handleSubmit}>
                <Form.Group controlId="datasetList">
                <Form.Control as="select" ref="dataSetID" onChange={this.saveDataSetID}>
                  <option key="" value=""></option>
                  { this.props.datasets.data.map((k) => (
                    <option key={k.dataSetID} value={k.dataSetID}>{k.dataSetName}</option>
                  ))}
                </Form.Control>
                <Button fill="true" type="submit">Upload</Button>
                </Form.Group>
              </Form>
            )}

            { this.state.dataSetID !== undefined && (
              <Dropzone accept="text/csv, application/vnd.ms-excel" onDrop={this.onDrop.bind(this)}>
                {({
                  getRootProps,
                  getInputProps,
                  isDragActive,
                  isDragAccept,
                  isDragReject,
                  rejectedFiles,
                }) => {
                  let styles = { ...baseStyle };
                  styles = isDragActive ? { ...styles, ...activeStyle } : styles;
                  styles = isDragReject ? { ...styles, ...rejectStyle } : styles;

                  if (rejectedFiles.length > 0) {
                    return (<p>Error reading {rejectedFiles[0].name}</p>);
                  }

                  return (
                    <div {...getRootProps()} style={styles} >
                      <input {...getInputProps()} />
                      <div>
                        {isDragAccept ? 'Drop' : 'Drag'} files here...
                      </div>
                      {isDragReject && <div>Please provide CSV files</div>}
                    </div>
                  );
                }}
              </Dropzone>
            )}
          </Card.Body>
        </Card>

        { (this.state.counterpartiesNotInFile.length > 0
          || this.state.counterpartiesNotInDataSet.length > 0) && (
          <Card className="mt-4">
            <Card.Header>Upload Summary - Mismatch in file & dataset</Card.Header>
            <Card.Body>
              { this.state.counterpartiesNotInFile.length > 0 && (
                <div>
                  <p>{'The following counterparty identifiers\
                    have not been found in the uploaded file'}</p>
                  <ul>
                  {this.state.counterpartiesNotInFile.map((elem) => (
                    <li>{this.state.counterpartiesInDataSet[elem.id]}</li>
                  ))}
                  </ul>
                </div>
              )}

              { this.state.counterpartiesNotInDataSet.length > 0 && (
                <div>
                  <p>{'The following counterparty identifiers found\
                  in the uploaded file are not present in the dataset'}</p>
                  <ul>
                  {this.state.counterpartiesNotInDataSet.map((elem) => (
                    <li>{elem.id}</li>
                  ))}
                  </ul>
                </div>
              )}
            </Card.Body>
          </Card>
        )}

        { this.state.firmfundamentals.length > 0 && (
          <Card className="mt-4">
            <Card.Header>Firm Fundamentals Preview</Card.Header>
            <Card.Body>
              <p>Preview on data read from {this.state.files[0].name}</p>
              <HeatMap
                xLabels={xLabels}
                xLabelWidth={500}
                yLabels={yLabels}
                data={this.state.firmfundamentals}
                squares
                cellStyle={(background, value, min, max) => ({
                  background: `rgb(66, 86, 244, ${1 - (max - value) / (max - min)})`,
                  fontSize: '11px',
                })}
                cellRender={(value) => value && `${Math.round(value, 2)}`}
              />
            </Card.Body>
          </Card>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => (
  {
    authentication: state.authentication,
    loading: state.loading,
    datasets: state.datasets,
    transactions: state.transactions,
    error: state.error,
  }
);

const mapDispatchToProps = (dispatch) => (
  {
    fetchDatasets: (token) => {
      dispatch(datasetActions.fetchDataSets(token));
    },
    fetchTransactions: (id, token) => {
      dispatch(transactionActions.fetchTransactions(id, token));
    },
    addFirmFundamentals: (data, token) => {
      dispatch(inputs.addFirmFundamentals(data, token));
    },
  }
);

export default connect(mapStateToProps, mapDispatchToProps)(FirmFundamentalUploads);

import React from "react";
import { Line } from 'react-chartjs-2';
import Format from '../components/Format';
import { Button, Card } from 'react-bootstrap';
import { generateCSVFile } from '../components/Helpers'

const createDataForChart = (xaxislabels, dataseries, dataserieslabels) => {
  let datasets =[]
  for(let i = 0; i < dataseries.length; ++i) {
    datasets.push({
      label: dataserieslabels[i],
      backgroundColor: Format.fillcolors[i%Format.fillcolors.length],
      borderColor: Format.bordercolors[i%Format.bordercolors.length],
      borderWidth: 1,
      hoverBackgroundColor: 'rgba(139,139,139,0.4)',
      hoverBorderColor: 'rgba(139,139,139,1)',
      pointRadius: 2,
      data: dataseries[i]
    })
  };
  return {
    labels: xaxislabels,
    datasets: datasets
  }
}

const setOptions = (xaxistooltips) => {
  return {
    legend:{
      display: [{}]
    },
    scales: {
      xAxes: [{
        ticks: {
          callback: function(value, index, values) {
            return value.toLocaleString("en-GB",
              { style: "decimal",
                minimumFractionDigits: 0,
                maximumFractionDigits: 0
              });
          }
        }
      }],
      yAxes: [{
        ticks: {
          callback: function(value, index, values) {
            return value.toLocaleString("en-GB",
              { style: 'percent',
                minimumFractionDigits: 1,
                maximumFractionDigits: 1
              });
          }
        }
      }]
    },
    tooltips: {
      enabled: true,
      mode: 'label',
      callbacks: {
        title: function(tooltipItems, data) {
          return xaxistooltips[tooltipItems[0].index]
        },
        label: function(tooltipItems, data) {
          return data.datasets[tooltipItems.datasetIndex].label + ": " + tooltipItems.yLabel.toLocaleString("en-GB",
            {"style": "percent",
            "minimumFractionDigits": 2,
            "maximumFractionDigits": 2
          });
        }
      }
    }
  }
}

const linkULToECOrder = (sortedULContributions, ECContributions) => {
  let sortedArray = []
  for(var i = 0; i < sortedULContributions.length; i++) {
    sortedArray.push(ECContributions[sortedULContributions[i]['key']]);
  }
  return sortedArray
}

class ULvsRCChart extends React.Component {
  constructor(props) {
    super(props);
  }

  saveToExcel = (axislabels, dataserieslabels, dataseries) => {
    generateCSVFile(axislabels, dataserieslabels, dataseries)
    return false;
  }

  render() {
    const sortedULContributions = this.props.assetsPerformance.data.map((elem,index) => {return {"assetULContribution": elem.assetULContribution, "key": index}}).sort(function(a, b){return a['assetULContribution']-b['assetULContribution']})
    const xaxistooltips = sortedULContributions.map((elem, index) => {return this.props.assetsPerformance.data[elem.key].assetID})
    const ECContributions = this.props.assetsPerformance.data.map((elem,index) => {return {"assetECContribution": elem.assetECContribution, "key": index}})
    const linkSortedECContributions = linkULToECOrder(sortedULContributions, ECContributions)
    const labels = sortedULContributions.map((elem, index) => {return index})
    const unexpectedLossValues = sortedULContributions.map(elem => {return elem.assetULContribution})
    const economicCapitalValues = linkSortedECContributions.map(elem => {return elem.assetECContribution})
    let options = setOptions(xaxistooltips)
    let data = createDataForChart(sortedULContributions.map((elem, index) => {return index}), [unexpectedLossValues, economicCapitalValues], ["Unexpected Loss", "Risk Contribution"])
    // sortedULContributions.map((elem, index) => {return this.props.assetsPerformance.data[elem.key].assetID})
    return (
      <React.Fragment>
        <Card className="mt-4">
          <Card.Header className="card-header-with-btn">
            <div className="fa-pull-left">Unexpected Loss versus Risk Contribution</div>
            <div className="fa-pull-right">
              <Button className="fa-icon" variant="secondary" size="sm" onClick={() => this.saveToExcel(labels,["Unexpected_Loss, Risk_Contribution"], [unexpectedLossValues, economicCapitalValues])}>
                <span className="fa fa-save"></span>
                <span> Export</span>
              </Button>
              <span
                className="ml-3 question-mark"
                onClick={() => this.props.renderHelp({
                  header: 'Unexpected Losses versus Risk Contribution',
                  body: 'Two measures of risk are shown for each transaction: the unexpected loss (i.e the risk carried by a transaction if considered individually), and the risk contribution to the portfolio’s economic capital (i.e the risk carried by a transaction when considered in the portfolio). <br /> The larger the difference between the two dots on the respective lines, the more diversification a transaction provides to the portfolio. The values are ordered by ascending unexpected loss for optimal visualisation purposes. Scrolling over a value of interest will provide the transaction name and actual values of the two measures.'
                })}
              ></span>
            </div>
          </Card.Header>
          <Card.Body>
            <Line data={data} options={options}/>
          </Card.Body>
        </Card>
      </React.Fragment>
    )
  }
}

export default ULvsRCChart;

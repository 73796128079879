import React from 'react'
import { connect } from 'react-redux';
import Dropzone from 'react-dropzone'
import HeatMap from "react-heatmap-grid";
import FormSelectName from "./FormSelectName";
import FormSelectOneDropDown from './FormSelectOneDropDown'
// Check options for the disabled tag!
import { Button, Card } from 'react-bootstrap';

import Header from "../components/Header";
import Notification from "../components/Notification"
import Papa from 'papaparse'

import { inputs, selections } from "../actions";

const baseStyle = {
  width: 200,
  height: 200,
  borderWidth: 2,
  borderColor: '#666',
  borderStyle: 'dashed',
  borderRadius: 5,
  paddingTop: 10,
  paddingLeft: 10
};

const activeStyle = {
  borderStyle: 'solid',
  borderColor: '#6c6',
  backgroundColor: '#eee'
};

const rejectStyle = {
  borderStyle: 'solid',
  borderColor: '#c66',
  backgroundColor: '#eee'
};

const headerDiv = {
  'textAlign': 'center'
}

const dropzoneBox = {
  margin: '10px',
  width: '200px',
}

const spanCenter = {
  display: 'inline-block',
}

function mapInputsToPortfolioConstituents(inputs, input_counterparty_list, state_port_constituents) {
  //var mapped_data = new Array(state_port_constituents.length).fill(new Array(16));
  let mapped_data = state_port_constituents.map(function(value, index, arr) {
    return inputs[input_counterparty_list.indexOf(value)]
    //mapped_data[index] = inputs[input_counterparty_list.indexOf(value)]
  })
  return mapped_data
}

class CounterpartyLoadingsUpload extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      counterpartyLoadings: [],
      counterpartyLoadingsName: null,
      dataSetID: null,
      selectedPortfolio: null,
      selectedPortfolioName: null,
      portfolioTransactions: null,
      input_counterparty_list: null,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.saveCounterpartyLoadingsName = this.saveCounterpartyLoadingsName.bind(this);
    this.onDropCounterpartyLoadings = this.onDropCounterpartyLoadings.bind(this)
  }

  saveSettings = (value) => {
    if(value.selectedItemIndex !== undefined){
      const dataSetID = this.props.selections.data[value.selectedItemIndex].dataSetID
      const selectionID = this.props.selections.data[value.selectedItemIndex].selectionID
      const selectionName = this.props.selections.data[value.selectedItemIndex].selectionName
      const portfolioTransactions = this.props.selections.data[value.selectedItemIndex].selectionTransactions
      const portfolioCounterparties = this.props.fetchPortfolioCounterparties(selectionID, sessionStorage.getItem('jwtToken'))
      this.setState({
        dataSetID: dataSetID,
        selectedPortfolio: selectionID,
        selectedPortfolioName: selectionName,
        portfolioTransactions: portfolioTransactions,
        portfolioCounterparties: portfolioCounterparties,
      })
    }
  }

  handleSubmit = (event) => {
    event.preventDefault();
    let data = {}
    data['counterpartyLoadingsName'] = this.state.counterpartyLoadingsName
    data['counterpartyLoadings'] = this.state.counterpartyLoadings
    data['counterpartyWWids'] = this.state.yLabels
    data['factorNames'] = this.state.xLabels
    data['datasetID'] = this.state.dataSetID
    data['portfolioID'] = this.state.selectedPortfolio
    // TODO: perform proper checks
    this.props.addCounterpartyLoadings(data, sessionStorage.getItem('jwtToken'))
  }

  onDropCounterpartyLoadings = (files) => {
    const reader = new FileReader()
    let react_var = this;
    reader.onload = function () {
      Papa.parse(reader.result, {
  	     complete: function(results) {
           let yLabels = results.data.map(elem => {
             return elem[0]
           })
           yLabels.shift()
           yLabels.pop()

          let counterpartyLoadings = results.data.map(x => {
            x.shift()
            return x.map(elem => { return parseFloat(elem)})
          });
          counterpartyLoadings.shift()
          counterpartyLoadings.pop()

          react_var.setState((state, props) => ({
            counterpartyLoadings: counterpartyLoadings,
            xLabels: results.data[0],
            yLabels: yLabels
          }));
  	    },
      });
    }
    reader.readAsText(files[0])
  }

  saveCounterpartyLoadingsName = (value) => {
    this.setState({
      counterpartyLoadingsName: value.inputName,
    })
  }

  componentDidMount(){
    if(this.props.selections.isLoading === true){
      this.props.fetchSelections(sessionStorage.getItem('jwtToken'))
    }
  }

  render() {

    if (this.props.selections.isLoading === true) {
      return (
        <div>
          <Header title={"Counterparty Loadings"} />
          <Notification heading={''} body={'Loading Data'}/>
        </div>
      )
    }

    return (
      <div>
      <Header title={"Counterparty Loadings Upload"} />
      <Card>
        <Card.Header>Counterparty Loadings</Card.Header>
        <Card.Body>
        <FormSelectOneDropDown
          onChange={this.saveSettings}
          itemLabel={'Portfolio Name'}
          itemName={this.props.selections.data.map(k => k.selectionName)}
          currentlySelectedItemName=""
        />
        <FormSelectName onChange={this.saveCounterpartyLoadingsName} inputName='Counterparty Loadings Name'/>
          <div style={dropzoneBox}>
            <div style={headerDiv}><span style={spanCenter}>Counterparty Loadings</span></div>
            <Dropzone accept="text/csv" onDrop={this.onDropCounterpartyLoadings}>
              {({ getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject, acceptedFiles, rejectedFiles }) => {
                let styles = {...baseStyle}
                styles = isDragActive ? {...styles, ...activeStyle} : styles
                styles = isDragReject ? {...styles, ...rejectStyle} : styles

                return (
                  <div {...getRootProps()} style={styles} >
                    <input {...getInputProps()} />
                    <div>
                      {isDragAccept ? 'Drop' : 'Drag'} files here...
                    </div>
                    {isDragReject && <div>Please provide CSV files</div>}
                  </div>
                )
              }}
            </Dropzone>
          </div>
        <Button variant="primary" fill="true" type="submit" onClick={this.handleSubmit}>
          Save
        </Button>
        </Card.Body>
      </Card>
      { console.log(this.state) }
      { this.state.counterpartyLoadings.length > 0 ? (
        <Card className="mt-4">
          <Card.Header>File Preview</Card.Header>
          <Card.Body>
            <HeatMap
              xLabels={this.state.xLabels}
              yLabels={this.state.yLabels}
              xLabelWidth={200}
              data={this.state.counterpartyLoadings}
              squares
              onClick={(x, y) => alert(`Clicked`)}
              cellStyle={(background, value, min, max, data, x, y) => ({
                background: `rgb(66, 86, 244, ${1 - (max - value) / (max - min)})`,
                fontSize: "11px",
              })}
              cellRender={value => `${value}`}
            />
          </Card.Body>
        </Card>
      ) : ( <br /> ) }
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    selectedPortfolio: state.selectedPortfolio,
    selections: state.selections,
    selectedPortfolioName: null,
    portfolioCounterparties: state.portfolioCounterparties,
    factorLoadings: state.factorLoadings,
    loading: state.loading,
    error: state.error,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    addCounterpartyLoadings: (data, token) => {
      return dispatch(inputs.addCounterpartyLoadings(data, token));
    },
    fetchSelections: (id, token) => {
      return dispatch(selections.fetchSelections(id, token));
    },
    fetchPortfolioCounterparties: (id, token) => {
      return dispatch(selections.fetchPortfolioCounterparties(id, token));
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CounterpartyLoadingsUpload);

import React from 'react';

import {
  Nav,
  Button,
} from 'react-bootstrap';

import CreatePortfolioSelection from '../components/CreatePortfolioSelection';
import CreateScenario from '../components/CreateScenario';
import CreateJob from '../components/CreateJob';

class SimulationWizard extends React.Component {
  constructor() {
    super();
    this.state = {
      activePage: 'portfolio-selection',
      nextStep: 'scenario-selection',
      validCreateSelection: 'progress',
      validCreateScenario: 'invalid',
      validCreateJob: 'invalid',
      selectedPortfolios: [],
      selectedScenario: undefined,
      nextPage: 'Scenario',
      previousPage: undefined,
    };
    this.updateProcess = this.updateProcess.bind(this);
    this.saveSelectionID = this.saveSelectionID.bind(this);
    this.saveScenarioID = this.saveScenarioID.bind(this);
  }

  saveSelectionID = (selectionID) => {
    this.setState({
      selectedPortfolios: [selectionID],
    });
  }

  saveScenarioID = (scenarioID) => {
    this.setState({
      selectedScenario: scenarioID,
    });
  }

  updateProcess = (processStep) => {
    switch (processStep) {
      case 'portfolio-selection':
        this.setState({
          activePage: 'portfolio-selection',
          previousStep: undefined,
          nextStep: 'create_scenario',
          validCreateSelection: 'progress',
          validCreateScenario: 'invalid',
          validCreateJob: 'invalid',
          nextPage: 'Scenario',
          previousPage: undefined,
        });
        break;
      case 'scenario-selection':
        this.setState({
          activePage: 'scenario-selection',
          previousStep: 'portfolio-selection',
          nextStep: 'create-job',
          validCreateSelection: 'valid',
          validCreateScenario: 'progress',
          validCreateJob: 'invalid',
          nextPage: 'Job',
          previousPage: 'Selection',
        });
        break;
      case 'create-job':
        this.setState({
          activePage: 'create-job',
          previousStep: 'scenario-selection',
          nextStep: undefined,
          validCreateSelection: 'valid',
          validCreateScenario: 'valid',
          validCreateJob: 'progress',
          nextPage: undefined,
          previousPage: 'Scenario',
        });
        break;
      default:
        this.setState({
          activePage: 'portfolio-selection',
          previousStep: undefined,
          nextStep: 'scenario-selection',
          nextPage: 'Scenario',
          previousPage: undefined,
          validCreateSelection: 'progress',
          validCreateScenario: 'invalid',
          validCreateJob: 'invalid',
        });
    }
  };

  render() {
    const createIcon = (status) => {
      if (status === 'valid') {
        return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" className="wizard-icon" viewBox="0 0 80 80"><path fill="#ffffff" d="M38.41,0A38.41,38.41,0,1,0,76.82,38.41,38.41,38.41,0,0,0,38.41,0ZM54,28.64,33.79,53.25,23.69,41.84a1.64,1.64,0,0,1-.26-.33A2.52,2.52,0,0,1,24.19,38a3.32,3.32,0,0,1,4.28.45l5.14,5.79L49,25.44a3.31,3.31,0,0,1,4.26-.59,1.5,1.5,0,0,1,.33.23A2.53,2.53,0,0,1,54,28.64Z"/></svg>;
      }
      if (status === 'progress') {
        return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" className="wizard-icon" viewBox="0 0 24 24"><path fill="transparent" d="M1.645 11.761c0-5.638 4.57-10.208 10.207-10.208 5.638 0 10.208 4.57 10.208 10.208s-4.57 10.208-10.208 10.208c-5.637 0-10.207-4.57-10.207-10.208z"/><path fill="none" stroke="#fff" strokeMiterlimit="50" strokeWidth="1" d="M1.645 11.761c0-5.638 4.57-10.208 10.207-10.208 5.638 0 10.208 4.57 10.208 10.208s-4.57 10.208-10.208 10.208c-5.637 0-10.207-4.57-10.207-10.208z"/></svg>;
      }
      return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" className="wizard-icon-invalid" viewBox="0 0 24 24"><path fill="transparent" d="M1.645 11.761c0-5.638 4.57-10.208 10.207-10.208 5.638 0 10.208 4.57 10.208 10.208s-4.57 10.208-10.208 10.208c-5.637 0-10.207-4.57-10.207-10.208z"/><path fill="none" stroke="#E7E8E9" strokeMiterlimit="50" strokeWidth="1" d="M1.645 11.761c0-5.638 4.57-10.208 10.207-10.208 5.638 0 10.208 4.57 10.208 10.208s-4.57 10.208-10.208 10.208c-5.637 0-10.207-4.57-10.207-10.208z"/></svg>;
    };

    return (
    <div>
      <div className="top-header">
        <div className="wizard-progress">
          <Nav
            className="wizard-labels mb-1"
            justify variant="tabs"
            defaultActiveKey="portfolio-selection"
            onSelect={(selectedKey) => {
              this.setState({ activePage: selectedKey });
              this.updateProcess(selectedKey);
            }}
          >
            <Nav.Item className="first-label">
              <Nav.Link eventKey="portfolio-selection" style={{ color: '#4eddd9' }}>Select Portfolio</Nav.Link>
            </Nav.Item>
            <Nav.Item className="second-label">
              <Nav.Link eventKey="scenario-selection" style={{ color: '#5bb7c2' }}>Select Scenario</Nav.Link>
            </Nav.Item>
            <Nav.Item className="third-label">
              <Nav.Link eventKey="create-job" style={{ color: '#355787' }}>Start Job</Nav.Link>
            </Nav.Item>
          </Nav>

          <Nav
            className="wizard-menu"
            style={{ marginBottom: '1rem' }}
            justify variant="tabs"
            defaultActiveKey="portfolio-selection"
            onSelect={(selectedKey) => {
              this.setState({ activePage: selectedKey });
              this.updateProcess(selectedKey);
            }}
          >
            <Nav.Item className={this.state.validCreateSelection !== 'invalid' ? 'first-key' : 'first-key invalid-key'}>
              <Nav.Link className={this.state.validCreateSelection !== 'invalid' ? 'nav-link-icons' : 'nav-link-icons-invalid'} eventKey="portfolio-selection">
                {createIcon(this.state.validCreateSelection)}
              </Nav.Link>
            </Nav.Item>
            <Nav.Item className={this.state.validCreateScenario !== 'invalid' ? 'second-key' : 'second-key invalid-key'}>
              <Nav.Link className={this.state.validCreateScenario !== 'invalid' ? 'nav-link-icons' : 'nav-link-icons-invalid'} eventKey="scenario-selection">
                {createIcon(this.state.validCreateScenario)}
              </Nav.Link>
            </Nav.Item>
            <Nav.Item className={this.state.validCreateJob !== 'invalid' ? 'third-key' : 'third-key invalid-key'}>
              <Nav.Link className={this.state.validCreateJob !== 'invalid' ? 'nav-link-icons' : 'nav-link-icons-invalid'} eventKey="create-job">
                {createIcon(this.state.validCreateJob)}
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </div>
      </div>
      <div className="bottom-content">
        <div>
          { this.state.activePage === 'portfolio-selection' && (
            <CreatePortfolioSelection
              updateFunction={this.saveSelectionID}
              selectedPortfolio={this.state.selectedPortfolios[0]}>
              {({ getRootProps }) => <div {...getRootProps()} />}
            </CreatePortfolioSelection>
          )}

          { this.state.activePage === 'scenario-selection' && (
            <CreateScenario
              updateFunction={this.saveScenarioID}
              selectedScenario={this.state.selectedScenario}>
              {({ getRootProps }) => <div {...getRootProps()} />}
            </CreateScenario>
          )}

          { this.state.activePage === 'create-job' && (
            <CreateJob
              selectedSelectionID={this.state.selectedPortfolios[0]}
              selectedScenarioID={this.state.selectedScenario}>
              {({ getRootProps }) => <div {...getRootProps()} />}
            </CreateJob>
          )}
         <div className="nagivation-footer mt-3">
          {this.state.previousPage !== undefined ? <Button variant="outline-primary" fill="false" onClick={() => this.updateProcess(this.state.previousStep)}>Previous</Button> : <div></div>}
          {this.state.nextPage !== undefined ? <Button variant="outline-primary" fill="false" onClick={() => this.updateProcess(this.state.nextStep)}>Next</Button> : <div></div> }
         </div>
        </div>
      </div>
    </div>
    );
  }
}
export default SimulationWizard;

// Import React & Redux components
import React from "react";
import { connect } from 'react-redux';
import { Button, Card, Form } from 'react-bootstrap'

// Import layout and helper functions/templates
import Header from "../components/Header";
import Notification from "../components/Notification"

// Import data sources
import { users } from "../actions";

// Import dumb component
import SimpleTable from './SimpleTable'

class UserManagement extends React.Component {
  constructor(props){
    super(props)
    this.saveSettings = this.saveSettings.bind(this);
    this.state = {
      showUserTable: true,
    }
    this.deleteAccount = this.deleteAccount.bind(this);
  }

  saveSettings = (values) => {
    values.preventDefault()
    let data = {}
    data.username = this.refs.username.value
    data.password = this.refs.password.value
    data.email = this.refs.email.value
    data.first_name = this.refs.first_name.value
    data.last_name = this.refs.last_name.value
    this.props.addUser(data, sessionStorage.getItem('jwtToken'))
  }

  deleteAccount = (accountId) => {
    this.props.deleteUser(accountId, sessionStorage.getItem('jwtToken'))
  }

  componentDidMount() {
    this.props.fetchUsers(sessionStorage.getItem('jwtToken'))
  }

  checkbox_column = {
    accessor: "delete_account",
    Cell: ({ original }) => {
      return (
        <i
          className="fa fa-trash"
          style={{ display: "inline-block", width: "100%", textAlign: "center" }}
          onClick={() => {this.deleteAccount(original.id)}}
        >
        </i>
      );
    },
    header: "Delete Account",
    sortable: false,
    width: 45
  };

  render() {

    const showUserCreated = this.props.users.last_added === true

    if(this.props.users.isLoading === true){
      return(
        <div>
          <Header title={"User Management"} />
          <Notification heading={'Accounts'} body={'Loading Data'}/>
        </div>
      )
    }

    return (
      <div>
        <Header title={"User Management"} />
        <Card className="mt-4">
          <Card.Header>User Creation</Card.Header>
          { showUserCreated ? (
              <Card.Body>
                <p>User succesfully created</p>
              </Card.Body>
            ) : (
              <Card.Body>
                <Form noValidate onSubmit={this.saveSettings}>
                  <Form.Group controlId="userManagement.createUser">
                  <Form.Control type="text" ref="username" placeholder="Username" />
                  <Form.Control type="text" ref="email" placeholder="E-mail" />
                  <Form.Control type="password" ref="password" placeholder="Password" />
                  <Form.Control type="text" ref="first_name" placeholder="First Name" />
                  <Form.Control type="text" ref="last_name" placeholder="Last Name" />
                  <Button fill="true" type="submit">Create User</Button>
                  </Form.Group>
                </Form>
              </Card.Body>
            )
          }
        </Card>

        { this.state.showUserTable && (
            <div>
              <Card className="mt-4">
                <Card.Header>Created Users</Card.Header>
                <Card.Body>
                  <SimpleTable
                    data={this.props.users.data}
                    sortColumn='username'
                    sortDescendingOrder={true}
                    columns={[
                      {accessor: "username", Header: "Username"},
                      {accessor: "email", Header: "Email"},
                      {accessor: "first_name", Header: "First Name"},
                      {accessor: "last_name", Header: "Last Name"},
                      this.checkbox_column,
                    ]}
                  />
                </Card.Body>
              </Card>
            </div>
          )
        }
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    users: state.users,
    loading: state.loading,
    error: state.error,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchUsers: (token) => {
      dispatch(users.fetchUsers(token));
    },
    addUser: (text, token) => {
      dispatch(users.addUser(text, token));
    },
    deleteUser: (userId, token) => {
      dispatch(users.deleteUser(userId, token));
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserManagement);

// Import React & Redux components
import React from 'react';
import { connect } from 'react-redux';
import {
  Button, Card, Form, Row, Col,
} from 'react-bootstrap';
import PropTypes from 'prop-types';

import ReactTable from 'react-table';

// Import layout and helper functions/templates
import Chart from './Chart2';
import Header from './Header';
import Notification from './Notification';
import { createLoadingSelector, createErrorMessageSelector } from './Helpers';

// Import data sources
import { datasetActions, transactionActions } from '../actions';

const sourcePortfolioFromAssetPool = (assetPool,
  portfolio, activeConstraints, budget, minimumLoanInclusion) => {
  // const existingPortfolio = portfolio;
  const constructedPortfolio = [];
  let sumWeightedOutstanding = portfolio.reduce((portfolioValue, elem) => (
    elem.Outstanding * elem.loan_inclusion + portfolioValue
  ), 0);
  assetPool.forEach((elem) => {
    const adjustedElem = elem;
    if (sumWeightedOutstanding >= budget) {
      return;
    }
    activeConstraints.forEach((constraintElem) => {
      constraintElem.constraint.forEach((memberOfConstraintGroup) => {
        if (memberOfConstraintGroup.category !== undefined && activeConstraints.type !== 'minNumberBinary') {
          if (elem.Country_Of_Risk === memberOfConstraintGroup.category
            || elem.Rating === memberOfConstraintGroup.category
            || elem.Group_Division === memberOfConstraintGroup.category
            || parseInt(elem.NAICS, 10) === memberOfConstraintGroup.category.NAICS) {
            let tempPortOutstanding = 0;
            if (constraintElem.type === 'country') {
              tempPortOutstanding = portfolio.filter((asset) => (
                asset.Country_Of_Risk === memberOfConstraintGroup.category
              ))
                .reduce((portfolioValue, asset) => (
                  asset.Outstanding * asset.loan_inclusion + portfolioValue
                ), 0);
            } else if (constraintElem.type === 'sector') {
              tempPortOutstanding = portfolio.filter((asset) => (
                parseInt(asset.NAICS, 10) === memberOfConstraintGroup.category.NAICS
              ))
                .reduce((portfolioValue, asset) => (
                  asset.Outstanding * asset.loan_inclusion + portfolioValue
                ), 0);
            } else if (constraintElem.type === 'rating') {
              tempPortOutstanding = portfolio.filter((asset) => (
                asset.Rating === memberOfConstraintGroup.category
              ))
                .reduce((portfolioValue, asset) => (
                  asset.Outstanding * asset.loan_inclusion + portfolioValue
                ), 0);
            } else if (constraintElem.type === 'country_rating') {
              tempPortOutstanding = portfolio.filter((asset) => (
                asset.Group_Division === memberOfConstraintGroup.category
              ))
                .reduce((portfolioValue, asset) => (
                  asset.Outstanding * asset.loan_inclusion + portfolioValue
                ), 0);
            } else if (constraintElem.type === 'single_obligor') {
              /* temp_port_outstanding = assets_to_be_included.filter((asset) => (
                asset.Country_Of_Risk === member_of_constraint_group.category
              ))
                .reduce((portfolio_value, elem) => (
                  elem.Outstanding * elem.loan_inclusion + portfolio_value
                ), 0); */
            }

            const proposedSumOutstanding = tempPortOutstanding + elem.Outstanding;
            if (proposedSumOutstanding >= (budget * memberOfConstraintGroup.value)) {
              const violationAmount = proposedSumOutstanding
                - budget * memberOfConstraintGroup.value;
              adjustedElem.loan_inclusion = Math.max(
                Math.min(elem.loan_inclusion, (elem.Outstanding - violationAmount)
                / elem.Outstanding),
                minimumLoanInclusion,
              );
            }
          }
        }
      });
    });
    constructedPortfolio.push(adjustedElem);
    sumWeightedOutstanding += elem.Outstanding * elem.loan_inclusion;
  });

  if (sumWeightedOutstanding > budget) {
    const violationAmount = sumWeightedOutstanding - budget;
    const lastAddedAsset = constructedPortfolio[constructedPortfolio.length - 1];
    const loanInclusion = (lastAddedAsset.loan_inclusion
      * lastAddedAsset.Outstanding - violationAmount)
      / (lastAddedAsset.Outstanding) - 0.00001;
    lastAddedAsset.loan_inclusion = loanInclusion;
  }
  return constructedPortfolio;
};

const adjustPortfolioToComplyWithMinimumNumberOfConstituents = (portfolio,
  assetPool, activeConstraints, minimumLoanInclusion) => {
  const positionInAssetPool = assetPool.findIndex((elem) => (
    elem.transactionID === portfolio[portfolio.length - 1].transactionID
  ));
  // TODO[JK]: Change for slice
  const availableAssets = assetPool.filter((elem, index) => index > positionInAssetPool);

  let assetsToAdd = [];

  activeConstraints.forEach((constraintElem) => {
    constraintElem.constraint.forEach((memberOfConstraintGroup) => {
      if (constraintElem.type === 'minNumberBinary') {
        if (memberOfConstraintGroup.category === 'minNumberOfFacilities') {
          const numberToAdd = Math.max(memberOfConstraintGroup.value - portfolio.length, 0);
          const subsetToAdd = availableAssets.filter((asset, index) => (
            index < numberToAdd
          ));
          assetsToAdd = assetsToAdd.concat(subsetToAdd);
        } else {
          let selectableAssets = [];
          if (memberOfConstraintGroup.category === 'country') {
            selectableAssets = availableAssets.filter((elem) => (
              elem.Country_Of_Risk === memberOfConstraintGroup
            ));
          } else if (memberOfConstraintGroup.category === 'sector') {
            selectableAssets = availableAssets.filter((elem) => (
              parseInt(elem.NAICS, 10) === memberOfConstraintGroup.category.NAICS
            ));
          }
          const numberToAdd = Math.max(memberOfConstraintGroup.value, selectableAssets.length);
          // TODO[JK]: Replace with slice!
          const subsetToAdd = availableAssets.filter((asset, index) => index < numberToAdd);
          assetsToAdd = assetsToAdd.concat(subsetToAdd);
        }
      }
    });
  });

  let adjustedPortfolio = portfolio;
  if (assetsToAdd.length > 0) {
    assetsToAdd = assetsToAdd.map((elem) => {
      const adjustedElem = elem;
      adjustedElem.loan_inclusion = minimumLoanInclusion;
      return adjustedElem;
    });
    adjustedPortfolio = portfolio.concat(assetsToAdd);
  }
  return adjustedPortfolio;
};

const adjustLoanInclusionToConstraints = (portfolio, activeConstraints,
  minimumLoanInclusion, budget) => {
  portfolio.reverse();
  portfolio.forEach((elem, index, object) => {
    const adjustedElem = elem;
    activeConstraints.forEach((constraintElem) => {
      constraintElem.constraint.forEach((memberOfConstraintGroup) => {
        const originalLoanInclusion = elem.loan_inclusion;
        if (memberOfConstraintGroup.category !== undefined) {
          if (elem.Country_Of_Risk === memberOfConstraintGroup.category
            || elem.Rating === memberOfConstraintGroup.category
            || elem.Group_Division === memberOfConstraintGroup.category
            || parseInt(elem.NAICS, 10) === memberOfConstraintGroup.category.NAICS) {
            let tempPortOutstanding = 0;
            if (constraintElem.type === 'country') {
              const subsetAssets = portfolio.filter((asset) => (
                asset.Country_Of_Risk === memberOfConstraintGroup.category
              ));
              tempPortOutstanding = subsetAssets.reduce((portfolioValue, asset) => (
                asset.Outstanding * asset.loan_inclusion + portfolioValue
              ), 0);
            } else if (constraintElem.type === 'sector') {
              const subsetAssets = portfolio.filter((asset) => (
                parseInt(asset.NAICS, 10) === memberOfConstraintGroup.category.NAICS
              ));
              tempPortOutstanding = subsetAssets.reduce((portfolioValue, asset) => (
                asset.Outstanding * asset.loan_inclusion + portfolioValue
              ), 0);
            } else if (constraintElem.type === 'rating') {
              const subsetAssets = portfolio.filter((asset) => (
                asset.Rating === memberOfConstraintGroup.category
              ));
              tempPortOutstanding = subsetAssets.reduce((portfolioValue, asset) => (
                asset.Outstanding * asset.loan_inclusion + portfolioValue
              ), 0);
            } else if (constraintElem.type === 'country_rating') {
              const subsetAssets = portfolio.filter((asset) => (
                asset.Group_Division === memberOfConstraintGroup.category
              ));
              tempPortOutstanding = subsetAssets.reduce((portfolioValue, asset) => (
                asset.Outstanding * asset.loan_inclusion + portfolioValue
              ), 0);
            } else if (constraintElem.type === 'single_obligor') {
              // const subsetAssets = portfolio.filter((asset) => (
              //   asset.Country_Of_Risk === member_of_constraint_group.category
              // ));
              // tempPortOutstanding.reduce((portfolioValue, elem) => (
              //   elem.Outstanding * elem.loan_inclusion + portfolio_value
              // ), 0);
            }

            const proposedSumOutstanding = tempPortOutstanding;
            if (proposedSumOutstanding >= (budget * memberOfConstraintGroup.value)) {
              const violationAmount = proposedSumOutstanding - budget
                * memberOfConstraintGroup.value;
              if (violationAmount > elem.Outstanding
                * (elem.loan_inclusion - minimumLoanInclusion)) {
                object.splice(index, 1);
              } else {
                const furtherBreach = 1.0 - (elem.Outstanding - violationAmount)
                  / elem.Outstanding;
                adjustedElem.loan_inclusion = Math.max(
                  Math.min(elem.loan_inclusion, originalLoanInclusion - furtherBreach),
                  minimumLoanInclusion,
                );
                // change the previos loan in case this adjustment was insufficient
                // or add another asset?
              }
            }
          }
        }
      });
    });
    return adjustedElem;
  });
  portfolio.reverse();
  return portfolio;
};

const fillPortfolioToBudget = (portfolio, assetPool, activeConstraints,
  minimumLoanInclusion, maximumLoanInclusion, budget) => {
  const positionInAssetPool = assetPool.findIndex((elem) => (
    elem.transactionID === portfolio[portfolio.length - 1].transactionID
  ));
  const availableAssets = assetPool.slice(positionInAssetPool + 1);

  availableAssets.forEach((elem) => {
    const adjustedElem = elem;
    const portfolioOutstanding = portfolio.reduce((portfolioValue, asset) => (
      asset.Outstanding * asset.loan_inclusion + portfolioValue
    ), 0);
    const gapToFill = budget - portfolioOutstanding;
    let finalLoanInclusion = 0;

    if (gapToFill < 0.000001) {
      return;
    }

    const violatedConstraints = [];
    activeConstraints.forEach((constraintElem) => {
      constraintElem.constraint.forEach((memberOfConstraintGroup) => {
        if (memberOfConstraintGroup.category !== undefined) {
          if (elem.Country_Of_Risk === memberOfConstraintGroup.category
            || elem.Rating === memberOfConstraintGroup.category
            || elem.Group_Division === memberOfConstraintGroup.category
            || parseInt(elem.NAICS, 10) === memberOfConstraintGroup.category.NAICS) {
            let subportOutstanding = 0;
            if (constraintElem.type === 'country') {
              const subsetAssets = portfolio.filter((asset) => (
                asset.Country_Of_Risk === memberOfConstraintGroup.category
              ));
              subportOutstanding = subsetAssets.reduce((portfolioValue, asset) => (
                asset.Outstanding * asset.loan_inclusion + portfolioValue
              ), 0);
            } else if (constraintElem.type === 'sector') {
              const subsetAssets = portfolio.filter((asset) => (
                parseInt(asset.NAICS, 10) === memberOfConstraintGroup.category.NAICS
              ));
              subportOutstanding = subsetAssets.reduce((portfolioValue, asset) => (
                asset.Outstanding * asset.loan_inclusion + portfolioValue
              ), 0);
            } else if (constraintElem.type === 'rating') {
              const subsetAssets = portfolio.filter((asset) => (
                asset.Rating === memberOfConstraintGroup.category
              ));
              subportOutstanding = subsetAssets.reduce((portfolioValue, asset) => (
                asset.Outstanding * asset.loan_inclusion + portfolioValue
              ), 0);
            } else if (constraintElem.type === 'country_rating') {
              const subsetAssets = portfolio.filter((asset) => (
                asset.Group_Division === memberOfConstraintGroup.category
              ));
              subportOutstanding = subsetAssets.reduce((portfolioValue, asset) => (
                asset.Outstanding * asset.loan_inclusion + portfolioValue
              ), 0);
            } else if (constraintElem.type === 'single_obligor') {
              // const subsetAssets = portfolio.filter((asset) => (
              //  asset.Country_Of_Risk === member_of_constraint_group.category
              //  ));
              // subportOutstanding = subsetAssets.reduce((portfolioValue, elem) => (
              //  elem.Outstanding * elem.loan_inclusion + portfolioValue
              // ), 0);
            }

            const availableBudgetWithinConstraint = memberOfConstraintGroup.value * budget;
            const proposedLoanInclusion = Math.min(availableBudgetWithinConstraint, gapToFill)
              / elem.Outstanding;
            const proFormaSubportfolioOutstanding = subportOutstanding
              + elem.Outstanding * proposedLoanInclusion;
            const proFormaPortfolioOutstanding = portfolioOutstanding
              + elem.Outstanding * proposedLoanInclusion;
            if (memberOfConstraintGroup.value < (
              proFormaSubportfolioOutstanding / proFormaPortfolioOutstanding)) {
              violatedConstraints.push(constraintElem);
            } else if (proposedLoanInclusion < maximumLoanInclusion + 0.0001
              && proposedLoanInclusion > minimumLoanInclusion - 0.0001) {
              finalLoanInclusion = proposedLoanInclusion;
            }
          }
        }
      });
    });

    if (finalLoanInclusion > minimumLoanInclusion - 0.0001) {
      adjustedElem.loan_inclusion = finalLoanInclusion;
      portfolio.push(adjustedElem);
    }
  });
  return portfolio;
};

const constructAndSortPortAllocations = (portfolio, exposureKey, relative) => {
  const uniqueEntries = [...new Set(portfolio.map((elem) => (
    elem[exposureKey]
  )))];

  const portfolioOutstanding = portfolio.reduce((portfolioValue, asset) => (
    asset.Outstanding * asset.loan_inclusion + portfolioValue
  ), 0);

  const exposureMap = uniqueEntries.map((entry, index) => (
    { id: index, category: entry, value: 0 }
  ));

  portfolio.forEach((asset) => {
    const exposureElem = exposureMap.filter((exposure) => (
      exposure.category === asset[exposureKey]
    ));

    exposureElem[0].value += relative
      ? (asset.Outstanding * asset.loan_inclusion) / portfolioOutstanding
      : asset.Outstanding * asset.loan_inclusion;
  });
  return exposureMap;
};

const constructPortfolioRedemption = (portfolio) => {
  const uniqueMaturityDates = [...new Set(portfolio.map((elem) => elem.Maturity_Date))];
  uniqueMaturityDates.sort((a, b) => (
    new Date(a) - new Date(b)
  ));

  const expirationProfile = uniqueMaturityDates.map((date, index) => (
    { id: index, date, expiringAssets: [] }
  ));

  portfolio.forEach((asset) => {
    const expDate = new Date(asset.Maturity_Date);
    const dateMapElem = expirationProfile.find((date) => (
      new Date(date.date) >= expDate
    ));
    const dateMapElemIndex = expirationProfile.map((elem) => (
      elem.id
    )).indexOf(dateMapElem.id);
    expirationProfile[dateMapElemIndex].expiringAssets.push(asset);
  });
  return expirationProfile;
};

const downscaleLoanInclusionToFitBudget = (portfolio, violationAmount, minimumLoanInclusion) => {
  let totalValueAdjustment = 0;
  const downscaledPortfolio = portfolio.forEach((elem) => {
    const adjustedElem = elem;
    if (totalValueAdjustment < violationAmount) {
      if (violationAmount > elem.Outstanding * elem.loan_inclusion) {
        const priorLoanInclusion = elem.loan_inclusion;
        adjustedElem.loan_inclusion = minimumLoanInclusion;
        totalValueAdjustment += (priorLoanInclusion - minimumLoanInclusion) * elem.Outstanding;
      } else {
        const priorLoanInclusion = elem.loan_inclusion;
        adjustedElem.loan_inclusion = Math.max(
          Math.min(
            elem.loan_inclusion,
            ((elem.loan_inclusion * elem.Outstanding) - violationAmount) / elem.Outstanding,
          ),
          minimumLoanInclusion,
        );
        totalValueAdjustment += (priorLoanInclusion - elem.loan_inclusion) * elem.Outstanding;
      }
    }
    return adjustedElem;
  });
  return downscaledPortfolio;
};

class PortfolioConstruction extends React.Component {
  constructor(props) {
    super(props);

    this.availableBudgetRef = React.createRef();
    this.dataSetIDRef = React.createRef();
    this.maxOriginalTermRef = React.createRef();
    this.maxPartialPurchasePercentageRef = React.createRef();
    this.minNumberOfFacilitiesRef = React.createRef();
    this.minPartialPurchaseModeRef = React.createRef();
    this.minPartialPurchaseValueRef = React.createRef();
    this.maxOriginalTermRef = React.createRef();
    this.maxPartialPurchasePercentageRef = React.createRef();
    this.minimumCountriesConstraintRef = React.createRef();
    this.minimumRegionsConstraintRef = React.createRef();
    this.minimumIndustryGroupsConstraintRef = React.createRef();
    this.maxWeightedAverageObligorPDRef = React.createRef();
    this.minNumberOfObligorsRef = React.createRef();
    this.minNumberOfProductsRef = React.createRef();
    this.maxSingleTransactionExposureRef = React.createRef();
    this.minExposureBelowRemainingTenorRef = React.createRef();
    this.maxPercPerIndustryInAnyRegionRef = React.createRef();
    this.daysApplicableRemainingTenorRef = React.createRef();
    this.maxNonTop1IndustryGroupExposureRef = React.createRef();
    this.maxTop1IndustryGroupExposureRef = React.createRef();

    const { match } = this.props;

    if (match === undefined || match.params.id === undefined) {
      this.state = {
        activeCountryConstraints: undefined,
        activeSectorContraints: undefined,
        activeRatingConstraints: undefined,
        activeCountryRatingConstraints: undefined,
        activeSingleObligorConstraints: undefined,
        availableBudget: undefined,
        optimizationMethod: 'maximize_return',
        constructedPortfolio: undefined,
        cachedConstructedPortfolios: [],
        constraintsDetermined: false,
        countriesInDataset: undefined,
        countryRatingsInDataset: undefined,
        sectorsInDataset: undefined,
        ratingsInDataset: undefined,
        originatingBanksInDataset: undefined,
        selectedDataSetID: undefined,
        PDScaling: false,
        minPartialPurchaseMode: 'percentage',
        PDScalingMethod: 'linear_pd_scaling',
        dataSetName: undefined,
        portfolioCharacteristics: [],
        renderHelp: false,
        helpText: undefined,
      };
    } else {
      this.state = {
        constructedPortfolio: undefined,
        selectedDataSetID: match.params.id,
        dataSetName: undefined,
        portfolioCharacteristics: [],
        renderHelp: false,
        helpText: undefined,
      };
    }
    this.findActiveConstraints = this.findActiveConstraints.bind(this);
    this.saveDataSetID = this.saveDataSetID.bind(this);
    this.savePortfolioConstraints = this.savePortfolioConstraints.bind(this);
    // this.constructPortfolio = this.constructPortfolio.bind(this);
    this.constructPortfolio2 = this.constructPortfolio2.bind(this);
    this.updatePortfolioCharacteristics = this.updatePortfolioCharacteristics.bind(this);
    this.calculatePortfolioCharacteristics = this.calculatePortfolioCharacteristics.bind(this);
    this.determineExposuresInDataset = this.determineExposuresInDataset.bind(this);
    this.renderHelp = this.renderHelp.bind(this);
    this.updateOptimizationMethod = this.updateOptimizationMethod.bind(this);
    this.togglePDScaling = this.togglePDScaling.bind(this);
    this.updatePDScalingMethod = this.updatePDScalingMethod.bind(this);
    this.timeScalePDsInPortfolio = this.timeScalePDsInPortfolio.bind(this);
    this.customSorter = this.customSorter.bind(this);
    this.addConstructedPortfolioToCache = this.addConstructedPortfolioToCache.bind(this);
    this.clearCachedConstructedPortfolios = this.clearCachedConstructedPortfolios.bind(this);
    this.downloadGeneratedPortfolioToCSV = this.downloadGeneratedPortfolioToCSV.bind(this);
    this.filterMaxOriginalTerm = this.filterMaxOriginalTerm.bind(this);
    this.checkMinimumCountriesCompliance = this.checkMinimumCountriesCompliance.bind(this);
    this.checkMinimumRegionsCompliance = this.checkMinimumRegionsCompliance.bind(this);
    this.mapCountriesToRegions = this.mapCountriesToRegions.bind(this);
    this.checkMinimumIndustryGroupsCompliance = this
      .checkMinimumIndustryGroupsCompliance.bind(this);
    this.mapIndustriesToIndustryGroups = this.mapIndustriesToIndustryGroups.bind(this);
    this.calculateWeightedAveragePD = this.calculateWeightedAveragePD.bind(this);
    this.checkMinimumNumberOfObligorCompliance = this
      .checkMinimumNumberOfObligorCompliance.bind(this);
    this.checkMinimumNumberOfProductsCompliance = this
      .checkMinimumNumberOfProductsCompliance.bind(this);
    this.checkMaximumSingleTransactionExposure = this
      .checkMaximumSingleTransactionExposure.bind(this);
    this.checkMaxPerIndustryExposureInAnyRegionCompliance = this
      .checkMaxPerIndustryExposureInAnyRegionCompliance.bind(this);
    this.checkMaxExposurePerIndustryGroupAndTopIndustry = this
      .checkMaxExposurePerIndustryGroupAndTopIndustry.bind(this);
    this.calculateRegionExposures = this.calculateRegionExposures.bind(this);
    this.lookUpRegionFromCountry = this.lookUpRegionFromCountry.bind(this);
    this.lookUpIndustryGroupFromIndustry = this.lookUpIndustryGroupFromIndustry.bind(this);
    this.lookUpSNPRatingToS2CapitalPercentage = this
      .lookUpSNPRatingToS2CapitalPercentage.bind(this);
    this.lookUpIndustryDescriptionFromNAICS = this.lookUpIndustryDescriptionFromNAICS.bind(this);
    this.loadFirstDataset = this.loadFirstDataset.bind(this);
  }

  componentDidMount() {
    const { fetchDataSets } = this.props;
    fetchDataSets(sessionStorage.getItem('jwtToken'));
  }

  componentDidUpdate(prevProps) {
    const {
      loading,
    } = this.props;
    const {
      selectedDataSetID,
    } = this.state;

    // UPDATE FOR UPDATED DATASET ID, TO CALL FETCH TRANSACTIONS
    if (loading.FETCH_TRANSACTIONS !== prevProps.loading.FETCH_TRANSACTIONS
      && prevProps.loading.FETCH_TRANSACTIONS !== undefined) {
      this.determineExposuresInDataset();
    }

    if (loading.FETCH_DATASETS === false && selectedDataSetID === undefined) {
      this.loadFirstDataset();
    }
  }

  loadFirstDataset = () => {
    const { datasets, fetchTransactions } = this.props;
    if (datasets.data !== undefined && datasets.data.length > 0) {
      this.setState({
        selectedDataSetID: datasets.data[0].dataSetID,
        dataSetName: datasets.data[0].dataSetName,
      });
      fetchTransactions(datasets.data[0].dataSetID, sessionStorage.getItem('jwtToken'));
    }
  }

  downloadGeneratedPortfolioToCSV = (portfolio, columns) => {
    let csvContent = `data:text/csv;charset=utf-8,${columns.join(',')}\n`;
    portfolio.forEach((elem) => {
      columns.forEach((col) => {
        csvContent += `${elem[col]},`;
      });
      csvContent += '\n';
    });
    const encodedUri = encodeURI(csvContent);
    window.open(encodedUri);
    return false;
  }

  customSorter = (portfolio, timeScaledValues, sortingMetric) => {
    if (timeScaledValues && sortingMetric === 'maximize_return') {
      portfolio.sort((a, b) => {
        const activeCustomerRateA = a.time_scaled_customer_rate;
        const activeCustomerRateB = b.time_scaled_customer_rate;
        if (activeCustomerRateA < activeCustomerRateB) {
          return 1;
        }
        return -1;
      });
    } else if (timeScaledValues && sortingMetric === 'solvency_RoC') {
      portfolio.sort((a, b) => {
        const activeCustomerRateA = a.time_scaled_customer_rate;
        const activeCustomerSolvencyChargeA = a.solvency_capital;
        const activeCustomerRateB = b.time_scaled_customer_rate;
        const activeCustomerSolvencyChargeB = b.solvency_capital;
        if ((activeCustomerRateA / activeCustomerSolvencyChargeA)
          < (activeCustomerRateB / activeCustomerSolvencyChargeB)) {
          return 1;
        }
        return -1;
      });
    } else if (timeScaledValues) {
      portfolio.sort((a, b) => {
        const activeCustomerRateA = a.time_scaled_customer_rate;
        const activeCustomerPDA = a.time_scaled_pd;
        const activeCustomerRateB = b.time_scaled_customer_rate;
        const activeCustomerPDB = b.time_scaled_pd;
        if ((activeCustomerRateA / (activeCustomerPDA * a.LGD_eff_RC))
          < (activeCustomerRateB / (activeCustomerPDB * b.LGD_eff_RC))) {
          return 1;
        }
        return -1;
      });
    } else if (!timeScaledValues && sortingMetric === 'maximize_return') {
      portfolio.sort((a, b) => {
        if (a.CUSTOMER_RATE < b.CUSTOMER_RATE) {
          return 1;
        }
        return -1;
      });
    } else if (!timeScaledValues && sortingMetric === 'solvency_RoC') {
      portfolio.sort((a, b) => (
        ((b.CUSTOMER_RATE * b.Outstanding) / b.solvency_capital)
        - ((a.CUSTOMER_RATE * a.Outstanding) / a.solvency_capital)
      ));
    } else {
      portfolio.sort((a, b) => {
        if ((a.CUSTOMER_RATE / (a.PD_RC * a.LGD_eff_RC))
          < (b.CUSTOMER_RATE / (b.PD_RC * b.LGD_eff_RC))) {
          return 1;
        }
        return -1;
      });
    }
    return portfolio;
  }

  addConstructedPortfolioToCache = (portfolio) => {
    this.setState((prevState) => ({
      cachedConstructedPortfolios: [
        ...prevState.cachedConstructedPortfolios,
        { id: prevState.cachedConstructedPortfolios.length, constructed_portfolio: portfolio },
      ],
    }));
  }

  clearCachedConstructedPortfolios = () => {
    this.setState({
      cachedConstructedPortfolios: [],
    });
  }

  // Force a url update to reflected the selected jobID and retrieve the data
  // for that jobID.
  saveDataSetID = () => {
    const { fetchTransactions, datasets } = this.props;
    fetchTransactions(this.dataSetIDRef.current.value,
      sessionStorage.getItem('jwtToken'));
    this.setState({
      selectedDatasetID: parseInt(this.dataSetIDRef.current.value, 10),
      dataSetName: datasets.data.filter((elem) => (
        elem.dataSetID === parseInt(this.dataSetIDRef.current.value, 10)
      ))[0].dataSetName,
      countriesInDataset: undefined,
    });
  }

  savePortfolioConstraints = (value) => {
    value.preventDefault();
    this.findActiveConstraints();
  }

  renderHelp = (content) => {
    if (content !== undefined) {
      this.setState({ renderHelp: true, helpText: content });
    } else {
      this.setState({ renderHelp: false, helpText: undefined });
    }
    return false;
  }

  saveToExcel = (portfolio, columns) => {
    this.downloadGeneratedPortfolioToCSV(portfolio, columns);
    return false;
  }

  constructPortfolio2 = (budget, previousPortfolio, budgetIterations, originalBudget,
    minimumLoanInclusion) => {
    const { optimizationMethod, PDScaling, PDScalingMethod } = this.state;
    const { transactions } = this.props;

    let constructedPortfolio;
    let investmentBudget = budget;

    if (previousPortfolio === undefined) {
      constructedPortfolio = transactions.data.map((elem) => elem);
    } else {
      constructedPortfolio = previousPortfolio;
    }

    const { availableBudget } = this.state;

    if (originalBudget === undefined) {
      investmentBudget = availableBudget !== undefined ? availableBudget : 1000000;
    }

    if (budgetIterations > 50) {
      return false;
    }

    let assetsToBeIncluded = [];
    let sumWeightedOutstanding = 0.0;

    constructedPortfolio = this.filterMaxOriginalTerm(constructedPortfolio,
      parseFloat(this.maxOriginalTermRef.current.value));

    if (PDScaling) {
      constructedPortfolio = this.timeScalePDsInPortfolio(constructedPortfolio, PDScalingMethod);
    }

    constructedPortfolio = constructedPortfolio.map((elem) => {
      const newElem = elem;
      if (elem.loan_inclusion === undefined || previousPortfolio === undefined) {
        newElem.loan_inclusion = parseFloat(this.maxPartialPurchasePercentageRef.current.value);
      }
      return newElem;
    });

    constructedPortfolio = constructedPortfolio.map((elem) => {
      const newElem = elem;
      newElem.solvency_capital = this.lookUpSNPRatingToS2CapitalPercentage(elem.Rating)
        * elem.Outstanding * elem.loan_inclusion;
      return newElem;
    });

    constructedPortfolio = this.customSorter(constructedPortfolio, PDScaling,
      optimizationMethod);

    constructedPortfolio = constructedPortfolio.map((elem) => {
      const newElem = elem;
      if (elem.loan_inclusion === undefined) {
        newElem.loan_inclusion = parseFloat(this.maxPartialPurchasePercentage.current.value);
      }
      return newElem;
    });

    const activeConstraints = [];
    activeConstraints.push({ type: 'country', constraint: [] });
    activeConstraints.push({ type: 'sector', constraint: [] });
    activeConstraints.push({ type: 'rating', constraint: [] });
    activeConstraints.push({ type: 'country_rating', constraint: [] });
    activeConstraints.push({ type: 'single_obligor', constraint: [] });
    activeConstraints.push({
      type: 'minNumberBinary',
      constraint: [
        { category: 'minNumberOfFacilities', value: parseInt(this.minNumberOfFacilitiesRef.current.value, 10) },
      ],
    });

    const { activeCountryConstraints } = this.state;
    activeCountryConstraints.forEach((elem) => {
      const dictToPush = activeConstraints.filter((constraint) => constraint.type === 'country')[0];
      const { countriesInDataset } = this.state;
      dictToPush.constraint.push(
        { index: elem.index, value: elem.value, category: countriesInDataset[elem.index] },
      );
    });

    const { activeSectorContraints } = this.state;
    activeSectorContraints.forEach((elem) => {
      const dictToPush = activeConstraints.filter((constraint) => constraint.type === 'sector')[0];
      const { sectorsInDataset } = this.state;
      dictToPush.constraint.push(
        { index: elem.index, value: elem.value, category: sectorsInDataset[elem.index] },
      );
    });

    const { activeRatingConstraints } = this.state;
    activeRatingConstraints.forEach((elem) => {
      const dictToPush = activeConstraints.filter((constraint) => constraint.type === 'rating')[0];
      const { ratingsInDataset } = this.state;
      dictToPush.constraint.push(
        { index: elem.index, value: elem.value, category: ratingsInDataset[elem.index] },
      );
    });

    const { activeCountryRatingConstraints } = this.state;
    activeCountryRatingConstraints.forEach((elem) => {
      const dictToPush = activeConstraints.filter((constraint) => constraint.type === 'country_rating')[0];
      const { countryRatingsInDataset } = this.state;
      dictToPush.constraint.push(
        { index: elem.index, value: elem.value, category: countryRatingsInDataset[elem.index] },
      );
    });

    const { activeSingleObligorConstraints, ratingsInDataset } = this.state;
    activeSingleObligorConstraints.forEach((elem) => {
      const dictToPush = activeConstraints.filter((constraint) => constraint.type === 'single_obligor')[0];
      dictToPush.constraint.push(
        { index: elem.index, value: elem.value, category: ratingsInDataset[elem.index] },
      );
    });

    let iterations = 0;
    while (sumWeightedOutstanding < investmentBudget) {
      if (iterations > 50 || Math.abs(sumWeightedOutstanding - investmentBudget) < 0.001) {
        break;
      }

      if (iterations > 0) {
        investmentBudget -= Math.max(0.0, investmentBudget - sumWeightedOutstanding);
      }

      sumWeightedOutstanding = 0.0;

      // What to do with this part?
      constructedPortfolio = constructedPortfolio.map((elem) => {
        const newElem = elem;
        newElem.solvency_capital = this.lookUpSNPRatingToS2CapitalPercentage(elem.Rating)
          * elem.Outstanding * elem.loan_inclusion;
        return newElem;
      });
      // End of check

      assetsToBeIncluded = sourcePortfolioFromAssetPool(constructedPortfolio,
        assetsToBeIncluded, activeConstraints, investmentBudget, minimumLoanInclusion);
      assetsToBeIncluded = adjustPortfolioToComplyWithMinimumNumberOfConstituents(
        assetsToBeIncluded, constructedPortfolio, activeConstraints, minimumLoanInclusion,
      );
      // needs to be a reversed iterator
      assetsToBeIncluded = adjustLoanInclusionToConstraints(assetsToBeIncluded,
        activeConstraints, minimumLoanInclusion, investmentBudget);
      assetsToBeIncluded = fillPortfolioToBudget(assetsToBeIncluded,
        constructedPortfolio, activeConstraints, minimumLoanInclusion,
        parseFloat(this.maxPartialPurchasePercentageRef.current.value), investmentBudget);
      sumWeightedOutstanding = assetsToBeIncluded.reduce((portfolioValue, elem) => (
        elem.Outstanding * elem.loan_inclusion + portfolioValue
      ), 0);
      iterations += 1;
    }

    sumWeightedOutstanding = assetsToBeIncluded.reduce((portfolioValue, elem) => (
      elem.Outstanding * elem.loan_inclusion + portfolioValue
    ), 0);

    assetsToBeIncluded.reverse();

    let violation = sumWeightedOutstanding - investmentBudget;

    while (violation > 0) {
      assetsToBeIncluded = downscaleLoanInclusionToFitBudget(assetsToBeIncluded,
        violation, minimumLoanInclusion);
      sumWeightedOutstanding = assetsToBeIncluded.reduce((portfolioValue, elem) => (
        elem.Outstanding * elem.loan_inclusion + portfolioValue
      ), 0);
      violation = sumWeightedOutstanding - investmentBudget;
    }

    assetsToBeIncluded.reverse();

    assetsToBeIncluded = assetsToBeIncluded.map((elem) => {
      const newElem = elem;
      const scalingFactor = originalBudget === undefined ? 1 : (originalBudget / investmentBudget);
      newElem.loan_inclusion *= scalingFactor;
      return elem;
    });

    sumWeightedOutstanding = assetsToBeIncluded.reduce((portfolioValue, elem) => (
      elem.Outstanding * elem.loan_inclusion + portfolioValue
    ), 0);

    assetsToBeIncluded = assetsToBeIncluded.map((elem) => {
      const newElem = elem;
      newElem.portfolio_weight = (elem.Outstanding * elem.loan_inclusion) / sumWeightedOutstanding;
      // console.log(elem.transactionID)
      // console.log(this.lookUpSNPRatingToS2CapitalPercentage(elem.Rating))
      // console.log(elem.Outstanding)
      // console.log(elem.loan_inclusion)
      newElem.solvency_capital = this.lookUpSNPRatingToS2CapitalPercentage(elem.Rating)
        * elem.Outstanding * elem.loan_inclusion;
      // console.log(this.lookUpSNPRatingToS2CapitalPercentage(elem.Rating)
      // * elem.Outstanding * elem.loan_inclusion)
      // console.log(elem.solvency_capital)
      return newElem;
    });

    this.setState({
      constructedPortfolio: assetsToBeIncluded,
    });
    const minimumCountryCompliance = this.checkMinimumCountriesCompliance(assetsToBeIncluded,
      parseInt(this.minimumCountriesConstraintRef.current.value, 10));
    const minimumRegionCompliance = this.checkMinimumRegionsCompliance(assetsToBeIncluded,
      parseInt(this.minimumRegionsConstraintRef.current.value, 10));
    const minimumIndustryGroupsCompliance = this.checkMinimumIndustryGroupsCompliance(
      assetsToBeIncluded, parseInt(this.minimumIndustryGroupsConstraintRef.current.value, 10),
    );
    const maximumWeightedAverageObligorPDCompliance = this.calculateWeightedAveragePD(
      assetsToBeIncluded,
    ) < parseFloat(this.maxWeightedAverageObligorPDRef.current.value);
    const minimumNumberOfObligorsCompliance = this.checkMinimumNumberOfObligorCompliance(
      assetsToBeIncluded, parseInt(this.minNumberOfObligorsRef.current.value, 10),
    );
    const minimumNumberOfFacilitiesCompliance = assetsToBeIncluded.length
      >= parseInt(this.minNumberOfFacilitiesRef.current.value, 10);
    const minimumNumberOfProductsCompliance = this.checkMinimumNumberOfProductsCompliance(
      assetsToBeIncluded, parseInt(this.minNumberOfProductsRef.current.value, 10),
    );
    const singleTransactionExposureCompliance = this.checkMaximumSingleTransactionExposure(
      assetsToBeIncluded, parseFloat(this.maxSingleTransactionExposureRef.current.value),
    );
    const minPercBelowRemTenorCompliance = this.checkMinimumExposureBelowRemainingTenor(
      assetsToBeIncluded, parseFloat(this.minExposureBelowRemainingTenorRef.current.value),
      parseInt(this.daysApplicableRemainingTenorRef.current.value, 10),
    );
    const maximumExposurePercentagePerIndustryInRegionsCompliance = this
      .checkMaxPerIndustryExposureInAnyRegionCompliance(
        assetsToBeIncluded,
        parseFloat(this.maxPercPerIndustryInAnyRegionRef.current.value),
      );
    const maximumExposurePercentagePerIndustryGroupAndTopExposureCompliance = this
      .checkMaxExposurePerIndustryGroupAndTopIndustry(
        assetsToBeIncluded,
        parseFloat(this.maxNonTop1IndustryGroupExposureRef.current.value),
        parseFloat(this.maxTop1IndustryGroupExposureRef.current.value),
      );

    const complianceChecks = [
      { check: minimumCountryCompliance, violation_message: 'Portfolio is not compliant with the minimum number of countries.' },
      { check: minimumRegionCompliance, violation_message: 'Portfolio is not compliant with the minimum number of regions.' },
      { check: minimumIndustryGroupsCompliance, violation_message: 'Portfolio is not compliant with the minimum number of industry groups.' },
      { check: maximumWeightedAverageObligorPDCompliance, violation_message: 'Portfolio is not compliant with the maximum weighted average obligor PD' },
      { check: minimumNumberOfObligorsCompliance, violation_message: 'Portfolio is not compliant with the minimum number of obligors.' },
      { check: minimumNumberOfFacilitiesCompliance, violation_message: 'Portfolio is not compliant with the minimum number of facilities.' },
      { check: minimumNumberOfProductsCompliance, violation_message: 'Portfolio is not compliant with the minimum number of Trade Finance products.' },
      { check: singleTransactionExposureCompliance, violation_message: 'Portfolio is not compliant with the maximum exposure of a single transaction.' },
      { check: minPercBelowRemTenorCompliance, violation_message: 'Portfolio is not compliant with the percentage that needs to be held for a shorter duration.' },
      { check: maximumExposurePercentagePerIndustryInRegionsCompliance, violation_message: 'Portfolio is not compliant with the exposure per industry in any region.' },
      { check: maximumExposurePercentagePerIndustryGroupAndTopExposureCompliance, violation_message: 'Portfolio is not compliant with the exposure per industry group except the top one.' },
    ];

    if (complianceChecks.filter((elem) => elem.check === false).length > 0) {
      if (budgetIterations < 40) {
        return this.constructPortfolio2(budget * 1.1, undefined, budgetIterations + 1,
          originalBudget === undefined ? investmentBudget : originalBudget, minimumLoanInclusion);
      }
      this.updatePortfolioCharacteristics([
        { id: 0, constructed_portfolio: assetsToBeIncluded },
      ]);
      const violatedChecks = complianceChecks.filter((elem) => (elem.check === false));

      const violationMessages = violatedChecks.map(
        (violationObject) => <div>{violationObject.violation_message}</div>,
      );

      this.renderHelp({ header: 'Constructed portfolio not compliant', body: violationMessages });
    } else {
      this.updatePortfolioCharacteristics([{ id: 0, constructed_portfolio: assetsToBeIncluded }]);
    }
    return true;
  }

  determineExposuresInDataset = () => {
    const { selectedDatasetID } = this.state;
    const { loading, transactions } = this.props;
    if (loading.FETCH_TRANSACTIONS === true
      || selectedDatasetID !== transactions.data[0].dataSetID) {
      this.setState({
        countriesInDataset: undefined,
      });
    }

    const datasetCountries = [...new Set(transactions.data.map((elem) => (
      elem.Country_Of_Risk
    )))];
    const datasetCountryRatings = [...new Set(transactions.data.map((elem) => (
      elem.Group_Division
    )))];
    let datasetSectors = [...new Set(transactions.data.map((elem) => (
      parseInt(elem.NAICS, 10)
    )))];
    datasetSectors = datasetSectors.map((elem, index) => (
      { index, NAICS: elem, description: this.lookUpIndustryDescriptionFromNAICS(elem) }
    ));
    const datasetRatings = [...new Set(transactions.data.map((elem) => elem.Rating))];
    const datasetOriginatingBanks = [...new Set(transactions.data.map((elem) => (
      elem.Originating_Entity
    )))];

    this.setState({
      countriesInDataset: datasetCountries,
      countryRatingsInDataset: datasetCountryRatings,
      sectorsInDataset: datasetSectors,
      ratingsInDataset: datasetRatings,
      originatingBanksInDataset: datasetOriginatingBanks,
    });
  }

  updatePortfolioCharacteristics = (arrayOfPortfolios) => {
    const { PDScaling } = this.state;
    const portfolioCharacteristics = this.calculatePortfolioCharacteristics(
      arrayOfPortfolios, PDScaling, parseFloat(this.availableBudgetRef.current.value),
    );
    this.setState({
      portfolioCharacteristics,
    });
  }

  calculatePortfolioCharacteristics = (arrayOfPortfolios, pdScaling, availableBudget) => {
    const portfolioCharacteristics = [];
    arrayOfPortfolios.forEach((portfolio) => {
      let portfolioExpectedReturn = 0.0;
      let portfolioExpectedLoss = 0.0;
      let investedAmount = 0.0;
      let solvencyCapital = 0.0;

      portfolio.constructed_portfolio.forEach((elem) => {
        const activeCustomerRate = pdScaling ? elem.time_scaled_customer_rate : elem.CUSTOMER_RATE;
        const activeCustomerPD = pdScaling ? elem.time_scaled_pd : elem.PD_RC;
        portfolioExpectedReturn += elem.portfolio_weight * activeCustomerRate;
        portfolioExpectedLoss += elem.portfolio_weight * activeCustomerPD * elem.LGD_eff_RC;
        investedAmount += elem.loan_inclusion * elem.Outstanding;
        solvencyCapital += elem.solvency_capital;
      });

      portfolioCharacteristics.push([
        {
          metric: 'solvency_capital', value: solvencyCapital, description_prior: 'Solvency Capital: ', description_after: '', active: true,
        },
        {
          metric: 'expectedPortfolioReturn', value: portfolioExpectedReturn, description_prior: 'Expected fund performance: ', description_after: 'bps above LIBOR', active: true,
        },
        {
          metric: 'expectedPortfolioLoss', value: 10000 * portfolioExpectedLoss, description_prior: 'Expected annual credit losses: ', description_after: 'bps', active: true,
        },
        {
          metric: 'fundsInvested', value: investedAmount, description_prior: '', description_after: '', active: false,
        },
        {
          metric: 'availableBudget', value: availableBudget, description_prior: '', description_after: '', active: false,
        },
        {
          metric: 'returnOnS2Capital', value: (((portfolioExpectedReturn - portfolioExpectedLoss) * investedAmount) / solvencyCapital) / 100, description_prior: 'Expected Return on S2 Capital: ', description_after: '%', active: true,
        },
        {
          metric: 'ReturnOverS2Capital', value: portfolioExpectedReturn / solvencyCapital, description_prior: '', description_after: '', active: false,
        },
        {
          metric: 'availableBudget', value: availableBudget, description_prior: '', description_after: '', active: false,
        },
        {
          metric: 'investedAmountOverBudget', value: 100 * (investedAmount / availableBudget), description_prior: 'Invested amount as % of budget: ', description_after: '%', active: true,
        },
      ]);
    });
    return portfolioCharacteristics;
  }

  updateOptimizationMethod = (method) => {
    this.setState({ optimizationMethod: method });
  }

  togglePDScaling = () => {
    this.setState((prevState) => ({ PDScaling: !prevState.PDScaling }));
  }

  updatePDScalingMethod = (method) => {
    this.setState({
      PDScalingMethod: method,
    });
  }

  timeScalePDsInPortfolio = (portfolio, method) => {
    let changedPortfolio = [];
    if (method === 'linear_pd_scaling') {
      changedPortfolio = portfolio.map((elem) => {
        const changedElem = elem;
        const startDate = new Date(changedElem.Start_Date);
        const maturityDate = new Date(changedElem.Maturity_Date);

        const daysUntilMaturity = (maturityDate - startDate) / (60 * 60 * 24 * 1000);
        changedElem.time_scaled_pd = (1.0 + changedElem.PD_RC / 100)
          ** (daysUntilMaturity / 364.24) - 1;
        changedElem.time_scaled_customer_rate = ((1.0 + changedElem.CUSTOMER_RATE / 10000)
          ** (daysUntilMaturity / 364.24) - 1) * 10000;
        return changedElem;
      });
    }
    return changedPortfolio;
  }

  findActiveConstraints = () => {
    const countryLimits = [];
    const countryRatingLimits = [];
    const sectorLimits = [];
    const ratingLimits = [];
    const singleObligorLimits = [];
    const {
      countryRatingsInDataset,
      countriesInDataset,
      originatingBanksInDataset,
      ratingsInDataset,
      sectorsInDataset,
    } = this.state;

    countriesInDataset.forEach((elem, index) => {
      if (parseFloat(this.refs[`country-limit-${index}`].value) < 2) {
        countryLimits.push({ index, value: parseFloat(this.refs[`country-limit-${index}`].value) });
      }
    });

    countryRatingsInDataset.forEach((elem, index) => {
      if (parseFloat(this.refs[`country-rating-limit-${index}`].value) < 2) {
        countryRatingLimits.push({ index, value: parseFloat(this.refs[`country-rating-limit-${index}`].value) });
      }
    });

    sectorsInDataset.forEach((elem, index) => {
      if (parseFloat(this.refs[`sector-limit-${index}`].value) < 2) {
        sectorLimits.push({ index, value: parseFloat(this.refs[`sector-limit-${index}`].value) });
      }
    });

    ratingsInDataset.forEach((elem, index) => {
      if (parseFloat(this.refs[`rating-limit-${index}`].value) < 2) {
        ratingLimits.push({ index, value: parseFloat(this.refs[`rating-limit-${index}`].value) });
      }
    });

    originatingBanksInDataset.forEach((bankElem, bankIndex) => {
      ratingsInDataset.forEach((ratingElem, ratingIndex) => {
        if (parseFloat(this.refs[`originating-bank-limit-${bankIndex}-${ratingIndex}`].value) < 2) {
          singleObligorLimits.push({ index: bankIndex * ratingsInDataset.length + ratingIndex, value: parseFloat(this.refs[`originating-bank-limit-${bankIndex}-${ratingIndex}`].value) });
        }
      });
    });

    this.setState({
      activeCountryConstraints: countryLimits,
      activeCountryRatingConstraints: countryRatingLimits,
      activeSectorContraints: sectorLimits,
      activeRatingConstraints: ratingLimits,
      activeSingleObligorConstraints: singleObligorLimits,
      availableBudget: parseFloat(this.availableBudgetRef.current.value),
      constraintsDetermined: true,
    });
  }

  filterMaxOriginalTerm = (portfolio, maxOriginalTerm) => {
    const filteredPortfolio = portfolio.filter((elem) => (
      (new Date(elem.Maturity_Date).getTime() - new Date(elem.Start_Date).getTime())
        / (1000 * 60 * 60 * 24) <= maxOriginalTerm
    ));
    return filteredPortfolio;
  }

  checkMinimumCountriesCompliance = (portfolio, minimumCountries) => {
    const countriesInPortfolio = [...new Set(portfolio.map((elem) => elem.Country_Of_Risk))].length;
    if (countriesInPortfolio < minimumCountries) {
      return false;
    }
    return true;
  }

  checkMinimumRegionsCompliance = (portfolio, minimumRegions) => {
    const countriesInPortfolio = [...new Set(portfolio.map((elem) => elem.Country_Of_Risk))];
    const mappedRegions = [...new Set(this.mapCountriesToRegions(countriesInPortfolio))];
    if (mappedRegions.length < minimumRegions) {
      return false;
    }
    return true;
  }

  mapCountriesToRegions = (countries) => {
    const countryToRegionMapping = [
      { country: 'Australia', region: 'Asia-Pacific' },
      { country: 'Bangladesh', region: 'South Asia' },
      { country: 'Belgium', region: 'Western Europe' },
      { country: 'Brazil', region: 'South America' },
      { country: 'China', region: 'South America' },
      { country: 'Egypt', region: 'Africa' },
      { country: 'France', region: 'Western Europe' },
      { country: 'Germany', region: 'Western Europe' },
      { country: 'Hong Kong', region: 'North Asia' },
      { country: 'India', region: 'South Asia' },
      { country: 'Japan', region: 'East Asia' },
      { country: 'Marocco', region: 'Africa' },
      { country: 'Mauritius', region: 'Africa' },
      { country: 'Netherlands', region: 'Europe' },
      { country: 'Nigeria', region: 'Africa' },
      { country: 'Oman', region: 'Middle East' },
      { country: 'Qatar', region: 'Middle East' },
      { country: 'Turkey', region: 'Eastern Europe' },
      { country: 'Singapore', region: 'East Asia' },
      { country: 'South Korea', region: 'East Asia' },
      { country: 'Spain', region: 'Western Europe' },
      { country: 'Sri Lanka', region: 'South Asia' },
      { country: 'Switzerland', region: 'Western Europe' },
      { country: 'UAE', region: 'Middle East' },
      { country: 'UK', region: 'Western Europe' },
      { country: 'USA', region: 'North America' },
    ];

    const mappedRegions = [];
    countries.forEach((elem) => {
      const region = countryToRegionMapping.filter((filterElem) => elem === filterElem.country);
      if (region.length > 0) {
        mappedRegions.push(region[0].region);
      } else {
        mappedRegions.push(undefined);
      }
    });
    return mappedRegions;
  }

  checkMinimumIndustryGroupsCompliance = (portfolio, minimumIndustryGroups) => {
    const industriesInPortfolio = [...new Set(portfolio.map((elem) => elem.NAICS))];
    const mappedIndustryGroups = [
      ...new Set(this.mapIndustriesToIndustryGroups(industriesInPortfolio)),
    ];
    return mappedIndustryGroups.length >= minimumIndustryGroups;
  }

  mapIndustriesToIndustryGroups = (industries) => (
    industries.map((elem) => (this.lookUpIndustryGroupFromIndustry(elem)))
  )

  calculateWeightedAveragePD = (portfolio) => {
    const pdOutstandingProduct = portfolio.map((elem) => (
      elem.PD_RC * elem.Outstanding * elem.loan_inclusion
    )).reduce((product, asset) => product + asset, 0);
    const portfolioOutstanding = portfolio.reduce((portfolioValue, elem) => (
      elem.Outstanding * elem.loan_inclusion + portfolioValue
    ), 0);
    const weightedAveragePD = pdOutstandingProduct / portfolioOutstanding;
    return weightedAveragePD;
  }

  checkMinimumNumberOfObligorCompliance = (portfolio, minimumNumberOfObligors) => {
    const obligorsInPortfolio = [...new Set(portfolio.map((elem) => elem.WWID_of_Transaction))];
    if (obligorsInPortfolio.length > minimumNumberOfObligors) {
      return true;
    }
    return false;
  }

  checkMinimumNumberOfProductsCompliance = (portfolio, minimumNumberOfProducts) => {
    const productsInPortfolio = [...new Set(portfolio.map((elem) => elem.Product_Type))];
    if (productsInPortfolio.length >= minimumNumberOfProducts) {
      return true;
    }
    return false;
  }

  checkMaximumSingleTransactionExposure = (portfolio, maxSingleTransactionExposure) => {
    const portfolioOutstanding = portfolio.reduce((portfolioValue, elem) => (
      elem.Outstanding * elem.loan_inclusion + portfolioValue
    ), 0);
    const assetsExceedingMaxExposure = portfolio.filter((elem) => (
      ((elem.Outstanding * elem.loan_inclusion) / portfolioOutstanding)
      > maxSingleTransactionExposure
    ));
    if (assetsExceedingMaxExposure.length === 0) {
      return true;
    }
    return false;
  }

  checkMinimumExposureBelowRemainingTenor = (portfolio, minimumPercentage,
    applicableRemainingTenor) => {
    const portfolioOutstanding = portfolio.reduce((portfolioValue, elem) => (
      elem.Outstanding * elem.loan_inclusion + portfolioValue
    ), 0);
    const assetsBelowRemainingTenorThreshold = portfolio.filter((elem) => (
      (new Date(elem.Maturity_Date).getTime() - new Date(elem.Start_Date).getTime())
        / (1000 * 60 * 60 * 24) < applicableRemainingTenor
    ));
    const belowThresholdExposure = assetsBelowRemainingTenorThreshold
      .reduce((subPortfolioValue, elem) => (
        elem.Outstanding + subPortfolioValue
      ), 0);
    if ((belowThresholdExposure / portfolioOutstanding) >= minimumPercentage) {
      return true;
    }
    return false;
  };

  checkMaxExposurePerIndustryGroupAndTopIndustry = (portfolio,
    maxIndustryGroupExposureExclTop, maxTopIndustryExposure) => {
    const industryGroups = this.mapIndustriesToIndustryGroups(
      [...new Set(portfolio.map((elem) => parseInt(elem.NAICS, 10)))],
    );
    const industryGroupExposures = industryGroups.map((elem) => (
      { industry_group: elem, exposure: 0 }
    ));

    portfolio.forEach((elem) => {
      const industryGroup = this.lookUpIndustryGroupFromIndustry(parseInt(elem.NAICS, 10));

      const exposureElement = industryGroupExposures.filter((industryGroupElem) => (
        industryGroupElem.industry_group === industryGroup
      ));

      if (exposureElement.length > 0) {
        exposureElement[0].exposure += elem.Outstanding * elem.loan_inclusion;
      }
    });

    const portfolioOutstanding = portfolio.reduce((portfolioValue, elem) => (
      elem.Outstanding * elem.loan_inclusion + portfolioValue
    ), 0);

    const exposuresAboveMaxIndustryGroupExposureExclTop = industryGroupExposures.filter((elem) => (
      elem.exposure / portfolioOutstanding > maxIndustryGroupExposureExclTop
    ));
    const exposuresAboveMaxTopIndustryExposure = industryGroupExposures.filter((elem) => (
      elem.exposure / portfolioOutstanding > maxTopIndustryExposure
    ));

    if (exposuresAboveMaxTopIndustryExposure.length === 0
      && exposuresAboveMaxIndustryGroupExposureExclTop.length <= 1) {
      return true;
    }
    return false;
  }

  // Not sure if this one is correct
  checkMaxPerIndustryExposureInAnyRegionCompliance = (portfolio, maxExposure) => {
    const regions = this.mapCountriesToRegions([...new Set(portfolio.map((elem) => (
      elem.Country_Of_Risk
    )))]);
    const regionExposures = this.calculateRegionExposures(portfolio, regions);
    const violations = [];
    regionExposures.forEach((region) => {
      const summedRegionExposure = region.exposures.reduce((singleElem, combinedExposure) => (
        singleElem + combinedExposure.exposure
      ), 0);
      if (region.exposures.filter((industry) => (
        industry.exposure / summedRegionExposure > maxExposure
      )).length > 0) {
        violations.push(region);
      }
    });

    if (violations.length > 0) {
      return false;
    }
    return true;
  }

  calculateRegionExposures = (portfolio, regions) => {
    const regionExposures = [];
    regions.forEach((elem) => {
      regionExposures.push({ region: elem, exposures: [] });
    });

    portfolio.forEach((elem) => {
      const counterpartyRegion = this.lookUpRegionFromCountry(elem.Country_Of_Risk);
      const regionExposureElem = regionExposures.filter((region) => (
        region.region === counterpartyRegion
      ));
      const elemOfInterest = regionExposureElem[0].exposures.filter((regionExpElem) => (
        parseInt(regionExpElem.NAICS, 10) === parseInt(elem.NAICS, 10)
      ));
      if (elemOfInterest.length === 0) {
        regionExposureElem[0].exposures.push(
          { NAICS: elem.NAICS, exposure: elem.Outstanding * elem.loan_inclusion },
        );
      } else {
        elemOfInterest[0].exposure += elem.Outstanding * elem.loan_inclusion;
      }
    });
    return regionExposures;
  };

  lookUpRegionFromCountry = (country) => {
    const countryToRegionMapping = [
      { country: 'Australia', region: 'Asia-Pacific' },
      { country: 'Bangladesh', region: 'South Asia' },
      { country: 'Belgium', region: 'Western Europe' },
      { country: 'Brazil', region: 'South America' },
      { country: 'China', region: 'South America' },
      { country: 'Egypt', region: 'Africa' },
      { country: 'France', region: 'Western Europe' },
      { country: 'Germany', region: 'Western Europe' },
      { country: 'Hong Kong', region: 'North Asia' },
      { country: 'India', region: 'South Asia' },
      { country: 'Japan', region: 'East Asia' },
      { country: 'Marocco', region: 'Africa' },
      { country: 'Mauritius', region: 'Africa' },
      { country: 'Netherlands', region: 'Europe' },
      { country: 'Nigeria', region: 'Africa' },
      { country: 'Oman', region: 'Middle East' },
      { country: 'Qatar', region: 'Middle East' },
      { country: 'Turkey', region: 'Eastern Europe' },
      { country: 'Singapore', region: 'East Asia' },
      { country: 'South Korea', region: 'East Asia' },
      { country: 'Spain', region: 'Western Europe' },
      { country: 'Sri Lanka', region: 'South Asia' },
      { country: 'Switzerland', region: 'Western Europe' },
      { country: 'UAE', region: 'Middle East' },
      { country: 'UK', region: 'Western Europe' },
      { country: 'USA', region: 'North America' },
    ];

    const region = countryToRegionMapping.filter((elem) => elem.country === country);
    if (region.length === 1) {
      return region[0].region;
    }
    return undefined;
  }

  lookUpIndustryGroupFromIndustry = (industry) => (
    Math.floor(industry / 10000)
  );

  lookUpSNPRatingToS2CapitalPercentage = (snpRating) => {
    const snpS2Mapping = [
      { snp_rating: 'AAA', s2_cap_percentage: 0.009 },
      { snp_rating: 'AA+', s2_cap_percentage: 0.011 },
      { snp_rating: 'AA', s2_cap_percentage: 0.011 },
      { snp_rating: 'AA-', s2_cap_percentage: 0.011 },
      { snp_rating: 'A+', s2_cap_percentage: 0.014 },
      { snp_rating: 'A', s2_cap_percentage: 0.014 },
      { snp_rating: 'A-', s2_cap_percentage: 0.014 },
      { snp_rating: 'BBB+', s2_cap_percentage: 0.025 },
      { snp_rating: 'BBB', s2_cap_percentage: 0.025 },
      { snp_rating: 'BBB-', s2_cap_percentage: 0.025 },
      { snp_rating: 'BB+', s2_cap_percentage: 0.045 },
      { snp_rating: 'BB', s2_cap_percentage: 0.045 },
      { snp_rating: 'BB-', s2_cap_percentage: 0.045 },
      { snp_rating: 'B+', s2_cap_percentage: 0.075 },
      { snp_rating: 'B', s2_cap_percentage: 0.075 },
      { snp_rating: 'B-', s2_cap_percentage: 0.075 },
      { snp_rating: 'Unrated', s2_cap_percentage: 0.03 },
    ];

    const capitalPercentageElem = snpS2Mapping.filter((elem) => elem.snp_rating === snpRating);
    if (capitalPercentageElem.length > 0) {
      return capitalPercentageElem[0].s2_cap_percentage;
    }
    return 0.075;
  }

  lookUpIndustryDescriptionFromNAICS = (NAICS) => {
    const naicsToDescription = [
      { naics: 10101010, description: 'Oil & Gas Drilling' },
      { naics: 10101020, description: 'Oil & Gas Equipment & Services' },
      { naics: 10102010, description: 'Integrated Oil & Gas' },
      { naics: 10102020, description: 'Oil & Gas Exploration & Production' },
      { naics: 10102030, description: 'Oil & Gas Refining & Marketing' },
      { naics: 10102040, description: 'Oil & Gas Storage & Transportation' },
      { naics: 10102050, description: 'Coal & Consumable Fuels' },
      { naics: 15101010, description: 'Commodity Chemicals' },
      { naics: 15101020, description: 'Diversified Chemicals' },
      { naics: 15101030, description: 'Fertilizers & Agricultural Chemicals' },
      { naics: 15101040, description: 'Industrial Gases' },
      { naics: 15101050, description: 'Specialty Chemicals' },
      { naics: 15102010, description: 'Construction Materials' },
      { naics: 15103010, description: 'Metal & Glass Containers' },
      { naics: 15103020, description: 'Paper Packaging' },
      { naics: 15104010, description: 'Aluminum' },
      { naics: 15104020, description: 'Diversified Metals & Mining' },
      { naics: 15104025, description: 'Copper' },
      { naics: 15104030, description: 'Gold' },
      { naics: 15104040, description: 'Precious Metals & Minerals' },
      { naics: 15104045, description: 'Silver' },
      { naics: 15104050, description: 'Steel' },
      { naics: 15105010, description: 'Forest Products' },
      { naics: 15105020, description: 'Paper Products' },
      { naics: 20101010, description: 'Aerospace & Defense' },
      { naics: 20102010, description: 'Building Products' },
      { naics: 20103010, description: 'Construction & Engineering' },
      { naics: 20104010, description: 'Electrical Components & Equipment' },
      { naics: 20104020, description: 'Heavy Electrical Equipment' },
      { naics: 20105010, description: 'Industrial Conglomerates' },
      { naics: 20106010, description: 'Construction Machinery & Heavy Trucks' },
      { naics: 20106015, description: 'Agricultural & Farm Machinery' },
      { naics: 20106020, description: 'Industrial Machinery' },
      { naics: 20107010, description: 'Trading Companies & Distributors' },
      { naics: 20201010, description: 'Commercial Printing' },
      { naics: 20201050, description: 'Environmental & Facilities Services' },
      { naics: 20201060, description: 'Office Services & Supplies' },
      { naics: 20201070, description: 'Diversified Support Services' },
      { naics: 20201080, description: 'Security & Alarm Services' },
      { naics: 20202010, description: 'Human Resource & Employment Services' },
      { naics: 20202020, description: 'Research & Consulting Services' },
      { naics: 20301010, description: 'Air Freight & Logistics' },
      { naics: 20302010, description: 'Airlines' },
      { naics: 20303010, description: 'Marine' },
      { naics: 20304010, description: 'Railroads' },
      { naics: 20304020, description: 'Trucking' },
      { naics: 20305010, description: 'Airport Services' },
      { naics: 20305020, description: 'Highways & Railtracks' },
      { naics: 20305030, description: 'Marine Ports & Services' },
      { naics: 25101010, description: 'Auto Parts & Equipment' },
      { naics: 25101020, description: 'Tires & Rubber' },
      { naics: 25102010, description: 'Automobile Manufacturers' },
      { naics: 25102020, description: 'Motorcycle Manufacturers' },
      { naics: 25201010, description: 'Consumer Electronics' },
      { naics: 25201020, description: 'Home Furnishings' },
      { naics: 25201030, description: 'Homebuilding' },
      { naics: 25201040, description: 'Household Appliances' },
      { naics: 25201050, description: 'Housewares & Specialties' },
      { naics: 25202010, description: 'Leisure Products' },
      { naics: 25203010, description: 'Apparel, Accessories & Luxury Goods' },
      { naics: 25203020, description: 'Footwear' },
      { naics: 25203030, description: 'Textiles' },
      { naics: 25301010, description: 'Casinos & Gaming' },
      { naics: 25301020, description: 'Hotels, Resorts & Cruise Lines' },
      { naics: 25301030, description: 'Leisure Facilities' },
      { naics: 25301040, description: 'Restaurants' },
      { naics: 25302010, description: 'Education Services' },
      { naics: 25302020, description: 'Specialized Consumer Services' },
      { naics: 25401010, description: 'Advertising' },
      { naics: 25401020, description: 'Broadcasting' },
      { naics: 25401025, description: 'Cable & Satellite' },
      { naics: 25401030, description: 'Movies & Entertainment' },
      { naics: 25401040, description: 'Publishing' },
      { naics: 25501010, description: 'Distributors' },
      { naics: 25502020, description: 'Internet & Direct Marketing Retail' },
      { naics: 25503010, description: 'Department Stores' },
      { naics: 25503020, description: 'General Merchandise Stores' },
      { naics: 25504010, description: 'Apparel Retail' },
      { naics: 25504020, description: 'Computer & Electronics Retail' },
      { naics: 25504030, description: 'Home Improvement Retail' },
      { naics: 25504040, description: 'Specialty Stores' },
      { naics: 25504050, description: 'Automotive Retail' },
      { naics: 25504060, description: 'Homefurnishing Retail' },
      { naics: 30101010, description: 'Drug Retail' },
      { naics: 30101020, description: 'Food Distributors' },
      { naics: 30101030, description: 'Food Retail' },
      { naics: 30101040, description: 'Hypermarkets & Super Centers' },
      { naics: 30201010, description: 'Brewers' },
      { naics: 30201020, description: 'Distillers & Vintners' },
      { naics: 30201030, description: 'Soft Drinks' },
      { naics: 30202010, description: 'Agricultural Products' },
      { naics: 30202030, description: 'Packaged Foods & Meats' },
      { naics: 30203010, description: 'Tobacco' },
      { naics: 30301010, description: 'Household Products' },
      { naics: 30302010, description: 'Personal Products' },
      { naics: 35101010, description: 'Health Care Equipment' },
      { naics: 35101020, description: 'Health Care Supplies' },
      { naics: 35102010, description: 'Health Care Distributors' },
      { naics: 35102015, description: 'Health Care Services' },
      { naics: 35102020, description: 'Health Care Facilities' },
      { naics: 35103010, description: 'Health Care Technology' },
      { naics: 35201010, description: 'Biotechnology' },
      { naics: 35202010, description: 'Pharmaceuticals' },
      { naics: 35203010, description: 'Life Sciences Tools & Services' },
      { naics: 40101010, description: 'Diversified Banks' },
      { naics: 40101015, description: 'Regional Banks' },
      { naics: 40102010, description: 'Thrifts & Mortgage Finance' },
      { naics: 40201020, description: 'Other Diversified Financial Services' },
      { naics: 40201030, description: 'Multi-Sector Holdings' },
      { naics: 40201040, description: 'Specialized Finance' },
      { naics: 40202010, description: 'Consumer Finance' },
      { naics: 40203010, description: 'Asset Management & Custody Banks' },
      { naics: 40203020, description: 'Investment Banking & Brokerage' },
      { naics: 40203030, description: 'Diversified Capital Markets' },
      { naics: 40203040, description: 'Financial Exchanges & Data' },
      { naics: 40204010, description: 'Mortgage REITs' },
      { naics: 40301010, description: 'Insurance Brokers' },
      { naics: 40301020, description: 'Life & Health Insurance' },
      { naics: 40301030, description: 'Multi-line Insurance' },
      { naics: 40301040, description: 'Property & Casualty Insurance' },
      { naics: 40301050, description: 'Reinsurance' },
      { naics: 45101010, description: 'Internet Software & Services' },
      { naics: 45102010, description: 'IT Consulting & Other Services' },
      { naics: 45102020, description: 'Data Processing & Outsourced Services' },
      { naics: 45103010, description: 'Application Software' },
      { naics: 45103020, description: 'Systems Software' },
      { naics: 45103030, description: 'Home Entertainment Software' },
      { naics: 45201020, description: 'Communications Equipment' },
      { naics: 45202030, description: 'Technology Hardware, Storage & Peripherals' },
      { naics: 45203010, description: 'Electronic Equipment & Instruments' },
      { naics: 45203015, description: 'Electronic Components' },
      { naics: 45203020, description: 'Electronic Manufacturing Services' },
      { naics: 45203030, description: 'Technology Distributors' },
      { naics: 45301010, description: 'Semiconductor Equipment' },
      { naics: 45301020, description: 'Semiconductors' },
      { naics: 50101010, description: 'Alternative Carriers' },
      { naics: 50101020, description: 'Integrated Telecommunication Services' },
      { naics: 50102010, description: 'Wireless Telecommunication Services' },
      { naics: 50203010, description: 'Empty' },
      { naics: 55101010, description: 'Electric Utilities' },
      { naics: 55102010, description: 'Gas Utilities' },
      { naics: 55103010, description: 'Multi-Utilities' },
      { naics: 55104010, description: 'Water Utilities' },
      { naics: 55105010, description: 'Independent Power Producers & Energy Traders' },
      { naics: 55105020, description: 'Renewable Electricity' },
      { naics: 60101010, description: 'Diversified REITs' },
      { naics: 60101020, description: 'Industrial REITs' },
      { naics: 60101030, description: 'Hotel & Resort REITs' },
      { naics: 60101040, description: 'Office REITs' },
      { naics: 60101050, description: 'Health Care REITs' },
      { naics: 60101060, description: 'Residential REITs' },
      { naics: 60101070, description: 'Retail REITs' },
      { naics: 60101080, description: 'Specialized REITs' },
      { naics: 60102010, description: 'Diversified Real Estate Activities' },
      { naics: 60102020, description: 'Real Estate Operating Companies' },
      { naics: 60102030, description: 'Real Estate Development' },
      { naics: 60102040, description: 'Real Estate Services' },
    ];

    const naicsElem = naicsToDescription.filter((elem) => elem.naics === parseInt(NAICS, 10));
    if (naicsElem.length > 0) {
      return naicsElem[0].description;
    }
    return undefined;
  }

  render() {
    const { error, datasets, transactions } = this.props;
    const {
      cachedConstructedPortfolios,
      constructedPortfolio,
      countriesInDataset,
      countryRatingsInDataset,
      dataSetName,
      helpText,
      minPartialPurchaseMode,
      originatingBanksInDataset,
      PDScaling,
      portfolioCharacteristics,
      ratingsInDataset,
      renderHelp,
      sectorsInDataset,
    } = this.state;
    const showLoadingScreen = datasets.isLoading === true;
    const showPageHeader = true;
    const showDataSetMenuSelection = true;
    const showError = Object.keys(error).length > 0;
    const showDataSetSelection = datasets.isLoading !== true
      && datasets !== undefined;
    const showDataSetTransactions = transactions.isLoading !== true
      && transactions.data !== undefined;
    const showPortfolioConstructionCriteria = countriesInDataset !== undefined
      && countryRatingsInDataset !== undefined
      && ratingsInDataset !== undefined
      && sectorsInDataset !== undefined
      && originatingBanksInDataset !== undefined;
    const showConstructedPortfolio = constructedPortfolio !== undefined;
    const showConstructedPortfolioCharacteristics = portfolioCharacteristics.length > 0;

    return (
      <div>
        { showPageHeader && (
          <div><Header title="Asset Analysis" /></div>
        )}

        { showLoadingScreen && (
          <div>
            <Notification heading="Portfolio Construction" body="Loading datasets" />
          </div>
        )}

        { showError && (
          <div>
            <Notification heading="Portfolio Construction" body="Error loading data" />
          </div>
        )}

        { renderHelp && (
          <div
            className="overlay-help"
            onClick={() => this.renderHelp(undefined)}
            onKeyDown={() => this.renderHelp(undefined)}
          >
            <div className="overlay-help-card">
              <Card className="mt-4">
                <Card.Header>
                  <div className="fa-pull-left">{helpText.header}</div>
                  <i
                    className="fa fa-window-close fa-pull-right"
                    aria-label="Close help"
                    role="button"
                    tabIndex={0}
                    onClick={() => this.renderHelp(undefined)}
                    onKeyDown={() => this.renderHelp(undefined)}
                  />
                </Card.Header>
                <Card.Body>{helpText.body}</Card.Body>
              </Card>
            </div>
          </div>
        )}

        { showDataSetSelection && (
          <div>
            <Card className="mt-4">
              <Card.Header className="card-header-with-btn">
                <div className="pull-left">{ showDataSetMenuSelection ? 'Select a data set' : 'Select a Transaction' }</div>
                <div className="fa-pull-right">
                  <span
                    className="ml-3 question-mark"
                    onClick={() => this.renderHelp({
                      header: 'Data set selection',
                      body: 'x, y, z.',
                    })}
                    onKeyDown={() => this.renderHelp({
                      header: 'Data set selection',
                      body: 'x, y, z.',
                    })}
                  />
                </div>
              </Card.Header>
              <Card.Body>
                <Form noValidate>
                  { showDataSetMenuSelection && (
                    <Form.Group controlId="portfolioConstruction.selectDataSetID">
                      <Form.Control as="select" ref={this.dataSetIDRef} onChange={this.saveDataSetID}>
                        { datasets.data.map((k) => (
                          <option key={k.dataSetID} value={k.dataSetID}>{k.dataSetName}</option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                  )}
                </Form>
              </Card.Body>
            </Card>
          </div>
        )}

        { showDataSetTransactions && (
          <div>
            <Card className="mt-4">
              <Card.Header className="card-header-with-btn">
                <div className="pull-left">Data set constituents</div>
                <div className="fa-pull-right">
                  <span
                    className="ml-3 question-mark"
                    onClick={() => this.renderHelp({
                      header: 'Data set transactions',
                      body: 'x, y, z.',
                    })}
                    onKeyDown={() => this.renderHelp({
                      header: 'Data set transactions',
                      body: 'x, y, z.',
                    })}
                  />
                </div>
              </Card.Header>
              <Card.Body>
                <ReactTable
                  data={transactions.data}
                  filterable
                  columns={[
                    { accessor: 'Global_Facility_ID', Header: 'Loan ID' },
                    {
                      accessor: 'Outstanding',
                      Header: 'Exposure',
                      Cell: (row) => (
                        <div style={{ textAlign: 'right' }}>
                          { row.value === null ? 'N/A' : row.value.toLocaleString('en-GB', {
                            style: 'currency',
                            currency: 'USD',
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }) }
                        </div>
                      ),
                    },
                    {
                      accessor: 'Rating',
                      Header: 'Rating',
                      Cell: (row) => (
                        <div style={{ textAlign: 'right', paddingRight: '10px' }}>
                          { row.value === null ? 'N/A' : row.value }
                        </div>
                      ),
                    },
                    {
                      accessor: 'CUSTOMER_RATE',
                      Header: 'Credit Margin',
                      Cell: (row) => (
                        <div style={{ textAlign: 'right', paddingRight: '10px' }}>
                          { row.value === null ? 'N/A' : row.value }
                        </div>
                      ),
                    },
                    {
                      accessor: 'Group_Division_Description',
                      Header: 'Industry',
                      Cell: (row) => (
                        <div style={{ textAlign: 'right', paddingRight: '10px' }}>
                          { row.value === null ? 'N/A' : row.value }
                        </div>
                      ),
                    },
                    {
                      accessor: 'Product_Type',
                      Header: 'Product Description',
                      Cell: (row) => (
                        <div style={{ textAlign: 'right', paddingRight: '10px' }}>
                          { row.value === null ? 'N/A' : row.value }
                        </div>
                      ),
                    },
                  ]}
                  // sorted={[{
                  //  id: this.state.sortColumn === undefined ? '' : this.state.sortColumn,
                  //  desc: false
                  // }]}
                  defaultPageSize={10}
                  keyField="id"
                  className="-striped -highlight"
                  // NextComponent={styledButton}
                  // PreviousComponent={styledButton}
                />
              </Card.Body>
            </Card>
          </div>
        )}

        { showPortfolioConstructionCriteria && (
          <Card className="mt-4">
            <Card.Header className="card-header-with-btn">
              <div className="pull-left">Portfolio construction criteria</div>
              <div className="fa-pull-right">
                <span
                  className="ml-3 question-mark"
                  onClick={() => this.renderHelp({
                    header: 'Portfolio construction criteria',
                    body: 'x, y, z.',
                  })}
                  onKeyDown={() => this.renderHelp({
                    header: 'Portfolio construction criteria',
                    body: 'x, y, z.',
                  })}
                />
              </div>
            </Card.Header>
            <Card.Body>
              <Form noValidate onSubmit={this.savePortfolioConstraints}>
                <Form.Group as={Row}>
                  <Form.Label column sm={3}>Available Budget</Form.Label>
                  <Col sm={9}>
                    <Form.Control
                      id="availableBudgetInput"
                      label="Available Budget"
                      type="number"
                      ref={this.availableBudgetRef}
                      defaultValue="1000"
                      style={{ textAlign: 'right' }}
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Form.Label column sm={3}>Maximum Partial Purchase Percentage</Form.Label>
                  <Col sm={9}>
                    <Form.Control
                      id="max_purchase_percentage"
                      label="Maximum Partial Purchase Percentage"
                      type="number"
                      ref={this.maxPartialPurchasePercentageRef}
                      defaultValue="0.9"
                      style={{ textAlign: 'right' }}
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Form.Label column sm={3}>Minimum Partial Purchase Quantity</Form.Label>
                  <Col sm={3}>
                    <Form.Check
                      custom
                      id="custom-switch"
                      label={
                        minPartialPurchaseMode.charAt(0).toUpperCase()
                        + minPartialPurchaseMode.slice(1)
                      }
                      type="switch"
                      ref={this.minPartialPurchaseModeRef}
                      onClick={() => (this.setState({ minPartialPurchaseMode: minPartialPurchaseMode === 'percentage' ? 'absolute' : 'percentage' }))}
                      style={{ textAlign: 'left', paddingLeft: '10px' }}
                    />
                  </Col>
                  <Col sm={6}>
                    <Form.Control
                      id="min_purchase_value"
                      label="Minimum Partial Purchase Value"
                      type="number"
                      ref={this.minPartialPurchaseValueRef}
                      defaultValue="0.01"
                      style={{ textAlign: 'right' }}
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Form.Label column sm={3}>Minimum number of obligors</Form.Label>
                  <Col sm={9}>
                    <Form.Control
                      id="min_number_of_obligors"
                      label="Minimum number of obligors"
                      type="number"
                      ref={this.minNumberOfObligorsRef}
                      defaultValue="1"
                      style={{ textAlign: 'right' }}
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Form.Label column sm={3}>Minimum number of facilities</Form.Label>
                  <Col sm={9}>
                    <Form.Control
                      id="min_number_of_facilities"
                      label="Minimum number of facilities"
                      type="number"
                      ref={this.minNumberOfFacilitiesRef}
                      defaultValue="1"
                      style={{ textAlign: 'right' }}
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Form.Label column sm={3}>Minimum number of Trade Finance products</Form.Label>
                  <Col sm={9}>
                    <Form.Control
                      id="min_number_of_products"
                      label="Minimum number of Trade Finance products"
                      type="number"
                      ref={this.minNumberOfProductsRef}
                      defaultValue="1"
                      style={{ textAlign: 'right' }}
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Form.Label column sm={3}>
                    Maximum percentage of single transaction exposure
                  </Form.Label>
                  <Col sm={9}>
                    <Form.Control
                      id="max_single_transaction_exposure"
                      label="Maximum percentage of single transaction exposure"
                      type="number"
                      ref={this.maxSingleTransactionExposureRef}
                      defaultValue="1"
                      style={{ textAlign: 'right' }}
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Form.Label column sm={3}>Minimum exposure below remaining tenor</Form.Label>
                  <Col sm={9}>
                    <Form.Control
                      id="min_exposure_below_remaining_tenor"
                      label="Minimum exposure below remaining tenor"
                      type="number"
                      ref={this.minExposureBelowRemainingTenorRef}
                      defaultValue="0"
                      style={{ textAlign: 'right' }}
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Form.Label column sm={3}>Applicable remaining tenor</Form.Label>
                  <Col sm={9}>
                    <Form.Control
                      id="applicable_remaining_tenor"
                      label="Applicable remaining tenor"
                      type="number"
                      ref={this.daysApplicableRemainingTenorRef}
                      defaultValue="90"
                      style={{ textAlign: 'right' }}
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Form.Label column sm={3}>Max non-Top 1 exposure per Industry Group</Form.Label>
                  <Col sm={9}>
                    <Form.Control
                      id="max_non_top_1_industry_group_exposure"
                      label="Max non-Top 1 exposure per Industry Group"
                      type="number"
                      ref={this.maxNonTop1IndustryGroupExposureRef}
                      defaultValue="1"
                      style={{ textAlign: 'right' }}
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Form.Label column sm={3}>Max Top 1 exposure per Industry Group</Form.Label>
                  <Col sm={9}>
                    <Form.Control
                      id="max_top_1_industry_group_exposure"
                      label="Max Top 1 exposure per Industry Group"
                      type="number"
                      ref={this.maxTop1IndustryGroupExposureRef}
                      defaultValue="1"
                      style={{ textAlign: 'right' }}
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Form.Label column sm={3}>Max % per Industry in any Region</Form.Label>
                  <Col sm={9}>
                    <Form.Control
                      id="max_percentage_per_industry_in_any_region"
                      label="Max % per Industry in any Region"
                      type="number"
                      ref={this.maxPercPerIndustryInAnyRegionRef}
                      defaultValue="1"
                      style={{ textAlign: 'right' }}
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Form.Label column sm={3}>Max original term in days</Form.Label>
                  <Col sm={9}>
                    <Form.Control
                      id="max_original_term"
                      label="Maximum original term in days"
                      type="number"
                      ref={this.maxOriginalTermRef}
                      defaultValue="360"
                      style={{ textAlign: 'right' }}
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Form.Label column sm={3}>Minimum countries in portfolio</Form.Label>
                  <Col sm={9}>
                    <Form.Control
                      id="min_countries_constraint"
                      label="Minimum countries in portfolio"
                      type="number"
                      ref={this.minimumCountriesConstraintRef}
                      defaultValue="1"
                      style={{ textAlign: 'right' }}
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Form.Label column sm={3}>Minimum regions in portfolio</Form.Label>
                  <Col sm={9}>
                    <Form.Control
                      id="min_regions_constraint"
                      label="Minimum regions in portfolio"
                      type="number"
                      ref={this.minimumRegionsConstraintRef}
                      defaultValue="1"
                      style={{ textAlign: 'right' }}
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Form.Label column sm={3}>Minimum industry groups in portfolio</Form.Label>
                  <Col sm={9}>
                    <Form.Control
                      id="min_industry_groups_constraint"
                      label="Minimum industry groups in portfolio"
                      type="number"
                      ref={this.minimumIndustryGroupsConstraintRef}
                      defaultValue="1"
                      style={{ textAlign: 'right' }}
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Form.Label column sm={3}>Maximum weighted average Obligor PD</Form.Label>
                  <Col sm={9}>
                    <Form.Control
                      id="max_weighted_average_obligor_pd"
                      label="Maximum weighted average Obligor PD"
                      type="number"
                      ref={this.maxWeightedAverageObligorPDRef}
                      defaultValue="0.06"
                      style={{ textAlign: 'right' }}
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Form.Label column sm={4}>Country Limits</Form.Label>
                  <Col sm={9}>
                    {countriesInDataset.map((elem, index) => (
                      <Row>
                        <Col sm={6}>{elem}</Col>
                        <Col sm={3}>
                          <Form.Control
                            id={`country-${index}`}
                            label={`country ${elem}`}
                            type="number"
                            ref={`country-limit-${index}`}
                            defaultValue="1"
                            style={{ textAlign: 'right' }}
                          />
                        </Col>
                      </Row>
                    ))}
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Form.Label column sm={4}>Country Rating Limits</Form.Label>
                  <Col sm={9}>
                    {countryRatingsInDataset.map((elem, index) => (
                      <Row>
                        <Col sm={6}>{elem}</Col>
                        <Col sm={3}>
                          <Form.Control
                            id={`country-rating-${index}`}
                            label={`country ${elem}`}
                            type="number"
                            ref={`country-rating-limit-${index}`}
                            defaultValue="1"
                            style={{ textAlign: 'right' }}
                          />
                        </Col>
                      </Row>
                    ))}
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Form.Label column sm={3}>Sector Limits</Form.Label>
                  <Col sm={9}>
                    {sectorsInDataset.map((elem, index) => (
                      <Row>
                        <Col sm={6}>{elem.description}</Col>
                        <Col sm={3}>
                          <Form.Control
                            id={`sector-${index}`}
                            label={`sector ${elem.description}`}
                            type="number"
                            ref={`sector-limit-${index}`}
                            defaultValue="1"
                            style={{ textAlign: 'right' }}
                          />
                        </Col>
                      </Row>
                    ))}
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Form.Label column sm={3}>Rating Limits</Form.Label>
                  <Col sm={9}>
                    {ratingsInDataset.map((elem, index) => (
                      <Row>
                        <Col sm={6}>{elem}</Col>
                        <Col sm={3}>
                          <Form.Control
                            id={`rating-${index}`}
                            label={`rating ${elem}`}
                            type="number"
                            ref={`rating-limit-${index}`}
                            defaultValue="1"
                            style={{ textAlign: 'right' }}
                          />
                        </Col>
                      </Row>
                    ))}
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Form.Label column sm={3}>Single Obligor Limits</Form.Label>
                  <Col sm={9}>
                    { originatingBanksInDataset.map((bankElem, bankIndex) => {
                      const generatedColumn = ratingsInDataset.map((ratingElem, ratingIndex) => (
                        <Row>
                          <Form.Label column sm={12}>{`Limit ${bankElem} ${ratingElem}`}</Form.Label>
                          <Form.Control
                            id={`originating-bank-limit-${bankIndex}-${ratingIndex}`}
                            label={`rating ${ratingElem}`}
                            type="number"
                            ref={`originating-bank-limit-${bankIndex}-${ratingIndex}`}
                            defaultValue="1"
                            style={{ textAlign: 'right' }}
                          />
                        </Row>
                      ));
                      return (<Col sm={9}>{generatedColumn}</Col>);
                    })}
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="contraintSetup.optimizationObjective">
                  <Form.Check
                    type="radio"
                    name="optimization_objective"
                    label="Maximal Return"
                    value="maximize_return"
                    id="optimization-objective-max-return"
                    onChange={() => (this.updateOptimizationMethod('maximize_return'))}
                    defaultChecked
                  />

                  <Form.Check
                    type="radio"
                    name="optimization_objective"
                    label="Maximal Return / Expected Loss"
                    value="maximize_risk_adjusted_return"
                    id="optimization-objective-risk-adjusted-return"
                    onChange={() => (this.updateOptimizationMethod('maximize_risk_adjusted_return'))}
                  />

                  <Form.Check
                    type="radio"
                    name="optimization_objective"
                    label="Return / Solvency Capital"
                    value="solvency_RoC"
                    id="optimization-objective-return-solvency-capital"
                    onChange={() => (this.updateOptimizationMethod('solvency_RoC'))}
                  />
                </Form.Group>
                <Form.Group as={Row} controlId="contraintSetup.optimizationObjective">
                  <Form.Check
                    type="checkbox"
                    name="pd_scaling"
                    label="Scale PD with lifetime of transaction"
                    value="pd_scaling"
                    checked={PDScaling}
                    onChange={this.togglePDScaling}
                  />
                </Form.Group>

                { PDScaling && (
                  <Form.Group as={Row} controlId="contraintSetup.PDScalingPattern">
                    <Form.Check
                      type="radio"
                      name="pd_scaling_method"
                      label="Linear PD Scaling"
                      value="linear_pd_scaling"
                      onChange={() => (this.updatePDScalingMethod('linear_pd_scaling'))}
                      defaultChecked
                    />

                    <Form.Check
                      type="radio"
                      name="pd_scaling_method"
                      label="Exponential PD Scaling"
                      value="exponential_pd_scaling"
                      onChange={() => (this.updatePDScalingMethod('exponential_pd_scaling'))}
                    />
                  </Form.Group>
                )}

                <Form.Group as={Row} controlId="scenarioCreation.discountCurve">
                  <Form.Label column sm={3}>Discount Curve</Form.Label>
                  <Col sm={9}>
                    To be included
                  </Col>
                </Form.Group>
                <Button variant="outline-primary" fill="false" type="submit" disabled={false}>
                  Save portfolio constraints
                </Button>
              </Form>
              <Button variant="outline-primary" fill="false" onClick={() => this.constructPortfolio2(10000000, undefined, 0, undefined, 0.01)}>Generate portfolio</Button>
              <Button variant="outline-primary" fill="false" onClick={() => this.addConstructedPortfolioToCache(constructedPortfolio)}>Add generated portfolio to x</Button>
              { cachedConstructedPortfolios.length > 0 && (
                <Button variant="outline-primary" fill="false" onClick={() => this.clearCachedConstructedPortfolios()}>Clear saved portfolios</Button>
              )}
            </Card.Body>
          </Card>
        )}

        { showConstructedPortfolioCharacteristics && (
          <Card className="mt-4">
            <Card.Header className="card-header-with-btn">
              <div className="pull-left">Portfolio performance characteristics</div>
              <div className="fa-pull-right">
                <span
                  className="ml-3 question-mark"
                  onClick={() => this.renderHelp({
                    header: 'Portfolio performance characteristics',
                    body: 'x, y, z.',
                  })}
                  onKeyDown={() => this.renderHelp({
                    header: 'Portfolio performance characteristics',
                    body: 'x, y, z.',
                  })}
                />
              </div>
            </Card.Header>
            <Card.Body>
              <p>{`Portfolio performance characteristics of latest generated portfolio build on ${dataSetName}`}</p>
              { portfolioCharacteristics.length > 0 && (
                portfolioCharacteristics.map((portfolioCharacteristic) => (
                  portfolioCharacteristic.filter((metric) => (
                    metric.active === true
                  ))
                    .map((actives) => (
                      <p>
                        {actives.description_prior
                          + parseFloat(actives.value).toFixed(2)
                          + actives.description_after}
                      </p>
                    ))
                ))
              )}
              { cachedConstructedPortfolios.length > 0 && (
                <Button variant="outline-primary" fill="false" onClick={() => this.updatePortfolioCharacteristics(cachedConstructedPortfolios)}>Show characteristics of saved portfolios</Button>
              )}
            </Card.Body>
          </Card>
        )}

        { showConstructedPortfolio && (
          <div>
            <Card className="mt-4">
              <Card.Header className="card-header-with-btn">
                <div className="pull-left">Constructed portfolio</div>
                <div className="fa-pull-right">
                  <Button className="fa-icon" variant="secondary" size="sm" onClick={() => this.saveToExcel(constructedPortfolio, ['Local_Contract_ID', 'CUSTOMER_RATE', 'Outstanding', 'Product_Type', 'Rating', 'loan_inclusion', 'portfolio_weight', 'time_scaled_customer_rate', 'time_scaled_pd'])}>
                    <span className="fa fa-save" />
                    <span> Export</span>
                  </Button>
                  <span
                    className="ml-3 question-mark"
                    onClick={() => this.renderHelp({
                      header: 'Data set selection',
                      body: 'x, y, z.',
                    })}
                    onKeyDown={() => this.renderHelp({
                      header: 'Data set selection',
                      body: 'x, y, z.',
                    })}
                  />
                </div>
              </Card.Header>
              <Card.Body>
                <ReactTable
                  data={constructedPortfolio}
                  filterable
                  columns={[
                    { accessor: 'Global_Facility_ID', Header: 'Loan ID' },
                    {
                      accessor: 'Outstanding',
                      Header: 'Exposure',
                      Cell: (row) => (
                        <div style={{ textAlign: 'right' }}>
                          { row.value === null ? 'N/A' : row.value.toLocaleString('en-GB', {
                            style: 'currency',
                            currency: 'USD',
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }) }
                        </div>
                      ),
                    },
                    {
                      accessor: 'Rating',
                      Header: 'Rating',
                      Cell: (row) => (
                        <div style={{ textAlign: 'right', paddingRight: '10px' }}>
                          {row.value === null ? 'N/A' : row.value}
                        </div>
                      ),
                    },
                    {
                      accessor: 'CUSTOMER_RATE',
                      Header: 'Credit Margin',
                      Cell: (row) => (
                        <div style={{ textAlign: 'right', paddingRight: '10px' }}>
                          {row.value === null ? 'N/A' : row.value}
                        </div>
                      ),
                    },
                    {
                      accessor: 'Group_Division_Description',
                      Header: 'Industry',
                      Cell: (row) => (
                        <div style={{ textAlign: 'right', paddingRight: '10px' }}>
                          { row.value === null ? 'N/A' : row.value }
                        </div>
                      ),
                    },
                    {
                      accessor: 'Product_Type',
                      Header: 'Product Description',
                      Cell: (row) => (
                        <div style={{ textAlign: 'right', paddingRight: '10px' }}>
                          { row.value === null ? 'N/A' : row.value }
                        </div>
                      ),
                    },
                    {
                      accessor: 'loan_inclusion',
                      Header: 'Loan Inclusion',
                      Cell: (row) => (
                        <div style={{ textAlign: 'right', paddingRight: '10px' }}>
                          { row.value === null ? 'N/A' : `${Math.round(row.value * 10000) / 100}%` }
                        </div>
                      ),
                    },
                    {
                      accessor: 'portfolio_weight',
                      Header: 'Portfolio Weight',
                      Cell: (row) => (
                        <div style={{ textAlign: 'right', paddingRight: '10px' }}>
                          { row.value === null ? 'N/A' : `${Math.round(row.value * 10000) / 100}%` }
                        </div>
                      ),
                    },
                  ]}
                  // sorted={[{
                  //  id: this.state.sortColumn === undefined ? '' : this.state.sortColumn,
                  //  desc: false
                  // }]}
                  defaultPageSize={10}
                  keyField="id"
                  className="-striped -highlight"
                  // NextComponent={styledButton}
                  // PreviousComponent={styledButton}
                />
              </Card.Body>
            </Card>
          </div>
        )}

        { showConstructedPortfolio && (
          <div>
            <Card className="mt-4">
              <Card.Header>
                <div className="pull-left">Constructed portfolio distribution</div>
              </Card.Header>
              <Card.Body>
                <div>
                  <Chart
                    type="Pie"
                    unit="currency"
                    dataseries={[
                      constructAndSortPortAllocations(
                        constructedPortfolio,
                        'Country_Of_Risk',
                        false,
                      ).sort((a, b) => (
                        b.value - a.value
                      ))
                        .map((elem) => elem.value)]}
                    dataserieslabels={constructAndSortPortAllocations(
                      constructedPortfolio,
                      'Country_Of_Risk',
                      false,
                    ).sort((a, b) => (
                      b.value - a.value
                    ))
                      .map((elem) => elem.category)}
                    categorySelection={undefined}
                  />
                  <Chart
                    type="Pie"
                    unit="currency"
                    dataseries={[
                      constructAndSortPortAllocations(
                        constructedPortfolio,
                        'Group_Division_Description',
                        false,
                      ).sort((a, b) => (
                        b.value - a.value
                      ))
                        .map((elem) => elem.value)]}
                    dataserieslabels={constructAndSortPortAllocations(
                      constructedPortfolio,
                      'Group_Division_Description',
                      false,
                    ).sort((a, b) => (
                      b.value - a.value
                    ))
                      .map((elem) => elem.category)}
                    categorySelection={undefined}
                  />
                  <Chart
                    type="Pie"
                    unit="percent"
                    dataseries={[
                      constructAndSortPortAllocations(
                        constructedPortfolio,
                        'Originating_Entity',
                        true,
                      ).sort((a, b) => (
                        b.value - a.value
                      ))
                        .map((elem) => elem.value)]}
                    dataserieslabels={constructAndSortPortAllocations(
                      constructedPortfolio,
                      'Originating_Entity',
                      true,
                    ).sort((a, b) => (
                      b.value - a.value
                    ))
                      .map((elem) => elem.category)}
                    categorySelection={undefined}
                  />
                  <Chart
                    type="Pie"
                    unit="percent"
                    dataseries={[
                      constructAndSortPortAllocations(
                        constructedPortfolio,
                        'Group_Division',
                        true,
                      ).sort((a, b) => (
                        b.value - a.value
                      ))
                        .map((elem) => elem.value)]}
                    dataserieslabels={constructAndSortPortAllocations(
                      constructedPortfolio,
                      'Group_Division',
                      true,
                    ).sort((a, b) => (
                      b.value - a.value
                    ))
                      .map((elem) => elem.category)}
                    categorySelection={undefined}
                  />
                  <Chart
                    type="Pie"
                    unit="percent"
                    dataseries={[
                      constructAndSortPortAllocations(
                        constructedPortfolio,
                        'Product_Type',
                        true,
                      ).sort((a, b) => (
                        b.value - a.value
                      ))
                        .map((elem) => elem.value)]}
                    dataserieslabels={constructAndSortPortAllocations(
                      constructedPortfolio,
                      'Product_Type',
                      true,
                    ).sort((a, b) => (
                      b.value - a.value
                    ))
                      .map((elem) => elem.category)}
                    categorySelection={undefined}
                  />
                  <Chart
                    type="Line"
                    unit="decimal"
                    dataseries={[[
                      constructedPortfolio.reduce((portfolioValue, asset) => (
                        portfolioValue + asset.Outstanding * asset.loan_inclusion
                      ), 0),
                      ...constructPortfolioRedemption(constructedPortfolio).map((elem) => (
                        elem.expiringAssets.reduce((timepointValue, portConst) => (
                          timepointValue - portConst.Outstanding * portConst.loan_inclusion
                        ), constructedPortfolio.reduce((portfolioValue, asset) => (
                          new Date(asset.Maturity_Date) >= new Date(elem.date)
                            ? asset.Outstanding * asset.loan_inclusion + portfolioValue
                            : portfolioValue
                        ), 0)))),
                    ]]}
                    xaxislabels={[
                      new Date().toISOString(),
                      ...constructPortfolioRedemption(constructedPortfolio).map((elem) => (
                        elem.date
                      )),
                    ]}
                    dataserieslabels={['Redemption profile']}
                  />
                  <Chart
                    type="Stacked Bar"
                    unit="decimal"
                    dataseries={constructedPortfolio.map((asset) => (
                      [0,
                        ...constructPortfolioRedemption(constructedPortfolio).map((elem) => (
                          new Date(asset.Maturity_Date) - new Date(elem.date) === 0
                            ? asset.Outstanding * asset.loan_inclusion
                            : 0
                        )),
                      ]
                    ))}
                    xaxislabels={[
                      new Date().toISOString(),
                      ...constructPortfolioRedemption(constructedPortfolio).map((elem) => (
                        elem.date
                      ))]}
                    dataserieslabels={constructedPortfolio.map((elem) => elem.Facility_Code)}
                  />
                </div>
              </Card.Body>
            </Card>
          </div>
        )}
      </div>
    );
  }
}

const loadingSelector = createLoadingSelector(['FETCH_TRANSACTIONS']);
const errorSelector = createErrorMessageSelector(['FETCH_TRANSACTIONS']);

const mapStateToProps = (state) => (
  {
    authentication: state.authentication,
    assets: state.assets,
    asset_characteristics: state.assetCharacteristics,
    datasets: state.datasets,
    isFetching: loadingSelector(state),
    isError: errorSelector(state),
    transactions: state.transactions,
    loading: state.loading,
    error: state.error,
  }
);

const mapDispatchToProps = (dispatch) => (
  {
    fetchDataSets: (token) => (
      dispatch(datasetActions.fetchDataSets(token))
    ),
    fetchTransactions: (id, token) => (
      dispatch(transactionActions.fetchTransactions(id, token))
    ),
  }
);

PortfolioConstruction.propTypes = {
  datasets: PropTypes.instanceOf(Array).isRequired,
  error: PropTypes.instanceOf(Object).isRequired,
  fetchDataSets: PropTypes.func.isRequired,
  fetchTransactions: PropTypes.func.isRequired,
  loading: PropTypes.instanceOf(Object).isRequired,
  match: PropTypes.instanceOf(Array).isRequired,
  transactions: PropTypes.instanceOf(Array).isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(PortfolioConstruction);

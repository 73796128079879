import React from 'react'
import { connect } from 'react-redux';
import Dropzone from 'react-dropzone'
import HeatMap from "react-heatmap-grid";
import FormSelectName from "./FormSelectName";
import FormSelectOneDropDown from './FormSelectOneDropDown'
// Check options for the disabled tag!
import { Button, Card, Row, Col, Form } from 'react-bootstrap';
import SimpleTable from './SimpleTable'

import Header from "../components/Header";
import Notification from "../components/Notification"
import Papa from 'papaparse'

import { inputs, selections } from "../actions";
import { createLoadingSelector, createErrorMessageSelector, createAddMessage } from '../components/Helpers'

// Have to submit: request.data['factorresidualsresults'],
// request.data['factortable'],
// request.data['factorreturns'] to the FactorReturnsViewSet

const baseStyle = {
  width: 200,
  height: 200,
  borderWidth: 2,
  borderColor: '#666',
  borderStyle: 'dashed',
  borderRadius: 5,
  paddingTop: 10,
  paddingLeft: 10
};

const activeStyle = {
  borderStyle: 'solid',
  borderColor: '#6c6',
  backgroundColor: '#eee'
};

const rejectStyle = {
  borderStyle: 'solid',
  borderColor: '#c66',
  backgroundColor: '#eee'
};

const containerStyle = {
  display: 'flex',
  alignItems: 'center'
};

const headerDiv = {
  'textAlign': 'center'
}

const dropzoneBox = {
  margin: '10px',
}

const spanCenter = {
  display: 'inline-block',
}

function mapInputsToPortfolioConstituents(inputs, input_counterparty_list, state_port_constituents) {
  var mapped_data = new Array(state_port_constituents.length).fill(new Array(16));
  state_port_constituents.map(function(value, index, arr) {
    mapped_data[index] = inputs[input_counterparty_list.indexOf(value)]
  })
  return mapped_data
}

class FactorGroupUpload extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      factorResiduals: [],
      factorReturns: [],
      factorTable: [],
      fundamentalCharacteristics: [],
      factorGroupName: null,
      dataSetID: null,
      selectedPortfolio: null,
      selectedPortfolioName: null,
      portfolioTransactions: null,
      input_counterparty_list: null,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.saveFactorGroup = this.saveFactorGroup.bind(this);
    this.saveFactorGroupName = this.saveFactorGroupName.bind(this);
    this.onDropFactorReturns = this.onDropFactorReturns.bind(this)
    this.onDropFactorTable = this.onDropFactorTable.bind(this)
    this.onDropFactorResiduals = this.onDropFactorResiduals.bind(this)
    this.onDropFundamentalCharacteristics = this.onDropFundamentalCharacteristics.bind(this)
    this.factorGroupsOverview = this.factorGroupsOverview.bind(this);
    this.deleteFactorOverview = this.deleteFactorOverview.bind(this);
  }

  saveSettings = (value) => {
    if(value.selectedItemIndex !== undefined){
      const dataSetID = this.props.selections.data[value.selectedItemIndex].dataSetID
      const selectionID = this.props.selections.data[value.selectedItemIndex].selectionID
      const selectionName = this.props.selections.data[value.selectedItemIndex].selectionName
      const portfolioTransactions = this.props.selections.data[value.selectedItemIndex].selectionTransactions
      const portfolioCounterparties = this.props.fetchPortfolioCounterparties(selectionID, sessionStorage.getItem('jwtToken'))
      this.setState({
        dataSetID: dataSetID,
        selectedPortfolio: selectionID,
        selectedPortfolioName: selectionName,
        portfolioTransactions: portfolioTransactions,
      })
    }
  }

  deleteFactorOverview = (id) => {
    this.props.deleteFactorOverview(id, sessionStorage.getItem('jwtToken'))
  }

  trashcan_column = {
    accessor: "delete_job",
    Cell: ({ original }) => {
      return (
        <i
          className="fa fa-trash"
          style={{ display: "inline-block", width: "100%", textAlign: "center" }}
          onClick={() => {this.deleteFactorOverview(original.setID)}}
        >
        </i>
      );
    },
    header: "Delete Factor Group",
    sortable: false,
    width: 45
  };

  factorGroupsOverview = () => {
    //risk_free_curve_group_ids.forEach(id => {
    ////  let entries = this.props.riskFreeCurves.data.filter(entry => id === entry.curveSetID)
    //  risk_free_curve_groups.push(entries[0])
    //})
    return (
      <SimpleTable
        data={this.props.factorLoadings.data}
        sortColumn='setID'
        sortDescendingOrder={false}
        columns={[
          {accessor: "setID", Header: "Set ID"},
          {accessor: "setName", Header: "Set Name"},
          {accessor: "createdOn", Header: "Created On"},
          this.trashcan_column,
        ]}
      />
    )
  }

  saveFactorGroup = () => {
    let factorReturns = this.state.factorReturns
    let factorTable = this.state.factorTable
    let factorResiduals = this.state.factorResiduals
    let factorGroup = [factorReturns, factorTable, factorResiduals]
    return factorGroup
  }

  handleSubmit = (event) => {
    //event.preventDefault();
    let data = {}
    data['factorGroupName'] = this.state.factorGroupName
    data['factorReturns'] = this.state.factorReturns
    data['factorTable'] = this.state.factorTable
    data['factorResiduals'] = this.state.factorResiduals
    data['fundamentalCharacteristics'] = this.state.fundamentalCharacteristics
    // TODO: perform proper checks
    this.props.addFactorGroup(data, sessionStorage.getItem('jwtToken'))
  }

  onDropFactorReturns = (files) => {
    const reader = new FileReader()
    let react_var = this;
    reader.onload = function () {
      Papa.parse(reader.result, {
  	     complete: function(results) {
            let floatarrayofdicts = results.data.map(x => {
              var new_dict = {}
              Object.entries(x).forEach(
                ([key, value]) => {if(key === 'Date') {new_dict[key] = value} else { new_dict[key] = parseFloat(value) }}
              )
              return new_dict
            });

            if(Object.keys(floatarrayofdicts[floatarrayofdicts.length - 1]).length < 2){
              floatarrayofdicts.pop()
            }
            react_var.setState((state, props) => ({
              factorReturns: floatarrayofdicts,
            }));
  	     },
         header: true,
      });
    }
    reader.readAsText(files[0])
  }

  onDropFactorTable = (files) => {
    const reader = new FileReader()
    let react_var = this;
    reader.onload = function () {
      Papa.parse(reader.result, {
  	     complete: function(results) {
            let floatarrayofdicts = results.data.map(x => {
              var new_dict = {}
              Object.entries(x).forEach(
                function([key, value]) {
                  if(key !== 'CountryIndustryName'){
                    new_dict[key] = parseFloat(value)
                    return new_dict
                  } else {
                    new_dict[key] = value
                    return new_dict
                  }
                })
                return new_dict
            });

            if(Object.keys(floatarrayofdicts[floatarrayofdicts.length - 1]).length < 2){
              floatarrayofdicts.pop()
            }
            react_var.setState((state, props) => ({
              factorTable: floatarrayofdicts,
            }));
  	     },
         header: true,
      });
    }
    reader.readAsText(files[0])
  }

  onDropFactorResiduals = (files) => {
    const reader = new FileReader()
    let react_var = this;
    reader.onload = function () {
      Papa.parse(reader.result, {
  	     complete: function(results) {
            let floatarrayofdicts = results.data.map(x => {
              var new_dict = {}
              Object.entries(x).forEach(
                ([key, value]) => {if(key === 'Date') {new_dict[key] = value} else { new_dict[key] = parseFloat(value) }}
              )
              return new_dict
            });

            if(Object.keys(floatarrayofdicts[floatarrayofdicts.length - 1]).length < 2){
              floatarrayofdicts.pop()
            }
            react_var.setState((state, props) => ({
              factorResiduals: floatarrayofdicts,
            }));
  	     },
         header: true,
      });
    }
    reader.readAsText(files[0])
  }

  onDropFundamentalCharacteristics = (files) => {
    const reader = new FileReader()
    let react_var = this;
    reader.onload = function () {
      Papa.parse(reader.result, {
        complete: function(results) {
          react_var.setState((state, props) => ({
            fundamentalCharacteristics: results.data,
          }))
        },
        header: true,
      });
    }
    reader.readAsText(files[0])
  }

  saveFactorGroupName = (value) => {
    this.setState({
      factorGroupName: value.inputName,
    })
  }

  componentDidMount(){
    if(this.props.selections.isLoading === true){
      this.props.fetchSelections(sessionStorage.getItem('jwtToken'))
    }
    this.props.fetchFactorOverviews(sessionStorage.getItem('jwtToken'))
  }

  render() {
    const showError = this.props.error.length > 0
    const renderFactorOverview = this.props.factorLoadings.isLoading !== true && this.props.factorLoadings.data.length > 0

    if(Object.keys(this.props.error).length > 0){
      const errors = Object.keys(this.props.error)

      if(errors.find(error => error === 'FETCH_FACTOR_OVERVIEWS')){
        return (
          <div>
            <Header title={"Factor Group Upload"} />
            <Notification heading={'Status'} body={'Error loading factoroverviews'}/>
          </div>
        )
      }
    }

    if (this.props.selections.isLoading === true) {
      return (
        <div>
          <Header title={"Portfolio Overview"} />
          <Notification heading={''} body={'Loading Data'}/>
        </div>
      )
    }

    let yLabels = new Array(2).fill(0).map((_, i) => `${i + 1}`);
    if (this.props.portfoliocounterparties !== undefined && this.props.portfoliocounterparties.data.length > 0){
      yLabels = this.props.portfoliocounterparties.data;
    }
    // Data is presented in a columnar fashion! no [row][column] but [column][row]
    if (this.state.xLabels !== undefined) {
      let data = new Array(yLabels.length)
        .fill(0)
        .map(() => new Array(this.state.xLabels.length).fill(0));
    }
    if (this.state.factorReturns > 0){
      let data = this.this.state.factorReturns;
    };

    return (
      <div>
      <Header title={"Factor uploads"} />
      <Card>
        <Card.Header>Factor Files</Card.Header>
        <Card.Body>
          <Form.Group as={Row} controlId="createJob.selectionID">
            <Form.Label column sm={4}>Selection Name</Form.Label>
              <Col sm={8}>
                <Form.Control as="select" ref="selectionID" onChange={this.saveSettings}>
                { this.props.selections.data.map((k, index) => {
                  return <option key={k.selectionID} value={k.selectionID}>{k.selectionName}</option>
                  })
                }
              </Form.Control>
            </Col>
          </Form.Group>
          <FormSelectName onChange={this.saveFactorGroupName} inputName='FactorGroupName'/>
          <div style={containerStyle}>
            <div style={dropzoneBox}>
              <div style={headerDiv}><span style={spanCenter}>Factor Returns</span></div>
              <Dropzone accept="text/csv, application/vnd.ms-excel" onDrop={this.onDropFactorReturns}>
                {({ getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject, acceptedFiles, rejectedFiles }) => {
                  let styles = {...baseStyle}
                  styles = isDragActive ? {...styles, ...activeStyle} : styles
                  styles = isDragReject ? {...styles, ...rejectStyle} : styles

                  return (
                    <div {...getRootProps()} style={styles} >
                      <input {...getInputProps()} />
                      <div>
                        {isDragAccept ? 'Drop' : 'Drag'} files here...
                      </div>
                      {isDragReject && <div>Please provide CSV files</div>}
                    </div>
                  )
                }}
              </Dropzone>
            </div>
            <div style={dropzoneBox}>
              <div style={headerDiv}><span style={spanCenter}>Factor Table</span></div>
              <Dropzone accept="text/csv, application/vnd.ms-excel" onDrop={this.onDropFactorTable}>
                {({ getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject, acceptedFiles, rejectedFiles }) => {
                  let styles = {...baseStyle}
                  styles = isDragActive ? {...styles, ...activeStyle} : styles
                  styles = isDragReject ? {...styles, ...rejectStyle} : styles

                  return (
                    <div {...getRootProps()} style={styles} >
                      <input {...getInputProps()} />
                      <div>
                        {isDragAccept ? 'Drop' : 'Drag'} files here...
                      </div>
                      {isDragReject && <div>Please provide CSV files</div>}
                    </div>
                  )
                }}
              </Dropzone>
            </div>
            <div style={dropzoneBox}>
              <div style={headerDiv}><span style={spanCenter}>Factor Residuals</span></div>
              <Dropzone accept="text/csv, application/vnd.ms-excel" onDrop={this.onDropFactorResiduals}>
                {({ getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject, acceptedFiles, rejectedFiles }) => {
                  let styles = {...baseStyle}
                  styles = isDragActive ? {...styles, ...activeStyle} : styles
                  styles = isDragReject ? {...styles, ...rejectStyle} : styles

                  return (
                    <div {...getRootProps()} style={styles} >
                      <input {...getInputProps()} />
                      <div>
                        {isDragAccept ? 'Drop' : 'Drag'} files here...
                      </div>
                      {isDragReject && <div>Please provide CSV files</div>}
                    </div>
                  )
                }}
              </Dropzone>
            </div>
            <div style={dropzoneBox}>
              <div style={headerDiv}><span style={spanCenter}>Fundamental Characteristics</span></div>
              <Dropzone accept="text/csv, application/vnd.ms-excel" onDrop={this.onDropFundamentalCharacteristics}>
                {({ getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject, acceptedFiles, rejectedFiles }) => {
                  let styles = {...baseStyle}
                  styles = isDragActive ? {...styles, ...activeStyle} : styles
                  styles = isDragReject ? {...styles, ...rejectStyle} : styles

                  return (
                    <div {...getRootProps()} style={styles} >
                      <input {...getInputProps()} />
                      <div>
                        {isDragAccept ? 'Drop' : 'Drag'} files here...
                      </div>
                      {isDragReject && <div>Please provide CSV files</div>}
                    </div>
                  )
                }}
              </Dropzone>
            </div>
          </div>

          <Button bsstyle="info" fill="true" type="submit" onClick={this.handleSubmit}>
            Save
          </Button>
        </Card.Body>
      </Card>

      { renderFactorOverview && (
        <Card className="mt-4">
          <Card.Header>Uploaded Factor Groups</Card.Header>
          <Card.Body>
            {this.factorGroupsOverview()}
          </Card.Body>
        </Card>
      )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    authentication: state.authentication,
    selectedPortfolio: state.selectedPortfolio,
    selections: state.selections,
    selectedPortfolio: null,
    selectedPortfolioName: null,
    portfolioCounterparties: state.portfolioCounterparties,
    FactorLoadingsMatrix: state.factorLoadingsMatrix,
    factorLoadings: state.factorLoadings,
    loading: state.loading,
    error: state.error,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    addFactorGroup: (data, token) => {
      return dispatch(inputs.addFactorGroup(data, token));
    },
    fetchSelections: (id, token) => {
      return dispatch(selections.fetchSelections(id, token));
    },
    fetchPortfolioCounterparties: (id, token) => {
      return dispatch(selections.fetchPortfolioCounterparties(id, token));
    },
    fetchFactorOverviews: (token) => {
      return dispatch(inputs.fetchFactorOverviews(token));
    },
    deleteFactorOverview: (id, token) => {
      return dispatch(inputs.deleteFactorOverview(id, token));
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FactorGroupUpload);

const initialState = {data: {}, isLoading: true};

export function transitionMatrix(state=initialState, action) {
  switch(action.type) {

    case 'ADD_TRANSITION_MATRIX_SUCCESS':
      return {data: [...state.data, action.transition_matrix], lastAdded: true};

    case 'ADD_TRANSITION_MATRIX_FAILURE':
      return {data: action.transitionMatrix, error: true, lastAdded: false};

    case 'DELETE_TRANSITION_MATRIX_SUCCESS':
      let new_state = [...state.data].filter(elem => {return elem.transition_matrix_id !== action.id})
      return {data: new_state, last_added: false};

    case 'DELETE_TRANSITION_MATRIX_FAILURE':
      return {data: action.transitionMatrix, error: true};

    case 'FETCH_TRANSITION_MATRICES_SUCCESS':
      return {data: action.transition_matrices, isLoading: false};

    case 'FETCH_TRANSITION_MATRICES_FAILURE':
      return {data: action.transition_matrices, error: true, isLoading: false};

    default:
      return state;
  }
}

export function factorLoadings(state=initialState, action) {
  switch(action.type) {
    case 'ADD_FACTOR_GROUP_SUCCESS':
      return {data: action.factorGroup, lastAdded: true};

    case 'ADD_FACTOR_GROUP_FAILURE':
      return {data: action.factorGroup, error:true, lastAdded: false};

    case 'DELETE_FACTOR_OVERVIEW_SUCCESS':
      let new_state = [...state.data].filter(elem => {return elem.setID !== action.id})
      return {data: new_state, deleted: true};

    case 'DELETE_FACTOR_OVERVIEW_FAILURE':
      return {data: action.factorGroup, error: true, deleted: false};

    case 'ADD_COUNTERPARTY_LOADINGS_SUCCESS':
      return {data: action.counterpartyLoadings, lastAdded: true};

    case 'ADD_COUNTERPARTY_LOADINGS_FAILURE':
      return {data: action.counterpartyLoadings, error: true, lastAdded: false};

    case 'ADD_FACTOR_LOADINGS_SUCCESS':
      return {data: action.factorLoadings, lastAdded: true};

    case 'ADD_FACTOR_LOADINGS_FAILURE':
      return {data: action.factorLoadings, error: true, lastAdded: false};

    case 'FETCH_FACTOR_OVERVIEWS_SUCCESS':
      return {data: action.factorOverviews, isLoading: false};

    case 'FETCH_FACTOR_OVERVIEWS_FAILURE':
      return {data: action.factorOverviews, error: true, isLoading: false};

    case 'FETCH_FACTOR_LOADINGS_SUCCESS':
      return {data: action.factorLoadings, isLoading: false};

    case 'FETCH_FACTOR_LOADINGS_FAILURE':
      return {data: action.factorLoadings, error: true, isLoading: false};

    default:
      return state;
  }
}

export function firmFundamentals(state=initialState, action) {
  switch(action.type) {
    case 'ADD_FIRM_FUNDAMENTALS_SUCCESS':
      return {data: action.firmFundamentals, lastAdded: true};

    case 'ADD_FIRM_FUNDAMENTALS_FAILURE':
      return {data: action.firmFundamentals, error: true, lastAdded: false};

    default:
      return state;
  }
}

export function RSQs(state=initialState, action) {
  switch(action.type) {
    case 'ADD_RSQS_SUCCESS':
      return {data: action.RSQs, lastAdded: true};

    case 'ADD_RSQS_FAILURE':
      return {data: action.RSQs, error: true, lastAdded: false};

    default:
      return state;
  }
}

export function riskFreeCurve(state=initialState, action) {
  switch(action.type) {
    case 'ADD_RISK_FREE_CURVE_SUCCESS':
      return {data: state.data, lastAdded: true};

    case 'ADD_RISK_FREE_CURVE_FAILURE':
      return {data: action.riskFreeCurve, error: true, lastAdded: false};

    case 'DELETE_RISK_FREE_CURVE_GROUP_SUCCESS':
      let new_state = [...state.data].filter(elem => {return elem.curveSetID !== action.id})
      return {data: new_state, deleted: true};

    case 'DELETE_RISK_FREE_CURVE_GROUP_FAILURE':
      return {data: state.data, error: true, deleted: false};

    case 'FETCH_RISK_FREE_CURVES_SUCCESS':
      return {data: action.riskFreeCurve, isLoading: false};

    case 'FETCH_RISK_FREE_CURVES_FAILURES':
      return {data: action.riskFreeCurve, error: true, isLoading: false};

    default:
      return state;
  }
}

const initialState = {data: [], isLoading: true};

export default function portfolioPerformance(state=initialState, action) {
  switch(action.type) {

    case 'CREATE_XLSX_PORTFOLIO_PERFORMANCE':
      return {data: action.portfolioperformance, isLoading: false};

    case 'FETCH_PORTFOLIO_PERFORMANCE_SUCCESS':
      return {data: action.portfolioperformance, isLoading: false};

    case 'FETCH_PORTFOLIO_PERFORMANCE_FAILURE':
      return {data: [], error: true, isLoading: false};

    default:
      return state;
  }
}

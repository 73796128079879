function handleErrors(response) {
    if (!response.ok) {
        throw Error(response.statusText);
    }
    return response;
}

export const fetchPortfolioValue = (id, token) => {
  return dispatch => {
    dispatch({ type: 'FETCH_PORTFOLIO_VALUE_REQUEST' });
    const headers = {"Content-Type": "application/json", "Authorization": "JWT " + token};
    return fetch(process.env.REACT_APP_HTTP_PROTOCOL + "://" + process.env.REACT_APP_API_IP + "/api/results/distributionportfolio/" + id + "/", {headers, })
      .then(handleErrors)
      .then(res => res.json())
      .then(portfoliovalue => {
        return dispatch({
          type: 'FETCH_PORTFOLIO_VALUE_SUCCESS',
          portfoliovalue
        })
      })
      .catch(error => {
        return dispatch({ type: 'FETCH_PORTFOLIO_VALUE_FAILURE', error_type: error, error: true })
      })
  }
}

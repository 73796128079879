import { combineReducers} from 'redux';
import { reducer as reduxFormReducer } from 'redux-form';

import authentication from './authentication'

import datasets from './datasets'
import transactions from './transactions'
import { counterparties, counterpartydata, industrydata, sectordata, industryexposures, countryexposures, portfoliooverview, industrybenchmarkdata, reports } from './counterparties'
import stratificationfeatures from './stratificationfeatures'

import inputs from './inputs'
import { selections, portfolio_counterparties } from './selections'
import scenarios from './scenarios'
import { jobs, comparableJobs } from './jobs'
import { transitionMatrix, factorLoadings, firmFundamentals, RSQs, riskFreeCurve } from './inputs'

import { assets, assetCharacteristics, allAssetMTMValues, alternativeAllAssetMTMValues } from './assets'
import performanceMeasures from './performancemeasures'
import performancefeatures from './performancefeatures'
import assetsperformance from './assetsperformance'
import portfolioperformance from './portfolioperformance'
import resultstransactions from './resultstransactions'
import { resultsdatasets, resultsjob } from './resultsdatasets'
import portfoliovalue from './portfoliovalue'
import users from './users'
import scraper, { tickerlists } from './scraper'

import configurations from './configurations'
import loading from './loading'
import error from './error'


const pm_app = combineReducers({
  authentication: authentication,
  datasets: datasets,
  transactions: transactions,
  counterparties: counterparties,
  counterpartydata: counterpartydata,
  industrydata: industrydata,
  sectordata: sectordata,
  industryexposures: industryexposures,
  countryexposures: countryexposures,
  reports: reports,
  stratificationfeatures: stratificationfeatures,
  transitionMatrix: transitionMatrix,
  factorLoadings: factorLoadings,
  firmFundamentals: firmFundamentals,
  RSQs: RSQs,
  riskFreeCurves: riskFreeCurve,
  selections: selections,
  portfolioCounterparties: portfolio_counterparties,
  scenarios: scenarios,
  scraper: scraper,
  tickerlists: tickerlists,
  jobs: jobs,
  comparableJobs: comparableJobs,
  assetMTMValues: allAssetMTMValues,
  alternativeAssetMTMValues: alternativeAllAssetMTMValues,
  assets: assets,
  assetCharacteristics: assetCharacteristics,
  performanceMeasures: performanceMeasures,
  performancefeatures: performancefeatures,
  assetsPerformance: assetsperformance,
  portfolioPerformance: portfolioperformance,
  portfoliodata: portfoliooverview,
  industrybenchmarkdata: industrybenchmarkdata,
  resultstransactions: resultstransactions,
  resultsdatasets: resultsdatasets,
  resultsjob: resultsjob,
  portfoliovalue: portfoliovalue,
  configurations: configurations,
  users: users,
  loading: loading,
  error: error,
  form: reduxFormReducer,
})

export default pm_app;

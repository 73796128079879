// Import React & Redux components
import React from 'react'
import { connect } from 'react-redux'
import { Card, Form, Nav, Row, Col } from 'react-bootstrap';
import { Redirect } from 'react-router-dom';

// Import layout and helper functions/templates
import Header from '../components/Header'
import Notification from "../components/Notification"
import { createLoadingSelector, createErrorMessageSelector, createAddMessage, findAssociatedValue } from './Helpers'

// Import data sources
import { transactionActions, selections, datasetActions } from '../actions'
import { authentication } from "../actions"

// Import dumb component
import FormSelectOneDropDownPlusNameMessage from './FormSelectOneDropDownPlusNameMessage'
import FormSelectTable from './FormSelectTable'
import ReactTable from 'react-table';

const styledButton = props => (
  <button type="button" {...props} className="btn btn-outline-primary-page-selection">
    {props.children}
  </button>
)

class CreatePortfolioSelection extends React.Component {
  constructor(props) {
    super(props);
    if (this.props.match === undefined || this.props.match.params.id === undefined) {
      this.state = {
        selectedDatasetID: null,
        dataSetName: undefined,
        selectionPortfolios: [],
        selectionTransactions: [],
        selectionName: null,
        renderHelp: false,
        helpText: undefined,
        pageView: 'select',
      }
    } else {
      this.state = {
        selectedDatasetID : this.props.match.params.id,
        dataSetName: undefined,
        selectionPortfolios: [],
        selectionTransactions: [],
        selectionName: null,
        pageView: 'select',
      }
    }
    this.saveData = this.saveData.bind(this);
    this.saveSelectionTransactions = this.saveSelectionTransactions.bind(this);
    this.deleteSelection = this.deleteSelection.bind(this);
    this.renderHelp = this.renderHelp.bind(this);
    this.changePageViewToSelect = this.changePageViewToSelect.bind(this)
    this.changePageViewToCreate = this.changePageViewToCreate.bind(this)
  }

  // Force a url update to reflected the selected dataSetID and retrieve the data
  // for that dataSetID.
  saveDataSetID = (value) => {
    this.setState({
      selectedDatasetID : parseInt(this.refs.dataSetID.value),
      dataSetName : this.props.datasets.data.filter(elem => (elem.dataSetID === parseInt(this.refs.dataSetID.value)))[0].dataSetName
    })
    this.props.fetchTransactions(this.refs.dataSetID.value, sessionStorage.getItem('jwtToken'))
  }

  deleteSelection = (id) => {
    this.props.deleteSelection(id, sessionStorage.getItem('jwtToken'))
  }

  saveSelectionName = (value) => {
    this.setState({
      selectionName: value.inputName,
    })
  }

  saveSelectionTransactions = (selectionTransactions) => {
    this.setState({
      selectionTransactions: selectionTransactions.map(elem => elem.transactionID)
    })
  }

  renderHelp = (content) => {
    this.state.renderHelp ? this.setState({ renderHelp: false, helpText: undefined}) : this.setState({ renderHelp: true, helpText: content})
  }

  toggleItem = (elem) => {
    let existing = this.state.selectionPortfolios.filter(item => {return item.selectionID === elem.selectionID})
    if (existing.length > 0){
      this.setState({
        selectionPortfolios: this.state.selectionPortfolios.filter(counterparty => {return counterparty.selectionID !== elem.selectionID})
      })
    } else {
      this.setState({
        selectionPortfolios: [...this.state.selectionPortfolios, elem]
      })
    }
  }

  toggleAll = () => {
    let indices_in_focus = this.portfolioSelectionTable.getResolvedState().sortedData;
    let elems_in_focus = indices_in_focus.map(elem => {return elem._original})
    const selectionPortfolios = [];
    let new_selected_array = [];
    let selectAll = this.state.selectAll ? false : true;
    if(selectAll){
      elems_in_focus.forEach(item => {
        let filter_result = this.state.selectionPortfolios.filter(elem => {return elem.selectionID === item.selectionID})
        if (filter_result.length === 0) {
          selectionPortfolios.push(item);
        }
      });
      new_selected_array = [...this.state.selectionPortfolios,...selectionPortfolios]
    } else {
      this.state.selectionPortfolios.forEach(item => {
        let selected_elem = elems_in_focus.filter(elem => {return elem.selectionID === item.selectionID})
        if(selected_elem.length === 0){
          selectionPortfolios.push(item)
        }
      })
      new_selected_array = [...selectionPortfolios]
    }
    this.setState({
      selectAll: selectAll,
		  selectionPortfolios: new_selected_array
		});
  };

  // Adjust this function!
  saveData = (values) => {
    //values.selectionName = this.state.selectionName
    values.selectionName = this.refs.selectionName.value.length > 1 ? this.refs.selectionName.value : new Date().toLocaleString('en-GB', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit'})
    values.selectionName = values.selectionName.includes(',') ? values.selectionName.replace(',','') : values.selectionName
    values.dataSetID = this.state.selectedDatasetID
    values.selectionTransactions = this.state.selectionTransactions.join()
    if(values.selectionTransactions.length === 0){
      this.renderHelp({header: 'Portfolio creation error', body: 'It looks like you tried to create an empty portfolio. Please select transactions by clicking the checkbox in front of the transaction.'})
      return false
    }
    values.selectionTransactions = "[" + values.selectionTransactions +  "]"
    this.props.addSelection(values, sessionStorage.getItem('jwtToken'))
    createErrorMessageSelector(['ADD_SELECTION'])
    this.setState({
      pageView: 'select'
    })
  }

  componentDidMount() {
    this.props.fetchDataSets(sessionStorage.getItem('jwtToken'));
    this.props.fetchSelections(sessionStorage.getItem('jwtToken'));
    if(this.props.transactions.isLoading === true && this.state.selectedDatasetID !==  null) {
      this.props.fetchTransactions(this.state.selectedDatasetID, sessionStorage.getItem('jwtToken'))
    }
  }

  componentDidUpdate() {
    if(this.state.selectedDatasetID === null && this.props.datasets.isLoading !== true && this.props.datasets.data !== undefined && this.props.datasets.data.length > 0){
      this.props.fetchTransactions(this.props.datasets.data[0].dataSetID, sessionStorage.getItem('jwtToken'))
      this.setState({
        selectedDatasetID: this.props.datasets.data[0].dataSetID,
  		});
    }
  }

  trashcan_column = {
    accessor: "delete_selection",
    Cell: ({ original }) => {
      return (
        <i
          className="fa fa-trash"
          style={{ display: "inline-block", width: "100%", textAlign: "center" }}
          onClick={() => {this.deleteSelection(original.selectionID)}}
        >
        </i>
      );
    },
    header: "Delete Selection",
    sortable: false,
    width: 45
  };

  changePageViewToSelect = () => {
    this.setState({
      pageView: "select"
    })
  }

  changePageViewToCreate = () => {
    this.setState({
      pageView: "create"
    })
  }

  render() {
    const renderCreatePortfolio = this.state.pageView === 'create'
    const renderExistingPortfolios = this.state.pageView === 'select' && this.props.selections.isLoading !== true
    // Show a loading screen if datasets has not finished loading yet.
    if (this.props.datasets.isLoading === true) {
      return (
        <div>
          <Header title={"Portfolio Selection"} />
          <Notification heading={'Data Set Selection'} body={'Loading Data'}/>
        </div>
      )
    }

    // If datasets being loaded resulted in an error, show an error screen.
    if (this.props.isError !== undefined || this.props.datasets === undefined || this.props.datasets.data === undefined){
      return (
        <div>
          <Header title={"Portfolio Selection"} />
          <Notification heading={'Data Set Selection'} body={'Error Loading Data'}/>
        </div>
      )
    }

    if (this.props.selections.error === true) {
      if (this.props.selections.error_text === "Unauthorized") {
        return <Redirect to={'/login_screen'} />;
      }
    }

    // If no dataset has selected, show only a selection menu.
    if (this.state.selectedDatasetID === null || this.props.transactions.isLoading === true){
      return(
        <div>
          { this.state.renderHelp === true && (
            <div className="overlay-help" onClick={() => this.renderHelp(undefined)}>
              <div className="overlay-help-card">
                <Card className="mt-4">
                  <Card.Header><div className="fa-pull-left">{this.state.helpText.header}</div>
                  <i className="fa fa-window-close fa-pull-right" onClick={() => this.renderHelp(undefined)}></i></Card.Header>
                  <Card.Body>{this.state.helpText.body}</Card.Body>
                </Card>
              </div>
            </div>
          )}
          <Header
            title={"Portfolio Selection"}
            subtitle={
              { startText: 'Choose your data set you want to work with and select your portfolio by either selecting an existing portfolio or creating a new portfolio.',
                list: undefined,
                closingText: undefined,
              }} />
          <Card className="mt-4">
            <Card.Header>
              <div className="fa-pull-left">Data Set Selection</div>
              <div><span className="question-mark fa-pull-right" onClick={() => this.renderHelp({header: 'Dataset Selection', body: 'Choose the data set you want to work with'})}></span></div>
            </Card.Header>
            <Card.Body>
              <Form.Group as={Row} controlId="createPortfolioSelection.dataSetID">
              <Form.Label column sm={3}>Data Set</Form.Label>
                <Col sm={9}>
                  <Form.Control as="select" ref="dataSetID" onChange={this.saveDataSetID} value={this.state.dataSetName} defaultValue="">
                    { this.props.datasets.data.map((k, index) => {
                      return <option key={index} value={k.dataSetID}>{k.dataSetName}</option>
                      })
                    }
                  </Form.Control>
                </Col>
              </Form.Group>

              <FormSelectOneDropDownPlusNameMessage
                onChange={this.saveSettings}
                itemLabel={'Data Set'}
                itemName={this.props.datasets.data.map(k => k.dataSetName)}
                currentlySelectedItemName='Data Set'
                inputName='Portfolio Name'
                message={createAddMessage(this.props.error.ADD_SELECTION, this.props.selections[0], 'Selection')}
              />
            </Card.Body>
          </Card>
        </div>
      )
    }

    // Show a loading screen if not all sources have finished loading yet.
    if (this.props.transactions.isLoading === true || this.props.datasets.isLoading === true) {
      return (
        <div>
          <Header title={"Portfolio Selection"} />
          <Notification heading={'Data Set Selection'} body={'Loading Data'}/>
        </div>
      )
    }

    // Show an error page when one of the sources did not load.
    if (this.props.isError !== undefined || this.props.transactions.data === undefined || this.props.datasets.data === undefined) {
      return (
        <div>
          <Header title={"Portfolio Selection"} />
          <Notification heading={'Data Set Selection'} body={'Error Loading Data'}/>
        </div>
      )
    }

    // Add a column to the table with all transactions to allow for selections.
    // Standard methodology will return the line number in the displayed table,
    // however we want to track selected transactionIDs. This columns allows us to
    // do so. The form called is a general solution also used in other components.
    // While looping is a rather roundabout way to effect a simple couple,
    // solutions like slice do not work as this is a two-dimensional object.
    var columnTable = this.props.transactions.data
    for(var i = 0; i < columnTable.length; ++i) {
      columnTable[i].tempColumn=columnTable[i].transactionID
    }

    return(
      <div>
        <Header
          title={"Portfolio Selection"}
          subtitle={
          { startText: 'Choose your data set you want to work with and select your portfolio by either selecting an existing portfolio or creating a new portfolio.',
            list: undefined,
            closingText: undefined,
          }}
        />
        <Card className="mt-4">
        <Card.Header>
          <div className="fa-pull-left">Data Set Selection</div>
          <div><span className="question-mark fa-pull-right" onClick={() => this.renderHelp({header: 'Data set Selection', body: 'Choose the data set you want to work with'})}></span></div>
        </Card.Header>
          <Card.Body>
            <Form.Group as={Row} controlId="createPortfolioSelection.dataSetID">
            <Form.Label column sm={3}>Data Set</Form.Label>
              <Col sm={9}>
                <Form.Control as="select" ref="dataSetID" onChange={this.saveDataSetID} value={this.state.dataSetID} defaultValue="">
                  { this.props.datasets.data.map((k, index) => {
                    return <option key={index} value={k.dataSetID}>{k.dataSetName}</option>
                    })
                  }
                </Form.Control>
              </Col>
            </Form.Group>
          </Card.Body>
        </Card>

        <Nav variant="pills" defaultActiveKey="simple" fill="true" className="mt-4">
          <Nav.Item style={{marginRight: '1.5em'}}>
            <Nav.Link className="main-selector" eventKey="simple" onClick={this.changePageViewToSelect}>Select Existing Portfolio</Nav.Link>
          </Nav.Item>
          <Nav.Item style={{marginLeft: '1.5em'}}>
            <Nav.Link className="main-selector" eventKey="detailed" onClick={this.changePageViewToCreate}>Create New Portfolio</Nav.Link>
          </Nav.Item>
        </Nav>

        { this.state.renderHelp === true && (
          <div className="overlay-help" onClick={() => this.renderHelp(undefined)}>
            <div className="overlay-help-card">
              <Card className="mt-4">
                <Card.Header>
                  <div className="fa-pull-left">{this.state.helpText.header}</div>
                  <i className="fa fa-window-close fa-pull-right" onClick={() => this.renderHelp(undefined)}></i>
                </Card.Header>
                <Card.Body>{this.state.helpText.body}</Card.Body>
              </Card>
            </div>
          </div>
        )}

        { renderCreatePortfolio && (
          <Card className="mt-4">
          <Card.Header>
            <div className="fa-pull-left">New Portfolio</div>
            <div><span className="question-mark fa-pull-right" onClick={() => this.renderHelp({header: 'Create new portfolio', body: 'Select the transactions you want to work with and click “save portfolio” to create a new portfolio. Don’t forget to give your new portfolio a name before you save it. If you don\'t, the portfolio will be save with today\'s date.'})}></span></div>
          </Card.Header>
            <Card.Body>
              <Form>
                <Form.Group as={Row} controlId="createSelection.selectionName" >
                  <Form.Label column sm={3}>Portfolio Name</Form.Label>
                  <Col sm="9">
                    <Form.Control type="text" ref="selectionName" required/>
                  </Col>
                </Form.Group>
              </Form>
              <FormSelectTable
                onSubmit={this.saveData}
                saveButtonText={'Save Portfolio'}
                data={columnTable}
                saveSelectionItems={this.saveSelectionTransactions}
                columns={[
                  {accessor: "Reporting_Date", Header: "Reporting_Date", Cell: row => (
                    <div style={{ textAlign: "right" }}>{row.value === null ? 'N/A' : new Date(row.value).toLocaleString('en-GB', { year: 'numeric', month: '2-digit', day: '2-digit' })}</div>
                  )},
                  {accessor: "Global_Facility_ID", Header: "Global_Facility_ID"},
                  {accessor: "Department", Header: "Department"},
                  {accessor: "Original_Exposure_Pre_CCF", Header: "Exposure", Cell: row => (
                    <div style={{ textAlign: "right" }}>{row.value === null ? 'N/A' : row.value.toLocaleString('en-GB', {"style": "currency", "currency": "EUR", "minimumFractionDigits": 2, "maximumFractionDigits": 2})}</div>
                  )},
                  {accessor: "Rating", Header: "Rating", Cell: row => (
                    <div style={{ textAlign: "right", paddingRight: "10px" }}>{row.value === null ? 'N/A' : row.value}</div>
                  )}
                ]}
              />
            </Card.Body>
          </Card>
        )}

        { renderExistingPortfolios && (
          <Card className="mt-4">
            <Card.Header>
              <div className="fa-pull-left">Existing Portfolios</div>
              <div><span className="question-mark fa-pull-right" onClick={() => this.renderHelp({header: 'Dataset Selection', body: 'Text'})}></span></div>
            </Card.Header>
            <Card.Body>
              <ReactTable
                data = {this.props.selections.data.filter(elem => elem.dataSetID === this.state.selectedDatasetID) }
                filterable
                ref={(r) => {
                  this.portfolioSelectionTable = r;
                }}
                columns={[
                  {
                    accessor: "checkbox",
                    Cell: ({ original }) => {
                      return (
                        <div style={{textAlign: "center"}}>
                          {//<Form.Check type="checkbox" key={original.selectionID} value={original.selectionID} onChange={() => this.toggleItem(original)} checked={this.state.selectionPortfolios.filter(elem => {return elem.selectionID === original.selectionID}).length > 0}/>
                          }
                          {<Form.Check type="checkbox" key={original.selectionID} value={original.selectionID} onChange={() => this.props.updateFunction(original.selectionID)} checked={this.props.selectedPortfolio === original.selectionID}/>}
                        </div>
                      );
                    },
                    Header: x => {
                      return (
                        <div style={{textAlign: "center"}}>
                          <input
                            type="checkbox"
                            className="checkbox"
                            checked={this.state.selectAll === true}
                            ref = { input => {
                                if (input) {
                                  input.indeterminate = this.state.selectAll === 2;
                                }
                               }
                             }
                            onChange = {() => this.toggleAll()}
                          />
                        </div>
                      );
                    },
                    sortable: false,
                    width: 45,
                  },
                  {accessor: "selectionName", Header: "Portfolio Name"},
                  //this.trashcan_column,
                ]}
                sorted={[{
                  id: 'selectionID',
                  desc: false
                }]}
                defaultPageSize={10}
                className="-striped -highlight"
                PreviousComponent={styledButton}
                NextComponent={styledButton}
              />
            </Card.Body>
          </Card>
        )}
      </div>
    );
  }
}

const loadingSelector = createLoadingSelector(['FETCH_TRANSACTIONS', 'FETCH_DATA_SETS']);
const errorSelector = createErrorMessageSelector(['FETCH_TRANSACTIONS', 'FETCH_DATA_SETS']);

const mapStateToProps = (state) => {
  return {
    authentication: state.authentication,
    transactions: state.transactions,
    datasets: state.datasets,
    selections: state.selections,
    selectionTransactions: state.selectionTransactions,
    selectionName: state.selectionName,
    isFetching: loadingSelector(state),
    isError: errorSelector(state),
    loading: state.loading,
    error: state.error,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchDataSets: (token) => {
      dispatch(datasetActions.fetchDataSets(token));
    },
    fetchTransactions: (id, token) => {
      dispatch(transactionActions.fetchTransactions(id, token));
    },
    fetchSelections: (token) => {
      dispatch(selections.fetchSelections(token));
    },
    addSelection: (text, token) => {
      return dispatch(selections.addSelection(text, token));
    },
    deleteSelection: (id, token) => {
      return dispatch(selections.deleteSelection(id, token));
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreatePortfolioSelection);

const initialState = {data: {}, isLoading: true};

export default function performancefeatures(state=initialState, action) {
  switch(action.type) {

    case 'CREATE_XLSX_PERFORMANCE_FEATURES':
      return {data: action.performancefeatures, isLoading: false};

    case 'FETCH_PERFORMANCE_FEATURES_SUCCESS':
      return {data: action.performancefeatures, isLoading: false};

    case 'FETCH_PERFORMANCE_FEATURES_FAILURE':
      return {data: action.performancefeatures, isLoading: false};

    default:
      return state;
  }
}

import React from "react";

export class Title extends React.Component {
  render() {
    return (
      <h1 className="mt-2"> {this.props.title}</h1>
    );
  }
}

export class Subtitle extends React.Component {
  render() {
    return (
      <div>
      { this.props.subtitle.startText !== undefined && (<h5 className="mt-2"> {this.props.subtitle.startText}</h5> )}
      { this.props.subtitle.list !== undefined && typeof(this.props.subtitle.list) === 'object' && (
        <li>
        {this.props.subtitle.list.map((bullet, index) => {return <ul key={index}>{bullet}</ul>})}
        </li>)
      }
      { this.props.subtitle.closingText !== undefined && (<h5 className="mt-2"> {this.props.subtitle.closingText}</h5> )}
      </div>
    );
  }
}

// Import React & Redux components
import React from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Card,
  Form,
  Row,
  Col,
  Nav,
} from 'react-bootstrap';

// Import layout and helper functions/templates
import ReactTable from 'react-table';
import Chart from './Chart';
import Header from './Header';
import Notification from './Notification';
import ResultsAsset from './ResultsAsset';
import {
  createLoadingSelector,
  createErrorMessageSelector,
  generateCSVFile,
} from './Helpers';

// Import data sources
import { resultsdatasets, portfoliovalue, jobs } from '../actions';

class ResultsPortfolio extends React.Component {
  constructor(props) {
    super(props);
    if (this.props.match.params.id === undefined) {
      this.state = {
        selectedJobID: null,
        selectedCompareJobID: undefined,
        helpText: undefined,
        tableData: [],
        aggregationIdentifier: 'portfolio',
        marginMethod: 'relative',
        returnOnRegulatoryCapital: [],
        returnOnEquity: [],
        compareReturnOnRegulatoryCapital: [],
        compareReturnOnEquity: [],
        comparePortfolioCRWA: [],
        comparePortfolioORWA: [],
        comparePortfolioOutstanding: [],
        comparePortfolioIM: [],
        comparePortfolioNIM: [],
        portfolioCRWA: [],
        portfolioORWA: [],
        portfolioOutstanding: [],
        portfolioIM: [],
        portfolioNIM: [],
      };
    } else {
      this.state = {
        selectedJobID: this.props.match.params.id,
        selectedCompareJobID: undefined,
        tableData: [],
        helpText: undefined,
        aggregationIdentifier: 'portfolio',
        marginMethod: 'relative',
        returnOnRegulatoryCapital: [],
        returnOnEquity: [],
        compareReturnOnRegulatoryCapital: [],
        compareReturnOnEquity: [],
        comparePortfolioCRWA: [],
        comparePortfolioORWA: [],
        comparePortfolioOutstanding: [],
        comparePortfolioIM: [],
        comparePortfolioNIM: [],
        portfolioCRWA: [],
        portfolioORWA: [],
        portfolioOutstanding: [],
        portfolioIM: [],
        portfolioNIM: [],
      };
    }
    this.renderHelp = this.renderHelp.bind(this);
    this.dataSummaryColumns = this.dataSummaryColumns.bind(this);
    this.dataDict = this.dataDict.bind(this);
    this.updateAggregationIdentifier = this.updateAggregationIdentifier.bind(this);
    this.updateStateRegulatoryReturnOnCapital = this.updateStateRegulatoryReturnOnCapital.bind(this);
  }

  // Bugged when there are jobs but the jobCompletion status is not yet 2. Causes a crash.
  saveJobID = (value) => {
    const selectedJobID = (this.refs.jobID === undefined || this.refs.jobID.value === undefined)
      ? value
      : parseInt(this.refs.jobID.value, 10);
    this.setState({
      selectedJobID,
      selectedJobName: this.props.jobs.data.filter((elem) => (
        elem.jobID === selectedJobID
      ))[0].jobName,
    });
    this.props.fetchResultsJob(selectedJobID, sessionStorage.getItem('jwtToken'));
  }

  saveCompareJobID = (value) => {
    const selectedCompareJobID = (this.refs.compareJobID === undefined || this.refs.compareJobID.value === undefined)
      ? value
      : parseInt(this.refs.compareJobID.value, 10);
    this.setState({
      compareReturnOnRegulatoryCapital: [...this.state.returnOnRegulatoryCapital],
      compareReturnOnEquity: [...this.state.returnOnEquity],
      comparePortfolioCRWA: [...this.state.portfolioCRWA],
      comparePortfolioORWA: [...this.state.portfolioORWA],
      comparePortfolioOutstanding: [...this.state.portfolioOutstanding],
      comparePortfolioIM: [...this.state.portfolioIM],
      comparePortfolioNIM: [...this.state.portfolioNIM],
      selectedCompareJobID,
      selectedCompareJobName: this.props.jobs.data.filter((elem) => (
        elem.jobID === selectedCompareJobID
      ))[0].jobName,
    });
    this.props.fetchResultsJob(selectedCompareJobID, sessionStorage.getItem('jwtToken'));
  }

  renderHelp = (content) => {
    if (this.state.renderHelp) {
      this.setState({ renderHelp: false, helpText: undefined });
    } else {
      this.setState({ renderHelp: true, helpText: content });
    }
  }

  updateAggregationIdentifier = (value) => {
    this.setState({
      aggregationIdentifier: value,
    });
  }

  saveToExcel = (axislabels, dataserieslabels, dataseries) => {
    generateCSVFile(axislabels, dataserieslabels, dataseries);
    return false;
  }

  // Load the required data
  componentDidMount() {
    this.props.fetchJobs(sessionStorage.getItem('jwtToken'));
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.selectedJobID === null
      && this.props.jobs.data !== undefined
      && this.props.jobs.data.length > 0
      && this.props.jobs.data[0].jobID !== undefined) {
      this.setState({
        selectedJobID: this.props.jobs.data[0].jobID,
        selectedJobName: this.props.jobs.data[0].jobName,
      });
      this.props.fetchResultsJob(this.props.jobs.data[0].jobID, sessionStorage.getItem('jwtToken'));
    }
    if (this.state.selectedJobID === null
      && this.props.jobs.isLoading !== true
      && this.props.jobs.data !== undefined
      && this.props.resultsjob.data !== undefined
      && this.props.jobs.data.length > 0) {
      const completedJobs = this.props.jobs.data.filter((elem) => (elem.jobCompletion >= 2));
      if (completedJobs.length > 0) {
        this.saveJobID(completedJobs[0].jobID);
      }
    }

    if (prevState.applicableJobID !== this.state.applicableJobID
      && this.state.applicableJobID !== undefined) {
      this.setState({
        tableData: this.dataDict(),
      })
    }

    if (this.props.resultsjob.data.length > 0 && !this.props.loading.FETCH_RESULTS_JOB && (prevProps.loading.FETCH_RESULTS_JOB && !this.props.loading.FETCH_RESULTS_JOB)) {
      this.updateStateRegulatoryReturnOnCapital();
    }


    if (this.props.resultsjob.data.length > 0
      && !this.props.loading.FETCH_RESULTS_JOB
      && this.state.applicableJobID !== this.props.resultsjob.data[0].jobID) {
      this.calculatePortfolioCharts();
    }

    if (this.state.marginMethod !== prevState.marginMethod) {
      this.calculatePortfolioCharts();
    }
  }

  updateStateRegulatoryReturnOnCapital = () => {
    if (this.props.resultsjob.data[0].creditRiskRWAThroughTime.length === 0) {
      const zero_array = [0];
      this.setState ({
        returnOnRegulatoryCapital: zero_array,
        returnOnEquity: zero_array,
      })
      return
    }
    const maxVectorLength = Math.max(...this.props.resultsjob.data.map((asset) => (
      asset.periodVector.length
    )));

    const returnOnRegulatoryCapitalPerAsset = this.props.resultsjob.data.map((asset) => (
      asset.creditRiskRWAThroughTime.map((cRWA, index) => (
        (cRWA + asset.operationalRiskB3RWAThroughTime[index]) > 0 ? (asset.netInterestIncome[index] * 12.0) / ((cRWA + asset.operationalRiskB3RWAThroughTime[index]) * 0.08) : 0
      ))
    ));

    const portfolioCRWA = new Array(maxVectorLength).fill(0);

    const returnOnEquity = portfolioCRWA.map((elem, index) => (
      this.props.resultsjob.data.map((asset, job_index) => (
        index > 0 ? (asset.redemptionProfile[index] > 0 ? ((asset.netInterestIncome[index] * 12.0 - asset.redemptionProfile[index - 1] * 0.0027) * 0.75) / (asset.redemptionProfile[index - 1] * 0.06) : 0) : 0
      )).reduce((a, b) => a + b, 0)
    ))

    const summedPortCRWA = portfolioCRWA.map((elem, index) => (
      this.props.resultsjob.data.map((asset) => (
        asset.redemptionProfile.length > index ? asset.redemptionProfile[index] : 0
      )).reduce((a, b) => a + b, 0)
    ));

    const summedInterestPayments = portfolioCRWA.map((elem, index) => (
      this.props.resultsjob.data.map((asset) => (
        asset.interestPayments.length > index ? asset.interestPayments[index] : 0
      )).reduce((a, b) => a + b, 0)
    ));

    const summedInterestIncome = portfolioCRWA.map((elem, index) => (
      this.props.resultsjob.data.map((asset) => (
        asset.netInterestIncome.length > index ? asset.netInterestIncome[index] : 0
      )).reduce((a, b) => a + b, 0)
    ));

    const summedCreditRiskRWAThroughTime = portfolioCRWA.map((elem, index) => (
      this.props.resultsjob.data.map((asset) => (
        asset.creditRiskRWAThroughTime.length > index ? asset.creditRiskRWAThroughTime[index] : 0
      )).reduce((a, b) => a + b, 0)
    ));

    const summedOperationalRiskB3RWAThroughTime = portfolioCRWA.map((elem, index) => (
      this.props.resultsjob.data.map((asset) => (
        asset.operationalRiskB3RWAThroughTime.length > index ? asset.operationalRiskB3RWAThroughTime[index] : 0
      )).reduce((a, b) => a + b, 0)
    ));

    const summedRedemptionProfile = portfolioCRWA.map((elem, index) => (
      this.props.resultsjob.data.map((asset) => (
        asset.redemptionProfile.length > index ? asset.redemptionProfile[index] : 0
      )).reduce((a, b) => a + b, 0)
    ));

    const summedPortfolioRC = summedCreditRiskRWAThroughTime.map((elem, index) => (
      ((elem + summedOperationalRiskB3RWAThroughTime[index]) * 0.08)
    ));

    const summedPortfolioEC = portfolioCRWA.map((elem, index) => (
      this.props.resultsjob.data.map((asset) => (
        asset.economicCapitalThroughTime.length > index ? asset.economicCapitalThroughTime[index] : 0
      )).reduce((a, b) => a + b, 0)
    ));

    const returnOnRegulatoryCapital = portfolioCRWA.map((asset, index) => (
      (summedCreditRiskRWAThroughTime[index] + summedOperationalRiskB3RWAThroughTime[index] > 0) ? (summedInterestIncome[index] * 12.0) / ((summedCreditRiskRWAThroughTime[index] + summedOperationalRiskB3RWAThroughTime[index]) * 0.08) : 0
    ))

    this.setState({
      returnOnRegulatoryCapital: returnOnRegulatoryCapital,
      returnOnEquity: returnOnEquity,
      portfolioCRWA: summedCreditRiskRWAThroughTime,
      portfolioORWA: summedOperationalRiskB3RWAThroughTime,
      portfolioOutstanding: summedRedemptionProfile,
      portfolioRC: summedPortfolioRC,
      portfolioEC: summedPortfolioEC,
      portfolioNII: summedInterestIncome,
      applicableJobID: this.props.resultsjob.data[0].jobID,
    });
  }

  dataSummaryColumns = () => {
    return [
      {
        Header: 'Metric',
        accessor: 'Metric',
      },
      {
        Header: 'Point-in-Time value',
        accessor: 'pit_value',
        Cell: (row) => (
          <div style={{ textAlign: 'right' }}>{ row.value }</div>
        ),
      },
      {
        Header: 'Lifetime value',
        accessor: 'lifetime_value',
        Cell: (row) => (
          <div style={{ textAlign: 'right' }}>{ row.value }</div>
        ),
      },
    ];
  }

  dataDict = () => {
    const lifetimeRoC = 10;

    const outstanding_pit = this.state.portfolioOutstanding.length > 0 ? this.state.portfolioOutstanding[0] : 0;

    const pd_wavg = this.props.resultsjob.data[0].wg_avg_pd
    const lgd_wavg = this.props.resultsjob.data[0].wg_avg_lgd
    const rate_wavg = this.props.resultsjob.data[0].wg_avg_rate

    const creditRiskRWA_pit = this.state.portfolioCRWA.length > 0 ? this.state.portfolioCRWA[0] : 0;
    const creditRiskRWA_weighted = Math.min(this.state.portfolioOutstanding.length, this.state.portfolioCRWA.length) > 0 ? this.state.portfolioCRWA.map((elem, index) => (
      elem * this.state.portfolioOutstanding[index])) : 0;
    const creditRiskRWA_wavg = creditRiskRWA_weighted.reduce((a, b) => a + b, 0) / this.state.portfolioOutstanding.reduce((a, b) => a + b, 0);

    const operationalRiskRWA_pit = this.state.portfolioORWA.length > 0 ? this.state.portfolioORWA[0] : 0;
    const operationalRiskRWA_weighted = Math.min(this.state.portfolioOutstanding.length, this.state.portfolioORWA.length) > 0 ? this.state.portfolioORWA.map((elem, index) => (
      elem * this.state.portfolioOutstanding[index])) : 0;
    const operationalRiskRWA_wavg = operationalRiskRWA_weighted.reduce((a, b) => a + b, 0) / this.state.portfolioOutstanding.reduce((a, b) => a + b, 0);

    const portfolioRC_pit = this.state.portfolioRC.length > 0 ? this.state.portfolioRC[0] : 0;
    const portfolioRC_weighted = Math.min(this.state.portfolioOutstanding.length, this.state.portfolioRC.length) > 0 ? this.state.portfolioRC.map((elem, index) => (
      elem * this.state.portfolioOutstanding[index]
    )) : 0;

    const portfolioRC_wavg = portfolioRC_weighted.reduce((a, b) => a + b, 0) / this.state.portfolioOutstanding.filter(a => a > 0).reduce((a, b) => a + b, 0);
    const return_RCap_pit = this.state.portfolioRC.length > 0 && this.state.portfolioNII.length > 1 ? (this.state.portfolioNII[1] * 12) / this.state.portfolioRC[0] : 0;

    const return_RCap_weighted = this.state.portfolioNII.map((elem, index) => (
      index > 0 && this.state.portfolioRC[index - 1] > 0 ? ((elem * 12.0) / this.state.portfolioRC[index - 1]) * this.state.portfolioRC[index - 1] : 0
    )).reduce((a, b) => a + b, 0);
    const return_RCap_wavg = return_RCap_weighted / this.state.portfolioRC.reduce((a, b) => a + b, 0);

    return [
      {
        Metric: 'Portfolio Outstanding',
        pit_value: outstanding_pit.toLocaleString('en-GB', {
          style: 'currency',
          currency: 'EUR',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        }),
        lifetime_value: 0.0,
      },
      {
        Metric: 'Weighted average PD',
        pit_value: pd_wavg.toLocaleString('en-GB', {
          style: 'percent',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
        lifetime_value: 0.0,
      },
      {
        Metric: 'Weighted average LGD',
        pit_value: lgd_wavg.toLocaleString('en-GB', {
          style: 'percent',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
        lifetime_value: 0.0,
      },
      {
        Metric: 'Weighted Average Rate',
        pit_value: (rate_wavg / 100.0).toLocaleString('en-GB', {
          style: 'percent',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
        lifetime_value: 0.0,
      },
      {
        Metric: 'Credit Risk RWA',
        pit_value: creditRiskRWA_pit.toLocaleString('en-GB', {
          style: 'currency',
          currency: 'EUR',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        }),
        lifetime_value: creditRiskRWA_wavg.toLocaleString('en-GB', {
          style: 'currency',
          currency: 'EUR',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        }),
      },
      {
        Metric: 'Operational Risk RWA',
        pit_value: operationalRiskRWA_pit.toLocaleString('en-GB', {
          style: 'currency',
          currency: 'EUR',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        }),
        lifetime_value: operationalRiskRWA_wavg.toLocaleString('en-GB', {
          style: 'currency',
          currency: 'EUR',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        }),
      },
      {
        Metric: 'Total Risk Weighted Assets',
        pit_value: (creditRiskRWA_pit + operationalRiskRWA_pit).toLocaleString('en-GB', {
          style: 'currency',
          currency: 'EUR',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        }),
        lifetime_value: (creditRiskRWA_wavg + operationalRiskRWA_wavg).toLocaleString('en-GB', {
          style: 'currency',
          currency: 'EUR',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        }),
      },
      {
        Metric: 'Regulatory Capital',
        pit_value: portfolioRC_pit.toLocaleString('en-GB', {
          style: 'currency',
          currency: 'EUR',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        }),
        lifetime_value: portfolioRC_wavg.toLocaleString('en-GB', {
          style: 'currency',
          currency: 'EUR',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        }),
      },
      {
        Metric: 'Economic Capital',
        pit_value: 100,
        lifetime_value: 200,
      },
      {
        Metric: 'Return on RCap',
        pit_value: return_RCap_pit.toLocaleString('en-GB', {
          style: 'percent',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
        lifetime_value: return_RCap_wavg.toLocaleString('en-GB', {
          style: 'percent',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
      },
      {
        Metric: 'Return on ECap',
        pit_value: 100,
        lifetime_value: 200,
      },
      {
        Metric: 'Return on Invested Capital (1.75x RC)',
        pit_value: (return_RCap_pit/ 1.75).toLocaleString('en-GB', {
          style: 'percent',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
        lifetime_value: (return_RCap_wavg / 1.75).toLocaleString('en-GB', {
          style: 'percent',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
      },
      {
        Metric: 'Spread Duration',
        pit_value: 100,
        lifetime_value: 200,
      },
    ]
  }

  calculatePortfolioCharts = () => {
    // Retrieve the reporting date from the dataset. TO BE REPLACED BY
    // ASSESMENT DATE
    // const DataSetDate = findAssociatedValue(
    //  1,
    //  this.props.jobs.data.map((k) => k.jobID),
    //  this.props.jobs.data.map((k) => k.reportingDate),
    // );
    const longestVector = Math.max(...this.props.resultsjob.data.map((o) => (o.periodVector.length)));
    const longestAsset = this.props.resultsjob.data.filter((elem) => (
      longestVector === elem.periodVector.length
    ));

    const periodVector = longestAsset[0].periodVector;
    const timestamps = periodVector.map((element) => {
      const d = new Date(0); d.setUTCSeconds(element);
      return d;
    });
    const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
    options.timeZone = 'UTC';

    const redemptionMatrix = periodVector.map((timestamp, timeIndex) => (
      this.props.resultsjob.data.filter((asset) => (
        timeIndex < asset.redemptionProfile.length
      )).map((asset) => asset.redemptionProfile[timeIndex])
    ));

    const redemptionProfile = redemptionMatrix.map((elem) => (
      elem.reduce((a, n) => {
        if (n !== undefined) {
          return parseFloat(a) + parseFloat(n);
        }
        return parseFloat(a);
      }, 0)
    ));

    const netInterestIncomeMatrix = periodVector.map((timestamp, timeIndex) => (
      this.props.resultsjob.data.filter((asset) => (
        timeIndex < asset.netInterestIncome.length
      )).map((asset) => asset.netInterestIncome[timeIndex])
    ));

    const netInterestIncome = netInterestIncomeMatrix.map((elem) => (
      elem.reduce((a, n) => {
        if (n !== undefined) {
          return parseFloat(a) + parseFloat(n);
        }
        return parseFloat(a);
      }, 0)
    ));

    const interestPaymentsMatrix = periodVector.map((timestamp, timeIndex) => (
      this.props.resultsjob.data.filter((asset) => (
        timeIndex < asset.interestPayments[0].length
      )).map((asset) => asset.interestPayments[0][timeIndex])
    ));

    const interestSpreadPaymentsMatrix = periodVector.map((timestamp, timeIndex) => (
      this.props.resultsjob.data.filter((asset) => (
        timeIndex < asset.interestPayments[1].length
      )).map((asset) => asset.interestPayments[1][timeIndex])
    ));

    const interestPayments = interestPaymentsMatrix.map((elem) => (
      elem.reduce((a, n) => {
        if (n !== undefined) {
          return parseFloat(a) + parseFloat(n);
        }
        return parseFloat(a);
      }, 0)
    ));

    const interestSpreadPayments = interestSpreadPaymentsMatrix.map((elem) => (
      elem.reduce((a, n) => {
        if (n !== undefined) {
          return parseFloat(a) + parseFloat(n);
        }
        return parseFloat(a);
      }, 0)
    ));

    // This guy reads out of bounds
    const portfolioNIM = netInterestIncome.map((elem, index) => (
      (index > 0 && redemptionProfile[index - 1] > 0) ? (10000 * 12 * elem) / (redemptionProfile[index - 1]) : 0
    ));

    const portfolioIM = interestPayments.map((elem, index) => {
      if (this.state.marginMethod === 'absolute') {
        return (index > 0 && redemptionProfile[index - 1] > 0) ? (10000 * 12 * elem) / (redemptionProfile[index - 1]) : 0
      }
      return (index > 0 && redemptionProfile[index - 1] > 0) ? (10000 * (12 * elem  - interestSpreadPayments[index])) / (redemptionProfile[index - 1]) : 0
    });

    const formattedDates = timestamps.map((timestamp) => timestamp.toLocaleString('en-GB', options));
    this.setState({
      portfolioNIM,
      portfolioIM,
      formattedDates,
      interestPayments,
      netInterestIncome,
      redemptionProfile,
      timestamps,
      periodVector,
      applicableJobID: this.props.resultsjob.data[0].jobID,
    })
  }

  // this.props.fetchPortfolioValue(sessionStorage.getItem('jwtToken'))
  // <Panel>
  //  <Panel.Heading>Projected Portfolio Value</Panel.Heading>
  //  <Panel.Body>
  //    <Chart
  //      type={"Bar"}
  //      unit={"decimal"} dataserieslabels={["Projected Portfolio Value"]}
  //      xaxisstart={0}
  //      xaxislabels={generateDateSeries(
  //        DataSetDate,
  //        'months',
  //        (this.props.portfoliovalue.data.map((k) => k.portfolioValue)).length,
  //      )}
  //      dataseries={[this.props.portfoliovalue.data.map((k) => k.portfolioValue)]}
  //     />
  //  </Panel.Body>
  // </Panel>
  render() {
    const showLoadingScreen = this.props.jobs.isLoading;
    const showError = Object.keys(this.props.error).length > 0
      && (Object.prototype.hasOwnProperty.call(this.props.error, 'FETCH_JOBS')
        || Object.prototype.hasOwnProperty.call(this.props.error, 'FETCH_RESULTS_JOB')
        || Object.prototype.hasOwnProperty.call(this.props.error, 'FETCH_PORTFOLIO_VALUE')
      );
    const showJobSelectionMenu = this.props.jobs.isLoading !== true
      && this.props.jobs.data !== undefined
      && this.props.jobs.data.length > 0;
    const showJobCompareSelectionMenu = !this.props.jobs.isLoading
      && this.props.jobs.data !== undefined
      && this.props.jobs.data.length > 0
      && this.state.selectedJobID !== null;
    const showJobLoadingScreen = this.state.selectedJobID !== null
      && this.props.resultsjob.isLoading === true;
    const showDescriptivesTable = this.state.tableData.length > 0;
    const showPortfolioCharts = this.props.resultsjob.isLoading !== true
      && this.props.resultsjob.data !== undefined
      && this.props.resultsjob.data.length > 0
      && this.state.aggregationIdentifier === 'portfolio'
      && this.state.portfolioIM.length > 0
      && this.state.portfolioNIM.length > 0;
    const showPortfolioChartsOnCalculatedData = this.state.returnOnRegulatoryCapital.length > 0
      && this.state.returnOnEquity.length > 0
      && this.state.aggregationIdentifier === 'portfolio';
    const showAssetCharts = this.props.resultsjob.isLoading !== true
      && this.props.resultsjob.data !== undefined
      && this.props.resultsjob.data.length > 0
      && this.state.aggregationIdentifier === 'asset';
    const renderAggregationSelector = this.state.selectedJobID !== null
      && this.state.selectedJobID !== undefined;

    if (showError) {
      return (
        <div>
          <Header
            title={'Projections'}
            subtitle={
              {
                startText: 'This section provides projections of key financial metrics, such as cashflows and interest payments, at portfolio level and individual asset level.',
                list: undefined,
                closingText: undefined,
              }
            }
          />
          {Object.keys(this.props.error).map((elem) => (
            <Card className="mt-4">
              <Card.Header>Error</Card.Header>
              <Card.Body>{elem}: {this.props.error[elem].message}</Card.Body>
            </Card>
          ))}
        </div>
      );
    }

    let longestVector;
    let longestAsset;
    let periodVector;
    let timestamps;
    let redemptionProfile;
    let interestPayments;
    let netInterestIncome;
    let portfolioNIM;
    let portfolioIM;
    let formattedDates;

    const timeseries = (field, periodVectorArg, synthetic) => {
      let assets;
      if (synthetic !== undefined) {
        assets = this.props.resultsjob.data.filter((asset) => (
          asset.syntheticTransaction === synthetic
        ));
      } else {
        assets = this.props.resultsjob.data;
      }
      const matrix = periodVectorArg.map((timestamp, timeIndex) => (
        assets.filter((asset) => (timeIndex < asset[field].length))
          .map((elem) => elem[field][timeIndex])
      ));

      return matrix.map((elem) => (
        elem.reduce((a, n) => {
          if (n !== undefined) {
            return parseFloat(a) + parseFloat(n);
          }
          return parseFloat(a);
        }, 0)
      ));
    };

    // Render the required outputs.
    return (
      <div>
        <Header
          title={'Projections'}
          subtitle={
            {
              startText: 'This section provides projections of key financial metrics, such as cashflows and interest payments, at portfolio level and individual asset level.',
              list: undefined,
              closingText: undefined,
            }
          }
        />

        { this.state.renderHelp && (
          <div className="overlay-help" onClick={() => this.renderHelp(undefined)}>
            <div className="overlay-help-card">
              <Card className="mt-4">
                <Card.Header>
                  <div className="fa-pull-left">{this.state.helpText.header}</div>
                  <i className="fa fa-window-close fa-pull-right" onClick={() => this.renderHelp(undefined)}></i>
                </Card.Header>
                <Card.Body>{this.state.helpText.body}</Card.Body>
              </Card>
            </div>
          </div>
        )}

        { showLoadingScreen && (
            <div>
              <Notification heading={'Projected Portfolio Value'} body={'Loading jobs'}/>
            </div>
        )}

        { showJobSelectionMenu && (
            <div>
              <Card className="mt-4">
                <Card.Header>
                  <div className="fa-pull-left">Select a Job</div>
                  <div><span className="question-mark fa-pull-right" onClick={() => this.renderHelp({ header: 'Select a Job', body: 'Text' })}></span></div>
                </Card.Header>
                <Card.Body>
                  <Form.Group as={Row} controlId="createJob.scenarioID">
                  <Form.Label column sm={3}>Job Name</Form.Label>
                    <Col sm={9}>
                      <Form.Control as="select" ref="jobID" onChange={this.saveJobID} value={this.state.selectedJobID} defaultValue=" ">
                        { this.props.jobs.data.filter((elem) => (
                          elem.jobCompletion >= 2))
                          .map((k) => (
                            <option key={k.jobID} value={k.jobID}>{k.jobName}</option>
                          ))}
                      </Form.Control>
                    </Col>
                  </Form.Group>
                </Card.Body>
              </Card>
            </div>
        )}

        { showJobCompareSelectionMenu && (
            <div>
              <Card className="mt-4">
                <Card.Header>
                  <div className="fa-pull-left">Select a Comparison Job</div>
                  <div><span className="question-mark fa-pull-right" onClick={() => this.renderHelp({ header: 'Select a Job', body: 'Text' })}></span></div>
                </Card.Header>
                <Card.Body>
                  <Form.Group as={Row} controlId="createJob.scenarioID">
                  <Form.Label column sm={3}>Job Name</Form.Label>
                    <Col sm={9}>
                      <Form.Control as="select" ref="compareJobID" onChange={this.saveCompareJobID} value={this.state.selectedCompareJobID} defaultValue="None">
                        { this.props.jobs.data.filter((elem) => (
                          elem.jobCompletion >= 2))
                          .map((k) => (
                            <option key={k.jobID} value={k.jobID}>{k.jobName}</option>
                          ))}
                      </Form.Control>
                    </Col>
                  </Form.Group>
                </Card.Body>
              </Card>
            </div>
        )}

        { renderAggregationSelector && (
          <Card className="mt-4">
            <Card.Header>Aggregation Level</Card.Header>
            <Card.Body>
              <Nav variant="pills" defaultActiveKey="portfolio" fill="true">
                <Nav.Item className="mx-2">
                  <Nav.Link className="main-selector" eventKey="portfolio" onClick={() => this.updateAggregationIdentifier('portfolio')}>Portfolio Level</Nav.Link>
                </Nav.Item>
                <Nav.Item className="mx-2">
                  <Nav.Link className="main-selector" eventKey="asset" onClick={() => this.updateAggregationIdentifier('asset')}>Asset Level</Nav.Link>
                </Nav.Item>
              </Nav>
            </Card.Body>
          </Card>
        )}

        { showJobLoadingScreen && (
          <Card className="mt-4">
            <Card.Header>Status</Card.Header>
            <Card.Body>
              Job results loading
            </Card.Body>
          </Card>
        )}

        { showDescriptivesTable && (
          <div>
            <Card className="mt-4">
              <Card.Header>
                <div className="fa-pull-left">Portfolio Characteristics</div>
                <div><span className="question-mark fa-pull-right" onClick={() => this.renderHelp({ header: 'Portfolio Characteristics', body: 'Overview of key risk/return metrics' })}></span></div>
              </Card.Header>
              <Card.Body>
                <ReactTable
                  data={this.state.tableData}
                  columns={this.dataSummaryColumns()}
                  defaultPageSize={13}
                  keyField={'id'}
                  className="-striped -highlight"
                  showPagination={false}
                />
              </Card.Body>
            </Card>
          </div>
        )}

        { showPortfolioCharts && (
          <div>
            <Card className="mt-4">
              <Card.Header className="card-header-with-btn">
                <div className="pull-left">Projected Swapped Rates</div>
                <div className="fa-pull-right">
                  <Button className="fa-icon" variant="secondary" size="sm" onClick={() => this.saveToExcel(this.state.formattedDates, ['Portfolio_IM'], this.state.portfolioIM)}>
                    <span className="fa fa-save"></span>
                    <span> Export</span>
                  </Button>
                  <span
                    className="ml-3 question-mark"
                    onClick={() => this.renderHelp({
                      header: 'Projected Interest Margin',
                      body: 'Cumulative interest margins (Interest Payments divided by Redemption Profile) of the transactions in the portfolio, taking into account redemptions of the transactions in the portfolio as well as new originations (if assumed in your scenario). You can view and select the original portfolio, the synthetic new originations portfolio and the cumulative portfolio.',
                    })}
                  ></span>
                </div>
              </Card.Header>
              <Card.Body>
                <Chart
                  type={'Line'}
                  unit={'decimal'}
                  xaxisstart={0}
                  xaxislabels={this.state.formattedDates}
                  dataseries={this.state.comparePortfolioIM.length > 0 ? [this.state.portfolioIM, this.state.comparePortfolioIM] : [this.state.portfolioIM]}
                  dataserieslabels={this.state.comparePortfolioIM.length > 0 ? ['Projected Swapped Rate Comparison Portfolio (bps)', 'Projected Swapped Rate Base Portfolio (bps)'] : ['Projected Interest Margin (bps)']}
                />
              </Card.Body>
            </Card>
          </div>
        )}

        { showPortfolioCharts && (
            <div>
              <Card className="mt-4">
                <Card.Header className="card-header-with-btn">
                  <div className="pull-left">Projected Net Interest Margin</div>
                  <div className="fa-pull-right">
                    <Button className="fa-icon" variant="secondary" size="sm" onClick={() => this.saveToExcel(this.state.formattedDates, ['Portfolio_NIM'], this.state.portfolioNIM)}>
                      <span className="fa fa-save"></span>
                      <span> Export</span>
                    </Button>
                    <span
                      className="ml-3 question-mark"
                      onClick={() => this.renderHelp({
                        header: 'Projected Interest Margin',
                        body: 'Cumulative interest margins (Interest Payments divided by Redemption Profile) of the transactions in the portfolio, taking into account redemptions of the transactions in the portfolio as well as new originations (if assumed in your scenario). You can view and select the original portfolio, the synthetic new originations portfolio and the cumulative portfolio.',
                      })}
                    ></span>
                  </div>
                </Card.Header>
                <Card.Body>
                  <Chart
                    type={'Line'}
                    unit={'decimal'}
                    xaxisstart={0}
                    xaxislabels={this.state.formattedDates}
                    dataseries={this.state.comparePortfolioNIM.length > 0 ? [this.state.portfolioNIM, this.state.comparePortfolioNIM] : [this.state.portfolioNIM]}
                    dataserieslabels={this.state.comparePortfolioNIM.length > 0 ? ['Projected Net Interest Margin Comparison Portfolio (bps)', 'Projected Net Interest Margin Base Portfolio (bps)'] : ['Projected Net Interest Margin (bps)']}
                  />
                </Card.Body>
              </Card>
              <Card className="mt-4">
                <Card.Header className="card-header-with-btn">
                  <div className="pull-left">Projected Redemption Profile</div>
                  <div className="fa-pull-right">
                    <Button className="fa-icon" variant="secondary" size="sm" onClick={() => this.saveToExcel(this.state.formattedDates, ['Existing Transansactions', 'New Originations', 'Total'], [timeseries('redemptionProfile', this.state.periodVector, false), timeseries('redemptionProfile', this.state.periodVector, true), timeseries('redemptionProfile', this.state.periodVector, undefined)])}>
                      <span className="fa fa-save"></span>
                      <span> Export</span>
                    </Button>
                    <span
                      className="ml-3 question-mark"
                      onClick={() => this.renderHelp({
                        header: 'Projected Redemption Profile',
                        body: 'Total outstanding of the portfolio. You can view and select the original portfolio, the synthetic new originations portfolio and the cumulative portfolio by clicking the legend.',
                      })}
                    ></span>
                  </div>
                </Card.Header>
                <Card.Body>
                  <Chart
                    type={'Bar'}
                    unit={'EUR'}
                    xaxislabels={this.state.formattedDates}
                    dataseries={[timeseries('redemptionProfile', this.state.periodVector, false), timeseries('redemptionProfile', this.state.periodVector, true), timeseries('redemptionProfile', this.state.periodVector, undefined)]}
                    dataserieslabels={['Existing Transansactions', 'New Originations', 'Total']}
                  />
                </Card.Body>
              </Card>
              <Card className="mt-4">
                <Card.Header className="card-header-with-btn">
                  <div className="pull-left">Projected Interest Income</div>
                  <div className="fa-pull-right">
                    <Button className="fa-icon" variant="secondary" size="sm" onClick={() => this.saveToExcel(this.state.formattedDates, ['Existing Transansactions', 'New Originations', 'Total'], [timeseries('interestPayments', this.state.periodVector, false), timeseries('interestPayments', this.state.periodVector, true), timeseries('interestPayments', this.state.periodVector, undefined)])}>
                      <span className="fa fa-save"></span>
                      <span> Export</span>
                    </Button>
                    <span
                      className="ml-3 question-mark"
                      onClick={() => this.renderHelp({
                        header: 'Projected Interest Income',
                        body: 'Total net interest income (interest income minus funding costs). You can view and select the original portfolio, the synthetic new originations portfolio and the cumulative portfolio.',
                      })}
                    ></span>
                  </div>
                </Card.Header>
                <Card.Body>
                  <Chart
                    type={'Bar'}
                    unit={'EUR'}
                    xaxislabels={this.state.formattedDates}
                    dataseries={(this.props.resultsjob.data !== undefined && this.props.resultsjob.data.length > 0)? [this.props.resultsjob.data[0].interestPayments[0].map((elem, index) => elem - this.props.resultsjob.data[0].interestPayments[1][index] / 12)] : []}
                    dataserieslabels={['Total']}
                  />
                </Card.Body>
              </Card>
              <Card className="mt-4">
                <Card.Header className="card-header-with-btn">
                  <div className="pull-left">Projected Cashflow Profile</div>
                  <div className="fa-pull-right">
                    <Button className="fa-icon" variant="secondary" size="sm" onClick={() => this.saveToExcel(this.state.formattedDates, ['Existing Transansactions', 'New Originations', 'Total'], [timeseries('cashFlows', this.state.periodVector, false), timeseries('cashFlows', this.state.periodVector, true), timeseries('cashFlows', this.state.periodVector, undefined)])}>
                      <span className="fa fa-save"></span>
                      <span> Export</span>
                    </Button>
                    <span
                      className="ml-3 question-mark"
                      onClick={() => this.renderHelp({
                        header: 'Projected Cashflow Profile',
                        body: 'Cumulative cashflows of the portfolio, taking into account all cash inflows (net interest payments, repayments, redemptions) and cash outflows (new originations). You can view and select the original portfolio, the synthetic new originations portfolio and the cumulative portfolio.',
                      })}
                    ></span>
                  </div>
                </Card.Header>
                <Card.Body>
                  <Chart
                    type={'Bar'}
                    unit={'EUR'}
                    xaxislabels={this.state.formattedDates}
                    dataseries={[timeseries('cashFlows', this.state.periodVector, false), timeseries('cashFlows', this.state.periodVector, true), timeseries('cashFlows', this.state.periodVector, undefined)]}
                    dataserieslabels={['Existing Transansactions', 'New Originations', 'Total']}
                  />
                </Card.Body>
              </Card>
            </div>
        )}

        { showPortfolioChartsOnCalculatedData && (
          <div>
            <Card className="mt-4">
              <Card.Header className="card-header-with-btn">
                <div className="pull-left">Projected Return on Regulatory Capital</div>
                <div className="fa-pull-right">
                  <Button className="fa-icon" variant="secondary" size="sm" onClick={() => this.saveToExcel(this.state.formattedDates, ['Portfolio_RoC'], this.state.returnOnRegulatoryCapital)}>
                    <span className="fa fa-save"></span>
                    <span> Export</span>
                  </Button>
                  <span
                    className="ml-3 question-mark"
                    onClick={() => this.renderHelp({
                      header: 'Projected Return on Regulatory Capital',
                      body: 'Chart shows the projected, risk-free, Return on Regulatory Capital based on an applied Basel IV floor.',
                    })}
                  ></span>
                </div>
              </Card.Header>
              <Card.Body>
                <Chart
                  type={'Line'}
                  unit={'decimal'}
                  xaxisstart={0}
                  xaxislabels={this.state.formattedDates}
                  dataseries={this.state.compareReturnOnRegulatoryCapital.length > 0 ? [this.state.returnOnRegulatoryCapital, this.state.compareReturnOnRegulatoryCapital] : [this.state.returnOnRegulatoryCapital]}
                  dataserieslabels={this.state.compareReturnOnRegulatoryCapital.length > 0 ? ['Return on Regulatory Capital Comparison Portfolio', 'Return on Regulatory Capital Base Portfolio'] : ['Risk-free return on regulatory capital']}
                />
              </Card.Body>
            </Card>

            <Card className="mt-4">
              <Card.Header className="card-header-with-btn">
                <div className="pull-left">Projected Return on Return on Equity</div>
                <div className="fa-pull-right">
                  <Button className="fa-icon" variant="secondary" size="sm" onClick={() => this.saveToExcel(this.state.formattedDates, ['Portfolio_RoE'], this.state.returnOnEquity)}>
                    <span className="fa fa-save"></span>
                    <span> Export</span>
                  </Button>
                  <span
                    className="ml-3 question-mark"
                    onClick={() => this.renderHelp({
                      header: 'Projected Return on Regulatory Capital',
                      body: 'Chart shows the projected, risk-free, Return on Regulatory Capital based on an applied Basel IV floor.',
                    })}
                  ></span>
                </div>
              </Card.Header>
              <Card.Body>
                <Chart
                  type={'Line'}
                  unit={'decimal'}
                  xaxisstart={0}
                  xaxislabels={this.state.formattedDates}
                  dataseries={this.state.compareReturnOnEquity.length > 0 ? [this.state.returnOnEquity, this.state.compareReturnOnEquity] : [this.state.returnOnEquity]}
                  dataserieslabels={this.state.compareReturnOnEquity.length > 0 ? ['Compare Risk-free Return on Equity', 'Base Case Risk-free Return on Equity'] : ['Risk-free Return on Equity']}
                />
              </Card.Body>
            </Card>
          </div>
        )}

        { showAssetCharts && (
            <ResultsAsset
              showPageHeader={false}
              showJobMenuSelection={false}
              selectedJobID={this.state.selectedJobID}
              selectionID={this.props.jobs.data.filter((elem) => (
                elem.jobID === this.state.selectedJobID
              ))[0].selectionID }
            />
        )}
      </div>
    );
  }
}

const loadingSelector = createLoadingSelector(['FETCH_PORTFOLIO_VALUE']);
const errorSelector = createErrorMessageSelector(['FETCH_PORTFOLIO_VALUE']);

const mapStateToProps = (state) => (
  {
    authentication: state.authentication,
    jobs: state.jobs,
    resultsjob: state.resultsjob,
    portfoliovalue: state.portfoliovalue,
    isFetching: loadingSelector(state),
    isError: errorSelector(state),
    loading: state.loading,
    error: state.error,
  }
);

const mapDispatchToProps = (dispatch) => (
  {
    fetchJobs: (token) => {
      dispatch(jobs.fetchJobs(token));
    },
    fetchResultsJob: (id, token) => {
      dispatch(resultsdatasets.fetchResultsJob(id, token));
    },
    fetchPortfolioValue: (token) => {
      dispatch(portfoliovalue.fetchPortfolioValue(token));
    },
  }
);

export default connect(mapStateToProps, mapDispatchToProps)(ResultsPortfolio);

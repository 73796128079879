function handleErrors(response) {
  if (!response.ok) {
    throw Error(response.statusText)
  }
  return response;
}

export const addJob = (text, token) => {
  return dispatch => {
    dispatch({ type: 'ADD_JOB_REQUEST' });
    const headers = {"Content-Type": "application/json", "Authorization": "JWT " + token};
    let body = JSON.stringify(text);
    return fetch(process.env.REACT_APP_HTTP_PROTOCOL + "://" + process.env.REACT_APP_API_IP + "/api/simulation/jobs/", {headers, method: "POST", body})
    .then(handleErrors)
    .then(job => {
      if(text['createJob'] === 0) {
        text['startedOn'] = undefined
      } else {
        text['startedOn'] = new Date().toISOString();
      }
      return dispatch({
        type: 'ADD_JOB_SUCCESS',
        text
      })
    })
    .catch(err => {
      return dispatch({ type: 'ADD_JOB_FAILURE', error: true, error_message: err.message})
    })
  }
}

export const deleteJob = (id, token) => {
  return (dispatch, getState) => {
    const headers = {"Content-Type": "application/json", "Authorization": "JWT " + token};
    let body = JSON.stringify(id);
    dispatch({ type: 'DELETE_JOB_IN_PROGRESS', id: id})
    return fetch(process.env.REACT_APP_HTTP_PROTOCOL + "://" + process.env.REACT_APP_API_IP + "/api/simulation/jobs/" + id + "/", {headers, method: "DELETE", body})
      .then(handleErrors)
      .then(res => {
        if (res.ok) {
          return dispatch({
            type: 'DELETE_JOB_SUCCESS',
            id
          })
        }
      })
      .catch(err => {
        return dispatch({ type: 'DELETE_JOB_FAILURE', error_type: err, error: true })
      })
  }
}

export const fetchJobs = (token) => {
  return dispatch => {
    dispatch({ type: 'FETCH_JOBS_REQUEST' });
    const headers = {"Content-Type": "application/json", "Authorization": "JWT " + token};
    return fetch(process.env.REACT_APP_HTTP_PROTOCOL + "://" + process.env.REACT_APP_API_IP + "/api/simulation/jobs/", {headers, })
      .then(res => res.json())
      .then(jobs => {
        return dispatch({
          type: 'FETCH_JOBS_SUCCESS',
          jobs
        })
      })
      .catch(error => {
        // Over here we can customize the errors via error.message = "Whatever we want"
        return dispatch({ type: 'FETCH_JOBS_FAILURE', error_type: error, error: true })
      })
  }
}

export const fetchComparableJobs = (portfolio_id, scenario_id, token) => {
  return dispatch => {
    dispatch({ type: 'FETCH_COMPARABLE_JOBS_REQUEST' });
    const headers = {"Content-Type": "application/json", "Authorization": "JWT " + token};
    return fetch(process.env.REACT_APP_HTTP_PROTOCOL + "://" + process.env.REACT_APP_API_IP + "/api/simulation/refrateimpact/portfolio/" + portfolio_id + "/scenario/" + scenario_id + "/", {headers, })
      .then(res => res.json())
      .then(comparable_jobs => {
        return dispatch({
          type: 'FETCH_COMPARABLE_JOBS_SUCCESS',
          comparable_jobs
        })
      })
      .catch(error => {
        return dispatch({ type: 'FETCH_COMPARABLE_JOBS_FAILURE', error_type: error, error: true })
      })
  }
}

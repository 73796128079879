function handleErrors(response) {
    if (!response.ok) {
        throw Error(response.statusText);
    }
    return response;
}

export const fetchResultsDatasets = (id, token) => {
  return dispatch => {
    dispatch({ type: 'FETCH_RESULTS_DATASETS_REQUEST' });
    const headers = {"Content-Type": "application/json", "Authorization": "JWT " + token};
    return fetch(process.env.REACT_APP_HTTP_PROTOCOL + "://" + process.env.REACT_APP_API_IP + "/api/results/datasets/" + id + "/", {headers, })
      .then(handleErrors)
      .then(res => res.json())
      .then(resultsdatasets => {
        return dispatch({
          type: 'FETCH_RESULTS_DATASETS_SUCCESS',
          resultsdatasets
        })
      })
      .catch(error => {
        return dispatch({ type: 'FETCH_RESULTS_DATASETS_FAILURE', error_type: error, error: true })
      })
  }
}

export const fetchResultsJob = (id, token) => {
  return dispatch => {
    dispatch({ type: 'FETCH_RESULTS_JOB_REQUEST' });
    const headers = {"Content-Type": "application/json", "Authorization": "JWT " + token};
    return fetch(process.env.REACT_APP_HTTP_PROTOCOL + "://" + process.env.REACT_APP_API_IP + "/api/results/aggregate_jobs/" + id + "/", {headers, })
      .then(handleErrors)
      .then(res => res.json())
      .then(resultsjob => {
        return dispatch({
          type: 'FETCH_RESULTS_JOB_SUCCESS',
          resultsjob
        })
      })
      .catch(error => {
        return dispatch({ type: 'FETCH_RESULTS_JOB_FAILURE', error_type: error, error: true })
      })
  }
}

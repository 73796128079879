const format = {
  primarycolors : [
    'rgba(59, 110, 143, 1)',
    'rgba(251, 193, 119, 1)',
    'rgba(115, 198, 161, 1)',
    'rgba(103, 153, 200, 1)'
  ],

  secondarycolors: [
    'rgba(191, 191, 191, 1)',
    'rgba(186, 163, 171, 1)',
    'rgba(127, 127, 127, 1)',
    'rgba(201, 48, 146, 1)',
    'rgba(27, 66, 152, 1)',
    'rgba(84, 7, 91, 1)',
    'rgba(248, 152, 29, 1)'
  ],

  backgroundcolors: [
    'rgba(242, 242, 242, 1)',
    'rgba(225, 235, 244, 1)',
    'rgba(227, 244, 236, 1)',
    'rgba(241, 237, 238, 1)',
    'rgba(254, 234, 210, 1)',
    'rgba(211, 227, 237, 1)'
  ],

  bordercolors : [
    'rgba(69, 176, 189, 0.6)',
    'rgba(53, 87, 135, 0.6)',
    'rgba(115, 198, 161, 1)',
    'rgba(103, 153, 200, 1)',
    'rgba(191, 191, 191, 1)',
    'rgba(186, 163, 171, 1)',
    'rgba(127, 127, 127, 1)',
    'rgba(201, 48, 146, 1)',
    'rgba(27, 66, 152, 1)',
    'rgba(84, 7, 91, 1)',
    'rgba(248, 152, 29, 1)'
  ],

  fillcolors : [
    'rgba(69, 176, 189, 0.6)',
    'rgba(53, 87, 135, 0.6)',
    'rgba(115, 198, 161, 0.2)',
    'rgba(103, 153, 200, 0.2)',
    'rgba(191, 191, 191, 0.2)',
    'rgba(186, 163, 171, 0.2)',
    'rgba(127, 127, 127, 0.2)',
    'rgba(201, 48, 146, 0.2)',
    'rgba(27, 66, 152, 0.2)',
    'rgba(84, 7, 91, 0.2)',
    'rgba(248, 152, 29, 0.2)'
  ],
}

export default format;

const initialState = {data: {}, isLoading: true};

 export default function resultstransactions(state = initialState, action) {
  switch(action.type) {
    case 'FETCH_RESULTS_TRANSACTIONS_SUCCESS':
      return {data: action.resultstransactions, isLoading: false};
    case 'FETCH_RESULTS_TRANSACTIONS_FAILURE':
      return {data: action.resultstransactions, error: true, isLoading: false};
    default:
      return state;
  }
}

import React from "react";

import { Title, Subtitle } from "./Header/Title";

export default class Header extends React.Component {
  render() {
    return (
      <div>
        <Title title={this.props.title}/>
        { this.props.subtitle !== undefined && (
          <div><Subtitle subtitle={this.props.subtitle}/></div>
        )}
      </div>
    )
  }
}

import React from "react";

import DropTransitionMatrix from "./DropTransitionMatrix"

// Import layout and helper functions/templates
import Header from "../components/Header";

class Heatmap extends React.Component{
  constructor() {
    super();
  }
  render() {
    return(
    <div>
      <Header title={"Upload new Transition Matrix"} />
      <DropTransitionMatrix>
        {({getRootProps}) => <div {...getRootProps()} />}
      </DropTransitionMatrix>
    </div>
    );
  }
}
export default Heatmap;

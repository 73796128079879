function handleErrors(response) {
    if (!response.ok) {
        throw Error(response.statusText);
    }
    return response;
}

export const fetchStratificationFeatures = (id, features, token) => {

  // Met search termen werken in api call ?=

  return dispatch => {
    dispatch({ type: 'FETCH_STRATIFICATION_FEATURES_REQUEST' });
    const headers = {"Content-Type": "application/json", "Authorization": "JWT " + token};
    return fetch(process.env.REACT_APP_HTTP_PROTOCOL + "://" + process.env.REACT_APP_API_IP + "/api/portfolio/stratificationfeatures/" + id + "/?features=" + features + "", {headers, })
      .then(handleErrors)
      .then(res => res.json())
      .then(stratificationfeatures => {
        return dispatch({
          type: 'FETCH_STRATIFICATION_FEATURES_SUCCESS',
          stratificationfeatures
        })
      })
      .catch(error => {
        return dispatch({ type: 'FETCH_STRATIFICATION_FEATURES_FAILURE', error_type: error, error: true })
      })
  }
}

import FileSaver from 'file-saver';
import blob from 'blob';

function handleErrors(response) {
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return response;
}

export const fetchPerformanceFeatures = (id, feature, token) => {
  return dispatch => {
  dispatch({ type: 'FETCH_PERFORMANCE_FEATURES_REQUEST' });
    const headers = {"Content-Type": "application/json", "Authorization": "JWT " + token};
    let body = JSON.stringify(feature);
    return fetch(process.env.REACT_APP_HTTP_PROTOCOL + "://" + process.env.REACT_APP_API_IP + "/api/results/performancefeatures/" + feature + "/" + id + "/", {headers, })
      .then(handleErrors)
      .then(res => res.json())
      .then(performancefeatures => {
        return dispatch({
          type: 'FETCH_PERFORMANCE_FEATURES_SUCCESS',
          performancefeatures
        })
      })
      .catch(error => {
        return dispatch({ type: 'FETCH_PERFORMANCE_FEATURES_FAILURE', error_type: error, error: true })
      })
  }
}

export const createXLSXPerformanceFeatures = (text, token) => {
  return dispatch => {
    const headers = {"Content-Type": "application/json", "Authorization": "JWT " + token};
    let body = JSON.stringify(text);
    return fetch(process.env.REACT_APP_HTTP_PROTOCOL + "://" + process.env.REACT_APP_API_IP + "/api/results/performancefeatures/?Start_Date", {headers, method: "POST", body})
      .then(handleErrors)
      .then(res => res.blob())
      .then(performancefeatures => {
        FileSaver.saveAs(performancefeatures, 'nameFile.xlsx');
      })
  }
}

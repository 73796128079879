// Import React & Redux components
import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { Button } from 'react-bootstrap';

// Import layout and helper functions/templates
import { populateOptions } from './Helpers'

class FormSelectTwoDropDownPlusNameMessage extends React.Component {

  render() {

    return (
      <div>
        <form className="form-inline" onSubmit={this.props.handleSubmit}>
          <div className="form-group">
            <label htmlFor="text" style={{marginRight: '10px'}}>{this.props.inputName}</label>
            <Field name="inputName" className="form-control" component="input" type="text" style={{width: '200px'}}/>
          </div>
          <div className="form-group">
            <label style={{marginRight: '10px'}}>{this.props.itemLabel1}</label>
            <Field name="selectedItemIndex1" className="form-control" component="select">
              <option value="">Choose a {this.props.itemLabel1}</option>
              {populateOptions(this.props.itemName1)}
            </Field>
          </div>
          <div className="form-group">
            <label style={{marginRight: '10px'}}>{this.props.itemLabel2}</label>
            <Field name="selectedItemIndex2" className="form-control" component="select">
              <option value="">Choose a {this.props.itemLabel2}</option>
              {populateOptions(this.props.itemName2)}
            </Field>
          </div>
          <Button bsStyle="info" fill="true" type="submit" disabled={this.props.submitting}>
            {this.props.buttonLabel}
          </Button>
          <div className="form-group">
            <label htmlFor="text" style={{marginRight: '10px' }}>{this.props.message}</label>
          </div>
        </form>
      </div>
    )
  }
};

export default reduxForm({
  form: 'selectTwoDropDownPlusNameMessage', // a unique identifier for this form
  // Set default values for the portfolio and timestep selected equal to
  // the first value shown in the drop down menu
})(FormSelectTwoDropDownPlusNameMessage);

export default function error(state = {}, action) {
  const { type } = action;
  const matches = /(.*)_(REQUEST|FAILURE)/.exec(type);

  // not a *_REQUEST / *_FAILURE actions, so we ignore them
  if (!matches) return state;
  const [, requestName, requestState] = matches;
  // used to be without the if
  if(requestState === 'FAILURE'){
    return {
      ...state,
      // Store errorMessage
      // e.g. stores errorMessage when receiving GET_TODOS_FAILURE
      //      else clear errorMessage when receiving GET_TODOS_REQUEST
      // [requestName]: requestState === 'FAILURE' ? action.error_type : '',
      [requestName]: requestState === 'FAILURE' ? action.error_type : '',
    };
  } else {
    return {...state};
  }
};
